import React from "react";

interface Props {
  color?: "neutral-black-20" | "white" | "primary-10" | "primary";
  className?: string;
  children: React.ReactNode;
  hasBorder?: boolean;
  hasShadowInstead?: boolean;
}

const ColorMapper = {
  "neutral-black-20": "bg-neutral-black-20",
  "primary-10": "bg-primary-10",
  primary: "bg-primary",
  white: "bg-white",
};

export const Card: React.FC<Props> = ({
  children,
  color = "white",
  className = "",
  hasBorder = false,
  hasShadowInstead = false,
}) => {
  return (
    <div
      className={`rounded-2xl ${hasBorder ? "border-[1px]" : ""} ${hasShadowInstead ? "shadow-cardAnalytics" : ""} border-neutral-black-30 ${ColorMapper[color]} ${className}`}
    >
      {children}
    </div>
  );
};
