import { ConnectButton } from "@rainbow-me/rainbowkit";
import { FlexRow } from "../../../flexRow/FlexRow";
import { Typography } from "../../../text/Typography/Typography";

export interface RainbowKitCustomProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "white";
}

const variantClasses = {
  primary:
    "w-full flex justify-center items-center h-9 px-2 py-4 bg-primary text-white rounded-md text-body-small-bold",
  white:
    "flex w-[138px] h-9 px-4 py-2 bg-white text-primary rounded-md text-body-small-bold",
};

export const RainbowKitCustom: React.FC<RainbowKitCustomProps> = ({
  variant = "primary",
}) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        const displayAddressSidebar = account
          ? `${account.address.slice(0, 21)}...${account.address.slice(-4)}`
          : "";
        return (
          <div
            className="w-full"
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    className={
                      variantClasses[variant] + " whitespace-nowrap text-center"
                    }
                    onClick={openConnectModal}
                  >
                    Connect wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button color="error" onClick={openChainModal}>
                    Switch To Mainnet
                  </button>
                );
              }
              return (
                <button className="w-full" onClick={openAccountModal}>
                  <FlexRow className="gap-1 justify-between h-9 border items-center rounded-full p-1 pl-2 bg-white">
                    <Typography type="caption-regular">
                      {displayAddressSidebar}
                    </Typography>
                    <div className="w-6 h-6 rounded-full bg-secondary"></div>
                  </FlexRow>
                </button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
