import { useState, useEffect, useCallback } from "react";
import { usePublicClient } from "wagmi";
import { Address, formatUnits, parseAbi } from "viem";
import { contractAddresses } from "@meta";
import { DisplayTokensOptions, displayTokens } from "@utils";
import { useGetPriceByAddress } from "../price-feed-aggregator/useGetPriceByAddress";
import { Token } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";

const ABI = parseAbi([
  "function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)",
]);

export const useSpotPrice = (
  tokenAddress: Address,
  secondTokenAddress = contractAddresses.WETH,
  config?: DisplayTokensOptions
) => {
  const publicClient = usePublicClient();
  const { originalPrice } = useGetPriceByAddress(secondTokenAddress);
  const [spotPrice, setSpotPrice] = useState("0");
  const [spotDisplayPrice, setSpotDisplayPrice] = useState("");
  const [reserve0, setReserve0] = useState(0n);
  const [reserve1, setReserve1] = useState(0n);
  const [isFetched, setIsFetched] = useState<boolean | undefined>(false);
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 700;

  const fetchSpotPrice = useCallback(async () => {
    let attempts = 0;
    setIsFetched(false);
    setError("");

    while (attempts < MAX_RETRIES) {
      try {
        const token = new Token(1, tokenAddress, 18);
        const token1 = new Token(1, secondTokenAddress, 18);
        const pairAddress = Pair.getAddress(token1, token);

        const reserves = await publicClient.readContract({
          address: pairAddress as Address,
          abi: ABI,
          functionName: "getReserves",
        });

        const calculatedSpotPrice =
          (reserves[1] * originalPrice!) / reserves[0];

        setReserve0(reserves[0]);
        setReserve1(reserves[1]);
        setSpotPrice(formatUnits(calculatedSpotPrice, 8));
        setSpotDisplayPrice(
          displayTokens(calculatedSpotPrice, {
            decimals: 8,

            ...config,
          })
        );
        setIsFetched(true);
        return; // Exit loop on success
      } catch (err) {
        attempts += 1;
        if (attempts >= MAX_RETRIES) {
          setError("Failed to fetch spot price after multiple attempts.");
          setIsError(true);
          setSpotDisplayPrice("n/a");
          setIsFetched(undefined);
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      }
    }
  }, [tokenAddress, secondTokenAddress, publicClient, originalPrice, config]);

  useEffect(() => {
    if (originalPrice !== null && originalPrice !== undefined) {
      fetchSpotPrice();
    }
  }, [originalPrice, fetchSpotPrice]);

  return {
    spotPrice,
    viewValue: spotDisplayPrice,
    isFetched,
    error,
    reserve0,
    reserve1,
    isError,
  };
};
