import { formatUnits } from 'viem';

export const formatUnitsToMaxValue = (value?: bigint, decimals = 18) => {
  const formatted = formatUnits(value || 0n, decimals);
  return formatted;
};

export const formatNumberToMaxValue = (value?: number) => {
  return String(value || 0);
};
