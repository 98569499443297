import {
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  TransactionComplete,
  TransferMoney,
  useNotificationContext,
  useWingsContractWrite,
  Button,
} from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */
import ChiEth from "@assets/tokens/chiEth.svg";
import UscEth from "@assets/tokens/uscEth.svg";
import VeChi from "@assets/tokens/veChi.svg";
import stETHIcon from "@assets/tokens/stEth.svg";
import { useGetStakedEthRewards } from "../../../../../state/rewards/useGetStakedEthRewards";
import {
  contractAddresses,
  RewardControllerAbi,
  StakingManagerAbi,
} from "@meta";
import { formatMoney } from "@utils";
import { useStepState } from "../../../../../state/common/useStepState.ui";
import { useSpotPrice } from "../../../../../state/usc/useSpotPrice";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { useStEthRewards } from "../../../../../state/chi/useStEthRewards";

export const StEthTooltipContent = () => {
  const rewardToken = contractAddresses.stETH;

  const {
    chiEthData: stakedEthChiEthRewards,
    uscEthData: stakedEthUscEthRewards,
    sum: stakedEthSum,
  } = useGetStakedEthRewards();

  const { address: userAddress } = useAccount();
  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps } = useStepState(
    ["Approve", "Claim"],
    1
  );

  const { spotPrice: stEthPrice, isFetched: isStEthPriceFetched } =
    useSpotPrice(contractAddresses.stETH);

  const dollarSum = formatMoney(Number(stakedEthSum) * Number(stEthPrice), {});

  const { totalStETHRewardsDSPL, writeContractAsync } = useStEthRewards();

  const { writeContractAsync: claimAsync } = useWingsContractWrite();

  const loadingNotif = (currentStep: number, lpToken: Address) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={stETHIcon} width={22} height={22} />}
                  text={formatMoney(
                    lpToken === contractAddresses.CHI_ETH_LP
                      ? stakedEthChiEthRewards.formattedChiEthData
                      : stakedEthUscEthRewards.formattedUscEthData
                  )}
                  symbol="stETH"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const loadingNotifStEth = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={stETHIcon} width={22} height={22} />}
                  text={totalStETHRewardsDSPL.viewValue}
                  symbol="stETH"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleClaimStEthAsync = async () => {
    loadingNotifStEth(currentStep);

    await writeContractAsync(
      {
        abi: RewardControllerAbi,
        address: contractAddresses.RewardController,
        functionName: "claimStEth",
      },
      {
        onSuccess: (txHash?: Address) => {
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={stETHIcon} width={22} height={22} />
                        }
                        text={formatMoney(totalStETHRewardsDSPL.viewValue, {})}
                        symbol="stETH"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  const handleClaimAsync = async (lpToken: Address) => {
    loadingNotif(currentStep, lpToken);

    if (!userAddress) return;

    await claimAsync(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "claimRewardsForToken",
        args: [lpToken, rewardToken, userAddress],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={stETHIcon} width={22} height={22} />
                        }
                        text={formatMoney(
                          lpToken === contractAddresses.CHI_ETH_LP
                            ? stakedEthChiEthRewards.formattedChiEthData
                            : stakedEthUscEthRewards.formattedUscEthData
                        )}
                        symbol="stETH"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  return (
    <FlexCol className="w-[200px] gap-4 p-3">
      <FlexRow className="gap-2">
        <SVGWrapper src={stETHIcon} width={20} height={20} />
        <Typography type="body-small-medium">stETH Rewards</Typography>
      </FlexRow>
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Staking
        </Typography>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(
              stakedEthChiEthRewards.formattedChiEthData,
              {}
            )}
            symbol="stETH"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            onClick={() => handleClaimAsync(contractAddresses.CHI_ETH_LP)}
            disabled={Number(stakedEthChiEthRewards.formattedChiEthData) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={ChiEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            CHI/ETH LP
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(
              stakedEthUscEthRewards.formattedUscEthData,
              {}
            )}
            symbol="stETH"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            onClick={() => handleClaimAsync(contractAddresses.USC_ETH_LP)}
            disabled={Number(stakedEthUscEthRewards.formattedUscEthData) === 0}
          >
            Claim
          </Button>
        </FlexRow>

        <FlexRow className="gap-2">
          <SVGWrapper src={UscEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            USC/ETH LP
          </Typography>
        </FlexRow>
      </FlexCol>
      <FlexRow className="w-full h-[1px] bg-neutral-black-30" />

      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Boosting
        </Typography>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            {...totalStETHRewardsDSPL}
            symbol="stETH"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            onClick={async () => {
              await handleClaimStEthAsync();
            }}
            disabled={Number(totalStETHRewardsDSPL.viewValue) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={VeChi} width={18} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            veCHI
          </Typography>
        </FlexRow>
      </FlexCol>
      <FlexCol className="w-full h-[1px] bg-neutral-black-30" />
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Total rewards
        </Typography>
        <DisplayMoney
          viewValue={dollarSum}
          isFetched={isStEthPriceFetched}
          className="text-body-medium-medium"
        />
      </FlexCol>
    </FlexCol>
  );
};
