import {
  Card,
  DisplayNumber,
  FlexCol,
  FlexRow,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  SVGWrapper,
  TransactionComplete,
  TransferMoney,
  Typography,
  useNotificationContext,
} from "@shared";
import stETHIcon from "@assets/tokens/stEth.svg";

/* ----------- */
/*    Icons    */
/* ----------- */
import StETH from "@assets/tokens/stEth.svg";
import { useStEthRewards } from "../../../state/chi/useStEthRewards";
import { contractAddresses, RewardControllerAbi } from "../../../../meta";
import { Address } from "viem";
import { useStepState } from "../../../state/common/useStepState.ui";

export const YourRewards = () => {
  const { showNotification } = useNotificationContext();

  const { steps } = useStepState(["Claim"], 1);

  const { totalStETHRewardsDSPL, writeContractAsync, isClaiming } =
    useStEthRewards();

  const loadingNotifStEth = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={stETHIcon} width={22} height={22} />}
                  text={totalStETHRewardsDSPL.viewValue}
                  symbol="stETH"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  return (
    <Card hasBorder className="p-4 ">
      <FlexCol className="gap-5">
        <Typography type="body-medium-bold">Your Rewards</Typography>
        <FlexCol className="gap-3">
          <FlexRow className="justify-between">
            <FlexRow className="gap-2 items-center">
              <SVGWrapper src={StETH} width={20} height={20} />
              <Typography type="body-small-regular">stETH</Typography>
              <button
                onClick={async () => {
                  loadingNotifStEth(1);
                  await writeContractAsync(
                    {
                      abi: RewardControllerAbi,
                      address: contractAddresses.RewardController,
                      functionName: "claimStEth",
                    },
                    {
                      onSuccess: (txHash: Address) => {
                        showNotification({
                          status: "success",
                          txHash,
                          content: (
                            <StandardNotifBody
                              headerComponent={<TransactionComplete />}
                              transferComponent={
                                <SingleComponent
                                  component={
                                    <TransferMoney
                                      icon={
                                        <SVGWrapper
                                          src={stETHIcon}
                                          width={22}
                                          height={22}
                                        />
                                      }
                                      text={totalStETHRewardsDSPL.viewValue}
                                      symbol="stETH"
                                    />
                                  }
                                />
                              }
                            />
                          ),
                        });
                      },
                    }
                  );
                }}
                disabled={isClaiming}
                className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md"
              >
                Claim
              </button>
            </FlexRow>
            <DisplayNumber
              {...totalStETHRewardsDSPL}
              typography="body-small-medium"
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
