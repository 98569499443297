import cn from "classnames";
import React, {
  CSSProperties,
  ChangeEventHandler,
  InputHTMLAttributes,
} from "react";

import styles from "./InputSliderField.module.css";
import { FlexRow } from "../../../flexRow/FlexRow";
import { Typography } from "../../../text/Typography/Typography";

interface InputSliderFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  min: number;
  max: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[] | undefined;
  label?: string;
  valueLabel?: string;
  tooltipText?: string;
}

interface CustomStyle extends CSSProperties {
  "--slider-percentage": string;
}

export const InputSliderField = React.forwardRef<
  HTMLInputElement,
  InputSliderFieldProps
>(
  (
    { min, max, value, label, valueLabel, tooltipText, onChange, ...rest },
    ref
  ) => {
    const classes = cn(styles.sliderContainer);
    const sliderClasses = cn(styles.sliderInput);

    const sliderPercentage = `${((Number(value) - min) / (max - min)) * 100}%`;
    const sliderStyle: CustomStyle = {
      "--slider-percentage": sliderPercentage,
    };

    return (
      <div className={classes}>
        <div className="flex flex-row items-center justify-between">
          <FlexRow className="gap-1 items-center">
            <Typography type="body-small-bold">{label}</Typography>
            {/* {tooltipText && <PolygonTooltip tooltipText={tooltipText} />} */}
          </FlexRow>
          <div className="flex flex-row items-center gap-3">
            {/* <Typography type="body-small-bold">
              {value} {valueLabel}
            </Typography> */}
          </div>
        </div>
        <input
          ref={ref}
          className={sliderClasses}
          type="range"
          title={label || ""}
          style={sliderStyle as React.CSSProperties}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          {...rest}
        />
      </div>
    );
  }
);

InputSliderField.displayName = "InputSliderField";
