import { Address, formatUnits } from "viem";
import { useBoostedTokenBalance } from "../../../../../state/positions/useBoostedUserPositions";
import { displayTokens, formatMoney } from "../../../../../../utils";

export const useSumOfExpiredPositions = (tokenAddress?: Address, tokenPriceInDollars?: number) => {
  const { positions, isFetched, queryKey } = useBoostedTokenBalance(tokenAddress);

  let sumOfExpiredPositions = 0n;
  let sumOfExpiredPositionsInDollars = 0n;
  let viewValue = '';
  let dollarViewValue = '';

  if (positions) {
    sumOfExpiredPositions = positions
      .filter(position => !position.isActive)
      .reduce((sum, position) => sum + BigInt(position.amount.originalValue), 0n);

    const sumOfExpiredPositionsInDollarsNum = Number(formatUnits(sumOfExpiredPositions, 18)) * Number(tokenPriceInDollars);
    viewValue = displayTokens(sumOfExpiredPositions, {});
    dollarViewValue = formatMoney(sumOfExpiredPositionsInDollarsNum);
  }

  return {
    sumOfExpiredPositions,
    sumOfExpiredPositionsInDollars,
    viewValue,
    dollarViewValue,
    isFetched,
    queryKey
  };
};

