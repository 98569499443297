import { contractAddresses, LockingManagerAbi } from "@meta";
import { displayTokens } from "@utils";
import { useReadContract } from "wagmi";
import { useGetLPPriceByAddress } from "../data-provider/useGetLPPriceByAddress";

export const useFetchBoostedUscEthTVL = () => {
  const { data: tvl, isFetched } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "totalLockedAmount",
    address: contractAddresses.USC_ETH_LP_Locking,
    args: [],
  });

  const { formattedPrice } = useGetLPPriceByAddress("USC_ETH_LP");

  return {
    viewValue: displayTokens(tvl, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    isFetched,
  };
};
