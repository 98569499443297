import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons
import BoostedWstUsc from "@assets/tokens/boostedWstUsc.svg";

export const FirstElementBoostedWstUSC = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow items-center">
        <SVGWrapper src={BoostedWstUsc} width={24} height={29} />

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular" className="whitespace-nowrap">
              Boosted wstUSC
            </Typography>
          </FlexRow>
          <Typography
            className="text-[#949494] truncate"
            type="body-small-regular"
          >
            Wrapped Staked USC
          </Typography>
        </div>
      </div>
    </>
  );
};
