"use client";

import { useState, useCallback, useEffect, useMemo } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { Address } from "viem";
import { contractAddresses } from "../../../meta";

const tokenAbi = [
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function allowance(address owner, address spender) public view returns (uint256)",
];

const ALWAYS_APPROVE_MAX = true;

export const useERC20Approval = (
  tokenAddress: Address | undefined,
  spenderAddress: string,
  amount: bigint,
  chainId: number = 1
) => {
  const { address, isConnected } = useAccount();
  const [isApproved, setIsApproved] = useState(true);
  const [allowance, setAllowance] = useState<bigint>(0n);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const provider = useMemo(() => {
    if (typeof window === "undefined" || !window.ethereum) {
      console.error("Ethereum provider is not available");
      return undefined;
    }
    return new ethers.providers.Web3Provider((window as any)?.ethereum);
  }, [chainId]);

  const signer = useMemo(() => provider?.getSigner(), [provider]);

  const tokenContract = useMemo(() => {
    if (!tokenAddress || !signer) return undefined;
    return new ethers.Contract(tokenAddress, tokenAbi, signer);
  }, [tokenAddress, signer]);

  const checkAllowance = useCallback(async () => {
    if (!address || !tokenContract) return;
    try {
      const currentAllowance = await tokenContract.allowance(
        address,
        spenderAddress
      );

      setAllowance(currentAllowance);

      setIsApproved(ethers.BigNumber.from(currentAllowance).gte(amount));
    } catch (error: any) {
      setError(error);
    }
  }, [address, spenderAddress, amount, tokenContract]);

  const approveAsync = useCallback(
    async (
      amount: bigint | undefined,
      settings?: {
        onSuccess?: (txHash?: string) => Promise<void>;
        onError?: (error: any) => void;
        onSettled?: () => void;
      }
    ) => {
      if (!tokenAddress || !spenderAddress) return;

      setIsLoading(true);

      const amountToApprove = ALWAYS_APPROVE_MAX
        ? ethers.constants.MaxUint256
        : amount || ethers.BigNumber.from(0);

      try {
        const tx = await tokenContract?.approve(
          spenderAddress,
          amountToApprove
        );

        // Wait for the transaction to be mined
        const receipt = await tx.wait();

        setIsApproved(true);

        // Call the onSuccess callback with the transaction hash
        if (settings?.onSuccess) {
          await settings.onSuccess(receipt.transactionHash);
        }
      } catch (error) {
        console.error("Error approving token:", error);
        if (settings?.onError) {
          const message = getParsedError(error);
          console.log({ message });
          settings.onError(message);
        }
      } finally {
        setIsLoading(false);
        if (settings?.onSettled) {
          settings.onSettled();
        }
      }
    },
    [tokenAddress, spenderAddress, tokenContract]
  );

  useEffect(() => {
    if (isConnected && address && tokenContract) {
      checkAllowance();
    }
  }, [address, checkAllowance, isConnected, tokenContract]);

  useEffect(() => {
    checkAllowance();
  }, [isApproved]);

  if (spenderAddress === contractAddresses.ETH) {
    return { isApproved: true, isLoading, approveAsync, error };
  }

  return { isApproved, isLoading, approveAsync, error, allowance };
};

/**
 * @dev utility function to parse error
 * @param e - error object
 * @returns {string} parsed error string
 */
export const getParsedError = (e: any): string => {
  let message = e.message ?? "An unknown error occurred";

  if (e) {
    if (e.details) {
      message = e.details;
    } else if (e.shortMessage) {
      message = e.shortMessage;
    } else if (e.message) {
      message = e.message;
    } else if (e.name) {
      message = e.name;
    }
  }

  return message;
};
