import { FlexCol, FlexRow, ModalBody, SVGWrapper, Typography } from "@shared";
import { useState } from "react";

/* ----------- */
/*    Icons    */
/* ----------- */

import Shield from "@assets/layout/shield-tick.svg";
import Nethermind from "@assets/logos/nethermindSmall.svg";
import ABDK from "@assets/logos/abdkSmall.svg";
import ArrowUp from "@assets/layout/arrow-up-right.svg";

export const AuditedBy = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <FlexRow className="gap-2 card-bordered items-center p-2 rounded-lg">
      <SVGWrapper src={Shield} width={16} height={16} />
      <a onClick={toggleModal}>
        <Typography
          type="caption-regular"
          className="hover:underline cursor-pointer"
        >
          Audited by the Best
        </Typography>
      </a>
      {isModalOpen && (
        <ModalBody setModalOpen={setModalOpen} size="modal">
          <FlexCol className="gap-8 px-2 pb-2 mt-[-15px]">
            <Typography type="h2" className="text-button-selection-stroke">
              Secured by Top Tier Auditors
            </Typography>
            <FlexRow className="flex-col gap-6 lg:flex-row">
              <FlexCol className="w-1/2 gap-4">
                <FlexRow className="gap-4 items-center">
                  <SVGWrapper src={Nethermind} width={80} height={40} />
                  <Typography type="body-large-medium" className="">
                    Nethermind
                  </Typography>
                </FlexRow>
                <a
                  href="https://github.com/NethermindEth/PublicAuditReports/blob/main/NM0257-FINAL_CHI_PROTOCOL.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FlexRow className="gap-1 items-center">
                    <Typography
                      type="body-small-regular"
                      className="text-primary hover:underline"
                    >
                      Read the full report
                    </Typography>
                    <SVGWrapper
                      src={ArrowUp}
                      width={16}
                      height={16}
                      color="#0051A6"
                    />
                  </FlexRow>
                </a>
              </FlexCol>
              <FlexCol className="w-1/2 gap-4">
                <FlexRow className="gap-4 items-center">
                  <SVGWrapper src={ABDK} width={40} height={40} />
                  <Typography type="body-large-medium">ABDK</Typography>
                </FlexRow>
                <a
                  href="https://github.com/abdk-consulting/audits/blob/main/chi/ABDK_Chi_ChiProtocol_v_1_0.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FlexRow className="gap-1 items-center">
                    <Typography
                      type="body-small-regular"
                      className="text-primary hover:underline"
                    >
                      Read the full report
                    </Typography>
                    <SVGWrapper
                      src={ArrowUp}
                      width={16}
                      height={16}
                      color="#0051A6"
                    />
                  </FlexRow>
                </a>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        </ModalBody>
      )}
    </FlexRow>
  );
};
