import React from "react";

import { FlexRow } from "../flexRow/FlexRow";
import { DisplayTokenAmount } from "../display/DisplayTokenAmount";

/* ----------- */
/*    Icons    */
/* ----------- */
import Right from "../../../assets/layout/lineRight.svg";
import { SVGWrapper } from "../svgWrapper/SvgWrapper";

export const Transfer: React.FC<{
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
}> = ({ leftComponent, rightComponent }) => {
  return (
    <FlexRow className="items-center gap-3">
      {leftComponent}
      <SVGWrapper src={Right} width={50} />
      {rightComponent}
    </FlexRow>
  );
};

export const TransferMoney: React.FC<{
  icon: React.ReactNode;
  text: string;
  symbol: string;
}> = ({ icon, text, symbol }) => {
  return (
    <FlexRow className="items-center gap-[6.75px]">
      {icon}
      <DisplayTokenAmount
        viewValue={text}
        typography="chi-protocol-small-bold"
        symbol={symbol}
      />
    </FlexRow>
  );
};
