import { useForm } from "react-hook-form";
import { ReserveHolderAbi, contractAddresses } from "@meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "@shared";
import { useReadContract } from "wagmi";
import { formatUnits, parseUnits } from "viem";

export const SetTwapTollerance: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const {
    writeContractAsync: setSwapTolerance,
    isPending: isSettingTolerance,
  } = useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setSwapTolerance(
      {
        abi: ReserveHolderAbi,
        address: contractAddresses.ReserveHolder,
        functionName: "setSwapTolerance",
        args: [parseUnits(data.swapTolerance, 2)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Swap tolerance set successfully</Typography>,
          });
        },
      }
    );
  };

  const { data: swapTolerance } = useReadContract({
    abi: ReserveHolderAbi,
    address: contractAddresses.ReserveHolder,
    functionName: "swapTolerance",
  });

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>
            Current SwapTolerance:{" "}
            <strong> {formatUnits(swapTolerance || 0n, 2)}</strong>
          </Typography>
          <Typography>Reserve Holder / setSwapTolerance /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("swapTolerance")}
              placeholder="Swap Tolerance"
              type="text"
              step="0.01"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSettingTolerance}>
              Set Swap Tolerance
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
