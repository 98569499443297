import { useForm } from "react-hook-form";
import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
  DisplayValue,
} from "@shared";
import { formatUnits, parseUnits } from "viem";

export const SetPegPriceToleranceAbs: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: setPegPriceToleranceAbs, isPending: isSetting } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setPegPriceToleranceAbs(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "setPegPriceToleranceAbs",
        args: [parseUnits(data.pegPriceToleranceAbs, 8)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Peg success</Typography>,
          });
        },
      }
    );
  };

  const { data: currentTolerance, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "pegPriceToleranceAbs",
  });

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>
            Current Peg Price Tolerance Abs:{" "}
            <DisplayValue
              viewValue={formatUnits(currentTolerance || 0n, 8)}
              {...rest}
            />
          </Typography>
          <Typography>Arbitrage / setPegPriceToleranceAbs /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("pegPriceToleranceAbs")}
              placeholder="Peg Price Tolerance Abs (8 decimals)"
              type="text"
              step="0.00000001"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSetting}>
              Set Peg Price Tolerance Abs
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
