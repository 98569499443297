"use client";

import { useMemo } from "react";
import { displayTokens } from "@utils";
import { Address, erc20Abi } from "viem";
import { contractAddresses } from "@meta";
import { useReadContract } from "wagmi";

/**
 * Use this custom hook to get the contract balance from the ERC20(contractAddress).
 *
 * @param token - The token to query for the balance.
 * @param contractAddress - The contractAddress to query for the balance.
 * @returns An object containing price details or undefined if data is not available.
 */
export const useGetContractBalanceByToken = (
  token: "USC" | "CHI" | "WETH" | "stETH" | "veCHI" | "OCHI",
  contractAddress: Address
) => {
  const { data, isFetched, refetch } = useReadContract({
    abi: erc20Abi,
    address: contractAddresses[token],
    functionName: "balanceOf",
    args: [contractAddress],
  });

  const displayData = useMemo(() => {
    return {
      value: displayTokens(data, {}),
      isFetched: isFetched,
    };
  }, [data, token, isFetched]);

  if (data === undefined) {
    return {
      displayData,
      balance: undefined,
      isFetched: undefined,
      refetch,
    };
  }

  return {
    displayData,
    balance: data,
    isFetched: isFetched,
    refetch,
  };
};
