import { useAccount, useReadContract } from "wagmi";
import { formatUnits, etherUnits } from "viem";
import { contractAddresses, LockingManagerAbi, WstUSCAbi } from "@meta";
import { displayTokens } from "@utils";
import { useSpotPrice } from "../usc/useSpotPrice";
import { useGetLPPriceByAddress } from "../data-provider/useGetLPPriceByAddress";
import { useGetUserStakedBalance } from "../stakingManager/useGetUserStakedBalance";
import { useGetWalletBalanceByToken } from "../erc20/useGetWalletBalanceByToken";
import { useFetchWstUscPrice } from "../wstUsc/useFetchWstUscPrice";

type BalanceData = { amount: bigint }[];

export const useTotalUserPositions = () => {
  const { address } = useAccount();

  const { balance: stUscBalance } = useGetWalletBalanceByToken(
    contractAddresses.USCStaking
  );
  const { balance: uscEthBalance } = useGetUserStakedBalance(
    contractAddresses.USC_ETH_LP
  );
  const { balance: chiEthBalance } = useGetUserStakedBalance(
    contractAddresses.CHI_ETH_LP
  );

  const { data: wstUscBalance } = useReadContract({
    abi: WstUSCAbi,
    functionName: "balanceOf",
    address: contractAddresses.WstUSC,
    args: [address!],
  });

  const { data: boostedUscEthBalance } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "userLockingPositions",
    address: contractAddresses.USC_ETH_LP_Locking,
    args: [address!],
  });

  const { data: boostedChiEthBalance } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "userLockingPositions",
    address: contractAddresses.CHI_ETH_LP_Locking,
    args: [address!],
  });

  const { data: boostedWstUscBalance, isFetched } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "userLockingPositions",
    address: contractAddresses.WST_USC_Locking,
    args: [address!],
  });

  const stUscPrice = useSpotPrice(contractAddresses.USC);
  const uscEthPrice = useGetLPPriceByAddress("USC_ETH_LP");
  const chiEthPrice = useGetLPPriceByAddress("CHI_ETH_LP");
  const wstUscPrice = useFetchWstUscPrice();

  const formatBalance = (balance: bigint | undefined) =>
    formatUnits(balance || 0n, etherUnits.wei);
  const stUscFormattedBalance = formatBalance(stUscBalance);
  const wstUscFormattedBalance = formatBalance(wstUscBalance);
  const uscEthFormattedBalance = formatBalance(uscEthBalance);
  const chiEthFormattedBalance = formatBalance(chiEthBalance);

  const boostedBalanceTotal = (balanceData: BalanceData | undefined): bigint =>
    balanceData?.reduce(
      (acc: bigint, position) => acc + position.amount,
      BigInt(0)
    ) || BigInt(0);

  const formattedBoostedBalances = {
    uscEth: formatBalance(
      boostedBalanceTotal(
        boostedUscEthBalance?.map(({ amount }) => ({ amount }))
      )
    ),
    chiEth: formatBalance(
      boostedBalanceTotal(
        boostedChiEthBalance?.map(({ amount }) => ({ amount }))
      )
    ),
    wstUsc: formatBalance(
      boostedBalanceTotal(
        boostedWstUscBalance?.map(({ amount }) => ({ amount }))
      )
    ),
  };

  const formattedBalances = {
    stUsc: stUscFormattedBalance,
    wstUsc: wstUscFormattedBalance,
    uscEth: uscEthFormattedBalance,
    chiEth: chiEthFormattedBalance,
    boostedUscEth: formattedBoostedBalances.uscEth,
    boostedChiEth: formattedBoostedBalances.chiEth,
    boostedWstUsc: formattedBoostedBalances.wstUsc,
  };

  const dollarValues = {
    stUsc: Number(stUscPrice.spotPrice || 0) * Number(stUscFormattedBalance),
    uscEth: (uscEthPrice?.formattedPrice || 0) * Number(uscEthFormattedBalance),
    chiEth: (chiEthPrice?.formattedPrice || 0) * Number(chiEthFormattedBalance),
    wstUsc: (wstUscPrice.formattedPrice || 0) * Number(wstUscFormattedBalance),
    boostedUscEth:
      (uscEthPrice?.formattedPrice || 0) *
      Number(formattedBoostedBalances.uscEth),
    boostedChiEth:
      (chiEthPrice?.formattedPrice || 0) *
      Number(formattedBoostedBalances.chiEth),
    boostedWstUsc:
      (wstUscPrice.formattedPrice || 0) *
      Number(formattedBoostedBalances.wstUsc),
  };

  const portfolioValue = Object.values(dollarValues).reduce(
    (acc, value) => acc + value,
    0
  );
  const hasBoostedTokens = Object.values(formattedBoostedBalances).some(
    (val) => Number(val) > 0
  );
  const hasPositions =
    [
      stUscFormattedBalance,
      wstUscFormattedBalance,
      uscEthFormattedBalance,
      chiEthFormattedBalance,
    ].some((balance) => Number(balance) > 0) || hasBoostedTokens;

  return {
    formattedBalances,
    dollarValues,
    portfolioValue,
    hasBoostedTokens,
    hasPositions,
    displayTokens,
    isFetched,
  };
};
