import { FlexCol, TabProvider, FlexRow, TabContent } from "../../../../shared";
import { FormTabButton } from "../../../components/tab/FormTabButton";
import { CHITab } from "./CHITab";
import { ReservesTab } from "./ReservesTab";
import { RevenueTab } from "./RevenueTab";
import { SolvencyTab } from "./SolvencyTab";
import { USCTab } from "./USCTab/USCTab";

type Tabs = "Reserves" | "Solvency" | "USC" | "CHI" | "Revenue";

export function Main() {
  return (
    <TabProvider<Tabs> defaultTab={"Reserves"} overrideUrlSlug="formTab">
      <FlexCol className="w-full gap-8 ">
        {/* Tab Buttons */}
        <FlexRow className="bg-white w-full border-neutral-black-20 border-b-2 ">
          <FormTabButton<Tabs> tab="Reserves" className="flex-1 py-2">
            Reserves
          </FormTabButton>
          <FormTabButton<Tabs> tab="Solvency" className="flex-1 py-2">
            Solvency
          </FormTabButton>
          <FormTabButton<Tabs> tab="USC" className="flex-1 py-2">
            USC
          </FormTabButton>
          <FormTabButton<Tabs> tab="CHI" className="flex-1 py-2">
            CHI
          </FormTabButton>
          <FormTabButton tab="Revenue" className="flex-1 py-2">
            Revenue
          </FormTabButton>
        </FlexRow>

        <TabContent<Tabs> tab="Reserves">
          <ReservesTab />
        </TabContent>

        <TabContent<Tabs> tab="Solvency">
          <SolvencyTab />
        </TabContent>

        <TabContent<Tabs> tab="USC">
          <USCTab />
        </TabContent>

        <TabContent<Tabs> tab="CHI">
          <CHITab />
        </TabContent>
        <TabContent<Tabs> tab="Revenue">
          <RevenueTab />
        </TabContent>
      </FlexCol>
    </TabProvider>
  );
}
