import { useMemo } from "react";
import { useAccount, useReadContract } from "wagmi";
import { LockingManagerAbi } from "../../../meta";
import { displayTokens, DisplayTokensOptions } from "../../../utils";
import { Address } from "viem";

export const useLockGetUserLockingRewards = (
  contractAddress?: Address,
  options?: DisplayTokensOptions
) => {
  const { address: userAddress } = useAccount();

  const { data, ...rest } = useReadContract({
    address: contractAddress,
    abi: LockingManagerAbi,
    functionName: "getUserRewards",
    args: [userAddress!],
    query: {
      enabled: !!userAddress,
    },
  });

  const displayData = useMemo(() => {
    return {
      value: displayTokens(data, {
        ...options,
      }),
      ...rest,
    };
  }, [data, contractAddress, rest.isFetched, rest.isLoading]);

  if (data === undefined) {
    return {
      displayData,
      viewValue: undefined,
      balance: undefined,
      ...rest,
    };
  }

  return {
    displayData,
    viewValue: displayData.value,
    balance: data,
    ...rest,
  };
};
