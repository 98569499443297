import { useReadContract } from "wagmi";
import { contractAddresses, CHILockingAbi } from "@meta";
import { useAccount } from "wagmi";
import { useMemo } from "react";
import { formatUnits } from "viem";

export const useYourBoostedChi = () => {
  const { address } = useAccount();

  // Fetch user's locked positions
  const { data: lockedPositions, isFetched: isLockedPositionsFetched } =
    useReadContract({
      abi: CHILockingAbi,
      address: contractAddresses.ChiLocking,
      functionName: "getAllLockedPositions",
      args: [address!],
    });

  // Calculate total boosted CHI from all positions
  const totalBoostedChi = useMemo(() => {
    if (lockedPositions && isLockedPositionsFetched) {
      return lockedPositions.reduce((total: number, position: any) => {
        return total + Number(formatUnits(position.position.amount, 18));
      }, 0);
    }
    return "0";
  }, [lockedPositions, isLockedPositionsFetched]);

  return {
    totalBoostedChi,
    isLoaded: isLockedPositionsFetched,
  };
};
