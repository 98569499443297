import { contractAddresses, LockingManagerAbi } from "@meta";
import { displayTokens } from "@utils";
import { useReadContract } from "wagmi";
import { useFetchWstUscPrice } from "../wstUsc/useFetchWstUscPrice";

export const useFetchBoostedWstUscTVL = () => {
  const { data: tvl, isFetched } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "totalLockedAmount",
    address: contractAddresses.WST_USC_Locking,
    args: [],
  });

  const { formattedPrice } = useFetchWstUscPrice();

  return {
    viewValue: displayTokens(tvl, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    isFetched,
  };
};
