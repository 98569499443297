import { PageContent } from "../components/PageContent/PageContent";
import { DisplayPegPriceToleranceAbs } from "./DisplayPegPriceToleranceAbs";
import { DisplayPriceTolerance } from "./DisplayPriceTolerance";
import { DisplayTotalMintedUsc } from "./DisplayTotalMintedUsc";
import { ExecuteArbitrage } from "./ExecuteArbitrage";
import { RewardUSC } from "./RewardUSC";
import { SetBurnPause } from "./SetBurnPause";
import { SetChiPriceTolerance } from "./SetChiPriceTolerance";
import { SetMaxMintBurnPriceDiff } from "./SetMaxMintBurnPriceDiff";
import { SetMaxMintBurnReserveTolerance } from "./SetMaxMintBurnReserveTolerance";
import { SetMintBurnFee } from "./SetMintBurnFee";
import { SetMintPause } from "./SetMintPause";
import { SetPegPriceToleranceAbs } from "./SetPegPriceToleranceAbs";
import { SetPriceTolerance } from "./SetPriceTolerance";
import { SetReserveBurnTxLimit } from "./SetReserveBurnTxLimit";
import { SetReserveMintTxLimit } from "./SetReserveMintTxLimit";
import { UpdateArbitrager } from "./UpdateArbitrager";

export const ArbitrageAdminPage = () => {
  return (
    <PageContent className="flex flex-col gap-4">
      <ExecuteArbitrage />
      <DisplayPegPriceToleranceAbs />
      <DisplayPriceTolerance />
      <DisplayTotalMintedUsc />
      <RewardUSC />
      <SetPegPriceToleranceAbs />
      <SetPriceTolerance />
      <UpdateArbitrager />
      <SetBurnPause />
      <SetMintPause />
      <SetChiPriceTolerance />
      <SetMaxMintBurnPriceDiff />
      <SetMaxMintBurnReserveTolerance />
      <SetMintBurnFee />
      <SetReserveMintTxLimit />
      <SetReserveBurnTxLimit />
    </PageContent>
  );
};
