import { FlexCol, FlexRow, Typography, DisplayMoney } from "@shared";
import { useTotalUscSupply } from "../../state/usc/useTotalUscSupply";
import { useSpotPrice } from "../../state/usc/useSpotPrice";
import { contractAddresses } from "../../../meta";
import { useReserveValue } from "../../state/reserveholder/useOverviewData";

export const UscMarketCapBanner = () => {
  const totalSupply = useTotalUscSupply();
  const reserveValue = useReserveValue();
  const uscSpotPrice = useSpotPrice(contractAddresses.USC);

  return (
    <>
      <FlexCol className="w-full rounded-xl bg-primary-10">
        <FlexRow className="w-full px-4 py-3 border-b-[1px] items-center justify-between">
          <Typography type="body-small-medium">USC Price</Typography>
          <DisplayMoney
            {...uscSpotPrice}
            symbolPosition="before"
            typography="body-small-medium"
          />
        </FlexRow>
        <FlexRow className="w-full px-4 py-3 border-b-[1px] items-center justify-between">
          <Typography type="body-small-medium">Reserves Value</Typography>
          <DisplayMoney {...reserveValue} typography="body-small-medium" />
        </FlexRow>
        <FlexRow className="w-full px-4 py-3 items-center justify-between">
          <Typography type="body-small-medium">USC Market Cap</Typography>
          <DisplayMoney {...totalSupply} typography="body-small-medium" />
        </FlexRow>
      </FlexCol>
    </>
  );
};
