import {
  Card,
  Typography,
  FlexRow,
  Divider,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  SVGWrapper,
  TransactionComplete,
  TransferMoney,
  useNotificationContext,
  useWingsContractWrite,
} from "@shared";
import {
  BoostingTableRowDesktopProps,
  StateComponent,
} from "../boostingTable/BoostingTableRowDesktop";
import { LockingManagerAbi } from "@meta";
import { useStepState } from "../../../../state/common/useStepState.ui";
import { Address } from "viem";

import Chi from "@assets/tokens/chi.svg";

export const MobileBoostingTableRowDesktop: React.FC<
  BoostingTableRowDesktopProps
> = ({
  isActive,
  iconSrc,
  tokenName,
  amount,
  unlockDate,
  chiReward,
  tokenAddress,
  index,
}) => {
  const { writeContractAsync: lockedClaimAsync } = useWingsContractWrite();

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps } = useStepState(
    ["Approve", "Claim"],
    1
  );

  const loadingNotifLocking = (currentStep: number, rewardDetails: string) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={Chi} width={22} height={22} />}
                  text={rewardDetails}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleClaimAsync = async (lpToken: Address, index: number) => {
    loadingNotifLocking(currentStep, chiReward);

    await lockedClaimAsync(
      {
        abi: LockingManagerAbi,
        address: lpToken,
        functionName: "claimRewards",
        args: [BigInt(index)],
      },
      {
        onSuccess: (txHash) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={<SVGWrapper src={Chi} width={22} height={22} />}
                        text={chiReward}
                        symbol="CHI"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };
  return (
    <Card hasBorder className="gap-2 p-3">
      <Typography type="body-medium-bold">Boosting</Typography>
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Position
        </Typography>
        <StateComponent isActive={isActive} />
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Boosted Token
        </Typography>

        <FlexRow className="gap-2">
          <SVGWrapper src={iconSrc} height={20} />
          <Typography type="body-small-regular">{tokenName}</Typography>
        </FlexRow>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Amount
        </Typography>
        <Typography type="body-small-regular">{amount}</Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          CHI Reward
        </Typography>
        <Typography type="body-small-regular">{chiReward}</Typography>
      </FlexRow>
      <Divider />

      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Unlock Date
        </Typography>
        <Typography type="body-small-regular">{unlockDate}</Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Action
        </Typography>
        <Typography type="body-small-regular">
          <button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md"
            onClick={() => handleClaimAsync(tokenAddress, index)}
            disabled={Number(amount) === 0}
          >
            Claim
          </button>
        </Typography>
      </FlexRow>
    </Card>
  );
};
