import {
  Button,
  Card,
  FlexCol,
  MyFormProvider,
  useWingsContractWrite,
} from "@shared";
import { useForm } from "react-hook-form";
import { contractAddresses, LockingManagerAbi } from "@meta";

interface FormData {
  amount: string;
}

export const UpdateEpochChiEth = () => {
  const methods = useForm<FormData>();
  const { handleSubmit } = methods;

  const { writeContractAsync, isPending: isStaking } = useWingsContractWrite();

  const onSubmit = async () => {
    await writeContractAsync({
      abi: LockingManagerAbi,
      address: contractAddresses.CHI_ETH_LP_Locking,
      functionName: "updateEpoch",
    });
  };
  return (
    <Card>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="gap-4">
          <Button loading={isStaking} type="submit">
            Update epoch CHI_ETH_LP_Locking
          </Button>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
