import { DisplayValue, SVGWrapper } from "@shared";
import { StateComponent } from "../CHIETHRow";
import { useQuery } from "@tanstack/react-query";
import { formatMoney } from "@utils";

/* ----------- */
/*    Icons    */
/* ----------- */
import Arrakis from "@assets/tokens/arrakis.svg";
import USC from "@assets/tokens/usc.svg";

const fetchUSCUSDCVaultData = async () => {
  const response = await fetch(
    "https://indexer.api.arrakis.finance/api/vault/single/0xCf1f05A82Ced1C189A7E8419A8437E0089b57c31"
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return {
    tvl: data.vault[0].tvl,
    rewardApr: data.vault[0].gauge.rewardApr,
  };
};

export const ArrakisTableHeader = () => {
  const { data, isFetched } = useQuery({
    queryKey: ["vaultData"],
    queryFn: fetchUSCUSDCVaultData,
  });

  const valueDSPL = formatMoney(data?.tvl, {});
  const totalApr = formatMoney(data?.rewardApr);
  return (
    <div className="flex flex-row border border-solid px-4 py-3 border-[#EEE] bg-white rounded-2xl">
      <div className="w-full max-w-[200px]">
        <div className="flex flex-row gap-2">
          <SVGWrapper src={Arrakis} width={28} height={28} />
          <LocalText value="Arrakis" />
        </div>
      </div>
      <div className="flex flex-row w-full max-w-[160px] items-center ">
        <LocalText value={`$${valueDSPL}`} isFetched={isFetched} />
      </div>
      <div className="flex flex-row w-full max-w-[130px] items-center justify-start ">
        <LocalText value={`${totalApr}%`} isFetched={isFetched} />
      </div>
      <div className="flex flex-row w-full max-w-[120px]">
        <div className="flex w-[100px] items-center">
          <SVGWrapper src={USC} width={38} height={18} />
        </div>
        <div className="flex flex-row  w-1/5 items-center">
          <StateComponent isActive={false} />
        </div>
      </div>
    </div>
  );
};

const LocalText: React.FC<{
  value?: string;
  isFetched?: boolean;
}> = ({ value, isFetched }) => {
  return (
    <div className="flex">
      <DisplayValue viewValue={value} isFetched={isFetched} loaderSkeleton />
    </div>
  );
};
