import { Link } from "react-router-dom";
import { Typography, FlexCol, SVGWrapper } from "@shared";
import { useChainIdContext } from "../../../../contexts/ChainIdContext";
import { ChainSwitcher } from "./ChainSwitcher";

import acrossIcon from "@assets/common/across.svg";

export const AcrossChains = () => {
  const { setChainId, chainId } = useChainIdContext();

  return (
    <FlexCol className="gap-0 sm:gap-3 w-full">
      <FlexCol className="w-full ">
        <Link to={"https://across.to/"} target="_blank" rel="noreferrer">
          <SVGWrapper width={211} src={acrossIcon} />
        </Link>
      </FlexCol>
      <div className="flex gap-4 mt-3 sm:mt-6 flex-row sm:flex-col items-center md:items-start">
        <Typography
          type="caption-medium"
          className="text-white whitespace-nowrap"
        >
          Source chain
        </Typography>
        <ChainSwitcher selectedChainId={chainId} onChainChange={setChainId} />
      </div>
    </FlexCol>
  );
};
