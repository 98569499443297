import { useState, useEffect } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { contractAddresses, LockingManagerAbi } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  Button,
  DisplayableInputField,
  SVGWrapper,
  FlexRow,
  InputSliderField,
  Typography,
  useTab,
  SingleComponent,
} from "@shared";
import { displayTokens, formatMoney, formatUnitsToMaxValue } from "@utils";

import { SmallDropdown, DropdownItem } from "../../smallDropdown/SmallDropdown";

import { useButtonPropsOverride } from "../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../state/common/useStepState.ui";
import { useGetUserStakedBalance } from "../../../../../state/stakingManager/useGetUserStakedBalance";
import { useGetLPPriceByAddress } from "../../../../../state/data-provider/useGetLPPriceByAddress";

/* ----------- */
/*    Icons    */
/* ----------- */
import UscEth from "@assets/tokens/uscEth.svg";
import ChiEth from "@assets/tokens/chiEth.svg";
import Plus from "@assets/layout/plus.svg";
import WstUsc from "@assets/tokens/wstUsc.svg";
import { useWingsApprove } from "../../../../../state/common/useWingsApprove";

interface ChiEthBoostFormProps {
  selectedKey: string | null;
  onKeySelect: (key: string) => void;
  setStakeSelectedItem: (item: string) => void;
}

export const ChiEthBoostForm: React.FC<ChiEthBoostFormProps> = ({
  selectedKey,
  onKeySelect,
  setStakeSelectedItem,
}) => {
  const { setActiveTab } = useTab();

  //SLIDER
  const [sliderValue, setSliderValue] = useState<number>(5);
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  const handleLockInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  const items: DropdownItem[] = [
    { key: "stUscEth", icon: UscEth, name: "Staked USC/ETH LP", size: "large" },
    { key: "stChiEth", icon: ChiEth, name: "Staked CHI/ETH LP", size: "large" },
    { key: "wstUsc", icon: WstUsc, name: "wstUSC", size: "small" },
  ];

  const handleDropdownSelect = (item: DropdownItem) => {
    onKeySelect(item.key);
  };

  const { writeContractAsync: boostAsync, isPending: isStaking } =
    useWingsContractWrite();

  const [boostValue, setBoostValue] = useState("");

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    contractAddresses.CHI_ETH_LP_Staking,
    contractAddresses.CHI_ETH_LP_Locking,
    parseUnits(String(boostValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Boost"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const onValueChange = (value: string) => {
    setBoostValue(value);
  };

  const boostFunction = async () => {
    await boostAsync(
      {
        abi: LockingManagerAbi,
        address: contractAddresses.CHI_ETH_LP_Locking,
        functionName: "lock",
        args: [
          parseUnits(String(boostValue), etherUnits.wei),
          BigInt(sliderValue * 7),
        ],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(boostValue)}
                        symbol="Staked CHI/ETH LP"
                      />
                    }
                  />
                }
              />
            ),
          });
          setBoostValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Boosting in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={ChiEth} width={22} height={22} />}
                  text={formatMoney(boostValue)}
                  symbol="Staked CHI/ETH LP"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(boostValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(boostValue)}
                        symbol="Staked CHI/ETH LP"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await boostFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await boostFunction();
    }
  };

  const { balance, isFetched: isMaxBalanceFetched } = useGetUserStakedBalance(
    contractAddresses.CHI_ETH_LP
  );

  const { formattedPrice, isFetched } = useGetLPPriceByAddress("CHI_ETH_LP");

  const valueDSPL: DisplayableInputField = {
    value: formatMoney(Number(boostValue || 0) * Number(formattedPrice || 0)),
    isFetched,
    label: "",
  };

  const maxBalanceDSPL: DisplayableInputField = {
    value: displayTokens(balance || 0n, {}),
    isFetched: isMaxBalanceFetched,
  };

  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div>
        <InputField
          name="Amount"
          value={boostValue}
          onChange={(e) => onValueChange(e.target.value)}
          label="You Boost"
          placeholder="0"
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!balance}
              onClick={() => {
                onValueChange(formatUnitsToMaxValue(balance)?.toString());
              }}
            >
              MAX
            </button>
          }
          dollarValue={valueDSPL}
          walletBalance={maxBalanceDSPL}
        />
      </div>

      <FlexRow className="justify-between items-center w-full">
        <SmallDropdown
          items={items}
          onSelect={handleDropdownSelect}
          selectedItem={items.find((item) => item.key === selectedKey)}
        />
        <FlexRow className="gap-1 items-center">
          <SVGWrapper src={Plus} width={16} height={16} />
          <a
            onClick={() => {
              setStakeSelectedItem("chiEth");
              setActiveTab("stake");
            }}
          >
            <Typography
              type="body-small-bold"
              className="text-secondary hover:underline cursor-pointer"
            >
              Stake LP Token
            </Typography>
          </a>
        </FlexRow>
      </FlexRow>

      <FlexCol className="w-full gap-4">
        <FlexCol className="w-full mt-3">
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60 mb-[-25px]"
          >
            Boost Period (weeks)
          </Typography>
          <FlexRow className="w-full gap-4 items-center">
            <InputSliderField
              min={1}
              max={52}
              value={sliderValue}
              onChange={handleSliderChange}
              width={240}
            />
            <InputField
              value={sliderValue}
              onChange={handleLockInputChange}
              className="w-[55px]"
              min={1}
              max={52}
            />
          </FlexRow>
        </FlexCol>

        <FlexRow className="w-full h-[1px] bg-neutral-black-30" />
        <FlexRow className="w-full justify-between items-center">
          <Typography
            type="body-small-regular"
            className="text-neutral-black-60"
          >
            Time Booster
          </Typography>
          <Typography type="body-medium-medium" className="text-primary">
            {(Number(sliderValue) / 52).toFixed(2)}x
          </Typography>
        </FlexRow>
        <FlexRow className="w-full h-[1px] bg-neutral-black-30" />
      </FlexCol>

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={!boostValue || isStaking || isApproving}
        loading={isStaking || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(boostValue || 0) > 0
          ? isApproved
            ? "Boost"
            : "Approve"
          : "Enter amount"}
      </Button>
    </FlexCol>
  );
};
