import { Typography, FlexRow, SVGWrapper, DisplayValue } from "@shared";

//icons
import CHIETH from "@assets/tokens/chiEth.svg";
import React from "react";

export const GenericRow: React.FC<{
  firstElement: React.ReactNode;
  tvlDSPL?: string;
  isFetched?: boolean;
  totalApr?: string;
  rewardIcon: string;
  isFetchedAPR?: boolean;
  isActive: boolean;
  aprTooltip?: React.ReactNode;
  isLastRow?: boolean;
}> = ({
  firstElement,
  tvlDSPL,
  isFetched,
  totalApr,
  rewardIcon,
  isFetchedAPR,
  isActive,
  isLastRow,
  aprTooltip,
}) => {
  return (
    <>
      <div
        className={`hidden md:flex flex-row p-4 py-3 items-center ${
          isLastRow ? "" : "border-b"
        }`}
      >
        <div className="w-full max-w-[200px]">{firstElement}</div>
        <div className="w-full max-w-[160px]">
          <LocalText value={`$${tvlDSPL}`} isFetched={isFetched} />
        </div>
        <div className="flex flex-row w-full max-w-[130px] items-center justify-start ">
          <LocalText value={`${totalApr}%`} isFetched={isFetchedAPR} />
          {aprTooltip}
        </div>
        <div className="flex flex-row w-full max-w-[120px]">
          <div className="w-[100px]">
            <SVGWrapper src={rewardIcon} width={38} height={18} />
          </div>
          <div className="flex w-1/5 items-center justify-start">
            <StateComponent isActive={isActive} />
          </div>
        </div>
      </div>
      <div
        className={`md:hidden flex flex-col p-4 items-center ${
          isLastRow ? "" : "border-b"
        }`}
      >
        <div className="flex flex-row justify-between items-center w-full">
          <div className="">{firstElement}</div>
        </div>
        <div className="flex flex-row justify-between pt-2 items-center w-full">
          <div className="flex flex-row gap-1 text-left">
            <Typography type="body-medium-medium" className="text-[#949494]">
              TVL
            </Typography>
            <LocalText value={tvlDSPL} isFetched={isFetched} />
          </div>
          <div className="flex flex-row gap-1 text-right">
            <Typography type="body-medium-medium" className="text-[#949494]">
              APR
            </Typography>
            <LocalText value={totalApr} isFetched={isFetchedAPR} />
          </div>
        </div>
      </div>
    </>
  );
};

export const FirstElementCHIETH = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow">
        <div className="flex items-center min-w-9">
          <SVGWrapper src={CHIETH} width={34} height={20} />
        </div>

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular">CHI/ETH</Typography>
          </FlexRow>
          <Typography className="text-[#949494]" type="body-small-regular">
            Staked CHI/ETH
          </Typography>
        </div>
      </div>
    </>
  );
};

export const StateComponent: React.FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div
        className={`w-[6px] h-[6px] rounded-full 
      ${isActive ? "bg-secondary" : "bg-red-500"}
        `}
      ></div>
      {/* <Typography type="caption-medium">
        {isActive ? "Active" : "Inactive"}
      </Typography> */}
    </div>
  );
};

const LocalText: React.FC<{
  value?: string;
  isFetched?: boolean;
}> = ({ value, isFetched }) => {
  return (
    <div className="flex">
      <DisplayValue viewValue={value} isFetched={isFetched} loaderSkeleton />
    </div>
  );
};
