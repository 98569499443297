import { useState } from "react";
import {
  Card,
  FlexCol,
  Typography,
  DisplayPercentage,
  FlexRow,
  Divider,
  DisplayTokenAmount,
  DisplayMoney,
} from "@shared";
import { LocalRowClickable } from "./LocalRowClickable";
import { contractAddresses } from "@meta";
import { Address, parseUnits } from "viem";
import { useTotalUserPositions } from "../../../../state/positions/useTotalUserPositions";
import { TOKEN_CONFIG } from "../../../../config/tokenConfig";
import { displayTokens, formatMoney } from "@utils";
import { useFetchUscStakingAPR } from "../../../../state/usc/useFetchUscStakingAPR";
import { useFetchBoostedChiEthApr } from "../../../../state/data-provider/useFetchBoostedChiEthApr";
import { useFetchBoostedUscEthApr } from "../../../../state/data-provider/useFetchBoostedUscEthApr";
import { useFetchBoostedWstUscApr } from "../../../../state/data-provider/useFetchBoostedWstUscApr";
import { useFetchUscEthLpDetailedAPR } from "../../../../state/data-provider/useFetchUscEthLpDetailedAPR";
import { useFetchChiEthLpDetailedAPR } from "../../../../state/data-provider/useFetchChiEthLpDetailedAPR";

type BalanceKeys =
  | "stUsc"
  | "wstUsc"
  | "uscEth"
  | "chiEth"
  | "boostedUscEth"
  | "boostedChiEth"
  | "boostedWstUsc";

export const YourPositionsCard = () => {
  const [selectedToken, setSelectedToken] = useState<Address>(
    contractAddresses.USCStaking
  );

  const {
    formattedBalances,
    dollarValues,
    portfolioValue,
    hasBoostedTokens,
    hasPositions,
    isFetched,
  } = useTotalUserPositions();

  const handleRowClick = (tokenAddress: Address) =>
    setSelectedToken(tokenAddress);

  const tokenKeyMap: Record<Address, BalanceKeys> = {
    [contractAddresses.USCStaking]: "stUsc",
    [contractAddresses.WstUSC]: "wstUsc",
    [contractAddresses.USC_ETH_LP_Staking]: "uscEth",
    [contractAddresses.CHI_ETH_LP_Staking]: "chiEth",
    [contractAddresses.WST_USC_Locking]: "boostedWstUsc",
    [contractAddresses.USC_ETH_LP_Locking]: "boostedUscEth",
    [contractAddresses.CHI_ETH_LP_Locking]: "boostedChiEth",
  };

  const selectedBalanceKey = tokenKeyMap[selectedToken];
  const selectedTokenConfig = TOKEN_CONFIG[selectedToken];
  const selectedSymbol = selectedTokenConfig?.symbol || "";

  const tokenAddresses = Object.keys(tokenKeyMap) as Address[];

  const standardTokens = tokenAddresses.filter(
    (address) => !tokenKeyMap[address].startsWith("boosted")
  );
  const boostedTokens = tokenAddresses.filter((address) =>
    tokenKeyMap[address].startsWith("boosted")
  );

  const { aprDSPL: stUscApr, isFetchedAPR: isStUscAprFetched } =
    useFetchUscStakingAPR();
  const { detailedApr: uscEthApr, isFetchedAPR: isUscEthAprFetched } =
    useFetchUscEthLpDetailedAPR();
  const { detailedApr: chiEthApr, isFetchedAPR: isChiEthAprFetched } =
    useFetchChiEthLpDetailedAPR();

  console.log(isStUscAprFetched, isUscEthAprFetched, isChiEthAprFetched);

  const {
    totalApr: totalBoostedUscEthApr,
    isFetchedAPR: isBoostedUscEthAprFetched,
  } = useFetchBoostedUscEthApr();
  const {
    totalApr: totalBoostedChiEthApr,
    isFetchedAPR: isBoostedChiEthAprFetched,
  } = useFetchBoostedChiEthApr();
  const {
    totalApr: totalBoostedWstUscApr,
    isFetchedAPR: isBoostedWstUscAprFetched,
  } = useFetchBoostedWstUscApr();

  console.log(
    isBoostedUscEthAprFetched,
    isBoostedChiEthAprFetched,
    isBoostedWstUscAprFetched
  );

  const aprMap = {
    stUsc: stUscApr.viewValue,
    wstUsc: stUscApr.viewValue,
    uscEth: uscEthApr.totalApr.formatted,
    chiEth: chiEthApr.totalApr.formatted,
    boostedUscEth: totalBoostedUscEthApr.formatted,
    boostedChiEth: totalBoostedChiEthApr.formatted,
    boostedWstUsc: totalBoostedWstUscApr.formatted,
  };

  if (!isFetched) {
    return (
      <Card hasBorder className="flex-grow min-h-[200px]">
        <FlexCol className="gap-4 items-center mt-12">
          <Typography type="body-medium-bold">Your Positions</Typography>
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Loading. . .
          </Typography>
        </FlexCol>
      </Card>
    );
  }
  if (!hasPositions) {
    return (
      <Card hasBorder className="flex-grow min-h-[200px]">
        <FlexCol className="gap-4 items-center mt-12">
          <Typography type="body-medium-bold">Your Positions</Typography>
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            You don't have any positions yet.
          </Typography>
        </FlexCol>
      </Card>
    );
  }

  return (
    <Card hasBorder className="flex-grow min-h-[200px]">
      <FlexCol className="gap-8 p-4">
        <FlexCol className="gap-5">
          <Typography type="body-medium-bold">Your Positions</Typography>
          <FlexCol className="gap-3">
            <FlexRow className="justify-between text-neutral-black-60">
              <Typography type="body-small-regular">Position</Typography>
              <Typography type="body-small-regular">APR</Typography>
            </FlexRow>

            {standardTokens.map((address) => {
              const key = tokenKeyMap[address];
              const balance = formattedBalances[key];
              const tokenConfig = TOKEN_CONFIG[address];

              if (!tokenConfig || Number(balance) <= 0) return null;

              const isIconBig = tokenConfig.symbol.includes("LP");

              return (
                <LocalRowClickable
                  key={key}
                  text={tokenConfig.symbol}
                  onClick={() => handleRowClick(address)}
                  isSelected={selectedToken === address}
                  icon={tokenConfig.logo}
                  isIconBig={isIconBig}
                >
                  <DisplayPercentage
                    viewValue={aprMap[key]}
                    typography="body-small-medium"
                  />
                </LocalRowClickable>
              );
            })}

            {/* Prikazivanje naslova "Boosting" i boostovanih pozicija */}
            {hasBoostedTokens && (
              <>
                <FlexRow className="gap-2 items-center mt-4">
                  <Typography
                    type="caption-regular"
                    className="text-neutral-black-60"
                  >
                    Boosting
                  </Typography>
                  <FlexRow className="w-full h-[1px] bg-neutral-black-30" />
                </FlexRow>

                {boostedTokens.map((address) => {
                  const key = tokenKeyMap[address];
                  const balance = formattedBalances[key];
                  const tokenConfig = TOKEN_CONFIG[address];

                  if (!tokenConfig || Number(balance) <= 0) return null;

                  const isIconBig = tokenConfig.symbol.includes("LP");

                  return (
                    <LocalRowClickable
                      key={key}
                      text={tokenConfig.symbol}
                      onClick={() => handleRowClick(address)}
                      isSelected={selectedToken === address}
                      icon={tokenConfig.logo}
                      isIconBig={isIconBig}
                    >
                      <DisplayPercentage
                        viewValue={aprMap[key]}
                        typography="body-small-medium"
                      />
                    </LocalRowClickable>
                  );
                })}
              </>
            )}
          </FlexCol>
        </FlexCol>

        <FlexCol className="gap-4">
          <Divider />
          <FlexRow className="justify-between text-neutral-black-70">
            <Typography type="body-small-medium">
              {selectedSymbol} Balance
            </Typography>
            <DisplayTokenAmount
              viewValue={displayTokens(
                parseUnits(
                  formattedBalances[selectedBalanceKey],
                  selectedTokenConfig?.decimals || 18
                ),
                {}
              )}
              typography="body-small-medium"
              className="text-primary"
            />
          </FlexRow>
          <FlexRow className="justify-between text-neutral-black-70">
            <Typography type="body-small-medium">
              {selectedSymbol} Value
            </Typography>
            <DisplayMoney
              viewValue={formatMoney(dollarValues[selectedBalanceKey] || 0)}
              typography="body-small-medium"
              className="text-primary"
              symbolPosition="before"
            />
          </FlexRow>
          <FlexRow className="justify-between text-neutral-black-70">
            <Typography type="body-small-medium">Portfolio Value</Typography>
            <DisplayMoney
              viewValue={formatMoney(portfolioValue)}
              typography="body-small-medium"
              className="text-primary"
              symbolPosition="before"
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
