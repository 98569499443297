import {
  FlexCol,
  FlexRow,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  DisplayPercentage,
} from "@shared";
import { useMintBurnFee } from "../../../../state/arbitrage/useMintBurnFee";
import { useGetPriceByAddress } from "../../../../state/price-feed-aggregator/useGetPriceByAddress";
import { Address } from "viem";
import { TOKEN_CONFIG } from "../../../../config/tokenConfig";
import { contractAddresses } from "@meta";

interface AdditionalInfoProps {
  selectedAsset?: Address;
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  selectedAsset,
}) => {
  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const symbol = selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : "ETH";
  const mintBurnFee = useMintBurnFee();
  if (selectedAsset && TOKEN_CONFIG[selectedAsset]?.symbol === "ETH") {
    selectedAsset = contractAddresses.WETH;
  }
  const price = useGetPriceByAddress(selectedAsset);

  return (
    <FlexCol className="w-full">
      <FlexRow className="w-full py-3 items-center justify-between">
        <Typography type="caption-regular">Exchange Rate</Typography>
        <FlexRow className="gap-2 items-center">
          <DisplayTokenAmount
            viewValue="1"
            symbol={symbol}
            symbolPosition="after"
            typography="caption-regular"
          />
          <Typography type="caption-regular"> = </Typography>
          <DisplayMoney
            {...price}
            viewValue={price.displayData.value}
            symbol="USD"
            symbolPosition="after"
            typography="caption-regular"
          />
        </FlexRow>
      </FlexRow>
      <FlexRow className="w-full py-3 items-center justify-between">
        <Typography type="caption-regular">Mint Fee</Typography>
        <FlexRow className="gap-2 items-center">
          <DisplayPercentage
            {...mintBurnFee}
            viewValue={mintBurnFee.data.viewValue}
            typography="caption-regular"
          />
        </FlexRow>
      </FlexRow>
    </FlexCol>
  );
};
