import { contractAddresses } from "../../meta";
import {
  ARBITRUM_ETH,
  ARBITRUM_WETH,
  ARBITRUM_WBTC,
  ARBITRUM_USDC,
  ARBITRUM_USDT,
  ARBITRUM_DAI,
  BASE_ETH,
  BASE_WETH,
  BASE_USDC,
  BASE_DAI,
  BLAST_ETH,
  BLAST_WETH,
  BLAST_WBTC,
  LINEA_ETH,
  LINEA_WETH,
  LINEA_WBTC,
  LINEA_USDC,
  LINEA_USDT,
  LINEA_DAI,
  MODE_ETH,
  MODE_WBTC,
  MODE_USDC,
  MODE_USDT,
  OPTIMISM_ETH,
  OPTIMISM_WBTC,
  OPTIMISM_USDC,
  OPTIMISM_USDT,
  POLYGON_WETH,
  POLYGON_WBTC,
  POLYGON_USDC,
  POLYGON_USDT,
  POLYGON_DAI,
  ZKSYNC_ETH,
  ZKSYNC_WETH,
  ZKSYNC_WBTC,
  ZKSYNC_USDC,
  ZKSYNC_USDT,
  ZKSYNC_DAI,
} from "../state/across/contractAddressesByChain";
import { Address } from "viem";

import wethIcon from "@assets/tokens/weth.svg";
import usdcIcon from "@assets/tokens/usdc.svg";
import ethIcon from "@assets/tokens/eth.svg";
import weethIcon from "@assets/tokens/weEth.svg";
import wbtcIcon from "@assets/tokens/wbtc.svg";
import usdtIcon from "@assets/tokens/usdt.svg";
import daiIcon from "@assets/tokens/dai.svg";
import stEthIcon from "@assets/tokens/stEth.svg";

import stUsc from "@assets/tokens/stUsc.svg";
import uscEth from "@assets/tokens/uscEth.svg";
import chiEth from "@assets/tokens/chiEth.svg";
import CHI from "@assets/tokens/chi.svg";
import Usc from "@assets/tokens/usc.svg";

import usdeIcon from "@assets/tokens/usde.svg";
import fraxIcon from "@assets/tokens/frax.svg";
import wstUsc from "@assets/tokens/wstUsc.svg";
import boostedWstUsc from "@assets/tokens/boostedWstUsc.svg";
import boostedUscEth from "@assets/tokens/boostedUscEth.svg";
import boostedChiEth from "@assets/tokens/boostedChiEth.svg";

export interface TokenConfig {
  symbol: string;
  logo: string;
  decimals: number;
  useCoingeckoForPrice?: boolean;
  isZappingIn?: boolean;
}

const WETH_CONFIG: TokenConfig = {
  symbol: "WETH",
  logo: wethIcon,
  decimals: 18,
};

const USDC_CONFIG: TokenConfig = {
  symbol: "USDC",
  logo: usdcIcon,
  decimals: 6,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const USDE_CONFIG: TokenConfig = {
  symbol: "USDe",
  logo: usdeIcon,
  decimals: 18,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const FRAX_CONFIG: TokenConfig = {
  symbol: "FRAX",
  logo: fraxIcon,
  decimals: 18,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const ETH_CONFIG: TokenConfig = {
  symbol: "ETH",
  logo: ethIcon,
  decimals: 18,
};

const weETH_CONFIG: TokenConfig = {
  symbol: "weETH",
  logo: weethIcon,
  decimals: 18,
};

const WBTC_CONFIG: TokenConfig = {
  symbol: "WBTC",
  logo: wbtcIcon,
  decimals: 8,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const USDT_CONFIG: TokenConfig = {
  symbol: "USDT",
  logo: usdtIcon,
  decimals: 6,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const DAI_CONFIG: TokenConfig = {
  symbol: "DAI",
  logo: daiIcon,
  decimals: 18,
  useCoingeckoForPrice: true,
  isZappingIn: true,
};

const stETH_CONFIG: TokenConfig = {
  symbol: "stETH",
  logo: stEthIcon,
  decimals: 18,
};

const USC_CONFIG: TokenConfig = {
  symbol: "USC",
  logo: Usc,
  decimals: 18,
};

const USC_STAKING_CONFIG: TokenConfig = {
  symbol: "stUSC",
  logo: stUsc,
  decimals: 18,
};

const USC_ETH_STAKING_CONFIG: TokenConfig = {
  symbol: "Staked USC/ETH LP",
  logo: uscEth,
  decimals: 18,
};

const CHI_ETH_STAKING_CONFIG: TokenConfig = {
  symbol: "Staked CHI/ETH LP",
  logo: chiEth,
  decimals: 18,
};

const CHI_CONFIG: TokenConfig = {
  symbol: "CHI",
  logo: CHI,
  decimals: 18,
};

const USC_ETH_BOOSTING_CONFIG: TokenConfig = {
  symbol: "Boosted USC/ETH LP",
  logo: boostedUscEth,
  decimals: 18,
};

const CHI_ETH_BOOSTING_CONFIG: TokenConfig = {
  symbol: "Boosted CHI/ETH LP",
  logo: boostedChiEth,
  decimals: 18,
};

const WST_USC_CONFIG: TokenConfig = {
  symbol: "wstUSC",
  logo: wstUsc,
  decimals: 18,
};

const WST_USC_BOOSTING_CONFIG: TokenConfig = {
  symbol: "Boosted wstUSC",
  logo: boostedWstUsc,
  decimals: 18,
};

export const TOKEN_CONFIG: {
  [key: Address]: TokenConfig;
} = {
  //stusc
  [contractAddresses.USCStaking]: USC_STAKING_CONFIG,
  //usc
  [contractAddresses.USC]: USC_CONFIG,
  //uscEth
  [contractAddresses.USC_ETH_LP_Staking]: USC_ETH_STAKING_CONFIG,
  //chiEth
  [contractAddresses.CHI_ETH_LP_Staking]: CHI_ETH_STAKING_CONFIG,
  //chi
  [contractAddresses.CHI]: CHI_CONFIG,

  //boostedUscEth
  [contractAddresses.USC_ETH_LP_Locking]: USC_ETH_BOOSTING_CONFIG,
  //boostedChiEth
  [contractAddresses.CHI_ETH_LP_Locking]: CHI_ETH_BOOSTING_CONFIG,
  //wstUsc
  [contractAddresses.WstUSC]: WST_USC_CONFIG,
  //boostedWstUsc
  [contractAddresses.WST_USC_Locking]: WST_USC_BOOSTING_CONFIG,

  //steth
  [contractAddresses.stETH]: stETH_CONFIG,
  //eth
  [contractAddresses.ETH]: ETH_CONFIG,
  //weeth
  [contractAddresses.weETH]: weETH_CONFIG,

  [contractAddresses.USDe]: USDE_CONFIG,
  [contractAddresses.FRAX]: FRAX_CONFIG,
  [contractAddresses.DAI]: DAI_CONFIG,
  // WETH
  [contractAddresses.WETH]: WETH_CONFIG,
  [ARBITRUM_WETH]: WETH_CONFIG,
  [BASE_WETH]: WETH_CONFIG,
  [BLAST_WETH]: WETH_CONFIG,
  [LINEA_WETH]: WETH_CONFIG,
  [POLYGON_WETH]: WETH_CONFIG,
  [ZKSYNC_WETH]: WETH_CONFIG,

  // USDC
  [contractAddresses.USDC]: USDC_CONFIG,
  [ARBITRUM_USDC]: USDC_CONFIG,
  [BASE_USDC]: USDC_CONFIG,
  [LINEA_USDC]: USDC_CONFIG,
  [MODE_USDC]: USDC_CONFIG,
  [OPTIMISM_USDC]: USDC_CONFIG,
  [POLYGON_USDC]: USDC_CONFIG,
  [ZKSYNC_USDC]: USDC_CONFIG,

  // ETH
  [ARBITRUM_ETH]: ETH_CONFIG,
  [BASE_ETH]: ETH_CONFIG,
  [BLAST_ETH]: ETH_CONFIG,
  [LINEA_ETH]: ETH_CONFIG,
  [MODE_ETH]: ETH_CONFIG,
  [OPTIMISM_ETH]: ETH_CONFIG,
  [ZKSYNC_ETH]: ETH_CONFIG,

  // WBTC
  [ARBITRUM_WBTC]: WBTC_CONFIG,
  [BLAST_WBTC]: WBTC_CONFIG,
  [LINEA_WBTC]: WBTC_CONFIG,
  [MODE_WBTC]: WBTC_CONFIG,
  [OPTIMISM_WBTC]: WBTC_CONFIG,
  [POLYGON_WBTC]: WBTC_CONFIG,
  [ZKSYNC_WBTC]: WBTC_CONFIG,

  // USDT
  [contractAddresses.USDT]: USDT_CONFIG,
  [ARBITRUM_USDT]: USDT_CONFIG,
  [LINEA_USDT]: USDT_CONFIG,
  [MODE_USDT]: USDT_CONFIG,
  [OPTIMISM_USDT]: USDT_CONFIG,
  [POLYGON_USDT]: USDT_CONFIG,
  [ZKSYNC_USDT]: USDT_CONFIG,

  // DAI
  [ARBITRUM_DAI]: DAI_CONFIG,
  [BASE_DAI]: DAI_CONFIG,
  [LINEA_DAI]: DAI_CONFIG,
  [POLYGON_DAI]: DAI_CONFIG,
  [ZKSYNC_DAI]: DAI_CONFIG,
};

const defaultConfig = {
  symbol: "",
  logo: "",
  decimals: 0,
};

export const getTokenConfig = (address?: Address) => {
  if (!address) return defaultConfig;

  const tokenConfig = TOKEN_CONFIG[address];
  if (!tokenConfig) {
    console.warn(`Token config not found for address: ${address}`);
    return defaultConfig;
  }

  return tokenConfig;
};
