import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import { DisplayValue, Typography } from "../../../../shared";
import { formatUnits } from "viem";

export const DisplayMaxMintBurnReserveTolerance: React.FC = () => {
  const { data: maxMintBurnReserveTolerance, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "maxMintBurnReserveTolerance",
  });

  return (
    <div className="flex flex-row gap-2">
      <Typography>Max Mint Burn Reserve Tolerance:</Typography>
      <DisplayValue
        viewValue={formatUnits(BigInt(maxMintBurnReserveTolerance || 0), 2)}
        {...rest}
      />
    </div>
  );
};
