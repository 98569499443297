import { PageContent } from "../admin/components/PageContent/PageContent";
import { Main } from "./components/Main";

export function AnalyticsPage() {
  return (
    <PageContent className="md:px-10">
      <Main />
    </PageContent>
  );
}
