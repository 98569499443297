import {
  Card,
  FlexCol,
  Typography,
  FlexRow,
  StandardTooltip,
  DisplayMoney,
  SVGWrapper,
} from "@shared";
import { UscEthUniswapPoolReservesChart } from "../../charts/reserves/UscEthUniswapPoolReservesChart";
import { TotalSupplyChart } from "../../charts/solvency/TotalSupplyChart";
import { contractAddresses } from "../../../../../meta";
import { useSpotPrice } from "../../../../state/usc/useSpotPrice";

import stEthIcon from "@assets/tokens/stEth.svg";
import uscIcon from "@assets/tokens/usc.svg";
import uscEthIcon from "@assets/tokens/uscEth.svg";

export const WstUSCContent = () => {
  const uscSpotPrice = useSpotPrice(contractAddresses.USC);

  return (
    <div className="flex flex-col gap-8">
      <Card hasShadowInstead hasBorder>
        <FlexCol className="p-4 gap-6">
          <Typography type="body-small-medium">wstUSC Stats</Typography>
          <FlexRow className=" justify-center items-center">
            <FlexRow className="max-w-[640px]">
              <FlexRow className="flex-col md:flex-row w-full justify-center items-center min-h-[244px] text-center">
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={stEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        stETH Reserves
                      </Typography>
                      <StandardTooltip>?</StandardTooltip>
                    </FlexRow>
                    <Typography type="body-large-medium">$1.00</Typography>
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={20} height={20} src={stEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        stETH APR
                      </Typography>
                      <StandardTooltip>?</StandardTooltip>
                    </FlexRow>
                    <Typography type="body-large-medium">$1.00</Typography>
                  </FlexCol>
                </FlexCol>
                <FlexCol className="md:w-1/3 justify-center items-center min-h-[244px] text-center mx-5">
                  <div className="flex flex-col items-center justify-center text-center bg-neutral-black-10 rounded-full w-[200px] h-[200px]">
                    <FlexRow className="gap-2 items-center">
                      <SVGWrapper width={24} height={24} src={uscIcon} />
                      <Typography
                        type="body-large-medium"
                        className="whitespace-nowrap"
                      >
                        wstUSC Price
                      </Typography>
                    </FlexRow>
                    <DisplayMoney
                      {...uscSpotPrice}
                      className="text-primary "
                      typography="h3"
                    />
                  </div>
                </FlexCol>
                <FlexCol className="md:w-1/3">
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        wstUSC in Circulation
                      </Typography>
                      <StandardTooltip>?</StandardTooltip>
                    </FlexRow>
                    <Typography type="body-large-medium">$1.00</Typography>
                  </FlexCol>
                  <FlexCol className="py-2 md:py-8 gap-2 justify-center text-center items-center">
                    <FlexRow className="gap-2">
                      <SVGWrapper width={30} height={30} src={uscEthIcon} />
                      <Typography
                        className="text-neutral-black-60"
                        type="body-small-regular"
                      >
                        USC/ETH LP APR
                      </Typography>
                      <StandardTooltip>?</StandardTooltip>
                    </FlexRow>
                    <Typography type="body-large-medium">$1.00</Typography>
                  </FlexCol>
                </FlexCol>
              </FlexRow>
            </FlexRow>
          </FlexRow>
        </FlexCol>
      </Card>
      <div className="flex flex-col 2xl:flex-row gap-8 md:min-h-200px lg:min-h-[500px]">
        <Card hasShadowInstead className="w-full 2xl:w-1/2">
          <TotalSupplyChart
            name="USC Supply"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
        <Card hasShadowInstead className="w-full 2xl:w-1/2">
          <UscEthUniswapPoolReservesChart
            name="Usc/Eth Uniswap Pool Reserves"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
      </div>
    </div>
  );
};
