import { useForm } from "react-hook-form";
import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
  DisplayValue,
} from "../../../../shared";
import { formatUnits, parseUnits } from "viem";

export const SetPriceTolerance: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: setPriceTolerance, isPending: isSetting } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setPriceTolerance(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "setPriceTolerance",
        args: [parseUnits(data.priceTolerance, 2) as any],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>success</Typography>,
          });
        },
      }
    );
  };

  const { data: currentTolerance, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "priceTolerance",
  });

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>
            Current Price Tolerance:{" "}
            <DisplayValue
              viewValue={formatUnits(BigInt(currentTolerance || 0), 2)}
              {...rest}
            />
          </Typography>
          <Typography>Arbitrage / setPriceTolerance /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("priceTolerance")}
              placeholder="Price Tolerance (2 decimals)"
              type="text"
              step="0.01"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSetting}>
              Update Arbitrager
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
