import { useForm } from "react-hook-form";
import { ReserveHolderAbi, contractAddresses } from "@meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
  DisplayMoney,
} from "@shared";
import { parseUnits } from "viem";
import { useReadContract } from "wagmi";
import { displayTokens } from "@utils";

export const SetReserveAssetPercentage: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const {
    writeContractAsync: setReserveAssetPercentage,
    isPending: isSettingPercentage,
  } = useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setReserveAssetPercentage(
      {
        abi: ReserveHolderAbi,
        address: contractAddresses.ReserveHolder,
        functionName: "setReserveAssetPercentage",
        args: [data.assetAddress, parseUnits(data.percentage, 2)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: (
              <Typography>Reserve asset percentage set successfully</Typography>
            ),
          });
        },
      }
    );
  };

  const { data, ...rest } = useReadContract({
    abi: ReserveHolderAbi,
    address: contractAddresses.ReserveHolder,
    functionName: "getReserveValue",
  });

  return (
    <Card className="w-2/3" hasBorder>
      <div>
        <Typography>Total Reserve Value</Typography>
        <DisplayMoney
          viewValue={displayTokens(data, {
            decimals: 8,
          })}
          {...rest}
        />
      </div>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>Reserve Holder / setReserveAssetPercentage /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("assetAddress")}
              placeholder="Asset Address"
              className="input input-bordered w-full"
            />
            <input
              {...methods.register("percentage")}
              placeholder="Percentage"
              type="text"
              step="0.01"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSettingPercentage}>
              Set Percentage
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
