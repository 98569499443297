import {
  mainnet,
  optimism,
  polygon,
  arbitrum,
  base,
  linea,
  zksync,
  blast,
  mode,
} from "@wagmi/core/chains";
import { contractAddresses } from "../../../meta";

// Arbitrum
export const ARBITRUM_ETH = contractAddresses.ETH;
export const ARBITRUM_WETH = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
export const ARBITRUM_WBTC = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
export const ARBITRUM_USDC = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
export const ARBITRUM_USDT = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
export const ARBITRUM_DAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";

// Base
export const BASE_ETH = contractAddresses.ETH;
export const BASE_WETH = "0x4200000000000000000000000000000000000006";
export const BASE_USDC = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
export const BASE_DAI = "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb";

// Blast
export const BLAST_ETH = contractAddresses.ETH;
export const BLAST_WETH = "0x4300000000000000000000000000000000000004";
export const BLAST_WBTC = "0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692";

// Optimism
export const OPTIMISM_ETH = contractAddresses.ETH;
export const OPTIMISM_WETH = "0x4200000000000000000000000000000000000006";
export const OPTIMISM_WBTC = "0x68f180fcCe6836688e9084f035309E29Bf0A2095";
export const OPTIMISM_USDC = "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85";
export const OPTIMISM_USDT = "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58";
export const OPTIMISM_DAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";

// Linea
export const LINEA_ETH = contractAddresses.ETH;
export const LINEA_WETH = "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f";
export const LINEA_WBTC = "0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4";
export const LINEA_USDC = "0x176211869cA2b568f2A7D4EE941E073a821EE1ff";
export const LINEA_USDT = "0xA219439258ca9da29E9Cc4cE5596924745e12B93";
export const LINEA_DAI = "0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5";

// Mode
export const MODE_ETH = contractAddresses.ETH;
export const MODE_WETH = "0x4200000000000000000000000000000000000006";
export const MODE_WBTC = "0xcDd475325D6F564d27247D1DddBb0DAc6fA0a5CF";
export const MODE_USDC = "0xd988097fb8612cc24eeC14542bC03424c656005f";
export const MODE_USDT = "0xf0F161fDA2712DB8b566946122a5af183995e2eD";

// Polygon
export const POLYGON_ETH = contractAddresses.ETH;
export const POLYGON_WETH = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
export const POLYGON_WBTC = "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6";
export const POLYGON_USDC = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
export const POLYGON_USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
export const POLYGON_DAI = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";

// ZK Sync
export const ZKSYNC_ETH = contractAddresses.ETH;
export const ZKSYNC_WETH = "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91";
export const ZKSYNC_WBTC = "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011";
export const ZKSYNC_USDC = "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4";
export const ZKSYNC_USDT = "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C";
export const ZKSYNC_DAI = "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656";

// Blast Stablecoins + Addresses:
// USDB - This is the native stablecoin of Blast (Across automatically converts it to DAI once bridged on Ethereum) - let me know if you can easily get the logo
// 0x4300000000000000000000000000000000000003

// @ts-ignore
export const MultiChainContractAddresses = {
  [mainnet.id]: {
    ETH: contractAddresses.ETH,
    WETH: contractAddresses.WETH,
    stETH: contractAddresses.stETH,
    weETH: contractAddresses.weETH,

    DAI: contractAddresses.DAI,
    USDC: contractAddresses.USDC,
    USDe: contractAddresses.USDe,
    FRAX: contractAddresses.FRAX,
  },
  [arbitrum.id]: {
    ETH: ARBITRUM_ETH,
    WETH: ARBITRUM_WETH,
    USDC: ARBITRUM_USDC,
    DAI: ARBITRUM_DAI,
    // WBTC: ARBITRUM_WBTC,
    // USDT: ARBITRUM_USDT,
  },
  [base.id]: {
    ETH: BASE_ETH,
    WETH: BASE_WETH,
    USDC: BASE_USDC,
    DAI: BASE_DAI,
  },
  [blast.id]: {
    ETH: BLAST_ETH,
    WETH: BLAST_WETH,
    // WBTC: BLAST_WBTC,
  },
  [optimism.id]: {
    ETH: OPTIMISM_ETH,
    WETH: OPTIMISM_WETH,
    USDC: OPTIMISM_USDC,
    DAI: OPTIMISM_DAI,
    // WBTC: OPTIMISM_WBTC,
    // USDT: OPTIMISM_USDT,
  },
  [linea.id]: {
    ETH: LINEA_ETH,
    WETH: LINEA_WETH,
    // WBTC: LINEA_WBTC,
    // USDC: LINEA_USDC,
    // USDT: LINEA_USDT,
    // DAI: LINEA_DAI,
  },
  [mode.id]: {
    ETH: MODE_ETH,
    WETH: MODE_WETH,
    // WBTC: MODE_WBTC,
    // USDC: MODE_USDC,
    // USDT: MODE_USDT,
  },
  [polygon.id]: {
    ETH: POLYGON_ETH,
    WETH: POLYGON_WETH,
    USDC: POLYGON_USDC,
    DAI: POLYGON_DAI,
    // USDT: POLYGON_USDT,
    // WBTC: POLYGON_WBTC,
  },
  [zksync.id]: {
    ETH: ZKSYNC_ETH,
    WETH: ZKSYNC_WETH,
    // WBTC: ZKSYNC_WBTC,
    // USDC: ZKSYNC_USDC,
    // USDT: ZKSYNC_USDT,
    // DAI: ZKSYNC_DAI,
  },
};

export const getChainNameByChainId = (chainId: number) => {
  switch (chainId) {
    case mainnet.id:
      return "ethereum";
    case arbitrum.id:
      return "arbitrum-one";
    case base.id:
      return "base";
    case blast.id:
      return "blast";
    case optimism.id:
      return "optimistic-ethereum";
    case linea.id:
      return "linea";
    case mode.id:
      return "mode";
    case polygon.id:
      return "polygon-pos";
    case zksync.id:
      return "zkSync";
    default:
      return "unknown";
  }
};

export const getChainSimpleNameByChainId = (chainId: number) => {
  switch (chainId) {
    case mainnet.id:
      return "ethereum";
    case arbitrum.id:
      return "arbitrum";
    case base.id:
      return "base";
    case blast.id:
      return "blast";
    case optimism.id:
      return "optimism";
    case linea.id:
      return "linea";
    case mode.id:
      return "mode";
    case polygon.id:
      return "polygon";
    case zksync.id:
      return "zkSync";
    default:
      return "unknown";
  }
};

export const getTokenActualAddressBySymbolAndChainId = (
  symbol?: string,
  chainId?: number
) => {
  if (!symbol || !chainId) {
    return undefined;
  }
  const chainConfig = (MultiChainContractAddresses as any)[chainId];
  if (!chainConfig) {
    return undefined;
  }

  // Handle the special case where we want WETH address for ETH symbol
  if (symbol === "ETH") {
    return chainConfig.WETH;
  }

  const tokenAddress = chainConfig[symbol];
  if (!tokenAddress) {
    return undefined;
  }

  return tokenAddress;
};

export const getTokenType = (
  symbol?: string,
  chainId?: number
): "eth" | "weth" | "other" => {
  if (!symbol || !chainId) {
    return "other";
  }
  const chainConfig = (MultiChainContractAddresses as any)[chainId];
  if (!chainConfig) {
    throw new Error(`Unsupported chainId: ${chainId}`);
  }

  // Handle the special case where we want WETH address for ETH symbol
  if (symbol === "ETH") {
    return "eth";
  }

  const tokenAddress = chainConfig[symbol];
  if (!tokenAddress) {
    throw new Error(`Token symbol ${symbol} not found on chainId ${chainId}`);
  }

  return "weth";
};
