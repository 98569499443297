import React from "react";

import styles from "./PolygonTooltip.module.css";
import { SVGWrapper, Typography } from "@shared";

import solarIcon from "@assets/common/solar_info-circle-bold.svg";

export const PolygonTooltip: React.FC<{
  tooltipText: string;
  icon?: React.ReactNode;
}> = ({ tooltipText, icon }) => {
  return (
    <div className={styles.mtTooltip}>
      {icon && icon}
      {icon == null && (
        <SVGWrapper src={solarIcon} className="w-4 h-4 flex-shrink-0" />
      )}
      <Typography className={styles.myTooltiptext}>{tooltipText}</Typography>
    </div>
  );
};
