export const semiSensitiveDataQueryConfig = {
  staleTime: 60 * 1000, // one minute
};

export const FIVE_SECONDS_IN_MS = 5 * 1000;
export const ONE_MINUTE_IN_MS = 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;

export const USD_DECIMALS = 8;

export const metadataQueryConfig = {
  staleTime: Infinity,
};
export const priceQueryConfig = {
  staleTime: ONE_HOUR_IN_MS,
};

export const queryConfigs = {
  priceQueryConfig: priceQueryConfig,
  metadataQueryConfig: metadataQueryConfig,
  balanceQueryConfig: {
    staleTime: FIVE_SECONDS_IN_MS,
  },
};
