import { CHIVestingAbi, contractAddresses } from "@meta";
import { Address } from "viem";
import { useAccount, useReadContract } from "wagmi";

export const calculateCurrentAmount = (
  totalLockedChi: bigint | undefined,
  shares: bigint | undefined,
  totalShares: bigint | undefined
): bigint | undefined => {
  if (
    totalLockedChi === undefined ||
    shares === undefined ||
    totalShares === undefined
  ) {
    return undefined;
  }

  if (totalShares === 0n) {
    return undefined;
  }

  return (totalLockedChi * shares) / totalShares;
};

export const useChiRewards = () => {
  const { address } = useAccount();
  const { data: vestingData, isFetched: isFetchedvestingData } =
    useReadContract({
      abi: CHIVestingAbi,
      functionName: "vestingData",
      address: contractAddresses.ChiVesting,
      args: [address as Address],
    });

  const [startAmount, shares] = (vestingData as any) || [0n, 0n];

  const { data: totalLockedChi, isFetched: isFetchedTotalLockedChi } =
    useReadContract({
      abi: CHIVestingAbi,
      functionName: "totalLockedChi",
      address: contractAddresses.ChiVesting,
    });
  const { data: totalShares, isFetched: isFetchedTotalShares } =
    useReadContract({
      abi: CHIVestingAbi,
      functionName: "totalShares",
      address: contractAddresses.ChiVesting,
    });

  const currentAmount = calculateCurrentAmount(
    totalLockedChi,
    shares,
    totalShares
  );

  return {
    chiReward: currentAmount != null ? currentAmount - startAmount : undefined,
    isFetched:
      isFetchedvestingData && isFetchedTotalLockedChi && isFetchedTotalShares,
  };
};
