import { formatUnits, etherUnits } from "viem";
import {
  CHIAbi,
  CHILockingAbi,
  CHIStakingAbi,
  contractAddresses,
  RewardControllerAbi,
  USCAbi,
  StakingManagerAbi,
} from "../../../../meta";
import { FlexCol, FlexRow, Divider } from "../../../../shared";
import {
  formatWithDynamicPrecision,
  formatMoney,
  displayTokens,
} from "../../../../utils";
import { useGetPriceByAddress } from "../../../state/price-feed-aggregator/useGetPriceByAddress";
import { useSpotPrice } from "../../../state/usc/useSpotPrice";
import { HorizontalRow } from "../components/HorizontalRow/HorizontalRow";
import { useReadContract } from "wagmi";
import { useFetchCHI_ETH_LPTotalStakedSupply } from "../queries/useFetchCHI_ETH_LPTotalStakedSupply";
import { useFetchUSC_ETH_LPTotalStakedSupply } from "../queries/useFetchUSC_ETH_LPTotalStakedSupply";

const spotConfig = {
  numberOfDecimals: 4,
};

export const Prices = () => {
  // const { formattedPrice: chiFPrice } = useGetPriceByAddress(
  //   contractAddresses.CHI
  // );

  const { formattedPrice: chiTwapPrice } = useGetPriceByAddress(
    contractAddresses.CHI
  );
  const { formattedPrice: uscTwapPrice } = useGetPriceByAddress(
    contractAddresses.USC
  );

  const { formattedPrice: wethPrice } = useGetPriceByAddress(
    contractAddresses.WETH
  );

  // const { displayData: totalSupplyDisplayData } =
  //   useFetchUSC_ETH_LPTotalSupply();

  // const { displayData: totalSupplyCHIDisplayData } =
  //   useFetchCHI_ETH_LPTotalSupply();

  const {
    viewValue: uscSpotDisplayPrice,
    reserve0: reserveusc0,
    reserve1: reserveusc1,
    isFetched: isFetchedUSCSpot,
    spotPrice: uscSpotPrice,
  } = useSpotPrice(contractAddresses.USC, contractAddresses.WETH, spotConfig);
  const reserveUsc0Price =
    Number(formatUnits(reserveusc0, etherUnits.wei)) *
    Number(uscSpotPrice || 0);
  const reserveUsc1Price =
    Number(formatUnits(reserveusc1, etherUnits.wei)) * Number(wethPrice || 0);
  const USCETHLiquidity = `${formatMoney(
    formatUnits(reserveusc0 + reserveusc1, etherUnits.wei),
    {}
  )} ($${reserveUsc0Price + reserveUsc1Price})`;

  const {
    viewValue: chiSpotDisplayPrice,
    reserve0: reservechi0,
    reserve1: reservechi1,
    isFetched: isChiSpotFetched,
    spotPrice: chiSpotPrice,
  } = useSpotPrice(contractAddresses.CHI, contractAddresses.WETH, spotConfig);
  const reserveChi0Price =
    Number(formatUnits(reservechi0, etherUnits.wei)) *
    Number(chiSpotPrice || 0);
  const reserveChi1Price =
    Number(formatUnits(reservechi1, etherUnits.wei)) * Number(wethPrice || 0);
  // CHI/ETH Liquidity (Total)
  const CHIETHLiquidity = `${formatMoney(
    formatUnits(reservechi0 + reservechi1, etherUnits.wei),
    {}
  )} ($${reserveChi0Price + reserveChi1Price})`;

  // const { formattedPrice: chi_eth_price } =
  //   useGetLPPriceByAddress("CHI_ETH_LP");
  // const { formattedPrice: usc_eth_price } =
  //   useGetLPPriceByAddress("USC_ETH_LP");

  const { data: CHItotalSupply } = useReadContract({
    abi: CHIAbi,
    address: contractAddresses.CHI,
    functionName: "totalSupply",
  });

  const { data: USCtotalSupply, isLoading: isUSCtotalSupply } = useReadContract(
    {
      abi: USCAbi,
      address: contractAddresses.USC,
      functionName: "totalSupply",
    }
  );

  // Current Epoch CHI Incentives to USC
  const { data: chiIncentivesForUscStaking } = useReadContract({
    // contractName: "RewardController",
    abi: RewardControllerAbi,
    address: contractAddresses.RewardController,
    functionName: "chiIncentivesForUscStaking",
  });
  const CHIAnnualRewards =
    Number(formatUnits(chiIncentivesForUscStaking || 0n, 18) || 0) *
    Number(chiTwapPrice || 0);

  const { data: totalSupplyUSC } = useReadContract({
    // contractName: "USCStaking",
    abi: StakingManagerAbi,
    address: contractAddresses.USCStaking,
    functionName: "getTotalStaked",
  });
  const TotalValueStakedUSC =
    Number(formatUnits(totalSupplyUSC || 0n, 18) || 0) *
    Number(uscTwapPrice || 0);

  const { data: totalSupplyCHI } = useReadContract({
    // contractName: "ChiStaking",
    abi: CHIStakingAbi,
    address: contractAddresses.ChiStaking,
    functionName: "totalSupply",
  });
  const TotalValueStakedCHI =
    Number(formatUnits(totalSupplyCHI || 0n, 18) || 0) *
    Number(chiTwapPrice || 0);

  const { data: totalSupplyLockedCHI } = useReadContract({
    // contractName: "ChiLocking",
    abi: CHILockingAbi,
    address: contractAddresses.ChiLocking,
    functionName: "totalLockedChi",
  });
  const TotalValueLockedCHI =
    Number(formatUnits(totalSupplyLockedCHI || 0n, 18) || 0) *
    Number(chiTwapPrice || 0);

  //--USC/ETH LP Staking--//
  const { data: chiIncentivesForUscEthLPStaking } = useReadContract({
    // contractName: "RewardController",
    abi: RewardControllerAbi,
    address: contractAddresses.RewardController,
    functionName: "chiIncentivesForUscEthLPStaking",
  });

  const USCETHLPAnnualRewards =
    Number(formatUnits(chiIncentivesForUscEthLPStaking || 0n, etherUnits.wei)) *
    52 *
    Number(chiTwapPrice || 0);
  const { totalStaked: totalStakedUSC_ETH_LP } =
    useFetchUSC_ETH_LPTotalStakedSupply();
  const apr_usc_eth_lp = totalStakedUSC_ETH_LP.value
    ? (USCETHLPAnnualRewards / totalStakedUSC_ETH_LP.value) * 100
    : 0;
  //--CHI/ETH LP Staking--//
  const { data: chiIncentivesForChiEthLPStaking } = useReadContract({
    // contractName: "RewardController",
    abi: RewardControllerAbi,
    address: contractAddresses.RewardController,
    functionName: "chiIncentivesForChiEthLPStaking",
  });

  const CHIETHLPAnnualRewards =
    Number(formatUnits(chiIncentivesForChiEthLPStaking || 0n, etherUnits.wei)) *
    52 *
    Number(chiTwapPrice || 0);

  const { totalStaked: totalStakedCHI_ETH_LP } =
    useFetchCHI_ETH_LPTotalStakedSupply();
  const apr_chi_eth_lp = totalStakedCHI_ETH_LP.value
    ? (CHIETHLPAnnualRewards / totalStakedCHI_ETH_LP.value) * 100
    : 0; // Multiplied by 100 to get percentage

  // const { data: balanceOfOCHI, isFetched: isFetchedboOCHI } = useReadContract({
  //   abi: erc20Abi,
  //   address: contractAddresses.OCHI,
  //   functionName: "balanceOf",
  //   args: [contractAddresses.OCHI],
  // });
  // const { data: apr, isFetched: isFetchedAPR } = useReadContract({
  //   // contractName: "DataProvider",
  //   abi: DataProviderAbi,
  //   address: contractAddresses.DataProvider,
  //   functionName: "dsoAPR",
  //   args: [
  //     contractAddresses.CHI,
  //     contractAddresses.OCHI,
  //     contractAddresses.PriceFeedAggregator,
  //   ],
  // });

  return (
    <FlexCol className="gap-5">
      <FlexRow className="gap-5">
        <FlexCol className="md:w-1/2 gap-5">
          <FlexCol className="gap-1 border p-2 rounded-md">
            <HorizontalRow
              value={displayTokens(CHItotalSupply || 0n, {
                displayInDollars: true,
                formattedPrice: Number(chiTwapPrice || 0),
              })}
              name="CHI total supply"
            />
            <Divider />
            <HorizontalRow
              value={formatMoney(chiTwapPrice || 0, {
                numberOfDecimals: 4,
              })}
              name="CHI twap price"
            />
            <Divider />
            <HorizontalRow
              value={chiSpotDisplayPrice}
              name="CHI spot price"
              isFetched={isChiSpotFetched}
            />
          </FlexCol>
          <FlexCol className="gap-1 border p-2 rounded-md">
            <HorizontalRow
              value={displayTokens(USCtotalSupply, {
                displayInDollars: true,
                formattedPrice: Number(uscSpotPrice || 0),
              })}
              isFetched={isUSCtotalSupply || isFetchedUSCSpot}
              name="USC Total Supply"
            />
            <Divider />
            <HorizontalRow
              value={formatMoney(uscTwapPrice || 0, {
                numberOfDecimals: 4,
              })}
              name="USC twap price"
            />
            <Divider />
            <HorizontalRow
              value={uscSpotDisplayPrice}
              isFetched={isFetchedUSCSpot}
              name="USC spot price"
            />
          </FlexCol>
          <FlexCol className="gap-1 border p-2 rounded-md">
            <HorizontalRow
              value={CHIETHLiquidity}
              isFetched={!!CHIETHLiquidity}
              name="CHI/ETH Liquidity (Total)"
            />
            <Divider />
            <HorizontalRow
              value={displayTokens(reservechi0 || 0n, {
                displayInDollars: true,
                formattedPrice: chiTwapPrice,
              })}
              name="CHI ETH reserves (chi)"
            />
            <Divider />
            <HorizontalRow
              value={displayTokens(reservechi1 || 0n, {
                displayInDollars: true,
                formattedPrice: wethPrice,
              })}
              name="CHI ETH reserves (eth)"
            />
            <Divider />
          </FlexCol>
          <FlexCol className="gap-1 border p-2 rounded-md">
            <HorizontalRow
              value={USCETHLiquidity}
              isFetched={!!USCETHLiquidity}
              name="USC/ETH Liquidity (Total)"
            />
            <Divider />
            <HorizontalRow
              value={displayTokens(reserveusc0 || 0n, {
                displayInDollars: true,
                formattedPrice: uscTwapPrice,
              })}
              name="USC ETH reserves (usc)"
            />
            <Divider />
            <HorizontalRow
              value={displayTokens(reserveusc1 || 0n, {
                displayInDollars: true,
                formattedPrice: wethPrice,
              })}
              name="USC ETH reserves (eth)"
            />
            <Divider />
          </FlexCol>
        </FlexCol>
        <FlexCol className="md:w-1/2 gap-5">
          <FlexCol className="gap-1 border rounded-md p-2">
            <HorizontalRow
              name="CHIAnnualRewards"
              value={formatMoney(CHIAnnualRewards)}
            />
            <Divider />
            <HorizontalRow
              name="TotalValueStakedUSC"
              value={formatMoney(TotalValueStakedUSC)}
            />
          </FlexCol>
          <FlexCol className="gap-1  border rounded-md p-2">
            <HorizontalRow
              name="TotalValueStakedCHI"
              value={formatMoney(TotalValueStakedCHI)}
            />
          </FlexCol>
          <FlexCol className="gap-1  border rounded-md p-2">
            <HorizontalRow
              name="TotalValueLockedCHI"
              value={formatMoney(TotalValueLockedCHI)}
            />
          </FlexCol>
          <FlexCol className="gap-1 border rounded-md p-2">
            <HorizontalRow
              name="USC/ETH LP AnnualRewards"
              value={formatMoney(USCETHLPAnnualRewards)}
            />
            <Divider />
            <HorizontalRow
              name="Total Staked USC/ETH LP"
              value={formatMoney(totalStakedUSC_ETH_LP.value)}
              isFetched={totalStakedUSC_ETH_LP.isFetched}
            />
            <Divider />
            <HorizontalRow
              name="APR"
              value={
                "%" +
                formatWithDynamicPrecision(apr_usc_eth_lp, {
                  maxDecimals: 5,
                })
              }
            />
          </FlexCol>
          <FlexCol className="gap-1  border rounded-md p-2">
            <HorizontalRow
              name="CHI/ETH LP AnnualRewards"
              value={formatMoney(CHIETHLPAnnualRewards)}
            />
            <Divider />
            <HorizontalRow
              name="Total Staked CHI/ETH LP"
              value={formatMoney(totalStakedCHI_ETH_LP.value)}
              isFetched={totalStakedCHI_ETH_LP.isFetched}
            />
            <Divider />
            <HorizontalRow
              name="APR"
              value={
                "%" +
                formatWithDynamicPrecision(apr_chi_eth_lp, {
                  maxDecimals: 5,
                })
              }
            />
          </FlexCol>
        </FlexCol>
      </FlexRow>
      {/* <FlexCol className="md:w-1/2 gap-5">
        <FlexCol className="gap-1 border p-2 rounded-md">
          <HorizontalRow
            name="Total Exercise CHI Available"
            value={displayTokens(balanceOfOCHI, {}) + " CHI"}
            isFetched={isFetchedboOCHI}
          />
          <Divider />
          <HorizontalRow
            name="DSO APR (not sure if this is correct)"
            value={
              displayTokens(apr ? apr[0] : 0n, {
                maxDecimals: 9,
              }) + "%"
            }
            isFetched={isFetchedAPR}
            iconText={tooltipTexts.DSO_APR}
          />
        </FlexCol>
      </FlexCol> */}
    </FlexCol>
  );
};
