import {
  FlexCol,
  FlexRow,
  DisplayTokenAmount,
  SVGWrapper,
  Typography,
  Card,
  DisplayMoney,
} from "@shared";
import { InfoTooltip } from "../../tooltip/InfoTooltip";
import { contractAddresses } from "../../../../meta";
import { useReserveAssetByAddress } from "../../../state/reserveholder/useReserveAssetByAddres";

import WETHIcon from "@assets/tokens/weth.svg";

export const WethCard = () => {
  const { data: weth, ...wethRest } = useReserveAssetByAddress(
    contractAddresses.WETH
  );

  return (
    <FlexCol className="h-[60px] py-2 px-[10px] justify-between ">
      {/* Display the value and tooltip icon */}
      <FlexRow className="w-full items-start justify-between">
        <DisplayTokenAmount
          {...wethRest}
          {...weth.balanceOf}
          symbol=""
          typography="body-medium-medium"
        />
        <InfoTooltip>
          {/* Tooltip content displaying detailed WETH information */}
          <FlexCol className="w-[300px] h-[208px] p-3 gap-3 ">
            <FlexRow className="gap-3 items-center">
              <SVGWrapper src={WETHIcon} width="16px" height="16px" />
              <Typography type="body-small-medium">WETH</Typography>
            </FlexRow>

            {/* Conversion details for WETH */}
            <Card hasBorder hasShadowInstead className="w-full">
              <FlexRow className="w-full gap-1 px-3 py-[10px] items-center justify-between">
                <DisplayTokenAmount
                  {...wethRest}
                  {...weth.balanceOf}
                  symbol=""
                  typography="body-medium-regular"
                />
                <Typography
                  type="body-medium-regular"
                  className="text-neutral-black-50"
                >
                  WETH
                </Typography>
              </FlexRow>
            </Card>
            <Card hasBorder hasShadowInstead className="w-full">
              <FlexRow className="w-full gap-1 px-3 py-[10px] items-center justify-between">
                <DisplayMoney
                  {...wethRest}
                  {...weth.dollarAmount}
                  symbol=""
                  typography="body-medium-regular"
                />

                <Typography
                  className="text-neutral-black-50"
                  type="body-medium-regular"
                >
                  USD
                </Typography>
              </FlexRow>
            </Card>

            {/* Conversion rate display */}
            <Typography
              type="caption-regular"
              className="text-neutral-black-50"
            >
              The price of converting 1 Wrapped Ether (WETH) to USD is{" "}
              {weth.price?.viewValue} today.
            </Typography>
          </FlexCol>
        </InfoTooltip>
      </FlexRow>

      {/* Asset identifier row */}
      <FlexRow className="w-full gap-1 items-center">
        <SVGWrapper src={WETHIcon} width="16px" height="16px" />
        <Typography type="caption-medium" className="text-neutral-black-60">
          WETH
        </Typography>
      </FlexRow>
    </FlexCol>
  );
};
