import {
  displayTokens,
  formatMoney,
  formatUnitsToMaxValue,
  formatWithDynamicPrecision,
} from "@utils";
import { useAccount, useReadContract } from "wagmi";
import { CHIStakingAbi, contractAddresses, veCHIAbi } from "@meta";
import { Address, etherUnits, parseUnits } from "viem";
import { useStepState } from "../../../../../state/common/useStepState.ui";
import { useButtonPropsOverride } from "../../../../../state/across/buttonPropsOverride";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { useSpotPrice } from "../../../../../state/usc/useSpotPrice";
import { BigDropdown } from "../../../../../components/rightSection/bigDropdown/BigDropdown";
import { DropdownItem } from "../../../../../components/rightSection/stake-component/smallDropdown/SmallDropdown";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  SVGWrapper,
  StepsContainer,
  ApproveComplete,
  DisplayErrorNotif,
  DisplayableInputField,
  FlexCol,
  Typography,
  InputField,
  FlexRow,
  InputSliderField,
  DisplayNumber,
  Button,
  DisplayTokenAmount,
  DisplayMoney,
} from "@shared";
import { useState, useMemo, useEffect } from "react";
import { useYourBoostedChi } from "./useYourBoostedChi";
import { AuditedBy } from "../../../../../components/auditedBy/AuditedBy";

/* ----------- */
/*    Icons    */
/* ----------- */
import CHI from "@assets/tokens/chi.svg";
import VeCHI from "@assets/tokens/veChi.svg";
import stCHI from "@assets/tokens/stChi.svg";
import BoostIcon from "@assets/layout/flash-on.svg";
import { useWingsApprove } from "../../../../../state/common/useWingsApprove";

interface BoostStChiFormProps {
  selectedKey: string | null;
  onKeySelect: (key: string) => void;
}

export const BoostStChiForm: React.FC<BoostStChiFormProps> = ({
  selectedKey,
  onKeySelect,
}) => {
  const { address } = useAccount();

  const [sliderValue, setSliderValue] = useState<number>(25);
  const [stChiValue, setStChiValue] = useState<string>("");

  const minLockPeriod = 4;
  const maxLockPeriod = 208;

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  const handleLockInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  const handleDropdownSelect = (item: DropdownItem) => {
    onKeySelect(item.key);
  };

  const items: DropdownItem[] = [
    {
      key: "chi",
      name: "CHI",
      icon: CHI,
      size: "small",
    },
    {
      key: "stCHI",
      name: "stCHI",
      icon: stCHI,
      size: "small",
    },
  ];

  const veChiAmount = useMemo(() => {
    return formatWithDynamicPrecision(
      (Number(stChiValue) * Number(sliderValue + 1)) / maxLockPeriod,
      {
        maxDecimals: 9,
      }
    );
  }, [stChiValue, sliderValue]);

  const onStChiValueChange = (value: string) => {
    setStChiValue(value);

    if (value) {
      setStChiValue(String(value));
    } else {
      setStChiValue("");
    }
  };

  const { writeContractAsync: boostAsync, isPending: isBoosting } =
    useWingsContractWrite();

  const { data: veChiBalance, isFetched: isVeChiLoadingBalance } =
    useReadContract({
      abi: veCHIAbi,
      address: contractAddresses.veCHI,
      functionName: "balanceOf",
      args: [address!],
    });

  const { approveAsync, isApproving, isApproved } = useWingsApprove(
    contractAddresses.CHI,
    contractAddresses.ChiStaking,
    parseUnits(String(stChiValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Boost"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const boostFunction = async () => {
    await boostAsync(
      {
        abi: CHIStakingAbi,
        address: contractAddresses.ChiStaking,
        functionName: "lock",
        args: [
          parseUnits(String(stChiValue), etherUnits.wei),
          BigInt(sliderValue * 7),
          true,
        ],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={<SVGWrapper src={VeCHI} width={22} height={22} />}
                        text={formatMoney(veChiAmount)}
                        symbol="veCHI"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={<SVGWrapper src={stCHI} width={22} height={22} />}
                        text={formatMoney(stChiValue)}
                        symbol="stCHI"
                      />
                    }
                  />
                }
              />
            ),
          });
          setStChiValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Boosting in Process"}
          loading
          transferComponent={
            <Transfer
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={VeCHI} width={22} height={22} />}
                  text={formatMoney(veChiAmount)}
                  symbol="veCHI"
                />
              }
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={stCHI} width={22} height={22} />}
                  text={formatMoney(stChiValue)}
                  symbol="stCHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(stChiValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={<SVGWrapper src={VeCHI} width={22} height={22} />}
                        text={formatMoney(veChiAmount)}
                        symbol="veCHI"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={<SVGWrapper src={stCHI} width={22} height={22} />}
                        text={formatMoney(stChiValue)}
                        symbol="stCHI"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await boostFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await boostFunction();
    }
  };

  const { getButtonPropsOverride } = useButtonPropsOverride();

  const { balance: stChiBalance, isLoading: isMaxBalanceLoading } =
    useGetWalletBalanceByToken(contractAddresses.ChiStaking);

  const chiPrice = useSpotPrice(contractAddresses.CHI);

  const valueDSPL: DisplayableInputField = {
    value: formatMoney(
      Number(stChiValue || 0) * Number(chiPrice.spotPrice || 0)
    ),
  };

  const maxBalanceDSPL: DisplayableInputField = {
    value: displayTokens(stChiBalance || 0n, {}),
    isFetched: !isMaxBalanceLoading,
  };

  const { totalBoostedChi, isLoaded: isYourBoostedChiLoaded } =
    useYourBoostedChi();

  return (
    <>
      <FlexCol className="gap-4">
        <FlexRow className="items-center gap-4">
          <Typography type="h2">Boost stCHI</Typography>
          <SVGWrapper src={BoostIcon} width={32} height={32} color="#0051A6" />
          <div className="hidden 2xl:block">
            <AuditedBy />
          </div>
        </FlexRow>
        <div className="block 2xl:hidden w-[156px]">
          <AuditedBy />
        </div>
        <Typography
          type="body-medium-regular"
          className="text-neutral-black-60"
        >
          Boost your CHI tokens, earn stETH rewards and unlock voting power
        </Typography>
      </FlexCol>
      <FlexCol className="w-full gap-6">
        <InputField
          label="To Boost"
          value={stChiValue}
          onChange={(e) => onStChiValueChange(e.target.value)}
          placeholder="0"
          variant="big"
          leftLabel={
            <BigDropdown
              items={items}
              onSelect={handleDropdownSelect}
              selectedItem={items.find((item) => item.key === selectedKey)}
            />
          }
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              onClick={() => {
                setStChiValue(formatUnitsToMaxValue(stChiBalance)?.toString());
              }}
              disabled={isBoosting || isApproving}
            >
              MAX
            </button>
          }
          walletBalance={maxBalanceDSPL}
          dollarValue={valueDSPL}
          max={1000000000000}
        />

        <InputField
          label="To Receive"
          value={veChiAmount}
          disabled
          placeholder="0"
          variant="big"
          leftLabel={
            <FlexRow className="gap-2 items-center">
              <SVGWrapper src={VeCHI} width={24} height={24} />
              <Typography type="body-medium-medium">veCHI</Typography>
            </FlexRow>
          }
          max={1000000000000}
        />
      </FlexCol>
      <FlexCol className="gap-4 pt-6">
        <FlexCol className="gap-5">
          <FlexRow className="w-full flex-col sm:flex-row items-start sm:items-center  justify-between">
            <Typography
              type="body-medium-regular"
              className="text-neutral-black-60"
            >
              Boost Period (Weeks)
            </Typography>
            <FlexRow className="w-full sm:w-1/2 gap-4 items-center">
              <InputSliderField
                min={minLockPeriod}
                max={maxLockPeriod}
                value={sliderValue}
                onChange={handleSliderChange}
                width={240}
              />
              <InputField
                value={sliderValue}
                onChange={handleLockInputChange}
                className="w-[65px]"
                max={maxLockPeriod}
                min={minLockPeriod}
              />
            </FlexRow>
          </FlexRow>
          <FlexRow className="justify-between">
            <Typography
              type="body-medium-regular"
              className="text-neutral-black-60"
            >
              Your Boosted CHI
            </Typography>
            <DisplayTokenAmount
              viewValue={totalBoostedChi.toString()}
              isLoading={!isYourBoostedChiLoaded}
              typography="h5"
            />
          </FlexRow>
          <FlexRow className="justify-between">
            <Typography
              type="body-medium-regular"
              className="text-neutral-black-60"
            >
              Your Boosted CHI Value
            </Typography>
            <DisplayMoney
              viewValue={
                totalBoostedChi
                  ? (
                      Number(totalBoostedChi) * Number(chiPrice.spotPrice || 0)
                    ).toFixed(2)
                  : "0"
              }
              isLoading={!isYourBoostedChiLoaded || !chiPrice.isFetched}
              typography="h5"
            />
          </FlexRow>
          <FlexRow className="justify-between">
            <Typography
              type="body-medium-regular"
              className="text-neutral-black-60"
            >
              Your veCHI Balance
            </Typography>
            <DisplayNumber
              viewValue={displayTokens(veChiBalance, {
                decimals: 18,
              })}
              typography="h5"
              isLoading={!isVeChiLoadingBalance}
            />
          </FlexRow>
        </FlexCol>
        <Button
          className="mt-[8.98px] md:mt-[19px]"
          onClick={handleButtonClick}
          disabled={
            isBoosting || isApproving || !stChiValue || Number(stChiValue) === 0
          }
          loading={isBoosting || isApproving}
          fullWidth
          size="big"
          color="primary"
          {...getButtonPropsOverride(1)}
        >
          {Number(stChiValue || 0) > 0 || Number(veChiAmount || 0) > 0
            ? isApproved
              ? "Boost stCHI"
              : "Approve"
            : "Enter amount"}
        </Button>
      </FlexCol>
    </>
  );
};
