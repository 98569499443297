import { useState, useEffect } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { useAccount } from "wagmi";
import { contractAddresses, StakingManagerAbi } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  Button,
  DisplayableInputField,
  SVGWrapper,
} from "@shared";
import { displayTokens, formatMoney, formatUnitsToMaxValue } from "@utils";

/* ----------- */
/*    Icons    */
/* ----------- */
import { useButtonPropsOverride } from "../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../state/common/useStepState.ui";
/* ----------- */
/*    Icons    */
/* ----------- */
import UscEth from "@assets/tokens/uscEth.svg";
import ChiEth from "@assets/tokens/chiEth.svg";
import Usc from "@assets/tokens/usc.svg";
import { SmallDropdown, DropdownItem } from "../../smallDropdown/SmallDropdown";
import { useGetLPPriceByAddress } from "../../../../../state/data-provider/useGetLPPriceByAddress";
import { useGetUserStakedBalance } from "../../../../../state/stakingManager/useGetUserStakedBalance";
import { useWingsApprove } from "../../../../../state/common/useWingsApprove";

interface ChiEthUnstakeFormProps {
  selectedKey: string | null;
  onKeySelect: (key: string) => void;
}

export const ChiEthUnstakeForm: React.FC<ChiEthUnstakeFormProps> = ({
  selectedKey,
  onKeySelect,
}) => {
  const { isConnected, address } = useAccount();

  const items: DropdownItem[] = [
    { key: "uscEth", icon: UscEth, name: "USC/ETH LP", size: "large" },
    { key: "chiEth", icon: ChiEth, name: "CHI/ETH LP", size: "large" },
    { key: "usc", icon: Usc, name: "USC", size: "small" },
  ];

  const handleDropdownSelect = (item: DropdownItem) => {
    onKeySelect(item.key);
  };

  const { writeContractAsync: stakeAsync, isPending: isStaking } =
    useWingsContractWrite();

  const [uscValue, setUscValue] = useState("");
  const [stUscValue, setStUscValue] = useState("");

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    contractAddresses.CHI_ETH_LP_Staking,
    contractAddresses.CHI_ETH_LP,
    parseUnits(String(uscValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Unstake"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const onUscValueChange = (value: string) => {
    setUscValue(value);

    if (value) {
      setStUscValue(String(value));
    } else {
      setStUscValue("");
    }
  };

  const stakeFunction = async () => {
    await stakeAsync(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "unstake",
        args: [
          contractAddresses.CHI_ETH_LP,
          parseUnits(String(uscValue), etherUnits.wei),
          address!,
        ],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="CHI/ETH LP"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="Staked CHI/ETH LP"
                      />
                    }
                  />
                }
              />
            ),
          });
          setUscValue("");
          setStUscValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Unstaking in Process"}
          loading
          transferComponent={
            <Transfer
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={ChiEth} width={22} height={22} />}
                  text={formatMoney(stUscValue)}
                  symbol="Staked CHI/ETH LP"
                />
              }
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={ChiEth} width={22} height={22} />}
                  text={formatMoney(uscValue)}
                  symbol="CHI/ETH LP"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(uscValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="Staked CHI/ETH LP"
                      />
                    }
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={ChiEth} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="CHI/ETH LP"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await stakeFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await stakeFunction();
    }
  };

  const { balance, isFetching } = useGetUserStakedBalance(
    contractAddresses.CHI_ETH_LP
  );

  const { formattedPrice, isFetched } = useGetLPPriceByAddress("CHI_ETH_LP");

  const valueDSPL: DisplayableInputField = {
    value: formatMoney(Number(uscValue || 0) * Number(formattedPrice || 0)),
    isFetched,
    label: "Value: ",
  };

  const maxBalanceDSPL: DisplayableInputField = {
    value: displayTokens(balance || 0n, {}),
    isFetched: !isFetching,
  };

  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div>
        <InputField
          name="USCAmount"
          value={uscValue}
          onChange={(e) => onUscValueChange(e.target.value)}
          label="You Unstake"
          placeholder="0"
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!balance || !isConnected}
              onClick={() => {
                onUscValueChange(formatUnitsToMaxValue(balance)?.toString());
              }}
            >
              MAX
            </button>
          }
          dollarValue={valueDSPL}
          walletBalance={maxBalanceDSPL}
        />
      </div>
      <SmallDropdown
        items={items}
        onSelect={handleDropdownSelect}
        selectedItem={items.find((item) => item.key === selectedKey)}
      />

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={!isConnected || (!uscValue && !stUscValue)}
        loading={isStaking || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(uscValue || 0) > 0 || Number(stUscValue || 0) > 0
          ? isApproved
            ? "Unstake CHI/ETH LP"
            : "Approve"
          : "Enter amount"}
      </Button>
    </FlexCol>
  );
};
