import type { TNotificationProps } from "../../contexts/notification/NotificationContext";
import { FlexCol } from "../flexCol/FlexCol";
import { FlexRow } from "../flexRow/FlexRow";
import { ModalBody } from "../modal/ModalBody";
import { SVGWrapper } from "../svgWrapper/SvgWrapper";

import { Typography } from "../text/Typography/Typography";
import Lightning from "../../../assets/layout/lightningYellow.svg";

interface Props extends TNotificationProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DisplayNotification = ({
  content,
  status,
  txHash,
  setModalOpen,
}: Props) => {
  return (
    <ModalBody setModalOpen={setModalOpen} size="small">
      <FlexCol className="gap-2">
        {status === "success" && (
          <FlexRow className="gap-2 justify-start items-center mt-[-15px] mb-5">
            <SVGWrapper src={Lightning} width={14} height={14} />
            <Typography
              type="chi-protocol-extra-small-text"
              className="underline"
            >
              Have you considered staking your tokens?
            </Typography>
          </FlexRow>
        )}

        <div>{content}</div>

        <div className="mt-10">
          {status === "success" && txHash && (
            <FlexCol className="justify-center w-full items-center">
              <Typography type="chi-protocol-extra-small-text">
                <a
                  href={`https://etherscan.io/tx/${txHash}`}
                  target="_blank"
                  className="underline text-primary"
                >
                  View Explorer
                </a>
              </Typography>
            </FlexCol>
          )}
          {status === "loading" && (
            <FlexCol className="justify-center w-full items-center">
              <Typography type="chi-protocol-extra-small-text">
                Need help?{" "}
                <a
                  href={"https://chi-protocol.gitbook.io/chi-docs"}
                  target="_blank"
                  className="underline text-primary"
                >
                  Check Documentation
                </a>
              </Typography>
            </FlexCol>
          )}
          {/* {status !== "success" && (
            <Typography type="extra-small-text">
              Need help?{" "}
              <a href="#" className="underline">
                View FAQ
              </a>
            </Typography>
          )} */}
        </div>
      </FlexCol>
    </ModalBody>
  );
};
