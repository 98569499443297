import {
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  SingleComponent,
  StandardNotifBody,
  TransactionComplete,
  TransferMoney,
  useWingsContractWrite,
  useNotificationContext,
  StepsContainer,
  Button,
} from "@shared";

import { useGetChiRewards } from "../../../../../state/rewards/useGetChiRewards";
import { formatMoney } from "@utils";
import { contractAddresses, LockingManagerAbi, StakingManagerAbi } from "@meta";
import { useSpotPrice } from "../../../../../state/usc/useSpotPrice";
import { Address, formatUnits } from "viem";
import { useAccount } from "wagmi";
import { useStepState } from "../../../../../state/common/useStepState.ui";

/* ----------- */
/*    Icons    */
/* ----------- */
import ChiEth from "@assets/tokens/chiEth.svg";
import UscEth from "@assets/tokens/uscEth.svg";
import Chi from "@assets/tokens/chi.svg";
import BoostedWstUsc from "@assets/tokens/boostedWstUsc.svg";
import BoostedChiEth from "@assets/tokens/boostedChiEth.svg";
import BoostedUscEth from "@assets/tokens/boostedUscEth.svg";
import { useLockGetUserLockingRewards } from "../../../../../state/lockingManager/useGetUserRewards";
import { useEffect, useState } from "react";

export const ChiTooltipContent = () => {
  const rewardToken = contractAddresses.CHI;

  const {
    sum: chiSum,
    uscEthData: chiUscEthRewards,
    chiEthData: chiChiEthRewards,
  } = useGetChiRewards();

  const { address: userAddress } = useAccount();
  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps } = useStepState(
    ["Approve", "Claim"],
    1
  );

  const chiEthLpLockingRwards = useLockGetUserLockingRewards(
    contractAddresses.CHI_ETH_LP_Locking
  );
  const uscEthLpLockingRwards = useLockGetUserLockingRewards(
    contractAddresses.USC_ETH_LP_Locking
  );
  const wstUscLockingRwards = useLockGetUserLockingRewards(
    contractAddresses.WST_USC_Locking
  );

  const [currentClaimingLockingRewards, setCurrentClaimingLockingRewards] =
    useState<{
      [x: string]: string | undefined;
    }>({});
  useEffect(() => {
    const rewardData = {
      [contractAddresses.CHI_ETH_LP_Locking]: chiEthLpLockingRwards.viewValue,
      [contractAddresses.USC_ETH_LP_Locking]: uscEthLpLockingRwards.viewValue,
      [contractAddresses.WST_USC_Locking]: wstUscLockingRwards.viewValue,
    };
    setCurrentClaimingLockingRewards(rewardData);
  }, [
    chiEthLpLockingRwards.viewValue,
    uscEthLpLockingRwards.viewValue,
    wstUscLockingRwards.viewValue,
  ]);

  const { spotPrice: chiPrice, isFetched: isChiPriceFetched } = useSpotPrice(
    contractAddresses.CHI
  );

  const chiValue = Number(chiSum);
  const chiEthBalance = formatUnits(
    (chiEthLpLockingRwards.balance || 0n) +
      (uscEthLpLockingRwards.balance || 0n) +
      (wstUscLockingRwards.balance || 0n),
    18
  );

  // Final calculation for dollarSum
  const dollarSum = formatMoney(
    (chiValue + Number(chiEthBalance)) * Number(chiPrice),
    {}
  );

  const { writeContractAsync: stakedClaimAsync } = useWingsContractWrite();
  const { writeContractAsync: lockedClaimAsync } = useWingsContractWrite();

  const loadingNotif = (currentStep: number, lpToken: Address) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={Chi} width={22} height={22} />}
                  text={formatMoney(
                    lpToken === contractAddresses.CHI_ETH_LP
                      ? chiChiEthRewards.formattedChiEthData
                      : chiUscEthRewards.formattedUscEthData,
                    {}
                  )}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const loadingNotifLocking = (currentStep: number, rewardDetails: string) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={Chi} width={22} height={22} />}
                  text={rewardDetails}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleClaimAsync = async (lpToken: Address) => {
    loadingNotif(currentStep, lpToken);

    if (!userAddress) return;

    await stakedClaimAsync(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "claimRewardsForToken",
        args: [lpToken, rewardToken, userAddress],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={<SVGWrapper src={Chi} width={22} height={22} />}
                        text={formatMoney(
                          lpToken === contractAddresses.CHI_ETH_LP
                            ? chiChiEthRewards.formattedChiEthData
                            : chiUscEthRewards.formattedUscEthData,
                          {}
                        )}
                        symbol="CHI"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  const handleLockClaimAsync = async (lpToken: Address) => {
    if (currentClaimingLockingRewards === undefined) return;
    const rewardDetails = currentClaimingLockingRewards[lpToken];

    if (!rewardDetails || !userAddress) return;

    loadingNotifLocking(currentStep, rewardDetails);

    await lockedClaimAsync(
      {
        abi: LockingManagerAbi,
        address: lpToken,
        functionName: "claimRewards",
      },
      {
        onSuccess: (txHash) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={<SVGWrapper src={Chi} width={22} height={22} />}
                        text={rewardDetails}
                        symbol="CHI"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  return (
    <FlexCol className="w-[300px] gap-4 p-3">
      <FlexRow className="gap-2">
        <SVGWrapper src={Chi} width={20} height={20} />
        <Typography type="body-small-medium">CHI Rewards</Typography>
      </FlexRow>
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Staking
        </Typography>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(chiChiEthRewards.formattedChiEthData, {})}
            symbol="CHI"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] h-6 py-1 rounded-md"
            onClick={() => handleClaimAsync(contractAddresses.CHI_ETH_LP)}
            disabled={Number(chiChiEthRewards.formattedChiEthData) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={ChiEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            CHI/ETH LP
          </Typography>
        </FlexRow>

        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(chiUscEthRewards.formattedUscEthData, {})}
            symbol="CHI"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] h-6 py-1 rounded-md"
            onClick={() => handleClaimAsync(contractAddresses.USC_ETH_LP)}
            disabled={Number(chiUscEthRewards.formattedUscEthData) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={UscEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            USC/ETH LP
          </Typography>
        </FlexRow>
      </FlexCol>
      <FlexRow className="w-full h-[1px] bg-neutral-black-30" />

      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Boosting
        </Typography>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            {...wstUscLockingRwards}
            symbol="CHI"
            className="text-body-medium-medium"
          />
          <Button
            onClick={() =>
              handleLockClaimAsync(contractAddresses.WST_USC_Locking)
            }
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            disabled={Number(wstUscLockingRwards.viewValue) === 0}
          >
            Claim
          </Button>
        </FlexRow>

        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={BoostedWstUsc} width={18} height={23} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            Boosted wstUSC
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            {...chiEthLpLockingRwards}
            symbol="CHI"
            className="text-body-medium-medium"
          />
          <Button
            onClick={() =>
              handleLockClaimAsync(contractAddresses.CHI_ETH_LP_Locking)
            }
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            disabled={Number(chiEthLpLockingRwards.viewValue) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={BoostedChiEth} width={30} height={23} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            Boosted CHI/ETH LP
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            {...uscEthLpLockingRwards}
            symbol="CHI"
            className="text-body-medium-medium"
          />
          <Button
            onClick={() =>
              handleLockClaimAsync(contractAddresses.USC_ETH_LP_Locking)
            }
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            disabled={Number(uscEthLpLockingRwards.viewValue) === 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={BoostedUscEth} width={30} height={23} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            Boosted USC/ETH LP
          </Typography>
        </FlexRow>
      </FlexCol>
      <FlexCol className="w-full h-[1px] bg-neutral-black-30" />
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Total rewards
        </Typography>
        <DisplayMoney
          viewValue={dollarSum}
          isFetched={isChiPriceFetched}
          className="text-body-medium-medium"
        />
      </FlexCol>
    </FlexCol>
  );
};
