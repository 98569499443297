import { useState, useEffect } from "react";

// Konstante za Redis API
const KV_REST_API_URL = "https://assuring-catfish-39110.upstash.io";
const KV_REST_API_TOKEN =
  "AZjGAAIncDE2OGMyNDIxOTBkNTI0OTc2OTgzYzBkZmRmZTk1YTg0MXAxMzkxMTA";

export const useGetForeignAPYFromStorage = () => {
  const [apyData, setApyData] = useState({
    susde: 0,
    sfrax: 0,
    stusdt: 0,
    sdai: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAPY = async () => {
      try {
        setIsLoading(true);

        // Fetch podataka iz Redis-a
        const response = await fetch(`${KV_REST_API_URL}/get/defi-llama-data`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch APY data");
        }

        const result = await response.json();
        const parsedResult = result.result ? JSON.parse(result.result) : null;

        // Provera da li postoji rezultat
        if (!parsedResult || !parsedResult.data) {
          throw new Error("No valid data found in the response");
        }

        // Ovde pretpostavljamo da je rezultat JSON objekat koji sadrži potrebne podatke
        const newApyData = {
          susde:
            parsedResult.data.find((item: any) => item.symbol === "SUSDE")
              ?.apy ?? 0,
          sfrax:
            parsedResult.data.find((item: any) => item.symbol === "SFRAX")
              ?.apy ?? 0,
          stusdt:
            parsedResult.data.find((item: any) => item.symbol === "STUSDT")
              ?.apy ?? 0,
          sdai:
            parsedResult.data.find((item: any) => item.symbol === "SDAI")
              ?.apy ?? 0,
        };

        setApyData(newApyData);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("Unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAPY();
  }, []);

  return { apyData, isLoading, error };
};
