import React from "react";
import { NumericFormat } from "react-number-format";

import { Typography } from "../../../text/Typography/Typography";
import {
  CustomInput,
  InputFieldProps as BaseInputFieldProps,
} from "../CustomInput/CustomInput";
import { DisplayTokenAmount } from "../../../display/DisplayTokenAmount";
import { FlexCol } from "../../../flexCol/FlexCol";
import { FlexRow } from "../../../flexRow/FlexRow";
import { DisplayMoney } from "../../../display/DisplayMoney";

// Extend base input field props with additional properties
export interface ExtendedInputFieldProps extends BaseInputFieldProps {
  label?: string;
  labelElement?: React.ReactNode;
  upperRightLabel?: string;
  upperRightLabelElement?: React.ReactNode;
  dollarValue?: DisplayableInputField;
  walletBalance?: DisplayableInputField;
  walletJSX?: React.ReactNode;
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
  bottomRightMax?: React.ReactNode;
  variant?: "default" | "big";
}

export interface DisplayableInputField {
  value?: string;
  isFetched?: boolean;
  label?: string;
}

const InputField = React.forwardRef<HTMLInputElement, ExtendedInputFieldProps>(
  (
    {
      value,
      onChange,
      label,
      upperRightLabelElement,
      labelElement,
      upperRightLabel,
      dollarValue,
      walletBalance,
      leftLabel,
      rightLabel,
      walletJSX,
      bottomRightMax,
      ...props
    },
    ref
  ) => {
    const MAX_VAL =
      props.max !== undefined ? props.max : Number.MAX_SAFE_INTEGER;
    const MIN_VAL = props.min !== undefined ? props.min : 0;

    const withValueCap = (inputObj: { floatValue: any }) => {
      const { floatValue } = inputObj;
      return (
        floatValue === undefined ||
        (floatValue <= MAX_VAL && floatValue >= MIN_VAL)
      );
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = event.target.value.replace(/,/g, "");
      if (onChange) {
        onChange({
          ...event,
          target: {
            ...event.target,
            value: cleanedValue,
            name: event.target.name,
          },
        });
      }
    };

    return (
      <FlexCol className={`gap-2 ${props.fullWidth ? "w-full" : ""}`}>
        <FlexRow className="justify-between mb-1">
          {label ? (
            <Typography type="body-small-medium">{label}</Typography>
          ) : labelElement ? (
            labelElement
          ) : (
            <span />
          )}
          {upperRightLabel ? (
            <Typography type="body-small-medium">{upperRightLabel}</Typography>
          ) : upperRightLabelElement ? (
            upperRightLabelElement
          ) : (
            <span />
          )}
        </FlexRow>
        <NumericFormat
          value={value}
          allowLeadingZeros
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          thousandSeparator=","
          customInput={CustomInput}
          onChange={handleChange}
          isAllowed={withValueCap}
          {...props}
          {...ref}
        />
        <FlexRow className="justify-between px-1 text-[#949494]">
          {dollarValue ? (
            <span className="flex flex-row gap-1 items-center">
              <DisplayMoney
                typography="caption-regular"
                {...dollarValue}
                viewValue={dollarValue.value || "0"}
              />
            </span>
          ) : (
            <span />
          )}
          <FlexRow className="gap-2 items-center">
            {walletBalance ? (
              <span className="flex flex-row gap-1 items-center">
                <Typography type="caption-regular">
                  {walletBalance.label || ""}
                </Typography>
                <DisplayTokenAmount
                  typography="caption-regular"
                  {...walletBalance}
                  viewValue={walletBalance.value || "0"}
                />
              </span>
            ) : (
              <>{walletJSX ? walletJSX : <span />}</>
            )}
            {bottomRightMax && bottomRightMax}
          </FlexRow>
        </FlexRow>
      </FlexCol>
    );
  }
);

InputField.displayName = "InputField";

export { InputField };
