import { Card, FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";

import LineChart from "@assets/layout/lineChart.svg";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "@router";

export const Analytics = () => {
  const navigate = useNavigate();

  return (
    <Card hasBorder>
      <FlexCol className="w-full py-4 px-6 gap-4">
        <FlexRow className="items-center gap-2">
          <SVGWrapper src={LineChart} width="24px" height="24px" />
          <a onClick={() => navigate(RouterConfig.Routes.analytics)}>
            <Typography
              type="body-medium-bold"
              className="text-primary hover:underline cursor-pointer"
            >
              Analytics
            </Typography>
          </a>
        </FlexRow>
        <Typography type="body-small-regular">
          Check out a few of our metrics, reserves and revenues
        </Typography>
      </FlexCol>
    </Card>
  );
};
