import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons
import StUSC from "@assets/tokens/stUsc.svg";

export const FirstElementstUSC = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow items-center">
        <SVGWrapper src={StUSC} width={24} height={24} />

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular">stUSC</Typography>
          </FlexRow>
          <Typography
            className="text-[#949494] truncate"
            type="body-small-regular"
          >
            Staked USC
          </Typography>
        </div>
      </div>
    </>
  );
};
