import { RewardControllerBalances } from "./RewardControllerBalances";
import { DSOBalances } from "./DSO-OCHI-Balances";
import { TreasuryBalances } from "./TreasuryBalances";
import { ReserveHolderBalances } from "./ReserveHolderBalances";
import { PageContent } from "../components/PageContent/PageContent";

export default function ContractBalancesAdminPage() {
  return (
    <PageContent className="grid grid-cols-2 gap-2">
      <RewardControllerBalances />
      <DSOBalances />
      <TreasuryBalances />
      <ReserveHolderBalances />
    </PageContent>
  );
}
