import "@rainbow-me/rainbowkit/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
//* * OTHER **/
import "../global.d";
// import "./app/config/sentry.config"; todo sentry
//* * WAGMI **/
import { config } from "./app/config/wagmi.config";
import { WagmiProvider } from "wagmi";
//* * REACT QUERY **/
import { CustomQueryClientProvider } from "./contexts/CustomQueryClientProvider";
//* * RAINBOW **/
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
// app
import { App } from "./App";

import "./index.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {/* <Sentry.ErrorBoundary fallback={FallbackPage} showDialog> */}

    <WagmiProvider config={config}>
      <CustomQueryClientProvider>
        <RainbowKitProvider>
          <App />
        </RainbowKitProvider>
      </CustomQueryClientProvider>
    </WagmiProvider>
    {/* </Sentry.ErrorBoundary> */}
  </React.StrictMode>
);
