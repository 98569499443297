import React, { useState, FC } from "react";

import { TypographyType } from "../../text/Typography/mappers";
import { Typography } from "../../text/Typography/Typography";

type AccordionItemProps = {
  title: string;
  children: React.ReactNode;
  typography?: TypographyType;
};

export const AccordionItem: FC<AccordionItemProps> = ({
  title,
  children,
  typography,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-[24px]">
      <button
        className="w-full flex justify-between items-center gap-6"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography
          type={typography || "body-medium-medium"}
          className="text-start"
        >
          {title}
        </Typography>
        <svg
          className={`w-6 h-6 min-w-6 min-h-6 transform ${
            isOpen ? "rotate-180 " : "text-neutral-black-50"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && <div className="mt-4">{children}</div>}
    </div>
  );
};

type AccordionProps = {
  children: React.ReactNode;
  isFirst: boolean;
};

export const Accordion: FC<AccordionProps> = ({ children, isFirst }) => {
  return (
    <div
      className={` border-solid border-neutral-black-30 ${isFirst ? "" : "border-t-[1px]"}`}
    >
      {children}
    </div>
  );
};
