import { useAccount, useReadContract } from "wagmi";
import { useMemo } from "react";
import { DisplayTokensOptions, displayTokens } from "@utils";
import { Address, erc20Abi } from "viem";
import { queryConfigs } from "../../../shared";

/**
 * Use this custom hook to get the price from the PriceFeedAggregator.
 *
 * @param token - The token to query for the balance.
 * @returns An object containing price details or undefined if data is not available.
 */
export const useGetWalletBalanceByToken = (
  // token: "USC" | "CHI" | "WETH" | "stETH" | "veCHI",
  contractAddress?: Address,
  options?: DisplayTokensOptions
) => {
  const { address } = useAccount();

  const { data, isFetched, refetch, ...rest } = useReadContract({
    abi: erc20Abi,
    address: contractAddress,
    functionName: "balanceOf",
    args: [address as Address],
    query: {
      enabled: !!address && !!contractAddress,
      ...queryConfigs.balanceQueryConfig,
    },
  });

  const displayData = useMemo(() => {
    return {
      value: displayTokens(data, {
        ...options,
      }),
      ...rest,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, contractAddress, isFetched, rest.isLoading]);

  if (data === undefined) {
    return {
      displayData,
      viewValue: undefined,
      balance: undefined,
      ...rest,
      refetch,
    };
  }

  return {
    displayData,
    viewValue: displayData.value,
    balance: data,
    refetch,
    ...rest,
  };
};
