import {
  Card,
  DisplayMoney,
  DisplayPercentage,
  FlexCol,
  FlexRow,
  InputField,
  SVGWrapper,
  Typography,
} from "@shared";

import Coins from "@assets/layout/coins.svg";
import { useState } from "react";
import { ModalBody } from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */

import StUsc from "@assets/tokens/stUsc.svg";
import { formatMoney } from "@utils";
import { useFetchUscStakingAPR } from "../../../app/state/usc/useFetchUscStakingAPR";

export const SavingsCalculator = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { aprDSPL, isFetchedAPR } = useFetchUscStakingAPR();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const stUscValue = Number(inputValue) || 0;

  const aprValue = Number(aprDSPL.viewValue) || 0;

  const monthlyEarnings = (stUscValue * aprValue) / 12;
  const yearlyEarnings = stUscValue * aprValue;

  const formattedMonthlyEarnings = formatMoney(monthlyEarnings);
  const formattedYearlyEarnings = formatMoney(yearlyEarnings);

  return (
    <Card hasBorder>
      <FlexCol className="w-full py-4 px-6 gap-4">
        <FlexRow className="items-center gap-2">
          <SVGWrapper src={Coins} width="24px" height="24px" />
          <a onClick={toggleModal}>
            <Typography
              type="body-medium-bold"
              className="text-primary cursor-pointer hover:underline"
            >
              Savings Calculator
            </Typography>
          </a>
        </FlexRow>
        <Typography type="body-small-regular">
          Glance at estimated savings rewards with a simple built-in tool
        </Typography>
      </FlexCol>

      {isModalOpen && (
        <ModalBody setModalOpen={setModalOpen} size="modal">
          <FlexCol className="gap-6 px-2 pb-1 mt-[-15px]">
            <FlexCol className="gap-2">
              <Typography type="h2" className="text-button-selection-stroke">
                Savings Calculator
              </Typography>
              <Typography type="body-medium-regular">
                Estimate your future stUSC savings rewards with our simple
                calculator tool
              </Typography>
            </FlexCol>
            <FlexRow className="w-full gap-3 flex-col lg:flex-row lg:items-center">
              <Typography
                type="body-small-medium"
                className="whitespace-nowrap"
              >
                Enter your amount
              </Typography>
              <InputField
                leftLabel={
                  <SVGWrapper src={StUsc} width="24px" height="24px" />
                }
                value={inputValue}
                placeholder="Amount"
                fullWidth
                onChange={(e) => setInputValue(e.target.value)}
                maxLength={14}
              ></InputField>
            </FlexRow>
            <FlexRow className="w-full rounded-xl bg-primary-10 flex-col lg:flex-row">
              <FlexCol className="w-full gap-1 px-4 py-3">
                <Typography
                  type="body-small-medium"
                  className="text-neutral-black-60"
                >
                  APY
                </Typography>
                <FlexRow className="items-center">
                  <DisplayPercentage
                    viewValue={aprDSPL.viewValue}
                    isFetched={isFetchedAPR}
                    typography="body-large-medium"
                    symbol=""
                  />
                  <Typography type="body-large-medium">%</Typography>
                </FlexRow>
              </FlexCol>
              <FlexCol className="border bg-neutral-black-60"></FlexCol>
              <FlexCol className="w-full gap-1 px-4 py-3">
                <Typography
                  type="body-small-medium"
                  className="text-neutral-black-60"
                >
                  Est. 30 days Earnings
                </Typography>
                <FlexRow className="items-center">
                  <Typography type="body-large-medium">$</Typography>
                  <DisplayMoney
                    symbol=""
                    viewValue={formattedMonthlyEarnings}
                    typography="body-large-medium"
                    isFetched={isFetchedAPR}
                  />
                </FlexRow>
              </FlexCol>
              <FlexCol className="border bg-neutral-black-60"></FlexCol>
              <FlexCol className="w-full gap-1 px-4 py-3">
                <Typography
                  type="body-small-medium"
                  className="text-neutral-black-60"
                >
                  Est. Yearly Earnings
                </Typography>
                <FlexRow className="items-center">
                  <Typography type="body-large-medium">$</Typography>
                  <DisplayMoney
                    symbol=""
                    viewValue={formattedYearlyEarnings}
                    typography="body-large-medium"
                    isFetched={isFetchedAPR}
                  />
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        </ModalBody>
      )}
    </Card>
  );
};
