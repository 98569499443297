import {
  FlexCol,
  FlexRow,
  DisplayTokenAmount,
  Typography,
  Card,
  DisplayMoney,
  SVGWrapper,
} from "@shared";
import { InfoTooltip } from "../../tooltip/InfoTooltip";
import { contractAddresses } from "../../../../meta";
import { useReserveAssetByAddress } from "../../../state/reserveholder/useReserveAssetByAddres";

import weETHIcon from "@assets/tokens/weEth.svg";
import { useGetTokenPriceCrosschain } from "../../../state/price/useGetTokenPriceCrosschain";
import { formatMoney } from "../../../../utils";

export const WeETHCard = () => {
  const { data: weETH, ...weETHRest } = useReserveAssetByAddress(
    contractAddresses.weETH
  );
  const { formattedPrice: weEthPrice } = useGetTokenPriceCrosschain(
    contractAddresses.weETH
  );

  return (
    <FlexCol className="h-[60px] py-2 px-[10px] justify-between ">
      {/* Display the value and tooltip icon */}
      <FlexRow className="w-full items-start justify-between">
        <DisplayTokenAmount
          {...weETHRest}
          {...weETH.balanceOf}
          symbol=""
          typography="body-medium-medium"
        />
        <InfoTooltip>
          {/* Tooltip content displaying detailed weETH information */}
          <FlexCol className="w-[300px] h-[208px] p-3 gap-3 ">
            <FlexRow className="gap-3 items-center">
              <SVGWrapper src={weETHIcon} width="16px" height="16px" />
              <Typography type="body-small-medium">weETH</Typography>
            </FlexRow>

            {/* Conversion details for weETH */}
            <Card hasBorder hasShadowInstead className="w-full">
              <FlexRow className="w-full gap-1 px-3 py-[10px] items-center justify-between">
                <DisplayTokenAmount
                  {...weETHRest}
                  {...weETH.balanceOf}
                  symbol=""
                  typography="body-medium-regular"
                />
                <Typography
                  type="body-medium-regular"
                  className="text-neutral-black-50"
                >
                  weETH
                </Typography>
              </FlexRow>
            </Card>
            <Card hasBorder hasShadowInstead className="w-full">
              <FlexRow className="w-full gap-1 px-3 py-[10px] items-center justify-between">
                <DisplayMoney
                  {...weETH.dollarAmount}
                  symbol=""
                  typography="body-medium-regular"
                />

                <Typography
                  className="text-neutral-black-50"
                  type="body-medium-regular"
                >
                  USD
                </Typography>
              </FlexRow>
            </Card>

            {/* Conversion rate display */}
            <Typography
              type="caption-regular"
              className="text-neutral-black-50"
            >
              The price of converting 1 Wrapped eEther (weETH) to USD is{" "}
              {formatMoney(weEthPrice || 0, {})} today.
            </Typography>
          </FlexCol>
        </InfoTooltip>
      </FlexRow>

      {/* Asset identifier row */}
      <FlexRow className="w-full gap-1 items-center">
        <SVGWrapper src={weETHIcon} width="16px" height="16px" />
        <Typography type="caption-medium" className="text-neutral-black-60">
          weETH
        </Typography>
      </FlexRow>
    </FlexCol>
  );
};
