import React from "react";
import { DisplayValue, SVGWrapper } from "@shared";

//ICONS

import CHI from "@assets/tokens/chi.svg";
import RewardTokens from "@assets/tokens/tableHeaderReward.svg";
import { StateComponent } from "./CHIETHRow";

const CollapseButton: React.FC<{
  isCollapsed: boolean;
  toggleCollapse: () => void;
}> = ({ isCollapsed, toggleCollapse }) => (
  <div
    className="bg-white p-1 rounded-md cursor-pointer"
    onClick={toggleCollapse}
  >
    {isCollapsed ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 9L12 15L18 9"
          stroke="#949494"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 15L12 9L18 15"
          stroke="#949494"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )}
  </div>
);

const HeaderContent: React.FC<{ tvl?: string; apr?: string }> = ({
  tvl,
  apr,
}) => (
  <>
    <div className="w-full max-w-[200px]">
      <div className="flex flex-row gap-2">
        <SVGWrapper src={CHI} width={28} height={28} />
        <LocalText text="Chi Protocol" />
      </div>
    </div>

    <div className="w-full max-w-[160px]">
      <LocalText text={tvl} />
    </div>
    <div className="w-full max-w-[130px]">
      <LocalText text={apr} />
    </div>
    <div className="flex flex-row w-full max-w-[120px]">
      <div className="flex w-[100px] ">
        <SVGWrapper src={RewardTokens} width={48} height={18} />
      </div>
      <div className="flex w-1/5 items-center">
        <StateComponent isActive={true} />
      </div>
    </div>
  </>
);

const MobileHeaderContent: React.FC<{
  tvl?: string;
  apr?: string;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  isFetched?: boolean;
}> = ({ tvl, apr, toggleCollapse, isCollapsed, isFetched }) => (
  <div className="flex flex-col items-center w-full">
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row gap-2 items-center">
        <SVGWrapper src={CHI} width={28} height={28} />
        <LocalText text="Chi Protocol" />
      </div>
      <div className="flex flex-row items-center gap-1">
        <Chain />
        <CollapseButton
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />
      </div>
    </div>
    <div className="flex flex-row justify-between pt-2 items-center w-full">
      <div className="flex flex-row gap-1 text-left items-center">
        <p className="text-[#303030] text-[15px] not-italic font-normal leading-[normal]">
          TVL
        </p>
      </div>
      <LocalText text={tvl} isFetched={isFetched} />
    </div>
    <div className="flex flex-row gap-1 text-right items-center">
      <p className="text-[#303030] text-[15px] not-italic font-normal leading-[normal]">
        APR
      </p>
      <LocalText text={apr} />
    </div>
  </div>
);

export const TableHeader: React.FC<{
  toggleCollapse: () => void;
  isCollapsed: boolean;
  tvl?: string;
  apr?: string;
  isFetched?: boolean;
}> = ({ toggleCollapse, isCollapsed, tvl, apr, isFetched }) => {
  return (
    <>
      <div className="hidden md:flex p-4 py-3 flex-row items-center relative">
        <HeaderContent tvl={tvl} apr={apr} />
        <div className="absolute right-4">
          <CollapseButton
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
        </div>
      </div>

      <div className="md:hidden flex flex-col p-4 items-center">
        <MobileHeaderContent
          tvl={tvl}
          apr={apr}
          toggleCollapse={toggleCollapse}
          isCollapsed={isCollapsed}
          isFetched={isFetched}
        />
      </div>
    </>
  );
};

const LocalText: React.FC<{
  text?: string;
  isFetched?: boolean;
}> = ({ text, isFetched }) => {
  return (
    <div className="flex  flex-grow items-center">
      <DisplayValue
        viewValue={text}
        isFetched={!isFetched}
        className="text-body-small-regular"
        loaderSkeleton
      />
    </div>
  );
};

export const Chain: React.FC<{
  name?: string;
}> = ({ name = "Ethereum" }) => {
  return (
    <div
      style={{
        background: "rgba(0, 81, 166, 0.1)",
        borderRadius: "48px",
      }}
      className="flex flex-row gap-1 p-1 pr-3 max-h-8 items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <circle cx="12" cy="12.5" r="12" fill="white" />
        <path
          d="M11.9673 5.29999L11.8718 5.62424V15.0326L11.9673 15.1278L16.3344 12.5464L11.9673 5.29999Z"
          fill="#343434"
        />
        <path
          d="M11.9673 5.29999L7.60001 12.5464L11.9673 15.1278V10.5613V5.29999Z"
          fill="#8C8C8C"
        />
        <path
          d="M11.9673 15.9547L11.9135 16.0203V19.3717L11.9673 19.5288L16.3371 13.3746L11.9673 15.9547Z"
          fill="#3C3C3B"
        />
        <path
          d="M11.9673 19.5288V15.9547L7.60001 13.3746L11.9673 19.5288Z"
          fill="#8C8C8C"
        />
        <path
          d="M11.9673 15.1278L16.3344 12.5464L11.9673 10.5613V15.1278Z"
          fill="#141414"
        />
        <path
          d="M7.60001 12.5464L11.9673 15.1278V10.5613L7.60001 12.5464Z"
          fill="#393939"
        />
      </svg>
      <p className="text-[#0051A6] text-[15px] not-italic font-normal leading-[normal] rounded-[48px]">
        {name}
      </p>
    </div>
  );
};
