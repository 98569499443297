import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import { DisplayValue, Typography } from "../../../../shared";
import { formatUnits } from "viem";

export const DisplayTotalMintedUsc: React.FC = () => {
  const { data: totalMintedUsc, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "totalMintedUsc",
  });

  return (
    <div className="flex flex-row gap-2">
      <Typography>Total Minted USC:</Typography>
      <DisplayValue
        viewValue={formatUnits(BigInt(totalMintedUsc || 0), 18)}
        {...rest}
      />
    </div>
  );
};
