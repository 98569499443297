import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons

import veChi from "@assets/tokens/veChi.svg";

export const FirstElementveCHI = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow items-center">
        <SVGWrapper src={veChi} width={24} height={24} />

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular">veCHI</Typography>
          </FlexRow>
          <Typography className="text-[#949494]" type="body-small-regular">
            veCHI
          </Typography>
        </div>
      </div>
    </>
  );
};
