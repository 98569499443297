import React from "react";
import { NumericFormat } from "react-number-format";

import { CustomInput, InputFieldProps } from "../CustomInput/CustomInput";

// Define the props for InputField including the additional props you want to support
interface InputFieldPropsWithExtras extends InputFieldProps {
  isLoading?: boolean;
  isFetched?: boolean;
}

// Use React.forwardRef to forward the ref to the underlying input component
export const DisplayInputField = React.forwardRef<
  HTMLInputElement,
  InputFieldPropsWithExtras
>(({ value, onChange, isLoading, isFetched, ...props }, ref) => {
  return (
    <NumericFormat
      value={value}
      allowLeadingZeros
      thousandSeparator=","
      customInput={CustomInput}
      {...props}
      {...ref}
    />
  );
});

DisplayInputField.displayName = "DisplayInputField";
