import { Address } from "viem";
import { useReadContract } from "wagmi";
import { CHIStakingAbi, contractAddresses, CHILockingAbi } from "../../../meta";
import { displayTokens } from "../../../utils";

/**
 * Use this custom hook to get the second item of the stakes data.
 *
 * @returns The second item of the stakes data or undefined.
 */
export const useCHIStakes = (address?: Address) => {
  const {
    data: stakes,
    isFetched: isStakesFetched,
    refetch: refetchStakes,
  } = useReadContract({
    abi: CHIStakingAbi,
    address: contractAddresses.ChiStaking,
    functionName: "balanceOf",
    args: [address as Address],
  });

  const {
    data: lockedChi,
    isFetched: isLockedFetched,
    refetch: refetchLocked,
  } = useReadContract({
    abi: CHILockingAbi,
    address: contractAddresses.ChiLocking,
    functionName: "availableChiWithdraw",
    args: [address as Address],
  });

  let stake = (lockedChi || 0n) + (stakes || 0n);

  const refetch = async () => {
    await refetchStakes();
    await refetchLocked();
  };

  return {
    stake: stake,
    viewValue: displayTokens(stake, {}),
    isFetched: isLockedFetched || isStakesFetched,
    refetch: refetch as any,
  };
};
