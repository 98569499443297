import { priceQueryConfig } from "./../../../shared/queries/query-config/queryConfig";
import { useQuery } from "@tanstack/react-query";
import { Address, parseUnits } from "viem";
import { formatMoney } from "@utils";
import { IS_DEV_MODE } from "../../../globals";
import { getQueryClient } from "../../../contexts/CustomQueryClientProvider";
import type { TokenPrice } from "../price-feed-aggregator/useGetPriceByAddress";

const getChainNameByChainId = (chainId: number) => {
  switch (chainId) {
    case 1:
      return "ethereum";
    case 42161:
      return "arbitrum-one";
    case 8453:
      return "base";
    case 81457:
      return "blast";
    case 10:
      return "optimistic-ethereum";
    case 59144:
      return "linea";
    case 34443:
      return "mode";
    case 137:
      return "polygon-pos";
    case 324:
      return "zkSync";
    default:
      return "unknown";
  }
};

export const fetchPriceFromCoinGecko = async (
  address: Address,
  chainId: number
): Promise<TokenPrice> => {
  const chainName = getChainNameByChainId(chainId!);
  const queryClient = getQueryClient();

  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      // todo: move this to api
      "x-cg-demo-api-key": import.meta.env.VITE_COINGECKO_API_KEY,
    },
  };

  const result = await queryClient.fetchQuery({
    queryKey: ["fetchPriceByAddress", address, chainId],
    queryFn: async () => {
      const url = `${import.meta.env.VITE_COINGECKO_URL}/v3/simple/token_price/${chainName}?contract_addresses=${address}&vs_currencies=usd`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error("Failed to fetch price from CoinGecko");
      }
      const data = await response.json();
      return data;
    },
    staleTime: priceQueryConfig.staleTime,
  });

  const data = result[address.toLowerCase()]?.usd || 0;

  // Since CoinGecko returns the price directly in USD, no need for additional decimal scaling
  const priceInDollars = data ? data.toFixed(2) : undefined;

  // Assuming that 'formatMoney' should format the raw price to a human-readable currency string
  const formattedMoneyPrice = data ? formatMoney(data) : "0";

  return {
    priceInDollars,
    originalPrice: parseUnits(data.toString(), 8),
    formattedPrice: data,
    formattedMoneyPrice,
    decimals: 2,
  };
};

/**
 * Use this custom hook to get the price from the CoinGecko API.
 *
 * @param address - The contract address to query for the price.
 * @param chainId - The chain ID to use for the query.
 * @returns An object containing price details or undefined if data is not available.
 */
export const useGetCoingeckoPriceByAddress = (
  address?: Address,
  chainId?: number,
  enabled?: boolean
) => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ["hookPriceByAddress", address, chainId],
    queryFn: () => fetchPriceFromCoinGecko(address!, chainId!),
    enabled: !!address && !!chainId && enabled,
    staleTime: IS_DEV_MODE ? Infinity : 600 * 1000,
  });

  return {
    ...data,
    isFetched,
    refetch,
  };
};
