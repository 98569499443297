import React from "react";
import { Accordion, AccordionItem, FlexCol } from "@shared";

// FAQ Item type
interface FAQItem {
  title: string;
  content: React.ReactNode;
}

interface FAQsComponentProps {
  items: FAQItem[];
}

export const FAQsComponent: React.FC<FAQsComponentProps> = ({ items }) => {
  return (
    <FlexCol className=" gap-[8px] items-center">
      <FlexCol className="w-full items-left">
        {items.map((item, index) => (
          <AccordionGroup key={index} title={item.title} isFirst={index === 0}>
            {item.content}
          </AccordionGroup>
        ))}
      </FlexCol>
    </FlexCol>
  );
};

const AccordionGroup: React.FC<{
  title: string;
  children: React.ReactNode;
  isFirst: boolean;
}> = ({ title, children, isFirst }) => {
  return (
    <Accordion isFirst={isFirst}>
      <AccordionItem title={title}>
        <FlexCol>{children}</FlexCol>
      </AccordionItem>
    </Accordion>
  );
};
