import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import { DisplayValue, Typography } from "../../../../shared";

export const DisplayBurnPaused: React.FC = () => {
  const { data: burnPaused, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "burnPaused",
  });

  return (
    <div className="flex flex-row gap-2">
      <Typography>Burn Paused:</Typography>
      <DisplayValue viewValue={burnPaused ? "Yes" : "No"} {...rest} />
    </div>
  );
};
