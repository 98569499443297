import { Card, FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";
import { SupplyVsReservesChart } from "../charts/solvency/SupplyVsReserves";

import uscIcon from "@assets/tokens/usc.svg";
import wethIcon from "@assets/tokens/weth.svg";
import stEthIcon from "@assets/tokens/stEth.svg";

export const RevenueTab = () => {
  return (
    <div className="flex flex-col gap-8">
      <Card hasShadowInstead hasBorder>
        <FlexCol className="p-4 gap-6">
          <Typography type="body-small-medium">Solvency Stats</Typography>
          <FlexRow className="flex-col 2xl:flex-row md:items-center 2xl:gap-6">
            <FlexCol className="py-4 xs:py-8  2xl:w-1/3 xs:items-center md:text-center">
              <FlexRow className="gap-2">
                <SVGWrapper width={20} height={20} src={uscIcon} />
                <Typography type="body-large-medium">
                  ETH Staking Rewards
                </Typography>
              </FlexRow>
              <Typography className="text-primary" type="h3">
                404,646,014 USC
              </Typography>
            </FlexCol>

            <div className="divider divider-horizontal" />

            <FlexCol className="py-4 xs:py-8 2xl:w-1/3 xs:items-center xs:text-center">
              <FlexRow className="flex-col xs:flex-row xs:items-center gap-6">
                <FlexCol>
                  <FlexRow className="gap-2">
                    <SVGWrapper width={20} height={20} src={stEthIcon} />
                    <Typography
                      className="text-neutral-black-60"
                      type="body-medium-medium"
                    >
                      stETH Rewards
                    </Typography>
                  </FlexRow>
                  <Typography type="body-large-medium">
                    404,646,014 USC
                  </Typography>
                </FlexCol>
                <FlexCol>
                  <FlexRow className="gap-2">
                    <SVGWrapper width={20} height={20} src={wethIcon} />
                    <Typography
                      className="text-neutral-black-60"
                      type="body-medium-medium"
                    >
                      WETH Rewards
                    </Typography>
                  </FlexRow>
                  <Typography type="body-large-medium">
                    404,646,014 USC
                  </Typography>
                </FlexCol>
              </FlexRow>
            </FlexCol>

            <div className="divider divider-horizontal" />

            <FlexCol className="py-4 xs:py-82xl:w-1/3 xs:items-center xs:text-center">
              <FlexRow>
                <FlexRow className="gap-2">
                  <SVGWrapper width={20} height={20} src={uscIcon} />
                  <Typography type="body-large-medium">
                    Arbitrage Revenue
                  </Typography>
                </FlexRow>
              </FlexRow>
              <Typography className="text-primary" type="h3">
                $41,003,014
              </Typography>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </Card>

      <div className="flex gap-8 md:min-h-200px lg:min-h-[500px]">
        <Card hasShadowInstead className="w-full">
          <SupplyVsReservesChart
            name="USC Supply vs Reserves"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
      </div>
    </div>
  );
};
