import React from "react";
import {
  FlexRow,
  FlexCol,
  Typography,
  CustomDropdown,
  SVGWrapper,
} from "@shared";

export interface Option {
  key: string;
  name: string;
  icon: string;
}

const mapOptions2ToOptions = (option: Option[]) => {
  return option.map(({ key, name, icon }) => {
    return {
      key,
      value: name,
      renderOption: (
        <FlexRow className="items-center w-full gap-1 pr-1">
          <SVGWrapper src={icon} width={24} height={24} />
          <FlexCol className="items-start text-start">
            <Typography type="body-medium-regular">{name}</Typography>
          </FlexCol>
        </FlexRow>
      ),
    };
  });
};

export const TokenPicker: React.FC<{
  selectedAsset?: string;
  setSelectedAsset: (tab?: string) => void;
  options2: Option[];
}> = ({ selectedAsset, setSelectedAsset, options2 }) => {
  return (
    <CustomDropdown
      selectedOption={selectedAsset}
      options={mapOptions2ToOptions(options2)}
      setSelectedOption={(e) => {
        setSelectedAsset(e as string);
      }}
    />
  );
};
