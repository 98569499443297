"use client";

import { ReactNode } from "react";

import { Typography } from "../text/Typography/Typography";

interface ModalBodyProps {
  children: ReactNode;
  header?: string;
  headerComponent?: ReactNode;
  fullScreen?: boolean;
  setModalOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  className?: string;
  size?: "small" | "normal" | "big" | "biger" | "modal" | "mobile";
}

const sizeMapper = {
  small: "min-w-[350px] max-w-[500px]",
  normal: "max-w-[540px]",
  big: "max-w-[690px]",
  biger: "max-w-[920px]",
  modal: "w-[727px]",
  mobile: "w-[90%] -mt-10",
};

export const ModalBody: React.FC<ModalBodyProps> = ({
  children,
  header,
  fullScreen,
  headerComponent,
  setModalOpen,
  onClose,
  size = "small",
  className = "",
}) => {
  const handleOverlayMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      setModalOpen(false);

      if (onClose) onClose();
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto 
      transition-opacity duration-200 ease-in-out bg-black bg-opacity-50
      ${fullScreen ? "w-full h-full" : ""} ${className}`}
      style={{ opacity: 1 }}
      onMouseDown={handleOverlayMouseDown}
    >
      <div
        className={`relative ${
          sizeMapper[size]
        }  rounded-2xl mx-3 bg-white shadow-lg p-4
      transition-opacity duration-200 ease-in-out max-h-[92%] overflow-y-auto ${
        fullScreen ? "w-full h-full" : ""
      }
      scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-100`}
        style={{ opacity: 1 }}
      >
        <div className="flex flex-row items-start justify-between">
          {headerComponent || (
            <Typography type={"body-small-bold"}>{header}</Typography>
          )}
          <button
            className="hover:bg-action-focus relative z-50 rounded-full"
            type="button"
            onClick={() => {
              setModalOpen(false);
              onClose?.();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15 5L5 15"
                stroke="#949494"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 5L15 15"
                stroke="#949494"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        {children}
      </div>
    </div>
  );
};
