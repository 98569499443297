import { createTestClient, http } from "viem";
import { mainnet } from "viem/chains";
import { useBlockTime } from "../../../state/common/useBlockTime";
import {
  Button,
  Card,
  Divider,
  FlexCol,
  MyFormProvider,
  RHFInputFieldFormatted,
  Typography,
} from "../../../../shared";
import { useForm } from "react-hook-form";
import { useState } from "react";

const rpcUrl = import.meta.env.VITE_RPC_URL;
const testClient = createTestClient({
  chain: mainnet,
  mode: "hardhat",
  transport: http(rpcUrl),
});
interface FormData {
  amount: string;
}

export const ChangeTimeForm = () => {
  const { formmattedBlockDate, refetchBlockTime } = useBlockTime();
  const [isIncreasing, setIsIncreasing] = useState(false);

  const methods = useForm<FormData>();
  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: FormData) => {
    setIsIncreasing(true);
    const days = Number(data.amount);
    const secondsInADay = 24 * 60 * 60;
    const secondsToAdd = days * secondsInADay;

    await testClient.increaseTime({
      seconds: secondsToAdd,
    });

    await testClient.increaseTime({
      seconds: 10,
    });

    refetchBlockTime();
    reset();
    setIsIncreasing(false);
  };
  return (
    <Card>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="gap-4">
          <Typography type="body-small-bold">
            Current testnet Time:{" "}
            {formmattedBlockDate ? formmattedBlockDate : "Loading..."}
          </Typography>
          {/* <Typography type="meta">
            firstEpochTimestamp ISO (timestamp*1000):{" "}
            {firstEpochTimestamp ? displayDate(fet) : "Loading..."}
          </Typography>
          <Typography type="meta">
            currentEpoch RewardController:{" "}
            {currentEpoch ? currentEpoch.toString() : "Loading..."}
          </Typography>
          <Typography type="meta">
            currentEpoch ochi:{" "}
            {currentEpochochi ? currentEpochochi.toString() : "Loading..."}
          </Typography> */}
          <Divider />

          <Typography type="chi-protocol-small-text">
            Enter how many days you want to increase the testnet time
          </Typography>
          <RHFInputFieldFormatted<FormData> name="amount" placeholder="0" />
          <Button loading={isIncreasing} type="submit">
            Increase Time
          </Button>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
