import { InfoTooltip } from "../../../../components/tooltip/InfoTooltip";
import { FlexRow, SVGWrapper, Typography } from "@shared";
import React from "react";

export const LocalRow: React.FC<{
  icon: string;
  text: string;
  children: React.ReactNode;
  isIconBig?: boolean;
  tooltip?: React.ReactNode;
}> = ({ icon, text, children, isIconBig, tooltip }) => {
  return (
    <FlexRow className="items-center p-1 justify-between w-full">
      <FlexRow className="gap-2 items-center">
        <SVGWrapper
          src={icon}
          width={isIconBig ? 33.75 : 20}
          height={isIconBig ? 33.75 : 20}
        />
        <Typography type="body-small-regular">{text}</Typography>
        {tooltip ? <InfoTooltip openOnClick>{tooltip}</InfoTooltip> : null}
      </FlexRow>
      {children}
    </FlexRow>
  );
};
