import { Card, Typography, FlexRow, Divider, SVGWrapper } from "@shared";

interface MobileRowContainerProps {
  iconSrc: string;
  name: string;
  tvl: string;
  apr: string;
  reward: string;
}

export const MobileRowContainer: React.FC<MobileRowContainerProps> = ({
  iconSrc,
  name,
  tvl,
  apr,
  reward,
}) => {
  return (
    <Card hasBorder className="gap-2 p-3">
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Market & Pair
        </Typography>

        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={iconSrc} height={24} />
          <Typography type="body-small-regular">{name}</Typography>
        </FlexRow>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          TVL
        </Typography>
        <Typography type="body-small-regular">{tvl}</Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          APR
        </Typography>
        <Typography type="body-small-regular">{apr}</Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-50">
          Reward
        </Typography>
        <SVGWrapper src={reward} height={24} />
      </FlexRow>
    </Card>
  );
};
