import { StakingManagerAbi, contractAddresses } from "@meta";
import { Card, FlexCol, Typography, FlexRow } from "@shared";
import { format } from "date-fns";
import { TOKEN_CONFIG } from "../../../config/tokenConfig";
import { Address, formatUnits } from "viem";
import { useReadContract } from "wagmi";

const RewardStatus: React.FC<{
  tokenName: string;
  startTimestamp: bigint;
  endTimestamp: bigint;
  emissionPerSecond: bigint;
  rewardToken?: Address;
}> = ({
  tokenName,
  startTimestamp,
  endTimestamp,
  emissionPerSecond,
  rewardToken,
}) => {
  const rewardTokenSymbol =
    rewardToken && TOKEN_CONFIG[rewardToken]
      ? TOKEN_CONFIG[rewardToken].symbol
      : "N/A";

  return (
    <Card className="p-4 mb-4">
      <FlexCol className="gap-2">
        <Typography type="body-medium-bold">{tokenName}</Typography>
        <FlexRow className="justify-between">
          <Typography type="body-small-medium">Start:</Typography>
          <Typography type="body-small-regular">
            {formatDate(startTimestamp)}
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <Typography type="body-small-medium">End:</Typography>
          <Typography type="body-small-regular">
            {formatDate(endTimestamp)}
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <Typography type="body-small-medium">Emission/sec:</Typography>
          <Typography type="body-small-regular">
            {formatUnits(emissionPerSecond, 18)}
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <Typography type="body-small-medium">Reward Token:</Typography>
          <Typography type="body-small-regular">{rewardTokenSymbol}</Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <Typography type="body-small-medium">
            Reward Token Address:
          </Typography>
          <Typography type="body-small-regular">
            {rewardToken ? rewardToken : "N/A"}
          </Typography>
        </FlexRow>
      </FlexCol>
    </Card>
  );
};

const formatDate = (timestamp: bigint) => {
  const date = new Date(Number(timestamp) * 1000);
  return format(date, "dd/MM/yyyy HH:mm:ss");
};

const RewardStatusOverview: React.FC = () => {
  const rewardTokens = ["CHI", "stETH", "weETH"] as const;
  const lpTokens = [
    { name: "CHI/ETH LP", address: contractAddresses.CHI_ETH_LP },
    { name: "USC/ETH LP", address: contractAddresses.USC_ETH_LP },
  ];

  const fetchRewardConfig = (
    lpAddress: Address,
    reward: keyof typeof contractAddresses
  ) =>
    useReadContract({
      abi: StakingManagerAbi,
      functionName: "getRewardTokenConfig",
      address: contractAddresses.StakingManager,
      args: [lpAddress, contractAddresses[reward]],
    });

  return (
    <FlexCol className="gap-4">
      {lpTokens.map((lp) =>
        rewardTokens.map((reward) => {
          const { data: config } = fetchRewardConfig(lp.address, reward);

          if (!config) {
            return (
              <Typography
                type="body-small-regular"
                key={`${lp.name}-${reward}`}
              >
                Loading {lp.name} ({reward})...
              </Typography>
            );
          }

          return (
            <RewardStatus
              key={`${lp.name}-${reward}`}
              tokenName={`${lp.name} (${reward})`}
              startTimestamp={config.startTimestamp}
              endTimestamp={config.endTimestamp}
              emissionPerSecond={config.emissionPerSecond}
              rewardToken={contractAddresses[reward]}
            />
          );
        })
      )}
    </FlexCol>
  );
};

export default RewardStatusOverview;
