import { contractAddresses, USCStakingAbi } from "@meta";
import { displayTokens } from "@utils";
import { useGetPriceByAddress } from "../price-feed-aggregator/useGetPriceByAddress";
import { useReadContract } from "wagmi";

export const useTotalUscStaked = () => {
  const { data: totalUSCSTaked, isFetched } = useReadContract({
    abi: USCStakingAbi,
    functionName: "totalStaked",
    address: contractAddresses.USCStaking,
    args: [],
  });

  const { formattedPrice } = useGetPriceByAddress(contractAddresses.USC);

  return {
    valueDSPL: displayTokens(totalUSCSTaked, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    viewValue: displayTokens(totalUSCSTaked, {
      formattedPrice,
      displayInDollars: true,
      hideTokenAmount: true,
    }),
    isFetched,
  };
};
