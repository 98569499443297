import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons
import BoostedUscEth from "@assets/tokens/boostedUscEth.svg";

export const FirstElementBoostedUscEth = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow items-center">
        <SVGWrapper src={BoostedUscEth} width={34} height={25} />

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography
              type="body-small-regular"
              className="whitespace-nowrap truncate"
            >
              Boosted USC/ETH
            </Typography>
          </FlexRow>
          <Typography
            className="text-[#949494] truncate"
            type="body-small-regular"
          >
            USC/ETH
          </Typography>
        </div>
      </div>
    </>
  );
};
