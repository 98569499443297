import React from "react";
import { FlexRow, SVGWrapper } from "@shared";
import { ChainIcons } from "../../../../../../config/chainIcons";

interface Props {
  selectedChainId?: number;
  onChainChange: (chainId: number) => void;
}

// Ordered array of chain IDs
const chainOrder = [1, 8453, 42161, 81457, 10, 137];

export const ChainSwitcher: React.FC<Props> = ({
  selectedChainId,
  onChainChange,
}) => {
  return (
    <FlexRow className="gap-2 w-full justify-between lg:px-12">
      {chainOrder.map((chainId) => (
        <div
          key={chainId}
          className={`cursor-pointer p-1 rounded-full ${
            selectedChainId === chainId
              ? "bg-neutral-white-56"
              : "bg-transparent"
          }`}
          onClick={() => onChainChange(chainId)}
        >
          <SVGWrapper
            src={(ChainIcons as any)[chainId]}
            width={30}
            height={30}
            className="block lg:hidden"
          />
          <SVGWrapper
            src={(ChainIcons as any)[chainId]}
            width={40}
            height={40}
            className="hidden lg:block"
          />
        </div>
      ))}
    </FlexRow>
  );
};
