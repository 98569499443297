import {
  Card,
  DisplayPercentage,
  DisplayTokenAmount,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import { useFetchPercentageCircChiLocked } from "../../../../../state/boost/useFetchPercentageCircChiLocked";
import { displayTokens } from "@utils";
import { useFetchLockedChiDetailedAprDisplayable } from "../../../../../state/data-provider/useFetchLockedChiDetailedApr";
import { APRTooltip } from "../../../../liquidity/components/table/DataContainer";
import { ProgressBar } from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */

import VeCHI from "@assets/tokens/veChi.svg";
import ChiIcon from "@assets/tokens/chi.svg";
import StEth from "@assets/tokens/stEth.svg";
import Star from "@assets/layout/star.svg";
import Users from "@assets/layout/groupIcon.svg";
import Lamma from "@assets/layout/lamma.svg";
import Lizard from "@assets/layout/lizard.svg";
import Circle from "@assets/layout/circle.svg";

export const VeChiStats = () => {
  const { data, lockedCHI } = useFetchPercentageCircChiLocked();
  const { chiApr, stChiApr, totalApr, isFetchedAPR } =
    useFetchLockedChiDetailedAprDisplayable();

  const formattedTotalBoostedChi = displayTokens(lockedCHI.value, {
    decimals: 18,
  });

  return (
    <Card className=" px-2 py-4 " color="primary-10">
      <FlexCol className="min-h-[212px]">
        <FlexCol className="px-2 gap-4">
          <FlexRow className="justify-between items-center">
            <Typography type="body-large-bold">veCHI Statistics</Typography>
            <FlexRow className="gap-[6px]">
              <a href="/" className="hover:underline" target="_blank">
                <SVGWrapper src={Lamma} width="24px" height="24px" />
              </a>
              <a
                href="https://www.coingecko.com/en/coins/usc"
                className="hover:underline"
                target="_blank"
              >
                <SVGWrapper src={Lizard} width="24px" height="24px" />
              </a>
              <a
                href="https://dune.com/quackery/chi-protocol-usc"
                className="hover:underline"
                target="_blank"
              >
                <SVGWrapper src={Circle} width="24px" height="24px" />
              </a>
            </FlexRow>
          </FlexRow>{" "}
          <FlexCol className=" gap-4">
            <FlexRow className="gap-3 items-center">
              <SVGWrapper src={VeCHI} width={32} height={32} />
              <FlexCol className="gap-1">
                <DisplayPercentage
                  viewValue={totalApr}
                  isLoading={!isFetchedAPR}
                  typography="body-medium-medium"
                  className="text-secondary"
                />
                <FlexRow className="gap-1 items-center">
                  <Typography
                    type="caption-medium"
                    className="text-neutral-black-60"
                  >
                    veCHI APR
                  </Typography>

                  {/* TOOLTIP */}
                  <APRTooltip
                    items={[
                      {
                        icon: ChiIcon,
                        name: "CHI",
                        apr: chiApr,
                      },
                      {
                        icon: StEth,
                        name: "stETH",
                        apr: stChiApr,
                      },
                    ]}
                  />
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <FlexCol className=" gap-4">
            <FlexRow className="gap-3 items-center">
              <SVGWrapper src={Star} width={32} height={32} />
              <FlexCol className="gap-1">
                <DisplayTokenAmount
                  viewValue={formattedTotalBoostedChi}
                  isLoading={!lockedCHI.isFetched}
                  typography="body-medium-medium"
                  symbol="CHI"
                />
                <Typography
                  type="caption-medium"
                  className="text-neutral-black-60"
                >
                  Total Boosted CHI
                </Typography>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <FlexCol className=" gap-4">
            <FlexRow className="gap-3 items-center">
              <SVGWrapper src={Users} width={32} height={32} />
              <FlexCol className="gap-1">
                <FlexRow className="gap-1 w-[200px] items-center">
                  <SVGWrapper src={ChiIcon} width={20} height={20} />

                  <ProgressBar
                    percentage={
                      data.value
                        ? Number(data.value.substring(0, data.value.length - 1))
                        : 0
                    }
                  />
                  <DisplayPercentage
                    viewValue={data.value}
                    isLoading={!data.isFetched}
                    typography="body-small-medium"
                    className="text-secondary"
                  />
                </FlexRow>
                <Typography
                  type="caption-medium"
                  className="text-neutral-black-60"
                >
                  Percentage of CHI Boosted
                </Typography>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
