import { FlexRow, SVGWrapper, Typography } from "@shared";
//icons
import USCETH from "@assets/tokens/uscEth.svg";

export const FirstElementUSCETH = () => {
  return (
    <div>
      <div className="flex flex-row gap-2 flex-grow">
        <div className="flex items-center min-w-9">
          <SVGWrapper src={USCETH} width={34} height={20} />
        </div>

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular">USC/ETH</Typography>
          </FlexRow>
          <Typography className="text-[#949494]" type="body-small-regular">
            Staked USC/ETH
          </Typography>
        </div>
      </div>
    </div>
  );
};
