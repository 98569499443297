import { Card, FlexCol, Typography } from "@shared";

interface NoPositionsProps {
  loading: boolean;
}

export const NoPositions: React.FC<NoPositionsProps> = ({ loading }) => {
  return (
    <Card hasBorder className=" px-6 py-8 gap-10">
      <FlexCol className="gap-4 items-center">
        <FlexCol className="gap-2 items-center">
          <Typography type="body-medium-bold">
            {loading ? "Loading..." : "No positions yet!"}
          </Typography>
          <Typography type="caption-regular" className="text-neutral-black-60">
            You don’t have any positions yet. Boost now and receive daily
            rewards.
          </Typography>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
