import { useState, useEffect } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { useAccount } from "wagmi";
import { contractAddresses, USCStakingAbi } from "@meta";
/* ----------- */
/*    Icons    */
/* ----------- */
import USCLogo from "@assets/tokens/usc.svg";
import StUscLogo from "@assets/tokens/stUsc.svg";
import UscEth from "@assets/tokens/uscEth.svg";
import ChiEth from "@assets/tokens/chiEth.svg";
import Usc from "@assets/tokens/usc.svg";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  Button,
  DisplayableInputField,
  SVGWrapper,
} from "@shared";
import { formatMoney, displayTokens, formatUnitsToMaxValue } from "@utils";
import { useButtonPropsOverride } from "../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../state/common/useStepState.ui";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { SmallDropdown, DropdownItem } from "../../smallDropdown/SmallDropdown";
import { useSpotPrice } from "../../../../../state/usc/useSpotPrice";
import { useWingsApprove } from "../../../../../state/common/useWingsApprove";

interface UscUnstakeFormProps {
  selectedKey: string | null;
  onKeySelect: (key: string) => void;
}

export const UscUnstakeForm: React.FC<UscUnstakeFormProps> = ({
  selectedKey,
  onKeySelect,
}) => {
  const items: DropdownItem[] = [
    { key: "uscEth", icon: UscEth, name: "USC/ETH LP", size: "large" },
    { key: "chiEth", icon: ChiEth, name: "CHI/ETH LP", size: "large" },
    { key: "usc", icon: Usc, name: "USC", size: "small" },
  ];

  const handleDropdownSelect = (item: DropdownItem) => {
    onKeySelect(item.key);
  };

  const { isConnected, address } = useAccount();
  const uscPrice = useSpotPrice(contractAddresses.USC);

  const { writeContractAsync: unstakeAsync, isPending: isStaking } =
    useWingsContractWrite();

  const [uscValue, setUscValue] = useState("");
  const [stUscValue, setStUscValue] = useState("");

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    contractAddresses.USCStaking,
    contractAddresses.USC,
    parseUnits(String(stUscValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Unstake"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const onStUscChange = (value: string) => {
    setStUscValue(value);
    if (value) {
      setUscValue(String(value));
    } else {
      setUscValue("");
    }
  };

  const unstakeFunction = async () => {
    await unstakeAsync(
      {
        abi: USCStakingAbi,
        address: contractAddresses.USCStaking,
        functionName: "unstake",
        args: [parseUnits(String(uscValue), etherUnits.wei), address!],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="USC"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="stUSC"
                      />
                    }
                  />
                }
              />
            ),
          });
          setUscValue("");
          setStUscValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Unstaking in Process"}
          loading
          transferComponent={
            <Transfer
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={USCLogo} width={22} height={22} />}
                  text={formatMoney(stUscValue)}
                  symbol="USC"
                />
              }
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={StUscLogo} width={22} height={22} />}
                  text={formatMoney(uscValue)}
                  symbol="stUSC"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(stUscValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="USC"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="stUSC"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await unstakeFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await unstakeFunction();
    }
  };

  const { balance } = useGetWalletBalanceByToken(contractAddresses.USC);
  const { isSuccess: isStUscBalanceFetched, balance: stUscBalance } =
    useGetWalletBalanceByToken(contractAddresses.USCStaking);

  const displayableStUscWalletBalance: DisplayableInputField = {
    value: displayTokens(stUscBalance, {}),
    isFetched: isStUscBalanceFetched,
    label: "",
  };

  const displayableUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(uscValue) * Number(uscPrice?.spotPrice)),
    label: "$ ",
  };
  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div>
        <InputField
          name="StUSCAmount"
          value={stUscValue}
          onChange={(e) => onStUscChange(e.target.value)}
          label="You Unstake"
          placeholder="0"
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!balance || !isConnected}
              onClick={() => {
                onStUscChange(formatUnitsToMaxValue(stUscBalance)?.toString());
              }}
            >
              MAX
            </button>
          }
          walletBalance={displayableStUscWalletBalance}
          dollarValue={displayableUscDollarValue}
        />
      </div>

      <SmallDropdown
        items={items}
        onSelect={handleDropdownSelect}
        selectedItem={items.find((item) => item.key === selectedKey)}
      />

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={!isConnected || (!uscValue && !stUscValue)}
        loading={isStaking || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(uscValue || 0) > 0 || Number(stUscValue || 0) > 0
          ? isApproved
            ? "Unstake stUSC"
            : "Approve"
          : "Enter amount"}
      </Button>
    </FlexCol>
  );
};
