import { useReadContract } from "wagmi";
import { USCAbi, contractAddresses } from "../../../meta";
import { displayTokens } from "../../../utils";

export const useTotalUscSupply = () => {
  const { data: totalSupply, ...restTotalSupply } = useReadContract({
    abi: USCAbi,
    address: contractAddresses.USC,
    functionName: "totalSupply",
  });

  const viewValue = displayTokens(totalSupply, {
    formattedPrice: 1,
    displayInDollars: true,
    hideTokenAmount: true,
  });

  return {
    ...restTotalSupply,
    viewValue,
    originalValue: totalSupply,
  };
};
