import { useMemo } from "react";
import { useAccount, useReadContract } from "wagmi";
import { addWeeks, differenceInDays, fromUnixTime } from "date-fns";
import { useBlockTime } from "../../../../../state/common/useBlockTime";
import { CHILockingAbi, contractAddresses, RewardControllerAbi } from "@meta";

// import { LockedPosition, fetchOptions } from "./fetchOptions";

export interface AllLockedPositionsOutputDto {
  position: LockedPositionDto;
  votingPower: bigint | undefined;
  stETHreward: bigint | undefined;
  totalAccumulatedChi: bigint | undefined;
  totalChiRewards: bigint | undefined;
}
export interface LockedPositionDto {
  amount: bigint | undefined;
  startEpoch: bigint | undefined;
  duration: bigint | undefined; // in epochs
  shares: bigint | undefined;
  withdrawnChiAmount: bigint | undefined;
}

export interface LockedPosition {
  id: number;
  timeToUnlock: string;
  startDate: Date;
  unlockDate: Date;
  veCHIAmount: bigint | undefined;
  claimableETHRewards: bigint | undefined;
  claimableCHIRewards: bigint | undefined;
  isExpired?: boolean;
  additionalData: {
    endEpoch?: number;
    unlockEpochs?: number;
  };
  hideBorderBot?: boolean;
}

export const useFetchPositions = () => {
  const { address } = useAccount();

  const { block } = useBlockTime();

  const { data: firstEpochTimestamp } = useReadContract({
    abi: RewardControllerAbi,
    address: contractAddresses.RewardController,
    functionName: "firstEpochTimestamp",
  });

  const { data: getAllLockedPositions, isFetched: isFetched1 } =
    useReadContract({
      abi: CHILockingAbi,
      functionName: "getAllLockedPositions",
      address: contractAddresses.ChiLocking,
      args: [address!],
    });

  const { data: currentEpoch } = useReadContract({
    abi: CHILockingAbi,
    address: contractAddresses.ChiLocking,
    functionName: "currentEpoch",
  });

  const result: LockedPosition[] = useMemo(() => {
    return (
      (getAllLockedPositions as AllLockedPositionsOutputDto[])?.map(
        (data, index) => {
          const firstEpochDate = fromUnixTime(Number(firstEpochTimestamp));
          const startDate = addWeeks(
            firstEpochDate,
            Number(data.position.startEpoch)
          );

          console.log("data", data);

          const endEpoch =
            Number(data.position.startEpoch) + Number(data.position.duration);

          const unlockDate = addWeeks(firstEpochDate, endEpoch - 1);

          const currentDate = block?.timestamp
            ? new Date(Number(block.timestamp) * 1000)
            : new Date();
          const daysToUnlock = differenceInDays(unlockDate, currentDate);

          return {
            id: index,
            claimableCHIRewards: data.totalChiRewards,
            claimableETHRewards: data.stETHreward,
            veCHIAmount: data.votingPower,
            timeToUnlock:
              daysToUnlock <= 0 ? "Expired" : `${daysToUnlock} Days`,
            startDate: startDate,
            unlockDate: unlockDate,
            isExpired: daysToUnlock <= 0,
            additionalData: {
              endEpoch,
            },
          };
        }
      ) || []
    );
  }, [getAllLockedPositions, firstEpochTimestamp, currentEpoch]);

  return { positions: result, isFetched: isFetched1 };
};
