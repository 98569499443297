import { CHIAbi, CHILockingAbi, CHIVestingAbi, contractAddresses } from "@meta";
import { DisplayableInputField } from "@shared";
import { calculatePercentage } from "@utils";
import { useMemo } from "react";
import { formatUnits } from "viem";
import { useReadContract } from "wagmi";

/**
 * Custom hook to fetch and format the estimated yield per week data.
 *
 * Display value is percentage.
 * Display value is formated with calculatePercentage function.
 *
 * This hook uses the Wings Contract to read the estimated yield per week data
 * and formats it for display. If `calculateDisplayData` is set to false, the
 * display value defaults to a placeholder. The display format is handled by
 * the `displayTokens` utility.
 *
 * @param {boolean} calculateDisplayData - Flag to determine if display data should be calculated.
 * @returns An object containing the formatted display data and the raw estimated yield per week data.
 *
 * @example
 * ```typescript
 * const { data, estimatedYieldPerWeek } = useFetchEstimatedYieldPerWeekData(true);
 * // data.displayValue will contain the formatted estimated yield per week.
 * ```
 */
export const useFetchPercentageCircChiLocked = (
  calculateDisplayData = true
): {
  data: DisplayableInputField;
  lockedCHI: {
    value: bigint;
    isFetched: boolean;
  };
} => {
  const contractReads = {
    ChiLocking: useReadContract({
      abi: CHILockingAbi,
      functionName: "getLockedChi",
      address: contractAddresses.ChiLocking,
    }),
    CHITotalSupply: useReadContract({
      abi: CHIAbi,
      functionName: "totalSupply",
      address: contractAddresses.CHI,
    }),
    ChiVesting: useReadContract({
      abi: CHIVestingAbi,
      functionName: "getLockedChi",
      address: contractAddresses.ChiVesting,
    }),
  };

  // Compute the sum of locked CHI
  const lockedCHI = useMemo(() => {
    return (
      BigInt(contractReads.ChiLocking.data ?? 0) +
      BigInt(contractReads.ChiVesting.data ?? 0)
    );
  }, [contractReads.ChiLocking.data, contractReads.ChiVesting.data]);

  // Compute the display value
  const displayValue = useMemo(() => {
    if (!calculateDisplayData) return "/";
    if (
      !contractReads.ChiLocking.isFetched ||
      !contractReads.CHITotalSupply.isFetched ||
      !contractReads.ChiVesting.isFetched
    )
      return "/";

    const percentage = calculatePercentage(
      formatUnits(lockedCHI || 0n, 18),
      formatUnits(contractReads.CHITotalSupply.data || 0n, 18),
      {
        maxDecimals: 6,
      }
    );

    return percentage || "0%";
  }, [
    lockedCHI,
    contractReads.CHITotalSupply.data,
    calculateDisplayData,
    contractReads.ChiLocking.isFetched,
    contractReads.CHITotalSupply.isFetched,
    contractReads.ChiVesting.isFetched,
  ]);

  return {
    data: {
      value: displayValue,
      isFetched:
        contractReads.ChiLocking.isFetched &&
        contractReads.CHITotalSupply.isFetched &&
        contractReads.ChiVesting.isFetched,
    },
    lockedCHI: {
      value: lockedCHI,
      isFetched:
        contractReads.ChiLocking.isFetched &&
        contractReads.ChiVesting.isFetched,
    },
  };
};
