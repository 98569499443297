import { useState } from "react";
import { BoostingHeader } from "./BoostingHeader";
import { BoostingTableRowDesktop } from "./BoostingTableRowDesktop";
import { contractAddresses } from "@meta";
import { TOKEN_CONFIG } from "../../../../config/tokenConfig";
import { useBoostedTokenBalance } from "../../../../state/positions/useBoostedUserPositions";
import { Address } from "viem";
import { NoPositions } from "./NoPositions";
import { MobileBoostingTableRowDesktop } from "../mobileBoostingTable/MobileBoostingRow";
import { Dropdown } from "./Dropdown";

const boostedTokenAddresses: Address[] = [
  contractAddresses.USC_ETH_LP_Locking,
  contractAddresses.CHI_ETH_LP_Locking,
  contractAddresses.WST_USC_Locking,
];

const boostedTokenOptions = boostedTokenAddresses.map((address) => ({
  value: address,
  label: TOKEN_CONFIG[address].symbol,
  icon: TOKEN_CONFIG[address].logo,
}));

export const BoostingTableBody = () => {
  const [selectedTokens, setSelectedTokens] = useState<Address[]>(
    boostedTokenAddresses
  );

  const boostedPositionsByToken = boostedTokenAddresses.reduce(
    (acc, tokenAddress) => {
      const { positions, isFetched } = useBoostedTokenBalance(tokenAddress);
      acc[tokenAddress] = { positions, isFetched };
      return acc;
    },
    {} as Record<
      Address,
      {
        positions: ReturnType<typeof useBoostedTokenBalance>["positions"];
        isFetched: boolean;
      }
    >
  );
  console.log({ boostedPositionsByToken })

  const allFetched = boostedTokenAddresses.every(
    (tokenAddress) => boostedPositionsByToken[tokenAddress].isFetched
  );

  const hasPositions = boostedTokenAddresses.some(
    (tokenAddress) =>
      selectedTokens.includes(tokenAddress) &&
      (boostedPositionsByToken[tokenAddress].positions?.length || 0) > 0
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="hidden 2xl:block">
        <BoostingHeader
          selectedTokens={selectedTokens}
          setSelectedTokens={setSelectedTokens}
        />
      </div>
      <div className="2xl:hidden">
        <Dropdown
          options={boostedTokenOptions}
          selectedValues={selectedTokens}
          setSelectedValues={setSelectedTokens}
          label="Filter Tokens"
        />
      </div>

      {allFetched ? (
        hasPositions ? (
          boostedTokenAddresses
            .filter((tokenAddress) => selectedTokens.includes(tokenAddress))
            .map((tokenAddress) => {
              const tokenConfig = TOKEN_CONFIG[tokenAddress];
              const boostedPositions =
                boostedPositionsByToken[tokenAddress].positions;

              if (!boostedPositions || !tokenConfig) return null;

              return boostedPositions.map((position, index) => (
                <>
                  {console.log("CHI:   " + position.chiReward)}
                  <div className="hidden 2xl:block">
                    <BoostingTableRowDesktop
                      key={`${tokenAddress}-${index}`}
                      isActive={position.isActive}
                      iconSrc={tokenConfig.logo}
                      tokenName={tokenConfig.symbol}
                      amount={position.amount.viewValue}
                      unlockDate={position.unlockDate.toLocaleDateString()}
                      chiReward={position.chiReward}
                      index={index}
                      tokenAddress={tokenAddress}
                    />
                  </div>
                  <div className="2xl:hidden">
                    <MobileBoostingTableRowDesktop
                      key={`${tokenAddress}-${index}`}
                      isActive={position.isActive}
                      iconSrc={tokenConfig.logo}
                      tokenName={tokenConfig.symbol}
                      amount={position.amount.viewValue}
                      unlockDate={position.unlockDate.toLocaleDateString()}
                      chiReward={position.chiReward}
                      index={index}
                      tokenAddress={tokenAddress}
                    />
                  </div>
                </>
              ));
            })
        ) : (
          <NoPositions loading={false} />
        )
      ) : (
        <NoPositions loading={true} />
      )}
    </div>
  );
};
