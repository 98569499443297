import React from "react";

import { Typography } from "../text/Typography/Typography";

/* ----------- */
/*    Icons    */
/* ----------- */
import Shield from "../../../assets/layout/successShield.svg";
import { SVGWrapper } from "../svgWrapper/SvgWrapper";

export const TransactionComplete: React.FC<{
  className?: string;
  label?: string;
}> = ({ className = "", label = "Transaction Complete" }) => {
  return (
    <>
      <div
        className={`flex flex-col items-center justify-center gap-7 mb-7 ${className}`}
      >
        <Typography type="chi-protocol-large">{label}</Typography>
        <SVGWrapper src={Shield} width={70} height={80} />
      </div>
    </>
  );
};
