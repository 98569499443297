import { CHIStakingAbi, contractAddresses } from "@meta";
import { displayTokens } from "@utils";
import { useGetLPPriceByAddress } from "../data-provider/useGetLPPriceByAddress";
import { useReadContract } from "wagmi";

export const useChiEthLpTotalSupply = () => {
  const { formattedPrice: formattedCHIETHLPPrice, isFetched: isFetchedPrice } =
    useGetLPPriceByAddress("CHI_ETH_LP");

  const { data: totalSupplyChi, isFetched: isFetchedE } = useReadContract({
    abi: CHIStakingAbi,
    functionName: "totalSupply",
    address: contractAddresses.CHI_ETH_LP_Staking,
  });

  return {
    valueDSPL: displayTokens(totalSupplyChi, {
      displayInDollars: true,
      formattedPrice: formattedCHIETHLPPrice,
      hideTokenAmount: true,
    }),
    isFetched: isFetchedPrice && isFetchedE,
  };
};
