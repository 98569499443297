import { formatMoney } from "@utils";
import { formatUnits } from "viem";
import { useFetchChiLockingAPR } from "./useFetchChiLockingAPR";

const decimals = 18;

export const useFetchLockedChiDetailedApr = () => {
  const { apr, isFetchedAPR } = useFetchChiLockingAPR();

  const result: bigint[] | undefined = apr as unknown as bigint[] | undefined;

  const totalAprOriginal = result ? result[0] : undefined;
  const chiAprOriginal = result ? result[1] : undefined;
  const stChiAprOriginal = result ? result[2] : undefined;

  const totalAprFormatted = formatUnits(totalAprOriginal || 0n, decimals);
  const chiAprFormatted = formatUnits(chiAprOriginal || 0n, decimals);
  const stChiAprFormatted = formatUnits(stChiAprOriginal || 0n, decimals);
  const boostedAndChiAprFormatted = formatUnits(chiAprOriginal || 0n, decimals);

  return {
    totalApr: {
      original: totalAprOriginal,
      formatted: totalAprFormatted,
    },
    chiApr: {
      original: chiAprOriginal,
      formatted: chiAprFormatted,
    },
    stChiApr: {
      original: stChiAprOriginal,
      formatted: stChiAprFormatted,
    },
    boostedAndChiApr: {
      original: chiAprOriginal || 0n,
      formatted: boostedAndChiAprFormatted,
    },
    isFetchedAPR,
  };
};

export const useFetchLockedChiDetailedAprDisplayable = () => {
  const { totalApr, chiApr, stChiApr, isFetchedAPR, boostedAndChiApr } =
    useFetchLockedChiDetailedApr();

  const formatAprForDisplay = (aprValue: string) =>
    formatMoney(Number(aprValue || 0) * 100);

  return {
    totalApr: formatAprForDisplay(totalApr.formatted),
    chiApr: formatAprForDisplay(chiApr.formatted),
    stChiApr: formatAprForDisplay(stChiApr.formatted),
    boostedAndChiApr: formatAprForDisplay(boostedAndChiApr.formatted),
    totalAprOriginal: totalApr.original,
    isFetchedAPR,
  };
};
