import {
  InputHTMLAttributes,
  ChangeEventHandler,
  ReactNode,
  forwardRef,
} from "react";
import cn from "classnames";

import styles from "./CustomInput.module.css";

export interface InputFieldProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "defaultValue" | "value" | "type"
  > {
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  rightLabel?: ReactNode;
  leftLabel?: ReactNode;
  fullWidth?: boolean;
  value: string | number | null | undefined;
  variant?: "default" | "big";
}

export const CustomInput = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      onChange,
      name,
      rightLabel,
      leftLabel,
      placeholder,
      fullWidth,
      className,
      variant = "default",
      ...rest
    },
    ref
  ) => {
    const inputType = "text";
    const classes = cn(
      styles.container,
      { ["w-full"]: fullWidth },
      { [styles.bigContainer]: variant === "big" },
      className
    );
    const inputClasses = cn(
      styles.inputRoot,
      styles.inputFont,
      "w-full",
      { [styles.bigInputRoot]: variant === "big" },
      { [styles.bigInputFont]: variant === "big" }
    );

    return (
      <div className={`${classes}`}>
        {leftLabel && <span className="inline-block pr-2">{leftLabel}</span>}
        <input
          ref={ref}
          type={inputType}
          className={inputClasses}
          autoComplete="off"
          value={value as any}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          step="any"
          min={rest.min || 0}
          {...rest}
        />
        {rightLabel && <span>{rightLabel}</span>}
      </div>
    );
  }
);

CustomInput.displayName = "CustomInput";
