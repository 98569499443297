import React, { useEffect, useState } from "react";
import { SVGWrapper } from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */
import Selector from "@assets/layout/selector.svg";

export interface DropdownItem {
  key: string;
  icon: string;
  name: string;
  size: "small" | "large";
}

interface IconDropdownProps {
  items: DropdownItem[];
  onSelect: (item: DropdownItem) => void;
  selectedItem?: DropdownItem;
}

export const SmallDropdown: React.FC<IconDropdownProps> = ({
  items,
  onSelect,
  selectedItem: propSelectedItem,
}) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(
    propSelectedItem || items[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  const getItemIconSize = (size: "small" | "large") => {
    return size === "small"
      ? { width: 24, height: 24 }
      : { width: 40, height: 24 };
  };

  const getSelectedItemIconSize = (size: "small" | "large") => {
    return size === "small"
      ? { width: 16, height: 16 }
      : { width: 27, height: 16 };
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectItem = (item: DropdownItem) => {
    setSelectedItem(item);
    setIsOpen(false);
    onSelect(item);
  };

  useEffect(() => {
    if (propSelectedItem) {
      setSelectedItem(propSelectedItem);
    }
  }, [propSelectedItem]);

  return (
    <div
      className={`dropdown dropdown-bottom ${isOpen ? "dropdown-open" : ""}`}
    >
      <div
        role="button"
        className="flex items-center m-1 bg-white w-[67px] border rounded-lg px-[10px] py-2 justify-between"
        onClick={toggleDropdown}
      >
        {selectedItem && (
          <SVGWrapper
            src={selectedItem.icon}
            width={getSelectedItemIconSize(selectedItem.size).width}
            height={getSelectedItemIconSize(selectedItem.size).height}
          />
        )}
        <SVGWrapper src={Selector} width={16} height={16} />
      </div>

      {isOpen && (
        <ul
          tabIndex={0}
          className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
        >
          {items.map((item, index) => (
            <li key={index}>
              <a
                onClick={() => handleSelectItem(item)}
                className="flex items-center gap-2"
              >
                <SVGWrapper
                  src={item.icon}
                  width={getItemIconSize(item.size).width}
                  height={getItemIconSize(item.size).height}
                />
                <span>{item.name}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
