import { useForm } from "react-hook-form";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "../../../../shared";
import { DisplayMintPaused } from "./DisplayMintPaused";

export const SetMintPause: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: setMintPause, isPending: isUpdating } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setMintPause(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "setMintPause",
        args: [data.status === "true"],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Arbitrager updated successfully</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <DisplayMintPaused />
          <Typography>Arbitrage / setBurnPause /</Typography>
          <FlexCol className="gap-3">
            <select
              {...methods.register("status")}
              className="select select-bordered w-full"
            >
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </select>
            <Button type="submit" loading={isUpdating}>
              Update Arbitrager
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
