export function pxToRem(px: number, baseFontSize = 16) {
  return `${px / baseFontSize}rem`;
}

export const fontSizes = {
  "body-small-bold": pxToRem(10),
  "body-large-regular": pxToRem(16),
  "body-medium-regular": pxToRem(14),
  "body-medium-medium": pxToRem(14),
  "body-small-regular": pxToRem(14),
  "body-large-medium": pxToRem(18),
  "body-large-bold": pxToRem(18),
  "body-medium-bold": pxToRem(16),
  "chi-protocol-small-text": pxToRem(10),
  "chi-protocol-extra-small-text": pxToRem(8),
  "chi-protocol-small-bold": pxToRem(10),
  "chi-protocol-large": pxToRem(20),
  "caption-regular": pxToRem(12),
  "body-small-medium": pxToRem(14),
  "caption-medium": pxToRem(12),
  h2: pxToRem(40),
  h3: pxToRem(20),
  h5: pxToRem(24),
};
