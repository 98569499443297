import { formatUnits, formatEther } from "viem";
import { calculatePercentage } from "../../../utils";
import { useReadContract } from "wagmi";
import { contractAddresses, ReserveHolderAbi, USCAbi } from "../../../meta";
import { Displayable, mergeQueryStates } from "../../../shared";

const calculateCollateralRatio = (reserves?: bigint, debt?: bigint) => {
  if (!reserves || !debt) return "";

  const reserveValue = formatUnits(reserves, 8);
  const debtInEther = formatEther(debt);

  return calculatePercentage(reserveValue, debtInEther);
};

export const useCollateralRatio = (): Displayable<{
  viewValue?: string;
}> => {
  const { data: reservesValue, ...reserveValueRest } = useReadContract({
    abi: ReserveHolderAbi,
    address: contractAddresses.ReserveHolder,
    functionName: "getReserveValue",
  });

  // multiply by 1$?
  const { data: uscTotalSupply, ...totalSupplyRest } = useReadContract({
    abi: USCAbi,
    address: contractAddresses.USC,
    functionName: "totalSupply",
  });
  console.log({
    rs: calculateCollateralRatio(reservesValue, uscTotalSupply),
  });

  return {
    ...mergeQueryStates([reserveValueRest, totalSupplyRest]),
    data: {
      viewValue: calculateCollateralRatio(reservesValue, uscTotalSupply),
    },
  };
};
