import {
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
  DisplayPercentage,
  DisplayMoney,
} from "@shared";
import { formatMoney } from "@utils";

export const TokenInfo = ({
  icon,
  tokenName,
  apy,
  inputValue,
  isLoading,
}: {
  icon: string;
  tokenName: string;
  apy: number;
  inputValue: number;
  isLoading: boolean;
}) => {
  const monthlyEarnings = (inputValue * apy) / 100 / 12;
  const yearlyEarnings = (inputValue * apy) / 100;

  const formattedMonthlyEarnings = formatMoney(monthlyEarnings);
  const formattedYearlyEarnings = formatMoney(yearlyEarnings);

  return (
    <FlexCol className="gap-3">
      <FlexRow className="w-full gap-3 items-center">
        <SVGWrapper src={icon} width="24px" height="24px" />
        <Typography type="body-medium-regular">{tokenName}</Typography>
      </FlexRow>
      <FlexRow className="w-full rounded-xl bg-primary-10 flex-col lg:flex-row">
        <FlexCol className="w-full gap-1 px-4 py-3">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            APY
          </Typography>
          <FlexRow className="items-center">
            <DisplayPercentage
              viewValue={apy.toFixed(2)}
              typography="body-large-medium"
              isLoading={isLoading}
              symbol=""
            />
            <Typography type="body-large-medium">%</Typography>
          </FlexRow>
        </FlexCol>
        <FlexCol className="border bg-neutral-black-60"></FlexCol>
        <FlexCol className="w-full gap-1 px-4 py-3">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Est. 30 days Earnings
          </Typography>
          <FlexRow className="items-center">
            <Typography type="body-large-medium">$</Typography>
            <DisplayMoney
              viewValue={formattedMonthlyEarnings}
              typography="body-large-medium"
              isLoading={isLoading}
              symbol=""
            />
          </FlexRow>
        </FlexCol>
        <FlexCol className="border bg-neutral-black-60"></FlexCol>
        <FlexCol className="w-full gap-1 px-4 py-3">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Est. Yearly Earnings
          </Typography>
          <FlexRow className="items-center">
            <Typography type="body-large-medium">$</Typography>
            <DisplayMoney
              viewValue={formattedYearlyEarnings}
              typography="body-large-medium"
              isLoading={isLoading}
              symbol=""
            />
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};
