import { FlexCol } from "@shared";

export const ContentWrapper727: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <FlexCol className={`items-center w-full ${className ? className : ""}`}>
      <div className="w-full max-w-[727px]">{children}</div>
    </FlexCol>
  );
};
