/* ----------- */
/*    Icons    */
/* ----------- */
import ethIcon from "@assets/tokens/eth.svg";
import wethIcon from "@assets/tokens/weth.svg";
import oneinchIcon from "@assets/tokens/oneInch.svg";
import bankNoteIcon from "@assets/layout/bank-note-01.svg";
import Right from "@assets/layout/chevron-right.svg";
import stUscIcon from "@assets/tokens/stUsc.svg";

/* ----------- */
/*    Other    */
/* ------------ */
import { contractAddresses } from "@meta";
import { FlexRow, Typography, SVGWrapper } from "@shared";
import { MultiChainContractAddresses } from "../../../../../state/across/contractAddressesByChain";
import { useAccount } from "wagmi";
import { TOKEN_CONFIG } from "../../../../../config/tokenConfig";
import { Address } from "viem";

export const RouteComponent: React.FC<{
  selectedAsset?: string;
  isZapin?: boolean;
  isMintAndStake?: boolean;
  chainId?: number;
}> = ({ selectedAsset, isZapin, isMintAndStake, chainId }) => {
  const { chain } = useAccount();
  const finalChainId = chainId || chain?.id || 1;
  return (
    <FlexRow className="gap-3 items-center">
      {isZapin && selectedAsset && (
        <ZapinRoute
          selectedAsset={selectedAsset as Address}
          isMintAndStake={isMintAndStake}
        />
      )}
      {selectedAsset ===
        (MultiChainContractAddresses as any)[finalChainId].ETH && (
        <EthRoute isMintAndStake={isMintAndStake} />
      )}
      {selectedAsset ===
        (MultiChainContractAddresses as any)[finalChainId].WETH && (
        <WethRoute isMintAndStake={isMintAndStake} />
      )}
      {selectedAsset === contractAddresses.stETH && (
        <StEthRoute isMintAndStake={isMintAndStake} />
      )}
      {selectedAsset === contractAddresses.weETH && (
        <Weeth isMintAndStake={isMintAndStake} />
      )}
    </FlexRow>
  );
};

export const StEthRoute = ({
  isMintAndStake,
}: {
  isMintAndStake?: boolean;
}) => {
  return (
    <FlexRow className="gap-2 items-center">
      <Typography type="caption-regular">Reserves</Typography>
      <SVGWrapper src={bankNoteIcon} width={20} height={20} />
      {isMintAndStake && (
        <>
          <SVGWrapper src={Right} width={17} height={17} />
          <Typography type="caption-regular">stUSC</Typography>
          <SVGWrapper src={stUscIcon} width={20} height={20} />
        </>
      )}
    </FlexRow>
  );
};

export const ZapinRoute: React.FC<{
  selectedAsset: Address;
  isMintAndStake?: boolean;
}> = ({ selectedAsset, isMintAndStake }) => {
  return (
    <FlexRow className="gap-2 items-center">
      <SVGWrapper
        src={TOKEN_CONFIG[selectedAsset].logo}
        width={20}
        height={20}
        className="rounded-full"
      />
      <Typography type="caption-regular">
        {TOKEN_CONFIG[selectedAsset].symbol}
      </Typography>
      <SVGWrapper src={Right} width={17} height={17} />

      <SVGWrapper
        src={oneinchIcon}
        width={20}
        height={20}
        className="rounded-full"
      />

      <Typography type="caption-regular">1inch</Typography>
      <SVGWrapper src={Right} width={17} height={17} />

      <SVGWrapper src={ethIcon} width={20} height={20} />
      <Typography type="caption-regular">ETH</Typography>
      <SVGWrapper src={Right} width={17} height={17} />

      <SVGWrapper src={bankNoteIcon} width={20} height={20} />
      <Typography type="caption-regular">Reserves</Typography>

      {isMintAndStake && (
        <>
          <SVGWrapper src={Right} width={17} height={17} />
          <Typography type="caption-regular">stUSC</Typography>
          <SVGWrapper src={stUscIcon} width={20} height={20} />
        </>
      )}
    </FlexRow>
  );
};

export const EthRoute = ({ isMintAndStake }: { isMintAndStake?: boolean }) => {
  return (
    <FlexRow className="gap-2 items-center">
      <SVGWrapper src={wethIcon} width={20} height={20} />
      <Typography type="caption-regular">WETH</Typography>
      <SVGWrapper src={Right} width={17} height={17} />
      <SVGWrapper src={bankNoteIcon} width={20} height={20} />
      <Typography type="caption-regular">Reserves</Typography>
      {isMintAndStake && (
        <>
          <SVGWrapper src={Right} width={17} height={17} />
          <Typography type="caption-regular">stUSC</Typography>
          <SVGWrapper src={stUscIcon} width={20} height={20} />
        </>
      )}
    </FlexRow>
  );
};

export const WethRoute = ({ isMintAndStake }: { isMintAndStake?: boolean }) => {
  return (
    <FlexRow className="gap-2 items-center">
      <SVGWrapper src={wethIcon} width={20} height={20} />
      <Typography type="caption-regular">Unwrap</Typography>
      <SVGWrapper src={Right} width={17} height={17} />
      <SVGWrapper src={bankNoteIcon} width={20} height={20} />
      <Typography type="caption-regular">Reserves</Typography>
      {isMintAndStake && (
        <>
          <SVGWrapper src={Right} width={17} height={17} />
          <Typography type="caption-regular">stUSC</Typography>
          <SVGWrapper src={stUscIcon} width={20} height={20} />
        </>
      )}
    </FlexRow>
  );
};
export const Weeth = ({ isMintAndStake }: { isMintAndStake?: boolean }) => {
  return (
    <FlexRow className="gap-2 items-center">
      <Typography type="caption-regular">Reserves</Typography>
      <SVGWrapper src={bankNoteIcon} width={20} height={20} />
      {isMintAndStake && (
        <>
          <SVGWrapper src={Right} width={17} height={17} />
          <Typography type="caption-regular">stUSC</Typography>
          <SVGWrapper src={stUscIcon} width={20} height={20} />
        </>
      )}
    </FlexRow>
  );
};
