//* * ROUTER **/
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { RouterConfig } from "@router";

//* * PAGES **/

//* * LAYOUT **/
import { FallbackPage, NotificationProvider, PageNotFound } from "@shared";
//* * SENTRY **/
import * as Sentry from "@sentry/react";
import { HomePage } from "./app/pages/home";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { DappLayout } from "./app/layout/DappLayout";
import { StakePage } from "./app/pages/stake";
import ReserveHolderPage from "./app/pages/admin/reserveHolder/page";
import { ArbitrageAdminPage } from "./app/pages/admin/arbitrage/page";
import ContractBalancesAdminPage from "./app/pages/admin/contractBalances/page";
import OracleAdminPage from "./app/pages/admin/oracles/page";
import PriceAdminPage from "./app/pages/admin/priceDashboard/page";
import { LiqudityPage } from "./app/pages/liquidity";
import { RewardPage } from "./app/pages/rewards";
import { AnalyticsPage } from "./app/pages/analytics";
import { GovernancePage } from "./app/pages/governance";
import StakingManagerPage from "./app/pages/admin/stakingManager/page";
import { VestPage } from "./app/pages/vest";
import LockingManagerPage from "./app/pages/admin/lockingManager/page";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  return (
    <Sentry.ErrorBoundary fallback={FallbackPage} showDialog>
      <NotificationProvider>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <DappLayout>
              <SentryRoutes>
                <Route path={RouterConfig.Routes.home} element={<HomePage />} />
                <Route
                  path={RouterConfig.Routes.stake}
                  element={<StakePage />}
                />
                <Route
                  path={RouterConfig.Routes.liquidity}
                  element={<LiqudityPage />}
                />
                <Route
                  path={RouterConfig.Routes.rewards}
                  element={<RewardPage />}
                />
                <Route
                  path={RouterConfig.Routes.analytics}
                  element={<AnalyticsPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.reserveHolder}
                  element={<ReserveHolderPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.arbitrage}
                  element={<ArbitrageAdminPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.contractBalances}
                  element={<ContractBalancesAdminPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.oracles}
                  element={<OracleAdminPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.stakingManager}
                  element={<StakingManagerPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.lockingManager}
                  element={<LockingManagerPage />}
                />
                <Route
                  path={RouterConfig.Routes.admin.priceDashboard}
                  element={<PriceAdminPage />}
                />
                <Route
                  path={RouterConfig.Routes.boost}
                  element={<GovernancePage />}
                />
                <Route path={RouterConfig.Routes.vest} element={<VestPage />} />
                <Route path="*" element={<PageNotFound />} />
              </SentryRoutes>
            </DappLayout>
          </QueryParamProvider>
        </BrowserRouter>
      </NotificationProvider>
    </Sentry.ErrorBoundary>
  );
}

/* ----------- */
/*    Props    */
/* ----------- */
