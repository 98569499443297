import { Address } from "viem";
import {
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  TNotificationProps,
  SVGWrapper,
} from "@shared";
import { formatMoney } from "@utils";
import { TOKEN_CONFIG } from "../../../../../../../config/tokenConfig";

import uscIcon from "@assets/tokens/usc.svg";
import stUSCLogo from "@assets/tokens/stUsc.svg";

export const successNotif = (
  showNotification: (notification: TNotificationProps) => void,
  uscValue: string,
  firstInputValue: string,
  txHash?: Address,
  selectedAsset?: Address,
  isMintAndStake?: boolean
) => {
  const icon = isMintAndStake ? stUSCLogo : uscIcon;
  const symbol = isMintAndStake ? "stUSC" : "USC";

  showNotification({
    status: "success",
    txHash,
    content: (
      <StandardNotifBody
        headerComponent={<TransactionComplete />}
        transferComponent={
          <Transfer
            leftComponent={
              <TransferMoney
                icon={
                  <SVGWrapper
                    src={selectedAsset ? TOKEN_CONFIG[selectedAsset].logo : ""}
                    width={22}
                    height={22}
                  />
                }
                symbol={selectedAsset ? TOKEN_CONFIG[selectedAsset].symbol : ""}
                text={formatMoney(firstInputValue)}
              />
            }
            rightComponent={
              <TransferMoney
                icon={<SVGWrapper src={icon} width={22} height={22} />}
                text={formatMoney(uscValue)}
                symbol={symbol}
              />
            }
          />
        }
      />
    ),
  });
};
