import { useAccount, useReadContract } from "wagmi";
import { Address } from "viem";
import { CHILockingAbi, contractAddresses, CHIVestingAbi } from "../../../meta";
import { useWingsContractWrite } from "../../../shared";
import { displayTokens } from "../../../utils";
import { useGetPriceByAddress } from "../price-feed-aggregator/useGetPriceByAddress";

export const useStEthRewards = () => {
  const { address } = useAccount();

  //chi locking
  const {
    data: CHILockingUnclaimedStETHAmount,
    isFetched: CHILockingUnclaimedStETHAmountFetching,
  } = useReadContract({
    abi: CHILockingAbi,
    address: contractAddresses.ChiLocking,
    functionName: "unclaimedStETHAmount",
    args: [address as Address],
  });

  //ChiVesting
  const {
    data: CHIVestingUnclaimedStETHAmount,
    isFetched: CHIVestingUnclaimedStETHAmountFetched,
  } = useReadContract({
    abi: CHIVestingAbi,
    address: contractAddresses.ChiVesting,
    functionName: "unclaimedStETHAmount",
    args: [address as Address],
  });

  const { writeContractAsync, isPending: isClaiming } = useWingsContractWrite();

  const { formattedPrice: formattedStEthPrice } = useGetPriceByAddress(
    contractAddresses.stETH
  );

  const chiLockingDSPL = {
    name: "CHI Locking",
    label: "CHI Locking",
    viewValue: displayTokens(
      (CHILockingUnclaimedStETHAmount || 0n) +
      (CHIVestingUnclaimedStETHAmount || 0n),
      {}
    ),
    usdValue: displayTokens(
      (CHILockingUnclaimedStETHAmount || 0n) +
      (CHIVestingUnclaimedStETHAmount || 0n),
      {
        hideTokenAmount: true,
        displayInDollars: true,
        formattedPrice: formattedStEthPrice,
      }
    ),
    isFetched: CHILockingUnclaimedStETHAmountFetching,
  };

  const chiVestingDSPL = {
    name: "CHI Vesting",
    label: "CHI Vesting",
    viewValue: displayTokens(CHIVestingUnclaimedStETHAmount, {}),
    usdValue: displayTokens(CHIVestingUnclaimedStETHAmount, {
      hideTokenAmount: true,
      displayInDollars: true,
      formattedPrice: formattedStEthPrice,
    }),
    isFetched: CHIVestingUnclaimedStETHAmountFetched,
  };

  const totalStETHRewardsDSPL = {
    name: "Total stETH Rewards",
    label: "Total stETH Rewards",
    viewValue: displayTokens(
      (CHILockingUnclaimedStETHAmount || BigInt(0)) +
      (CHIVestingUnclaimedStETHAmount || BigInt(0)),
      {}
    ),
    originalValue: (CHILockingUnclaimedStETHAmount || BigInt(0)) +
      (CHIVestingUnclaimedStETHAmount || BigInt(0)),
    usdValue: displayTokens(
      (CHILockingUnclaimedStETHAmount || BigInt(0)) +
      (CHIVestingUnclaimedStETHAmount || BigInt(0)),
      {
        hideTokenAmount: true,
        displayInDollars: true,
        formattedPrice: formattedStEthPrice,
      }
    ),
    isFetched:
      CHILockingUnclaimedStETHAmountFetching &&
      CHIVestingUnclaimedStETHAmountFetched,
  };
  return {
    totalStETHRewardsDSPL,
    chiVestingDSPL,
    chiLockingDSPL,
    writeContractAsync,
    isClaiming,
    formattedStEthPrice,
    CHILockingUnclaimedStETH: {
      amount: CHILockingUnclaimedStETHAmount,
      isFetched: CHILockingUnclaimedStETHAmountFetching,
    },
    CHIVestingUnclaimedStETH: {
      amount: CHIVestingUnclaimedStETHAmount,
      isFetched: CHIVestingUnclaimedStETHAmountFetched,
    },
  };
};
