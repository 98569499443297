import React from "react";
import { ReactSVG } from "react-svg";

/**
 * `SVGWrapper` Component
 *
 * A reusable component for rendering SVG icons with customizable size, optional color, and additional styles.
 *
 * @param {string} src - The source of the SVG icon.
 * @param {(number | string)} [width="24px"] - The width of the SVG icon.
 * @param {(number | string)} [height="24px"] - The height of the SVG icon.
 * @param {string} [color] - Optional stroke color of the SVG paths. If not provided, the original SVG color is used.
 * @param {string} [className=""] - Additional classes for the SVG element.
 *
 * @returns {JSX.Element} The rendered SVG icon.
 *
 * @example
 * ```tsx
 * <SVGWrapper
 *   src={Icons.Star}
 *   width="32px"
 *   height="32px"
 *   color="#FF0000"
 *   className="custom-class"
 * />
 * ```
 *
 * The above example renders an SVG with a width and height of 32px, a red stroke color, and an additional custom class.
 */
interface SVGWrapperProps {
  src: string;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  flip?: boolean;
  onClick?: () => void;
}

export const SVGWrapper: React.FC<SVGWrapperProps> = ({
  src,
  width = "100%",
  height = "100%",
  color,
  className = "",
  flip,
  onClick,
}) => {
  return (
    <ReactSVG
      src={src}
      onClick={onClick}
      beforeInjection={(svg) => {
        svg.setAttribute("width", `${width}`);
        svg.setAttribute("height", `${height}`);
        if (color) {
          svg.querySelectorAll("path").forEach((path) => {
            path.setAttribute("stroke", color);
          });
        }
        if (flip) {
          svg.style.transform = "rotate(180deg)";
        }
      }}
      className={className}
    />
  );
};
