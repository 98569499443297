import { Address } from "viem";
import { MultiChainContractAddresses } from "../../../state/across/contractAddressesByChain";
import { TOKEN_CONFIG } from "../../../config/tokenConfig";

export interface Option2 {
  key: Address;
  value: Address;
  name: string;
  icon: string;
}

export const getOptionsPerChainId = (
  chainId: number
): {
  zapInOptions: Option2[];
  options: Option2[];
} => {
  const chainConfig = (MultiChainContractAddresses as any)[chainId];
  if (!chainConfig) {
    return {
      options: [],
      zapInOptions: [],
    };
  }

  const seenKeys = new Set<Address>();
  const options: Option2[] = [];
  const zapInOptions: Option2[] = [];

  Object.values(chainConfig).forEach((address) => {
    const config = TOKEN_CONFIG[address as Address];
    const key = address as Address;

    if (!seenKeys.has(key)) {
      seenKeys.add(key);

      const option = {
        key,
        value: key,
        name: config ? config.symbol : "Unknown",
        icon: config ? config.logo : "",
      };

      if (config?.isZappingIn) {
        zapInOptions.push(option);
      } else {
        options.push(option);
      }
    }
  });

  return {
    zapInOptions,
    options,
  };
};
