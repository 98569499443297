import React, { useCallback, useEffect, useState } from "react";
import { differenceInMilliseconds, addMilliseconds } from "date-fns";

import { Card, FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";
import AlertCircle from "@assets/layout/alertCircle.svg";

export const Countdown: React.FC<{
  initialStartingDatetime: Date;
  durationInDays: number;
}> = ({ initialStartingDatetime, durationInDays }) => {
  const calculateStartDatetime = useCallback(() => {
    const now = new Date();
    const start = new Date(initialStartingDatetime);
    const durationInMilliseconds = durationInDays * 24 * 60 * 60 * 1000;
    const timePassedSinceStart = differenceInMilliseconds(now, start);
    const timeIntoCurrentPeriod = timePassedSinceStart % durationInMilliseconds;
    const currentPeriodStart = addMilliseconds(now, -timeIntoCurrentPeriod);
    return currentPeriodStart;
  }, [durationInDays, initialStartingDatetime]);

  const [currentStartingDatetime, setCurrentStartingDatetime] = useState(
    calculateStartDatetime()
  );
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const endDatetime = addMilliseconds(
        new Date(currentStartingDatetime),
        durationInDays * 24 * 60 * 60 * 1000
      );
      const distanceInMilliseconds = differenceInMilliseconds(endDatetime, now);

      if (distanceInMilliseconds <= 0) {
        setCurrentStartingDatetime(calculateStartDatetime());
        return;
      }

      const seconds = String(
        Math.floor((distanceInMilliseconds / 1000) % 60)
      ).padStart(2, "0");
      const minutes = String(
        Math.floor((distanceInMilliseconds / (1000 * 60)) % 60)
      ).padStart(2, "0");
      const hours = String(
        Math.floor((distanceInMilliseconds / (1000 * 60 * 60)) % 24)
      ).padStart(2, "0");
      const days = String(
        Math.floor(distanceInMilliseconds / (1000 * 60 * 60 * 24))
      ).padStart(2, "0");

      setCountdown({ days, hours, minutes, seconds });
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [calculateStartDatetime, currentStartingDatetime, durationInDays]);

  const renderDigit = (digit: string) => {
    return (
      <FlexRow className="gap-1 justify-between">
        <FlexCol className="w-[26px] h-[28px] rounded-md items-center justify-center bg-neutral-black-20 border border-neutral-black-30">
          <Typography type="body-small-regular" className="text-primary">
            {digit[0]}
          </Typography>
        </FlexCol>
        <FlexCol className="w-[26px] h-[28px] rounded-md items-center justify-center bg-neutral-black-20 border border-neutral-black-30">
          <Typography type="body-small-regular" className="text-primary">
            {digit[1]}
          </Typography>
        </FlexCol>
      </FlexRow>
    );
  };

  return (
    <Card hasBorder className="flex flex-row w-full p-4 gap-3 justify-center">
      <SVGWrapper
        src={AlertCircle}
        width="24px"
        height="24px"
        className="my-3"
      />
      <FlexCol className="w-full gap-4">
        <FlexCol className="gap-2">
          <Typography type="body-medium-bold">
            Rewards on your staked positions are earned with every Ethereum
            block
          </Typography>
          <Typography type="body-small-regular">
            Boosting rewards are distributed daily, with the next distribution
            happening in:
          </Typography>
        </FlexCol>
        <FlexRow className="justify-between">
          <FlexCol className="gap-1 items-center">
            {renderDigit(countdown.hours)}
            <Typography
              type="body-small-regular"
              className="text-neutral-black-60"
            >
              HOUR
            </Typography>
          </FlexCol>
          <FlexCol className="gap-1 items-center">
            {renderDigit(countdown.minutes)}
            <Typography
              type="body-small-regular"
              className="text-neutral-black-60"
            >
              MIN
            </Typography>
          </FlexCol>
          <FlexCol className="gap-1 items-center">
            {renderDigit(countdown.seconds)}
            <Typography
              type="body-small-regular"
              className="text-neutral-black-60"
            >
              SEC
            </Typography>
          </FlexCol>
        </FlexRow>
      </FlexCol>
    </Card>
  );
};
