import React from "react";
import { FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";
import { ReactSVG } from "react-svg";
import { MenuItem, NavItem } from "./MenuItem";
//icons
import Drop from "@assets/layout/drop.svg";
import BankNotes from "@assets/layout/bank-note.svg";
import BarChart from "@assets/layout/bar-chart.svg";
import Flash from "@assets/layout/flash-on.svg";
import Stars from "@assets/layout/stars.svg";
// import Sun from "@assets/layout/sun.svg";
//logo
import ChiLogo from "@assets/logos/chi-logo.svg";
//routes
import { RouterConfig } from "@router";

/* ----------- */
/*    Icons    */
/* ----------- */

import Twitter from "@assets/socials/twitter.svg";
import Discord from "@assets/socials/discord.svg";
import Telegram from "@assets/socials/telegram.svg";
import Medium from "@assets/socials/medium.svg";
import Cirlce from "@assets/socials/circle.svg";
import Lizard from "@assets/socials/lizard.svg";
import Lamma from "@assets/socials/lamma.svg";
import Book from "@assets/socials/book.svg";
import StakeIcon from "@assets/layout/stakeIcon.svg";
import Vest from "@assets/layout/fileLock.svg";

// Define your navigation items with routes
export const navItems: NavItem[] = [
  { icon: Stars, label: "Mint", route: RouterConfig.Routes.home },
  { icon: StakeIcon, label: "Stake", route: RouterConfig.Routes.stake },
  { icon: Drop, label: "Liquidity", route: RouterConfig.Routes.liquidity },
  { icon: BankNotes, label: "Rewards", route: RouterConfig.Routes.rewards },
  { icon: Flash, label: "Boost", route: RouterConfig.Routes.boost },
  { icon: Vest, label: "Vest", route: RouterConfig.Routes.vest },
  { icon: BarChart, label: "Analytics", route: RouterConfig.Routes.analytics },
];

const adminNavItems: NavItem[] = [
  {
    icon: "",
    label: "Reserve Holder",
    route: RouterConfig.Routes.admin.reserveHolder,
  },
  { icon: "", label: "Arbitrage", route: RouterConfig.Routes.admin.arbitrage },
  {
    icon: "",
    label: "Price dashboard",
    route: RouterConfig.Routes.admin.priceDashboard,
  },
  {
    icon: "",
    label: "ContractBalances",
    route: RouterConfig.Routes.admin.contractBalances,
  },
  { icon: "", label: "Oracles", route: RouterConfig.Routes.admin.oracles },
  {
    icon: "",
    label: "Staking Manager",
    route: RouterConfig.Routes.admin.stakingManager,
  },
  {
    icon: "",
    label: "Locking Manager",
    route: RouterConfig.Routes.admin.lockingManager,
  },
];

// NavSidebar component that uses MenuItem to render the sidebar
export const NavSidebar: React.FC = () => {
  return (
    <FlexCol className="fixed w-[269px] min-h-screen  border-x-[1px] px-5 py-4 border-neutral-black-20 bg-primary-10 justify-between">
      <FlexCol className="gap-4">
        <a href="https://chi-protocol-landingv2.vercel.app/">
          <ReactSVG src={ChiLogo} className="w-[174px] h-[40px]" />
        </a>

        <FlexCol className="w-full gap-1">
          {navItems.map((item) => (
            <MenuItem key={item.route} item={item} />
          ))}
        </FlexCol>

        <div className="admin-items">
          <div className="divider divider-primary">Admin</div>
          <FlexCol className="w-full gap-1">
            {adminNavItems.map((item) => (
              <MenuItem key={item.route} item={item} />
            ))}
          </FlexCol>
        </div>
      </FlexCol>
      <FlexCol className="gap-2">
        <FlexRow className="gap-2 items-center justify-center">
          <Typography type="chi-protocol-small-text" className="text-primary">
            <a
              href="https://chi-protocol-landingv2.vercel.app/terms-and-conditions"
              className="hover:underline"
            >
              Terms of Use
            </a>
          </Typography>
          <Typography type="chi-protocol-small-text" className="text-primary">
            |
          </Typography>
          <Typography type="chi-protocol-small-text" className="text-primary">
            <a
              href="https://chi-protocol-landingv2.vercel.app/privacy-policy"
              className="hover:underline"
            >
              {" "}
              Privacy Notice
            </a>
          </Typography>
        </FlexRow>
        <FlexRow className="gap-[6px] items-center justify-center">
          <a
            href="https://x.com/ProtocolChi"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Twitter} width="24px" height="24px" />
          </a>
          <a
            href="https://discord.com/invite/99Awe7Wrby"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Discord} width="24px" height="24px" />
          </a>
          <a
            href="https://t.me/protocolchi"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Telegram} width="24px" height="24px" />
          </a>
          <a
            href="https://medium.com/chi-protocol"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Medium} width="24px" height="24px" />
          </a>
          <a
            href="https://dune.com/quackery/chi-protocol-usc"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Cirlce} width="24px" height="24px" />
          </a>
          <a
            href="https://www.coingecko.com/en/coins/usc"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Lizard} width="24px" height="24px" />
          </a>
          <a href="/" className="hover:underline " target="_blank">
            <SVGWrapper src={Lamma} width="24px" height="24px" />
          </a>
          <a
            href="https://chi-protocol.gitbook.io/chi-docs"
            className="hover:underline"
            target="_blank"
          >
            <SVGWrapper src={Book} width="24px" height="24px" />
          </a>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};
