"use client";

import { BytesLike, ethers } from "ethers";
import { Address, zeroAddress } from "viem";

import { SpokePoolAddresses } from "./spokePoolAddreses";
import { contractAddresses } from "../../../meta";

const DOMAIN_CALLDATA_DELIMITER = "0x1dc0de";
const integratorId = "0x0019";

interface DepositV3Params {
  depositor: Address;
  recipient: Address;
  inputToken: Address;
  outputToken?: Address;
  inputAmount: ethers.BigNumber;
  outputAmount: ethers.BigNumber;
  destinationChainId: number;
  originChainId: number;
  exclusiveRelayer: Address;
  quoteTimestamp: number;
  fillDeadline: number;
  exclusivityDeadline: number;
  message: string;
  isEth?: boolean;
}

function tagIntegratorId(txData: BytesLike, integratorId: string) {
  return ethers.utils.hexConcat([
    txData,
    DOMAIN_CALLDATA_DELIMITER,
    integratorId,
  ]);
}

export async function depositV3({
  depositor,
  recipient,
  inputToken,
  inputAmount,
  outputAmount,
  destinationChainId,
  originChainId,
  exclusiveRelayer,
  quoteTimestamp,
  fillDeadline,
  exclusivityDeadline,
  message,
  outputToken = zeroAddress,
  isEth = false,
}: DepositV3Params): Promise<any> {
  const provider = window
    ? new ethers.providers.Web3Provider((window as any)?.ethereum)
    : undefined;

  const signer = provider?.getSigner();

  const spokePoolAddress = (SpokePoolAddresses as any)[originChainId];

  const spokePoolContract = new ethers.Contract(
    spokePoolAddress,
    [
      "function depositV3(address depositor, address recipient, address inputToken, address outputToken, uint256 inputAmount, uint256 outputAmount, uint256 destinationChainId, address exclusiveRelayer, uint32 quoteTimestamp, uint32 fillDeadline, uint32 exclusivityDeadline, bytes calldata message) external payable",
    ],
    signer
  );
  const calldata = spokePoolContract.interface.encodeFunctionData("depositV3", [
    depositor,
    recipient,
    inputToken,
    outputToken,
    inputAmount,
    outputAmount,
    destinationChainId,
    exclusiveRelayer,
    quoteTimestamp,
    fillDeadline,
    exclusivityDeadline,
    message,
  ]);

  const modifiedTxData = tagIntegratorId(calldata, integratorId);

  const txValue = isEth ? inputAmount : "0";

  // Estimate gas
  const estimatedGas = await signer?.estimateGas({
    to: spokePoolAddress,
    data: modifiedTxData,
    value: txValue,
  });

  // Create a transaction object
  const tx = {
    to: spokePoolAddress,
    data: modifiedTxData, // Use the modified data with the identifier
    value: txValue,
    gasLimit: estimatedGas,
  };

  // Send the transaction
  return await signer?.sendTransaction(tx);
}

export function generateMessageForMulticallHandler(
  contractAddress: string, // Updated ArbitrageV4 contract address
  depositAmount: ethers.BigNumber, // outputAmount value
  depositCurrency: string,
  userAddress?: string,
  zapTokenAddress?: string,
  tokenInAddress?: string,
  zapCallData?: string
): string {
  const abiCoder = new ethers.utils.AbiCoder();
  const erc20Interface = new ethers.utils.Interface([
    "function approve(address spender, uint256 value)",
  ]);
  const mintInterface = new ethers.utils.Interface([
    "function mint(address token, uint256 amount, address receiver)",
  ]);

  const zapMintInterface = new ethers.utils.Interface([
    "function zapMint(address arbitrage, address tokenFrom, address tokenTo, uint256 amountIn, address receiver, bytes data)",
  ]);
  const contractInterface = zapTokenAddress ? zapMintInterface : mintInterface;
  const approveCalldata = erc20Interface.encodeFunctionData("approve", [
    contractAddress,
    depositAmount,
  ]);
  const mintCalldata = contractInterface.encodeFunctionData(
    zapTokenAddress ? "zapMint" : "mint",
    zapTokenAddress
      ? [
          contractAddresses.Arbitrage,
          tokenInAddress,
          zapTokenAddress,
          depositAmount,
          userAddress,
          zapCallData,
        ]
      : [contractAddresses.WETH, depositAmount, userAddress]
  );

  return abiCoder.encode(
    [
      "tuple(" +
        "tuple(" +
        "address target," +
        "bytes callData," +
        "uint256 value" +
        ")[]," +
        "address fallbackRecipient" +
        ")",
    ],
    [
      [
        [
          [depositCurrency, approveCalldata, 0],
          [contractAddress, mintCalldata, 0],
        ],
        userAddress,
      ],
    ]
  );
}
