import { Address } from "viem";
import {
  StandardNotifBody,
  TransferMoney,
  TNotificationProps,
  StepsContainer,
  Transfer,
  SVGWrapper,
} from "@shared";
import { formatMoney } from "@utils";
import { TOKEN_CONFIG } from "../../../../../../../config/tokenConfig";

/* ----------- */
/*    Icons    */
/* ----------- */
import USCLogo from "@assets/tokens/usc.svg";
import stUSCLogo from "@assets/tokens/stUsc.svg";

export const loadingNotif = (
  showNotification: (notification: TNotificationProps) => void,
  firstInputValue: string,
  secondInputValue: string,
  selectedAsset: Address | undefined,
  currentStep: number,
  steps: string[],
  additionalData?: React.ReactNode,
  isMintAndStake?: boolean
) => {
  const icon = isMintAndStake ? stUSCLogo : USCLogo;
  const symbol = isMintAndStake ? "stUSC" : "USC";

  showNotification({
    status: "loading",
    content: (
      <div>
        <StandardNotifBody
          header={"Minting in Process"}
          loading
          transferComponent={
            <Transfer
              leftComponent={
                <TransferMoney
                  icon={
                    <SVGWrapper
                      src={
                        selectedAsset ? TOKEN_CONFIG[selectedAsset].logo : ""
                      }
                      width={22}
                      height={22}
                    />
                  }
                  text={formatMoney(firstInputValue)}
                  symbol={
                    selectedAsset ? TOKEN_CONFIG[selectedAsset].symbol : ""
                  }
                />
              }
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={icon} width={22} height={22} />}
                  text={formatMoney(secondInputValue)}
                  symbol={symbol}
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
        <div>{additionalData}</div>
      </div>
    ),
  });
};
