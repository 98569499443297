import {
  Card,
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import ArrowUp from "@assets/layout/arrow-up-right.svg";
import StUsc from "@assets/tokens/stUsc.svg";

interface TokenSummaryProps {
  selectedToken: string;
  selectedTokenAPY: number;
  icon: string;
  stUscApy: number;
}

export const TokenSummary = ({
  selectedToken,
  selectedTokenAPY,
  icon,
  stUscApy,
}: TokenSummaryProps) => {
  let apyDifference;

  if (stUscApy >= selectedTokenAPY) {
    if (selectedTokenAPY > 0) {
      apyDifference = ((stUscApy - selectedTokenAPY) / selectedTokenAPY) * 100;
    } else {
      apyDifference = stUscApy - selectedTokenAPY;
    }
  } else {
    if (stUscApy > 0) {
      apyDifference = ((selectedTokenAPY - stUscApy) / stUscApy) * 100;
    } else {
      apyDifference = selectedTokenAPY - stUscApy;
    }
  }

  const isStUSCHigher = stUscApy >= selectedTokenAPY;
  const tokenToDisplay = isStUSCHigher ? "stUSC" : selectedToken;
  const iconToDisplay = isStUSCHigher ? StUsc : icon;

  return (
    <Card>
      <FlexRow className="w-full h-24 gap-3 items-center flex-col lg:flex-row">
        <FlexCol className="w-full px-4 py-5 gap-1">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            APY
          </Typography>
          <FlexRow className="w-full gap-1 items-center">
            <SVGWrapper src={iconToDisplay} width="24px" height="24px" />
            <Typography type="body-medium-regular">{tokenToDisplay}</Typography>
            <SVGWrapper src={ArrowUp} width={16} height={16} />
            <DisplayPercentage
              viewValue={apyDifference.toFixed(2)}
              typography="body-small-medium"
              className="text-secondary"
            />
          </FlexRow>
        </FlexCol>

        <FlexCol className="border bg-neutral-black-60 h-full"></FlexCol>

        <FlexCol className="w-full px-4 py-5 gap-1">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Est. 30 days Earnings
          </Typography>
          <FlexRow className="w-full gap-1 items-center">
            <SVGWrapper src={iconToDisplay} width="24px" height="24px" />
            <Typography type="body-medium-regular">{tokenToDisplay}</Typography>
            <SVGWrapper src={ArrowUp} width={16} height={16} />
            <DisplayPercentage
              viewValue={apyDifference.toFixed(2)}
              typography="body-small-medium"
              className="text-secondary"
            />
          </FlexRow>
        </FlexCol>

        <FlexCol className="border bg-neutral-black-60 h-full"></FlexCol>

        <FlexCol className="w-full px-4 py-5 gap-1">
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Est. Yearly Earnings
          </Typography>
          <FlexRow className="w-full gap-1 items-center">
            <SVGWrapper src={iconToDisplay} width="24px" height="24px" />
            <Typography type="body-medium-regular">{tokenToDisplay}</Typography>
            <SVGWrapper src={ArrowUp} width={16} height={16} />
            <DisplayPercentage
              viewValue={apyDifference.toFixed(2)}
              typography="body-small-medium"
              className="text-secondary"
            />
          </FlexRow>
        </FlexCol>
      </FlexRow>
    </Card>
  );
};
