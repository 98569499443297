import React from "react";

import { Typography } from "../text/Typography/Typography";
import { FlexCol } from "../flexCol/FlexCol";

export const StepComp: React.FC<{
  step: number;
  doing?: boolean;
  done?: boolean;
  stepName: string;
}> = ({ step, doing, stepName }) => {
  return (
    <FlexCol className="gap-[10px] w-32 items-center text-center">
      <FlexCol className={`gap-1 ${doing ? "" : "text-[#949494]"}`}>
        <Typography type="chi-protocol-extra-small-text">
          Step {step}
        </Typography>
        <Typography
          className={`${doing ? "" : "text-[#949494]"} `}
          type="chi-protocol-small-bold"
        >
          {stepName}
        </Typography>
      </FlexCol>
    </FlexCol>
  );
};
