import { formatMoney } from "@utils";
import { formatUnits } from "viem";

import { useFetchUscStakingAPR } from "./useFetchUscStakingAPR";

const decimals = 18;

export const useFetchUscStakingDetailedApr = () => {
  const { apr, isFetchedAPR } = useFetchUscStakingAPR();
  const result: bigint[] | undefined = apr as unknown as bigint[] | undefined;

  const totalAprOriginal = result ? result[0] : undefined;
  const uscAprOriginal = result ? result[1] : undefined;
  const chiAprOriginal = result ? result[2] : undefined;
  const stETHAprOriginal = result ? result[3] : undefined;

  const totalAprFormatted = formatUnits(totalAprOriginal || 0n, decimals);
  const uscAprFormatted = formatUnits(uscAprOriginal || 0n, decimals);
  const chiAprFormatted = formatUnits(chiAprOriginal || 0n, decimals);
  const stETHAprFormatted = formatUnits(stETHAprOriginal || 0n, decimals);

  return {
    totalApr: {
      original: totalAprOriginal,
      formatted: totalAprFormatted,
    },
    uscApr: {
      original: uscAprOriginal,
      formatted: uscAprFormatted,
    },
    chiApr: {
      original: chiAprOriginal,
      formatted: chiAprFormatted,
    },
    stETHApr: {
      original: stETHAprOriginal,
      formatted: stETHAprFormatted,
    },
    isFetchedAPR,
  };
};

export const useFetchAprDisplayable = () => {
  const { chiApr, isFetchedAPR, stETHApr, totalApr, uscApr } =
    useFetchUscStakingDetailedApr();

  return {
    chiApr: formatMoney(Number(chiApr.formatted || 0) * 100),
    stETHApr: formatMoney(Number(stETHApr.formatted || 0) * 100),
    uscApr: formatMoney(Number(uscApr.formatted || 0) * 100),
    totalApr: formatMoney(Number(totalApr.formatted || 0) * 100),
    totalAprOriginal: totalApr.original,
    isFetchedAPR,
  };
};
