import { useEffect, useMemo } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { contractAddresses, LockingManagerAbi } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  Button,
  SVGWrapper,
} from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */
import { useButtonPropsOverride } from "../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../state/common/useStepState.ui";
/* ----------- */
/*    Icons    */
/* ----------- */
import boostedUscEth from "@assets/tokens/boostedUscEth.svg";
import boostedChiEth from "@assets/tokens/boostedChiEth.svg";
import boostedWstUsc from "@assets/tokens/boostedWstUsc.svg";
import uscEth from "@assets/tokens/uscEth.svg";
import chiEth from "@assets/tokens/chiEth.svg";
import wstUsc from "@assets/tokens/wstUsc.svg";

import { SmallDropdown, DropdownItem } from "../../smallDropdown/SmallDropdown";
import { useSumOfExpiredPositions } from "./useWithdrawAmount";
import { useGetLPPriceByAddress } from "../../../../../state/data-provider/useGetLPPriceByAddress";
import { useFetchWstUscPrice } from "../../../../../state/wstUsc/useFetchWstUscPrice";
import { useWingsApprove } from "../../../../../state/common/useWingsApprove";

interface WithdrawItem extends DropdownItem {
  address: Address;
  withdrawTokenName: string;
  withdrawTokenIcon: string;
  withdrawTokenAddress: Address;
  priceKey?: "CHI_ETH_LP" | "USC_ETH_LP" | undefined;
}

interface WithDrawFormProps {
  selectedKey: string | null;
  onKeySelect: (key: string) => void;
}

const items: WithdrawItem[] = [
  {
    key: "stUscEth", icon: boostedUscEth, name: "Boosted USC/ETH LP",
    size: "large", address: contractAddresses.USC_ETH_LP_Locking,
    withdrawTokenName: "Staked USC/ETH LP", withdrawTokenIcon: uscEth,
    withdrawTokenAddress: contractAddresses.USC_ETH_LP_Staking,
    priceKey: "USC_ETH_LP",
  },
  {
    key: "stChiEth", icon: boostedChiEth, name: "Boosted CHI/ETH LP",
    size: "large", address: contractAddresses.CHI_ETH_LP_Locking,
    withdrawTokenName: "Staked CHI/ETH LP", withdrawTokenIcon: chiEth,
    withdrawTokenAddress: contractAddresses.CHI_ETH_LP_Staking,
    priceKey: "CHI_ETH_LP",
  },
  {
    key: "wstUsc", icon: boostedWstUsc, name: "Boosted wstUSC",
    size: "small", address: contractAddresses.WST_USC_Locking,
    withdrawTokenName: "wstUSC", withdrawTokenIcon: wstUsc,
    withdrawTokenAddress: contractAddresses.WstUSC,
  },
];

export const WithdrawForm: React.FC<WithDrawFormProps> = ({
  selectedKey,
  onKeySelect,
}) => {
  const selectedItem = useMemo(() => {
    return items.find((item) => item.key === selectedKey);
  }, [selectedKey]);

  const { formattedPrice } = useGetLPPriceByAddress(selectedItem?.priceKey);
  const { formattedPrice: formattedWSTUSCPrice } = useFetchWstUscPrice();

  const { viewValue: balanceView, isFetched: isBalanceFetched, dollarViewValue } = useSumOfExpiredPositions(selectedItem?.address,
    selectedItem?.priceKey ? formattedPrice : formattedWSTUSCPrice
  );

  const handleDropdownSelect = (item: DropdownItem) => {
    onKeySelect(item.key);
  };

  const { writeContractAsync: withdrawAsync, isPending: isStaking } =
    useWingsContractWrite();

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    selectedItem?.address as Address,
    selectedItem?.withdrawTokenAddress as Address,
    parseUnits(String(Number(balanceView)), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Withdraw"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);


  const withdrawFunction = async () => {
    await withdrawAsync(
      {
        abi: LockingManagerAbi,
        address: selectedItem?.address as Address,
        functionName: "withdrawAllUnlockedPositions",
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={
                            selectedItem?.icon || ""
                          } width={22} height={22} />
                        }
                        text={
                          balanceView
                        }
                        symbol={selectedItem?.name || ""}
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={
                            selectedItem?.withdrawTokenIcon || ""
                          } width={22} height={22} />
                        }
                        //todo: convert balance
                        text={balanceView}
                        symbol={
                          selectedItem?.withdrawTokenName || ""
                        }
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Withdraw in Process"}
          loading
          transferComponent={
            <Transfer
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={
                    selectedItem?.icon || ""
                  } width={22} height={22} />}
                  text={balanceView}
                  symbol={
                    selectedItem?.name || ""
                  }
                />
              }
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={
                    selectedItem?.withdrawTokenIcon || ""
                  } width={22} height={22} />}
                  //todo: convert balance
                  text={balanceView}
                  symbol={
                    selectedItem?.withdrawTokenName || ""
                  }
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(Number(balanceView)), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={
                            selectedItem?.icon || ""
                          } width={22} height={22} />
                        }
                        text={balanceView}
                        symbol={
                          selectedItem?.name || ""
                        }
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={
                            selectedItem?.withdrawTokenIcon || ""
                          } width={22} height={22} />
                        }
                        text={balanceView}
                        symbol={
                          selectedItem?.withdrawTokenName || ""
                        }
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await withdrawFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await withdrawFunction();
    }
  };

  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div>
        <InputField
          disabled
          name="USCAmount"
          value={balanceView}
          label="You Withdraw"
          placeholder="Loading. . ."
          dollarValue={{
            isFetched: isBalanceFetched,
            value: dollarViewValue,
          }}
          walletBalance={{
            isFetched: isBalanceFetched,
            value: balanceView,
          }}
        />
      </div>
      <SmallDropdown
        items={items}
        onSelect={handleDropdownSelect}
        selectedItem={items.find((item) => item.key === selectedKey)}
      />

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        loading={isStaking || isApproving || !isBalanceFetched}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {isApproved ? "Withdraw" : "Approve"}
      </Button>
    </FlexCol>
  );
};
