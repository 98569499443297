import { useForm } from "react-hook-form";
import { parseUnits } from "viem";
import { contractAddresses, OracleCommonAbi } from "../../../../meta";
import {
  useWingsContractWrite,
  MyFormProvider,
  Typography,
  FlexCol,
  RHFInputFieldFormatted,
  Button,
} from "../../../../shared";

interface ChangePriceForm {
  price: number;
}

export const WETHChangePriceForm: React.FC = () => {
  const methods = useForm<ChangePriceForm>();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: changePriceAsync } = useWingsContractWrite();

  const onSubmit = async (data: ChangePriceForm) => {
    await changePriceAsync(
      {
        abi: OracleCommonAbi,
        address: contractAddresses.WETH_ORACLE,
        functionName: "setPrice",
        args: [parseUnits(data.price?.toString(), 8)],
      },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography>ORACLE/WETH ORACLE/</Typography>
      <FlexCol className="gap-3">
        <RHFInputFieldFormatted<ChangePriceForm>
          name="price"
          placeholder="Enter price"
        />
        <Button type="submit">Submit</Button>
      </FlexCol>
    </MyFormProvider>
  );
};
