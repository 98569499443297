import { useMemo } from "react";
import { Address } from "viem";
import { contractAddresses, StakingManagerAbi } from "@meta";
import { useReadContract } from "wagmi";

/**
 * Use this custom hook to get the staking status of a token from the StakingManager contract.
 *
 * @param tokenAddress - The address of the token to check staking status for.
 * @returns An object containing staking status details or undefined if data is not available.
 */
export const useCheckStakingStatus = (tokenAddress: Address) => {
  const { data, isFetched, refetch } = useReadContract({
    abi: StakingManagerAbi,
    address: contractAddresses.StakingManager,
    functionName: "isStakingStarted",
    args: [tokenAddress],
  });

  const stakingStatus = useMemo(() => {
    return {
      isStaking: data,
      isFetched: isFetched,
    };
  }, [data, isFetched]);

  if (data === undefined) {
    return {
      stakingStatus,
      isStaking: undefined,
      isFetched: undefined,
      refetch,
    };
  }

  return {
    stakingStatus,
    isStaking: data,
    isFetched: isFetched,
    refetch,
  };
};
