import { FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";
import Check from "@assets/layout/check-circle.svg";

interface InfoItemsProps {
  infos: (string | JSX.Element)[];
}

const InfoItems: React.FC<InfoItemsProps> = ({ infos }) => {
  return (
    <FlexCol className="gap-4">
      {infos.map((info, index) => (
        <FlexRow key={index} className="gap-3 items-center">
          <SVGWrapper src={Check} width={20} height={20} />
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60"
          >
            {info}
          </Typography>
        </FlexRow>
      ))}
    </FlexCol>
  );
};

export default InfoItems;
