import {
  arbitrum,
  base,
  blast,
  linea,
  mainnet,
  mode,
  optimism,
  polygon,
} from "viem/chains";

/* --------- */
/*   Icons   */
/* --------- */
import mainnetIcon from "@assets/chains/mainnet-d67af686.svg";
import baseIcon from "@assets/chains/base-79dee8a2.svg";
import blastIcon from "@assets/chains/blast.svg";
import lineaIcon from "@assets/chains/linea-f6562b77.svg";
import modeIcon from "@assets/chains/mode-darkmode-7a5caaf5.svg";
import optimismIcon from "@assets/chains/optimism-8c52ff09.svg";
import polygonIcon from "@assets/chains/polygon-9196afe6.svg";
import arbitrumIcon from "@assets/chains/arbitrum-arb-logo.svg";

export const ChainIcons = {
  [mainnet.id]: mainnetIcon,
  [base.id]: baseIcon,
  [blast.id]: blastIcon,
  [linea.id]: lineaIcon,
  [mode.id]: modeIcon,
  [optimism.id]: optimismIcon,
  [polygon.id]: polygonIcon,
  [arbitrum.id]: arbitrumIcon,
};
