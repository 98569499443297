import { FlexRow, RainbowKitCustom } from "@shared";
import { NavSidebar } from "../components/sidebar/NavSidebar";
import { MobileNavBar } from "../components/sidebar/MobileNavbar";

export const DappLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="lg:hidden">
        <MobileNavBar />
      </div>

      <div className="flex flex-row w-full flex-grow mt-16 lg:mt-0">
        <div className="hidden lg:block flex-none w-[269px]">
          <NavSidebar />
        </div>

        {/* Main Content */}
        <div className="flex flex-grow p-3 lg:p-8 pt-4 lg:pl-0">
          <div className="w-full">
            <FlexRow className="w-full justify-end mb-5">
              <FlexRow className="hidden min-w-[154px] lg:block">
                <RainbowKitCustom />
              </FlexRow>
            </FlexRow>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
