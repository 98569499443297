import {
  Card,
  DisplayMoney,
  FlexCol,
  FlexRow,
  StandardTooltip,
  SVGWrapper,
  Typography,
} from "@shared";

import uscEthIcon from "@assets/tokens/uscEth.svg";
import { UscEthUniswapPoolReservesChart } from "../charts/reserves/UscEthUniswapPoolReservesChart";
import { UscUsdcUniswapPoolReservesChart } from "../charts/reserves/UscUsdcUniswapPoolReservesChart";

import stEthIcon from "@assets/tokens/stEth.svg";
import weETHIcon from "@assets/tokens/weEth.svg";
import { HoldingsPieChart } from "../charts/StUSCHolders";
import { useReserveValue } from "../../../state/reserveholder/useOverviewData";

export const ReservesTab = () => {
  const reserveValue = useReserveValue();

  return (
    <div className="flex flex-col gap-8">
      <FlexRow className="flex-col xl:flex-row gap-8">
        <div className="w-full xl:w-2/3 ">
          <Card hasShadowInstead hasBorder>
            <FlexCol className="p-4 gap-6">
              <Typography type="body-small-medium">Reserves Stats</Typography>
              <FlexRow className="flex-col md:flex-row">
                <FlexCol className="w-full items-left xs:items-center md:w-1/2 justify-normal xs:justify-center   text-left xs:text-center">
                  <Typography
                    type="body-medium-regular"
                    className="text-neutral-black-60"
                  >
                    Total Reserves
                  </Typography>
                  <DisplayMoney {...reserveValue} typography="h3" />
                </FlexCol>
                <FlexRow className="flex-col xs:flex-row w-full md:w-1/2 justify-normal xs:justify-center items-left xs:items-center min-h-[244px] items-left xs:text-center">
                  <FlexCol className="w-1/2">
                    <FlexCol className="pt-8 pb-4 xs:py-8 xs:gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                      <FlexRow className="gap-2">
                        <SVGWrapper width={20} height={20} src={stEthIcon} />
                        <Typography
                          className="text-neutral-black-60"
                          type="body-small-regular"
                        >
                          stETH Reserves
                        </Typography>
                        <StandardTooltip>?</StandardTooltip>
                      </FlexRow>
                      <Typography type="body-large-bold">$404,646</Typography>
                    </FlexCol>
                    <FlexCol className="py-4 xs:py-8 gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                      <FlexRow className="gap-2">
                        <SVGWrapper width={20} height={20} src={stEthIcon} />
                        <Typography
                          className="text-neutral-black-60"
                          type="body-small-regular"
                        >
                          stETH APR
                        </Typography>
                        <StandardTooltip>?</StandardTooltip>
                      </FlexRow>
                      <Typography type="body-large-bold">$404,646</Typography>
                    </FlexCol>
                  </FlexCol>
                  <FlexCol className="w-1/2">
                    <FlexCol className="py-4 xs:py-8  gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                      <FlexRow className="gap-2">
                        <SVGWrapper width={20} height={20} src={weETHIcon} />
                        <Typography
                          className="text-neutral-black-60 whitespace-nowrap"
                          type="body-small-regular"
                        >
                          weETH Reserves
                        </Typography>
                        <StandardTooltip>?</StandardTooltip>
                      </FlexRow>
                      <Typography type="body-large-bold">$404,646</Typography>
                    </FlexCol>
                    <FlexCol className="py-4 xs:py-8  gap-2 justify-normal text-start items-start xs:justify-center xs:text-center xs:items-center">
                      <FlexRow className="gap-2">
                        <SVGWrapper width={20} height={20} src={weETHIcon} />
                        <Typography
                          className="text-neutral-black-60"
                          type="body-small-regular"
                        >
                          weETH APR
                        </Typography>
                        <StandardTooltip>?</StandardTooltip>
                      </FlexRow>
                      <Typography type="body-large-bold">$404,646</Typography>
                    </FlexCol>
                  </FlexCol>
                </FlexRow>
              </FlexRow>
            </FlexCol>
          </Card>
        </div>
        <div className="w-full xl:w-1/3 ">
          <Card hasShadowInstead className="w-full h-full">
            <HoldingsPieChart
              name="Holders"
              icon={<SVGWrapper width={20} height={20} src={stEthIcon} />}
            />
          </Card>
        </div>
      </FlexRow>

      <div className="flex-grow ">
        <Card hasShadowInstead>
          <UscEthUniswapPoolReservesChart
            name="Usc/Eth Uniswap Pool Reserves"
            icon={<SVGWrapper width={34.6} height={34.6} src={uscEthIcon} />}
          />
        </Card>
      </div>
      <div className="flex-grow">
        <Card hasShadowInstead>
          <UscUsdcUniswapPoolReservesChart name="Usc/Usdc Uniswap Pool Reserves" />
        </Card>
      </div>
    </div>
  );
};
