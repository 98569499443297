import {
  Card,
  DisplayTokenAmount,
  FlexCol,
  FlexRow,
  SVGWrapper,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";
import { AuditedBy } from "../../../../components/auditedBy/AuditedBy";
//icons
import { FormTabButton } from "../../../../components/tab/FormTabButton";
import { StakeDepositPanel } from "./stake-tab/StakeDepositPanel";
import { UscMarketCapBanner } from "../../../../components/uscMarketCapBanner/UscMarketCapBanner";
import { StakeForm } from "./stake-tab/form/StakeForm";
import { UnstakeDepositPanel } from "./unstake-tab/UnstakeDepositPanel";
import { UnstakeForm } from "./unstake-tab/form/UnstakeForm";
import { useActiveTabsFromLocation } from "../../../../state/activeTab/useTabsFromLocation";
import { WrapDepositPanel } from "./wrap-tab/WrapDepositPanel";
import { WrapForm } from "./wrap-tab/form/WrapForm";
import { UnwrapDepositPanel } from "./unwrap-tab/UnwrapDepositPanel";
import { UnwrapForm } from "./unwrap-tab/form/UnwrapForm";

import StakeIcon from "@assets/layout/stakeIcon.svg";
import { useFetchWstUscPrice } from "../../../../state/wstUsc/useFetchWstUscPrice";

export const Main = () => {
  const { activeStakeFormTab } = useActiveTabsFromLocation();

  const getModifiedTitle = () => {
    let title = "Stake USC";
    if (activeStakeFormTab === "Wrap") {
      title = "Wrap stUSC";
    } else if (activeStakeFormTab === "Unwrap") {
      title = "Unwrap wstUSC";
    } else if (activeStakeFormTab === "Unstake") {
      title = "Unstake stUSC";
    } else {
      title = "Stake USC";
    }

    return title;
  };

  const { exchangeRate, isFetched } = useFetchWstUscPrice();
  const exchangeRateNumber = Number(exchangeRate);
  const exchangeRateString = exchangeRateNumber.toFixed(2);

  return (
    <Card hasBorder className=" overflow-hidden">
      <FlexCol className="w-full">
        <FlexCol className="gap-6">
          <FlexCol className="px-4 sm:px-8 pt-8 gap-6 bg-white">
            <FlexRow className="items-center gap-4">
              <Typography type="h2" className="text-button-selection-stroke">
                {getModifiedTitle()}
              </Typography>
              <SVGWrapper
                src={StakeIcon}
                width={32}
                height={32}
                color="#0051A6"
              />
              <div className="hidden xs:block">
                <AuditedBy />
              </div>
            </FlexRow>
            <div className="block xs:hidden w-[156px]">
              <AuditedBy />
            </div>
            <UscMarketCapBanner />
          </FlexCol>
          {/* Tab Section */}
          <FlexCol className="w-full   bg-neutral-black-10">
            <TabProvider defaultTab={"Stake"} overrideUrlSlug="formTab">
              {/* Tab Buttons */}
              <FlexRow className="bg-white w-full px-8">
                <FormTabButton tab="Stake" className="flex-1 py-2">
                  Stake
                </FormTabButton>
                <FormTabButton tab="Unstake" className="flex-1 py-2">
                  Unstake
                </FormTabButton>
                <FormTabButton tab="Wrap" className="flex-1 py-2">
                  Wrap
                </FormTabButton>
                <FormTabButton tab="Unwrap" className="flex-1 py-2">
                  Unwrap
                </FormTabButton>
              </FlexRow>

              {/* Mint Section */}
              <TabContent tab="Stake">
                <StakeDepositPanel />

                <FlexCol className="p-2 pt-4 gap-4 sm:p-6">
                  <StakeForm />

                  <FlexCol className="w-full">
                    <FlexRow className="w-full py-3 items-center justify-between">
                      <Typography type="caption-regular">
                        Exchange Rate
                      </Typography>
                      <FlexRow className="gap-2 items-center">
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="USC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                        <Typography type="caption-regular"> = </Typography>
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="stUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                      </FlexRow>
                    </FlexRow>
                  </FlexCol>
                </FlexCol>
              </TabContent>

              {/* Redeem Section */}
              <TabContent tab="Unstake">
                <UnstakeDepositPanel />
                <FlexCol className="p-2 pt-4 gap-4 sm:p-6">
                  <UnstakeForm />
                  <FlexCol className="w-full">
                    <FlexRow className="w-full py-3 items-center justify-between">
                      <Typography type="caption-regular">
                        Exchange Rate
                      </Typography>
                      <FlexRow className="gap-2 items-center">
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="USC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                        <Typography type="caption-regular"> = </Typography>
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="stUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                      </FlexRow>
                    </FlexRow>
                  </FlexCol>
                </FlexCol>
              </TabContent>
              <TabContent tab="Wrap">
                <WrapDepositPanel />
                <FlexCol className="p-2 pt-4 gap-4 sm:p-6">
                  <WrapForm />
                  <FlexCol className="w-full">
                    <FlexRow className="w-full py-3 items-center justify-between">
                      <Typography type="caption-regular">
                        Exchange Rate
                      </Typography>
                      <FlexRow className="gap-2 items-center">
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="stUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                        <Typography type="caption-regular"> = </Typography>
                        <DisplayTokenAmount
                          viewValue={exchangeRateString}
                          isFetched={isFetched}
                          symbol="wstUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                      </FlexRow>
                    </FlexRow>
                  </FlexCol>
                </FlexCol>
              </TabContent>
              <TabContent tab="Unwrap">
                <UnwrapDepositPanel />
                <FlexCol className="p-2 pt-4 gap-4 sm:p-6">
                  <UnwrapForm />
                  <FlexCol className="w-full">
                    <FlexRow className="w-full py-3 items-center justify-between">
                      <Typography type="caption-regular">
                        Exchange Rate
                      </Typography>
                      <FlexRow className="gap-2 items-center">
                        <DisplayTokenAmount
                          viewValue="1"
                          symbol="stUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                        <Typography type="caption-regular"> = </Typography>
                        <DisplayTokenAmount
                          viewValue={exchangeRateString}
                          isFetched={isFetched}
                          symbol="wstUSC"
                          symbolPosition="after"
                          typography="caption-regular"
                        />
                      </FlexRow>
                    </FlexRow>
                  </FlexCol>
                </FlexCol>
              </TabContent>
            </TabProvider>
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
