import { useNavigate } from "react-router-dom";
import { FAQsComponent } from "../../../../components/info/FAQsComponent";
import InfoItems from "../../../../components/info/InfoItems";
import { InfoTabButton } from "../../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";
import { RouterConfig } from "@router";

const infos = [
  "USC is minted using ETH, LSTs, or LRTs with zero slippage, removing exposure to the deposited asset. Zapin allows minting directly with stablecoins, converting them into protocol assets.",
  "USC can be minted from multiple L2 chains and is always received on Ethereum mainnet.",
  "USC can be redeemed for ETH, LSTs, or LRTs with zero slippage, with no withdrawal queues or cooldown periods.",
  "A 0.3% fee applies to both minting and redeeming, deducted from the amount.",
  "USC doesn’t generate yield; users must stake it to get stUSC, Chi Protocol’s rebasing stablecoin, to earn rewards.",
  "stUSC can be wrapped into wstUSC, a non-rebasing, value-accruing version.",
];

export const InfoSection = () => {
  const nav = useNavigate();

  const faqItems = [
    {
      title: "Which assets can I use to mint USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          USC is minted using ETH, LSTs, and LRTs, which serve as reserve
          assets. You can also mint USC with stablecoins, which are
          auto-converted into protocol reserve assets.
        </Typography>
      ),
    },
    {
      title: "What opportunities can I access with USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          After minting or buying USC from external pools, users can explore
          reward opportunities within the Chi Protocol ecosystem and external
          markets in the{" "}
          <a
            className="text-primary underline cursor-pointer"
            onClick={() => nav(RouterConfig.Routes.liquidity)}
          >
            Liquidity page.
          </a>
        </Typography>
      ),
    },
    {
      title: "What are the fees for minting and redeeming USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          Along with network gas fees, minting and redeeming incur a fee of 0.3%
          of the value.
        </Typography>
      ),
    },
    {
      title: "Is USC a yield-bearing stablecoin?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          No, USC doesn’t generate yield by itself. To earn rewards, users must
          stake USC in exchange for stUSC, the yield-bearing version.
        </Typography>
      ),
    },
    {
      title: "Is there a cap on minting and redeeming USC?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          There’s no cap for ETH or WETH minting. For LSTs, LRTs, and
          stablecoins, caps depend on the size of their respective liquidity
          pools.
        </Typography>
      ),
    },
    {
      title: "What are the risks?",
      content: (
        <Typography type="body-small-regular" className="text-neutral-black-60">
          As with any DeFi product, risks exist. While Chi Protocol’s contracts
          are regularly audited, there are blockchain, third-party, and economic
          risks. More details can be found in the{" "}
          <a
            href="https://chi-protocol.gitbook.io/chi-docs"
            target="_blank"
            className="text-primary underline"
          >
            Documentation
          </a>
          .
        </Typography>
      ),
    },
  ];

  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};
