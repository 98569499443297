import { FlexRow, SVGWrapper, Typography } from "@shared";
import { useLocation, useNavigate } from "react-router-dom";

export interface NavItem {
  icon: string;
  label: string;
  route: string;
}

export const MenuItem: React.FC<{ item: NavItem; onClick?: () => void }> = ({
  item,
  onClick,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname === item.route;

  return (
    <FlexRow
      className={`gap-3 p-3 rounded-md items-center cursor-pointer hover:bg-primary-10 ${
        isActive ? "bg-primary-10" : ""
      }`}
      onClick={() => {
        navigate(item.route);
        if (onClick) onClick();
      }}
    >
      <SVGWrapper
        src={item.icon}
        width="20px"
        height="20px"
        color={isActive ? "currentColor" : "#8996A9"}
        className={`${isActive ? "text-primary" : "text-neutral-black-50"}`}
      />
      <Typography
        type="body-medium-medium"
        className={`${
          isActive ? "text-button-selection-stroke" : "text-neutral-black-50"
        }`}
      >
        {item.label}
      </Typography>
    </FlexRow>
  );
};
