import cn from "classnames";
import React, { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const PageContent: React.FC<Props> = ({ children, ...rest }) => {
  const classes = cn(" mb-2", rest.className);

  return (
    <div {...rest} className={`pb-5 ${classes}`}>
      {children}
    </div>
  );
};
