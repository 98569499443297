import React from "react";
import {
  FlexRow,
  FlexCol,
  Typography,
  CustomDropdown,
  SVGWrapper,
} from "@shared";
import { Option2 } from "../../pages/home/utils/getOptions2";
import { Address } from "viem";

const mapOptions2ToOptions = (option: Option2[]) => {
  return option.map(({ key, value, name, icon }) => {
    return {
      key,
      value,
      renderOption: (
        <FlexRow className="items-center w-full gap-1 pr-1">
          <SVGWrapper src={icon} width={19} height={19} />
          <FlexCol className="items-start text-start">
            <Typography type="caption-regular">{name}</Typography>
          </FlexCol>
        </FlexRow>
      ),
    };
  });
};

export const AssetPicker: React.FC<{
  selectedAsset?: Address;
  setSelectedAsset: (tab?: Address) => void;
  options2: Option2[];
}> = ({ selectedAsset, setSelectedAsset, options2 }) => {
  return (
    <CustomDropdown
      isSmall
      selectedOption={selectedAsset}
      options={mapOptions2ToOptions(options2)}
      setSelectedOption={(e) => {
        setSelectedAsset(e as Address);
      }}
    />
  );
};
