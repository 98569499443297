import React from "react";
import {
  Button,
  FlexRow,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  SVGWrapper,
  TransactionComplete,
  TransferMoney,
  Typography,
  useNotificationContext,
  useWingsContractWrite,
} from "@shared";
import { Address } from "viem";
import { LockingManagerAbi } from "@meta";
import Chi from "@assets/tokens/chi.svg";
import { useStepState } from "../../../../state/common/useStepState.ui";

export type BoostingTableRowDesktopProps = {
  isActive: boolean;
  iconSrc: string;
  tokenName: string;
  amount: string | number;
  unlockDate: string;
  chiReward: string;
  index: number;
  tokenAddress: Address;
};

export const BoostingTableRowDesktop: React.FC<
  BoostingTableRowDesktopProps
> = ({
  isActive,
  iconSrc,
  tokenName,
  amount,
  unlockDate,
  chiReward,
  tokenAddress,
  index,
}) => {
  const { writeContractAsync: lockedClaimAsync } = useWingsContractWrite();

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps } = useStepState(
    ["Approve", "Claim"],
    1
  );

  const loadingNotifLocking = (currentStep: number, rewardDetails: string) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={Chi} width={22} height={22} />}
                  text={rewardDetails}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleClaimAsync = async (lpToken: Address, index: number) => {
    loadingNotifLocking(currentStep, chiReward);

    await lockedClaimAsync(
      {
        abi: LockingManagerAbi,
        address: lpToken,
        functionName: "claimRewards",
        args: [BigInt(index)],
      },
      {
        onSuccess: (txHash) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={<SVGWrapper src={Chi} width={22} height={22} />}
                        text={chiReward}
                        symbol="CHI"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };

  return !isActive && Number(chiReward) == 0 ? null : (
    <div className="grid grid-cols-22 items-center justify-between p-4 border border-[#EEE] shadow-[0px_2px_2px_0px_rgba(0,0,0,0.02)] rounded-xl border-solid">
      <div className="flex col-span-1 items-center ">
        <StateComponent isActive={isActive} />
      </div>
      <div className="col-span-7">
        <FlexRow className="gap-2">
          <SVGWrapper src={iconSrc} height={20} />
          <Typography type="body-small-regular">{tokenName}</Typography>
        </FlexRow>
      </div>

      <div className="col-span-4">
        <Typography type="body-small-regular">{amount}</Typography>
      </div>
      <div className="col-span-5">
        <Typography type="body-small-regular">{chiReward}</Typography>
      </div>
      <div className="col-span-3">
        <Typography type="body-small-regular">{unlockDate}</Typography>
      </div>
      <div className="col-span-2 flex items-end justify-end">
        <Button
          className="bg-primary text-white text-caption-medium px-[6px] h-6 rounded-md"
          onClick={() => handleClaimAsync(tokenAddress, index)}
          disabled={Number(chiReward) == 0}
        >
          Claim
        </Button>
      </div>
    </div>
  );
};

export const StateComponent: React.FC<{ isActive: boolean }> = ({
  isActive,
}) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <div
        className={`w-[6px] h-[6px] rounded-full 
      ${isActive ? "bg-secondary" : "bg-red-500"}
        `}
      ></div>
    </div>
  );
};
