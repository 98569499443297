import React from "react";

export const Header: React.FC<{}> = () => {
  return (
    <div className="relative items-center  p-4 flex flex-row border border-[#EEE] bg-[#F9F8F8] shadow-[0px_2px_2px_0px_rgba(0,0,0,0.02)] rounded-xl border-solid">
      <div className="w-full max-w-[200px] ">
        <LocalText text="Market & Pair" />
      </div>

      <div className="w-full max-w-[160px]">
        <LocalText text="TVL" />
      </div>
      <div className="w-full max-w-[130px]">
        <LocalText text="APR" />
      </div>

      <div className="flex flex-row w-full max-w-[120px]">
        <div className="w-[100px]">
          <LocalText text="Reward" />
        </div>
        <div className="w-1/5">
          <LocalText text="State" />
        </div>
        <div className="w-1/5 mr-[-7px]">
          <LocalText text="" />
        </div>
      </div>
    </div>
  );
};

const LocalText: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <div className="flex flex-row gap-2 bg-[#F9F8F8] flex-grow items-center">
      <p className="text-[#949494] text-[15px] not-italic font-medium leading-[normal]">
        {text}
      </p>
    </div>
  );
};
