import {
  mainnet,
  optimism,
  polygon,
  arbitrum,
  base,
  linea,
  zksync,
  blast,
  mode,
} from "@wagmi/core/chains";

export const SpokePoolAddresses = {
  [mainnet.id]: "0x5c7BCd6E7De5423a257D81B442095A1a6ced35C5",
  [optimism.id]: "0x6f26Bf09B1C792e3228e5467807a900A503c0281",
  [polygon.id]: "0x9295ee1d8C5b022Be115A2AD3c30C72E34e7F096",
  [arbitrum.id]: "0xe35e9842fceaCA96570B734083f4a58e8F7C5f2A",
  [base.id]: "0x09aea4b2242abC8bb4BB78D537A67a245A7bEC64",
  [linea.id]: "0x7E63A5f1a8F0B4d0934B2f2327DAED3F6bb2ee75",
  [zksync.id]: "0xE0B015E54d54fc84a6cB9B666099c46adE9335FF",
  [blast.id]: "0x2D509190Ed0172ba588407D4c2df918F955Cc6E1",
  [mode.id]: "0x3baD7AD0728f9917d1Bf08af5782dCbD516cDd96",
};

export const MulticallHandler = "0x924a9f036260DdD5808007E1AA95f08eD08aA569";
