import { ReserveHolderAbi, contractAddresses } from "@meta";
import { displayTokens } from "@utils";
import { useReadContract } from "wagmi";

export const useReserveValue = () => {
  const { data: reserveValue, ...restReserveValue } = useReadContract({
    abi: ReserveHolderAbi,
    address: contractAddresses.ReserveHolder,
    functionName: "getReserveValue",
  });

  const reserveValueDSPL = {
    value: displayTokens(reserveValue || 0n, { decimals: 8 }),
    name: "Reserves Value",
  };

  return {
    ...restReserveValue,
    viewValue: reserveValueDSPL.value,
    reserveValueDSPL,
    originalValue: reserveValue,
  };
};
