/* ------------- */
/*   Icons       */
/* ------------- */
import Plus from "@assets/layout/plus-circle.svg";

/* ----------- */
/*    Other    */
/* ------------ */
import {
  FlexCol,
  FlexRow,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  SVGWrapper,
  DisplayPercentage,
} from "@shared";

import { useAccount, useWatchAsset } from "wagmi";
import { WalletBadge } from "../../../../../components/walletBadge/WalletBadge";
import { AcrossChains } from "./form/AcrossChains";
import { useChainIdContext } from "../../../contexts/ChainIdContext";
import {
  useEthersBalance,
  useEthersErc20Balance,
} from "../../../../../state/erc20/useBalanceEthers";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { displayTokens } from "@utils";
import { contractAddresses } from "@meta";
import { useFetchAprDisplayable } from "../../../../../state/usc/useFetchAprDisplayable";
import { useGetTokenPriceCrosschain } from "../../../../../state/price/useGetTokenPriceCrosschain";
import React from "react";
import { Address } from "viem";
import { TOKEN_CONFIG } from "../../../../../config/tokenConfig";

export const MintDepositPanel: React.FC<{
  setIsMintAndStake: React.Dispatch<React.SetStateAction<boolean>>;
  isMintAndStake: boolean;
  selectedAsset?: Address;
}> = ({ setIsMintAndStake, isMintAndStake, selectedAsset }) => {
  /* --------------- */
  /*    Meta data    */
  /* --------------- */
  const { chainId: accrossChainId } = useChainIdContext();
  const { isConnected } = useAccount();
  const isEthSelected = selectedAsset === contractAddresses.ETH;
  const tokenConfig = TOKEN_CONFIG[selectedAsset || contractAddresses.ETH];

  /* ------------- */
  /*    Methods    */
  /* ------------- */
  const { watchAsset } = useWatchAsset();

  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const assetPrice = useGetTokenPriceCrosschain(
    selectedAsset || contractAddresses.ETH,
    accrossChainId
  );

  const ethBalance = useEthersBalance(accrossChainId);
  const otherTokenBalance = useEthersErc20Balance(
    accrossChainId,
    selectedAsset
  );
  const balance = isEthSelected ? ethBalance : otherTokenBalance;
  const uscBalance = useGetWalletBalanceByToken(contractAddresses.USC);
  const stUscBalance = useGetWalletBalanceByToken(contractAddresses.USCStaking);
  const { totalApr, isFetchedAPR } = useFetchAprDisplayable();

  //TOGGLE
  const handleToggleChange = () => {
    setIsMintAndStake((prev) => !prev);
  };

  const handleAddUsc = () => {
    watchAsset({
      type: "ERC20",
      options: {
        address: contractAddresses.USC,
        symbol: "USC",
        decimals: 18,
      },
    });
  };

  return (
    <div>
      <FlexCol className="w-full gap-5 px-3 py-5 sm:px-6 sm:py-10  bg-primary selection:bg-white selection:text-primary">
        <div className="hidden sm:block w-full mb-2">
          <AcrossChains />
        </div>
        <FlexRow className="flex-col sm:flex-row w-full items-start gap-4 sm:gap-0 sm:items-center justify-between">
          <FlexCol className=" gap-2">
            <Typography
              type="body-small-medium"
              className="text-neutral-white-80"
            >
              Available to Deposit
            </Typography>
            <DisplayTokenAmount
              isFetched={isConnected ? balance?.isFetched : true}
              viewValue={isConnected ? balance?.data?.viewValue : "0.00"}
              symbol={tokenConfig?.symbol}
              typography="body-large-bold"
              className="text-white"
            />
            <DisplayMoney
              isFetched={assetPrice.isFetched}
              viewValue={displayTokens(balance.data.originalValue || 0n, {
                formattedPrice: assetPrice.formattedPrice,
                displayInDollars: true,
                hideTokenAmount: true,
                decimals: tokenConfig.decimals,
              })}
              typography="caption-regular"
              className="text-neutral-white-80"
            />
          </FlexCol>
          <FlexCol className="gap-3 items-end">
            <WalletBadge />
          </FlexCol>
          <div className="block sm:hidden w-full mb-2">
            <AcrossChains />
          </div>
        </FlexRow>
        <FlexRow className="w-full border-[1px] border-neutral-white-20"></FlexRow>
        <FlexRow className="w-full items-center justify-between">
          <FlexCol className="gap-2">
            <Typography
              type="body-small-medium"
              className="text-neutral-white-80"
            >
              {isMintAndStake ? "stUSC Balance" : "USC Balance"}
            </Typography>
            <FlexRow className="gap-2 items-center">
              {!isMintAndStake ? (
                <DisplayTokenAmount
                  symbol="USC"
                  typography="body-large-bold"
                  className="text-white min-w-4"
                  isLoading={isConnected ? uscBalance.isFetching : false}
                  viewValue={isConnected ? uscBalance?.viewValue : "0.00"}
                />
              ) : (
                <DisplayTokenAmount
                  symbol="stUSC"
                  typography="body-large-bold"
                  className="text-white min-w-4"
                  isLoading={isConnected ? stUscBalance.isFetching : false}
                  viewValue={isConnected ? stUscBalance?.viewValue : "0.00"}
                />
              )}
              <button onClick={handleAddUsc} className="focus:outline-none">
                <SVGWrapper src={Plus} width={16} height={16} />
              </button>
            </FlexRow>
          </FlexCol>
          <FlexCol className="gap-4 items-center">
            <FlexRow className="flex-col sm:flex-row gap-2 items-end sm:items-center">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-56"
              >
                stUSC APR
              </Typography>
              <DisplayPercentage
                viewValue={totalApr}
                isFetched={isFetchedAPR}
                symbolPosition="after"
                className="text-white"
                typography="body-large-bold"
              />
            </FlexRow>
            <FlexRow className="hidden sm:flex gap-2 items-center">
              <Typography
                type="caption-medium"
                className={
                  isMintAndStake ? `text-white` : `text-neutral-white-56`
                }
              >
                Mint And Stake
              </Typography>
              <input
                type="checkbox"
                className={`toggle    ${
                  isMintAndStake
                    ? "[--tglbg:white] bg-primary border-primary"
                    : "bg-gray-400 border-gray-400"
                }`}
                checked={isMintAndStake}
                onChange={handleToggleChange}
              />
            </FlexRow>
          </FlexCol>
        </FlexRow>
        <FlexCol className="flex sm:hidden w-full items-end">
          <FlexRow className=" gap-2 items-center">
            <Typography
              type="caption-medium"
              className={
                isMintAndStake ? `text-white` : `text-neutral-white-56`
              }
            >
              Mint And Stake
            </Typography>
            <input
              type="checkbox"
              className={`toggle    ${
                isMintAndStake
                  ? "[--tglbg:white] bg-primary border-primary"
                  : "bg-gray-400 border-gray-400"
              }`}
              checked={isMintAndStake}
              onChange={handleToggleChange}
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </div>
  );
};
