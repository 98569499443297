import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons
import WstUsc from "@assets/tokens/wstUsc.svg";

export const FirstElementwstUSC = () => {
  return (
    <>
      <div className="flex flex-row gap-2 items-center">
        <SVGWrapper src={WstUsc} width={24} height={24} />
        <div className="flex flex-col gap-1 max-w-full">
          <FlexRow className="gap-[2px]">
            <Typography type="body-small-regular">wstUSC</Typography>
          </FlexRow>
          <Typography
            className="text-[#949494] truncate overflow-hidden whitespace-nowrap text-ellipsis max-w-full"
            type="body-small-regular"
          >
            Wrapped Staked USC
          </Typography>
        </div>
      </div>
    </>
  );
};
