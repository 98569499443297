import React from "react";
import { useAccount } from "wagmi";
import { RainbowKitCustom } from "../connect-buttons/RainbowKitCustom/RainbowKitCustom";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "small" | "normal" | "big";
  fullWidth?: boolean;
  loading?: boolean;
  variant?: "primary" | "white";
  type?: any;
  textOverride?: string;
  onClickOverride?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  size = "normal",
  fullWidth = false,
  variant = "primary",
  className = "",
  type = "button",
  loading,
  children,
  textOverride,
  ...props
}) => {
  const { isConnected } = useAccount();
  const sizeClasses = {
    small: "py-1 md:py-2 lg:py-2 px-5",
    normal: "py-3 md:py-3 lg:py-3 px-6",
    big: "py-5 md:py-4 lg:py-4 px-8",
  };

  const variantClasses = {
    text: "bg-transparent hover:bg-primary bg-primary text-white",
    outlined: "hover:bg-primary bg-primary text-white",
    contained: "hover:bg-primary bg-primary text-white",
    primary: "hover:bg-primary bg-primary text-white",
    white:
      "flex w-[138px] h-9 px-4 py-2 bg-white text-primary whitespace-nowrap",
  };

  const buttonClasses = `${props.disabled || loading ? "bg-primary-10 shadow-button text-text-disabled cursor-default" : variantClasses[variant]} ${sizeClasses[size]} 
    ${fullWidth ? "w-full" : ""} flex items-center 
     justify-center space-x-2 text-center rounded-lg ${className}`;

  if (!isConnected) {
    return <RainbowKitCustom variant={variant} />;
  }

  return (
    <button
      className={` text-body-small-bold ${buttonClasses}`}
      type={type}
      disabled={props.disabled || loading}
      onClick={props.onClick}
      {...props}
    >
      {textOverride || children}
      {loading && <span className="flex loading loading-spinner ml-1.5" />}
    </button>
  );
};
