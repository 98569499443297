import {
  Button,
  Card,
  FlexCol,
  MyFormProvider,
  useWingsContractWrite,
} from "@shared";
import { useForm } from "react-hook-form";
import { contractAddresses, LockingManagerAbi } from "@meta";

interface FormData {
  amount: string;
}

export const UpdateEpochWstEth = () => {
  const methods = useForm<FormData>();
  const { handleSubmit } = methods;

  const { writeContractAsync, isPending: isStaking } = useWingsContractWrite();

  const onSubmit = async () => {
    await writeContractAsync({
      abi: LockingManagerAbi,
      address: contractAddresses.WST_USC_Locking,
      functionName: "updateEpoch",
    });
  };
  return (
    <Card>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="gap-4">
          <Button loading={isStaking} type="submit">
            Update epoch WST_USC_Locking
          </Button>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
