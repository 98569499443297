import { useForm } from "react-hook-form";
import { ReserveHolderAbi, contractAddresses } from "../../../../../meta";
import {
  useWingsContractWrite,
  MyFormProvider,
  Typography,
  FlexCol,
  Button,
  Card,
  useNotificationContext,
} from "../../../../../shared";

export const Rebalance: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: rebalance, isPending: isRebalancing } =
    useWingsContractWrite();

  const onSubmit = async () => {
    await rebalance(
      {
        abi: ReserveHolderAbi,
        address: contractAddresses.ReserveHolder,
        functionName: "rebalance",
        args: [[0n, 0n]],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Rebalance success</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>Reserve Holder/rebalance/</Typography>
          <FlexCol className="gap-3">
            <Button type="submit" loading={isRebalancing}>
              Rebalance
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
