import { FlexRow, Typography, SVGWrapper } from "@shared";

//icons
import BoostedChiEth from "@assets/tokens/boostedChiEth.svg";

export const FirstElementBoostedChiEth = () => {
  return (
    <>
      <div className="flex flex-row gap-2 flex-grow items-center">
        <SVGWrapper src={BoostedChiEth} width={34} height={25} />

        <div className="flex flex-col gap-1">
          <FlexRow className="gap-[2px]">
            <Typography
              type="body-small-regular"
              className="whitespace-nowrap "
            >
              Boosted CHI/ETH
            </Typography>
          </FlexRow>
          <Typography
            className="text-[#949494] truncate"
            type="body-small-regular"
          >
            CHI/ETH
          </Typography>
        </div>
      </div>
    </>
  );
};
