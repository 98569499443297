import { useAccount, useReadContract } from "wagmi";
import { useMemo } from "react";
import { DisplayTokensOptions, displayTokens } from "@utils";
import { Address } from "viem";
import { contractAddresses, StakingManagerAbi } from "@meta";
import { queryConfigs } from "@shared";

export const useGetUserStakedBalance = (
  contractAddress?: Address,
  options?: DisplayTokensOptions
) => {
  const { address } = useAccount();

  const { data, refetch, ...rest } = useReadContract({
    abi: StakingManagerAbi,
    address: contractAddresses.StakingManager,
    functionName: "getUserStakedBalance",
    args: [address!, contractAddress!],
    query: {
      enabled: !!address && !!contractAddress,
      ...queryConfigs.balanceQueryConfig,
    },
  });
  const displayData = useMemo(() => {
    return {
      value: displayTokens(data, {
        ...options,
      }),
      ...rest,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, contractAddress, rest.isFetched, rest.isLoading]);

  if (data === undefined) {
    return {
      displayData,
      viewValue: undefined,
      balance: undefined,
      ...rest,
      refetch,
    };
  }

  return {
    displayData,
    viewValue: displayData.value,
    balance: data,
    refetch,
    ...rest,
  };
};
