import { Card, FlexCol, FlexRow, TabContent, TabProvider } from "@shared";

import { StakeTabButton } from "../../tab/StakeTabButton";
import { UscStakeForm } from "./form/stake/UscStakeForm";

import { useState } from "react";
import { UscEthStakeForm } from "./form/stake/UscEthStakeForm";
import { ChiEthStakeForm } from "./form/stake/ChiEthStakeForm";
import { UscUnstakeForm } from "./form/unstake/UscUnstakeForm";
import { UscEthUnstakeForm } from "./form/unstake/UscEthUnstakeForm";
import { ChiEthUnstakeForm } from "./form/unstake/ChiEthUnstakeForm";
import { UscEthBoostForm } from "./form/boost/UscEthBoostForm";
import { WithdrawForm } from "./form/withdraw/WithdrawForm";
import { ChiEthBoostForm } from "./form/boost/ChiEthBoostForm";
import { WstUscBoostForm } from "./form/boost/WstUscBoostForm";

export const StakeComponent = () => {
  const [stakeSelectedItem, setStakeSelectedItem] = useState<string | null>(
    "uscEth"
  );
  const [unstakeSelectedItem, setUnstakeSelectedItem] = useState<string | null>(
    "uscEth"
  );
  const [boostSelectedItem, setBoostSelectedItem] = useState<string | null>(
    "stUscEth"
  );

  const [withdrawSelectedItem, setWithdrawSelectedItem] = useState<
    string | null
  >("stUscEth");

  const handleStakeSelect = (item: string) => {
    setStakeSelectedItem(item);
  };
  const handleUnstakeSelect = (item: string) => {
    setUnstakeSelectedItem(item);
  };
  const handleBoostSelect = (item: string) => {
    setBoostSelectedItem(item);
  };
  const handleWithdrawSelect = (item: string) => {
    setWithdrawSelectedItem(item);
  };

  return (
    <Card hasBorder>
      <FlexCol className="w-full p-4 gap-4">
        <TabProvider defaultTab="stake" overrideUrlSlug="stakeTab">
          <FlexRow className="justify-between">
            <FlexRow className="w-full h-[44px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
              <StakeTabButton tab="stake">Stake</StakeTabButton>
              <StakeTabButton tab="unstake">Unstake</StakeTabButton>
              <StakeTabButton tab="boost">Boost</StakeTabButton>
              <StakeTabButton tab="withdraw">Withdraw</StakeTabButton>
            </FlexRow>
          </FlexRow>
          <TabContent tab="stake">
            {stakeSelectedItem === "uscEth" && (
              <UscEthStakeForm
                selectedKey={stakeSelectedItem}
                onKeySelect={handleStakeSelect}
              />
            )}
            {stakeSelectedItem === "usc" && (
              <UscStakeForm
                selectedKey={stakeSelectedItem}
                onKeySelect={handleStakeSelect}
              />
            )}

            {stakeSelectedItem === "chiEth" && (
              <ChiEthStakeForm
                selectedKey={stakeSelectedItem}
                onKeySelect={handleStakeSelect}
              />
            )}
          </TabContent>
          <TabContent tab="unstake">
            {unstakeSelectedItem === "usc" && (
              <UscUnstakeForm
                selectedKey={unstakeSelectedItem}
                onKeySelect={handleUnstakeSelect}
              />
            )}
            {unstakeSelectedItem === "uscEth" && (
              <UscEthUnstakeForm
                selectedKey={unstakeSelectedItem}
                onKeySelect={handleUnstakeSelect}
              />
            )}
            {unstakeSelectedItem === "chiEth" && (
              <ChiEthUnstakeForm
                selectedKey={unstakeSelectedItem}
                onKeySelect={handleUnstakeSelect}
              />
            )}
          </TabContent>
          <TabContent tab="boost">
            {boostSelectedItem === "stUscEth" && (
              <UscEthBoostForm
                selectedKey={boostSelectedItem}
                onKeySelect={handleBoostSelect}
                setStakeSelectedItem={setStakeSelectedItem}
              />
            )}
            {boostSelectedItem === "stChiEth" && (
              <ChiEthBoostForm
                selectedKey={boostSelectedItem}
                onKeySelect={handleBoostSelect}
                setStakeSelectedItem={setStakeSelectedItem}
              />
            )}
            {boostSelectedItem === "wstUsc" && (
              <WstUscBoostForm
                selectedKey={boostSelectedItem}
                onKeySelect={handleBoostSelect}
              />
            )}
          </TabContent>
          <TabContent tab="withdraw">
            <WithdrawForm
              selectedKey={withdrawSelectedItem}
              onKeySelect={handleWithdrawSelect}
            />
          </TabContent>
        </TabProvider>
      </FlexCol>
    </Card>
  );
};
