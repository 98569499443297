import { useWingsContractWrite } from "../../../shared";

// USDC : 0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48
// USDT: 0xdAC17F958D2ee523a2206206994597C13D831ec7
// USDe: 0x4c9EDD5852cd905f086C759E8383e09bff1E68B3
// FRAX: 0x853d955aCEf822Db058eb8505911ED77F175b99e

export const useZapinToken = () => {
  const { writeContractAsync: zapinAsync, isPending: isZaping } =
    useWingsContractWrite();

  return {
    zapinAsync,
    isZaping,
  };
};
