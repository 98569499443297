import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { InfoTabType, MintFormTabType, StakeFormTabType } from "@shared";

export const useActiveTabsFromLocation = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const activeStakeFormTab = (params.formTab as StakeFormTabType) || "Stake"; //
  const activeMintFormTab = (params.formTab as MintFormTabType) || "Mint"; //
  const activeInfoTab = (params.infoTab as InfoTabType) || "key-insight"; //

  return {
    activeMintFormTab,
    activeStakeFormTab,
    activeInfoTab,
  };
};
