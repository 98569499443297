import { useMemo } from "react";
import { formatUnits, etherUnits } from "viem";
import { CHIStakingAbi, contractAddresses } from "../../../../meta";
import { displayTokens } from "../../../../utils";
import { useGetLPPriceByAddress } from "../../../state/data-provider/useGetLPPriceByAddress";
import { useReadContract } from "wagmi";

export const useFetchUSC_ETH_LPTotalStakedSupply = () => {
  const { data: totalSupplyChi, isFetched: isFetchedE } = useReadContract({
    // contractName: "ChiStaking",
    abi: CHIStakingAbi,
    address: contractAddresses.USC_ETH_LP_Staking,
    functionName: "totalSupply",
  });
  const { formattedPrice, isFetched: isFetchedUELP } =
    useGetLPPriceByAddress("USC_ETH_LP");

  const displayData = useMemo(() => {
    return {
      value: displayTokens(totalSupplyChi, {
        formattedPrice,
        displayInDollars: true,
      }),
      isFetched: isFetchedE && isFetchedUELP,
    };
  }, [totalSupplyChi, formattedPrice, isFetchedE, isFetchedUELP]);

  const formattedTotalSupply = formatUnits(
    totalSupplyChi || 0n,
    etherUnits.wei
  );

  const totalStakedValue =
    Number(formattedTotalSupply || 0) * Number(formattedPrice || 0);

  return {
    displayData,
    formattedTotalSupply,
    originalTotalSupply: totalSupplyChi,
    isFetched: isFetchedE,
    price: {
      formattedPrice,
      isFetched: isFetchedUELP,
    },
    totalStaked: {
      value: totalStakedValue,
      isFetched: isFetchedE && isFetchedUELP,
    },
  };
};
