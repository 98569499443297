import { useState, useEffect } from "react";
import { useAccount, useReadContract } from "wagmi";
import { LockingManagerAbi } from "@meta";
import { Address } from "viem";
import { fromUnixTime, isAfter } from "date-fns";
import { displayTokens } from "@utils";
import { useBlockTime } from "../common/useBlockTime";
import { readContract } from "wagmi/actions";
import { getWagmiConfig } from "../../config/wagmi.config";
import { QueryKey } from "@tanstack/react-query";

type BoostedTokenPosition = Readonly<{
  amount: {
    originalValue: string;
    viewValue: string;
  };
  lockPeriod: bigint;
  startEpoch: bigint;
  endEpoch: bigint;
  startDate: Date;
  unlockDate: Date;
  isActive: boolean;
  chiReward: string;
}>;

export const useBoostedTokenBalance = (
  tokenAddress?: Address
): { positions: BoostedTokenPosition[] | undefined; isFetched: boolean, queryKey: QueryKey } => {
  const { address } = useAccount();
  const { block } = useBlockTime();

  const {
    data: lockingPositions,
    isLoading,
    isError,
    queryKey
  } = useReadContract({
    abi: LockingManagerAbi,
    functionName: "userLockingPositions",
    address: tokenAddress,
    args: address ? [address] : undefined,
    query: {
      enabled: !!address && !!tokenAddress,
    },
  });

  const {
    data: firstEpochTimestamp,
    isLoading: isLoadingEpoch,
    isError: isErrorEpoch,
  } = useReadContract({
    abi: LockingManagerAbi,
    address: tokenAddress,
    functionName: "epochStartTime",
  });

  const secondsInDay = 86400;

  const isFetched =
    !isLoading &&
    !isLoadingEpoch &&
    !isError &&
    !isErrorEpoch &&
    !!lockingPositions;

  // State to hold the positions
  const [positions, setPositions] = useState<
    BoostedTokenPosition[] | undefined
  >(undefined);

  useEffect(() => {
    if (!isFetched) return;
    if (!address) return;
    if (!block?.timestamp) return;

    const currentDate = new Date(Number(block.timestamp) * 1000);

    const firstEpochDate = firstEpochTimestamp
      ? fromUnixTime(Number(firstEpochTimestamp))
      : undefined;

    if (!firstEpochDate) return;

    const fetchRewards = async () => {
      if (!tokenAddress) return;

      try {
        // Fetch rewards for all positions
        const rewardsPromises = lockingPositions.map((_: any, index: number) =>
          readContract(getWagmiConfig(), {
            abi: LockingManagerAbi,
            functionName: "getUserRewards",
            address: tokenAddress,
            args: [address, BigInt(index)],
          })
        );

        const rewards = await Promise.all(rewardsPromises);

        const positionsWithRewards = lockingPositions.map(
          (position: any, index: number) => {
            const reward = rewards[index];

            const startDate = new Date(
              firstEpochDate.getTime() +
              Number(position.startEpoch) * secondsInDay * 1000
            );

            const unlockDate = new Date(
              firstEpochDate.getTime() +
              (Number(position.startEpoch) + Number(position.lockPeriod)) *
              secondsInDay *
              1000
            );

            const isActive = isAfter(unlockDate, currentDate);

            return {
              amount: {
                originalValue: position.amount,
                viewValue: displayTokens(position.amount, {}),
              },
              lockPeriod: position.lockPeriod,
              startEpoch: position.startEpoch,
              endEpoch: position.endEpoch,
              startDate: startDate,
              unlockDate: unlockDate,
              isActive: isActive,
              chiReward: displayTokens(reward, {}),
            };
          }
        );

        setPositions(positionsWithRewards);
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    };

    fetchRewards();
  }, [
    isFetched,
    tokenAddress,
    lockingPositions?.length,
    firstEpochTimestamp,
    block?.timestamp,
  ]);

  return { positions, isFetched, queryKey };
};
