import { useForm } from "react-hook-form";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "../../../../shared";
import { parseUnits } from "viem";
import { DisplayReserveTxLimit } from "./DisplayReserveTxLimit";

export const SetReserveMintTxLimit: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: setReserveMintTxLimit, isPending: isSetting } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setReserveMintTxLimit(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "setReserveMintTxLimit",
        args: [data.reserveAsset, parseUnits(data.amount, 18)],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>success</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <DisplayReserveTxLimit fn="reserveMintTxLimit" />
          <Typography>Arbitrage / SetReserveMintTxLimit /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("reserveAsset")}
              placeholder="Reserve Asset (address)"
              type="text"
              className="input input-bordered w-full"
            />
            <input
              {...methods.register("amount")}
              placeholder="Limit (18 decimals)"
              type="text"
              step="0.01"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSetting}>
              Update Arbitrager
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
