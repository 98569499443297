import { useState, useEffect } from "react";
import { parseUnits, etherUnits, formatUnits, Address } from "viem";
import { useAccount } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  FlexRow,
  Typography,
  Button,
  DisplayableInputField,
  SVGWrapper,
} from "@shared";
import { formatMoney, formatUnitsToMaxValue } from "@utils";
import { useButtonPropsOverride } from "../../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../../state/common/useStepState.ui";
import { useGetWalletBalanceByToken } from "../../../../../../state/erc20/useGetWalletBalanceByToken";
import { useGetPriceByAddress } from "../../../../../../state/price-feed-aggregator/useGetPriceByAddress";
import { useMintBurnFee } from "../../../../../../state/arbitrage/useMintBurnFee";

import { AdditionalInfo } from "../../../additionalInfo/AdditionalInfo";
import ReserveIcon from "@assets/layout/bank-note-01.svg";
import Right from "@assets/layout/chevron-right.svg";
import { AssetPicker } from "../../../../../../components/dropdowns/AssetPicker";
import { Option2 } from "../../../../utils/getOptions2";
/* ----------- */
/*    Icons    */
/* ----------- */
import USCLogo from "@assets/tokens/usc.svg";
import WETHLogo from "@assets/tokens/weth.svg";
import StEthLogo from "@assets/tokens/stEth.svg";
import WeETHLogo from "@assets/tokens/weEth.svg";
import { TOKEN_CONFIG } from "../../../../../../config/tokenConfig";
import { getTokenActualAddressBySymbolAndChainId } from "../../../../../../state/across/contractAddressesByChain";
import { useWingsApprove } from "../../../../../../state/common/useWingsApprove";

const options: Option2[] = [
  {
    key: contractAddresses.WETH,
    name: "WETH",
    value: contractAddresses.WETH,
    icon: WETHLogo,
  },
  {
    key: contractAddresses.stETH,
    name: "stETH",
    value: contractAddresses.stETH,
    icon: StEthLogo,
  },
  {
    key: contractAddresses.weETH,
    name: "weETH",
    value: contractAddresses.weETH,
    icon: WeETHLogo,
  },
];
export const RedeemForm = () => {
  /* ------------- */
  /*   Other       */
  /* ------------- */
  const { getButtonPropsOverride } = useButtonPropsOverride();
  const { isConnected } = useAccount();
  const { writeContractAsync: redeemAsync, isPending: isMinting } =
    useWingsContractWrite();

  /* --------------- */
  /*   Local State   */
  /* --------------- */
  const [selectedAsset, setSelectedAsset] = useState<Address>(
    contractAddresses.WETH
  );
  const [uscValue, setUscValue] = useState("");
  const [receiveValue, setReceiveValue] = useState("");

  /* ------------- */
  /*   Methods     */
  /* ------------- */
  const { formattedPrice: formattedETHPrice, isFetched: isFormattedFetched } =
    useGetPriceByAddress(
      getTokenActualAddressBySymbolAndChainId(
        TOKEN_CONFIG[selectedAsset]?.symbol,
        1
      )
    );

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    contractAddresses.USC,
    contractAddresses.Arbitrage,
    parseUnits(String(uscValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Redeem"],
    isApproved ? 1 : 0
  );
  /* ---------------- */
  /*   Side effects   */
  /* ---------------- */
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);
  useEffect(() => {
    setUscValue("");
    setReceiveValue("");
  }, [selectedAsset]);

  const { data: mintBurnFee } = useMintBurnFee();

  const onUscValueChange = (value: string) => {
    setUscValue(value);

    if (value) {
      const ethEquivalent =
        (Number(value) *
          (1 - Number(formatUnits(mintBurnFee.mintBurnFee || 0n, 4)))) /
        (formattedETHPrice || 0);

      setReceiveValue(String(ethEquivalent));
    } else {
      setReceiveValue("");
    }
  };

  const onEthValueChange = (value: string) => {
    setReceiveValue(value);

    if (value) {
      const uscValue =
        (formattedETHPrice || 0) *
        (Number(value || 0) *
          (1 + Number(formatUnits(mintBurnFee.mintBurnFee || 0n, 4))));

      setUscValue(String(uscValue));
    } else {
      setUscValue("");
    }
  };

  /* ---------------- */
  /*   Form functions */
  /* ---------------- */
  const redeemFunction = async () => {
    await redeemAsync(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "burn",
        args: [parseUnits(String(uscValue), etherUnits.wei), selectedAsset!],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper
                            src={TOKEN_CONFIG[selectedAsset]?.logo}
                            width={22}
                            height={22}
                          />
                        }
                        text={formatMoney(receiveValue)}
                        symbol={TOKEN_CONFIG[selectedAsset]?.symbol}
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="USC"
                      />
                    }
                  />
                }
              />
            ),
          });
          setUscValue("");
          setReceiveValue("");
        },
      }
    );
  };

  /* ---------------- */
  /*   Notifications  */
  /* ---------------- */
  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(uscValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper
                            src={TOKEN_CONFIG[selectedAsset]?.logo}
                            width={22}
                            height={22}
                          />
                        }
                        text={formatMoney(receiveValue)}
                        symbol={TOKEN_CONFIG[selectedAsset]?.symbol}
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="USC"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer stepNames={steps} currentStep={currentStep} />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await redeemFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await redeemFunction();
    }
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Redeeming in Process"}
          loading
          transferComponent={
            <Transfer
              rightComponent={
                <TransferMoney
                  icon={
                    <SVGWrapper
                      src={TOKEN_CONFIG[selectedAsset]?.logo}
                      width={22}
                      height={22}
                    />
                  }
                  text={formatMoney(receiveValue)}
                  symbol={TOKEN_CONFIG[selectedAsset]?.symbol}
                />
              }
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={USCLogo} width={22} height={22} />}
                  text={formatMoney(uscValue)}
                  symbol="USC"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  /* -------------- */
  /*   Balance      */
  /* -------------- */
  const { displayData: selectedAssetBalanceDSPL } = useGetWalletBalanceByToken(
    selectedAsset,
    {
      ...TOKEN_CONFIG[selectedAsset],
    }
  );
  const { displayData: uscBalance, balance } = useGetWalletBalanceByToken(
    contractAddresses.USC
  );

  /* -------------- */
  /*   Display data */
  /* -------------- */
  const displayableSelectedAssetWalletBalance: DisplayableInputField = {
    value: selectedAssetBalanceDSPL?.value,
    isFetched: isFormattedFetched,
    label: "Balance: ",
  };
  const finalBalanceDSPL = displayableSelectedAssetWalletBalance;

  const displayableDollarValue: DisplayableInputField = {
    value: formatMoney(Number(receiveValue) * (formattedETHPrice || 0)),
    isFetched: isFormattedFetched,
  };

  const displayableUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(uscValue)),
    isFetched: isFormattedFetched,
  };

  return (
    <FlexCol className="gap-1">
      <div className="md:mt-[18.74px] mt-[12.98px]">
        <InputField
          name="USCAmount"
          value={uscValue}
          onChange={(e) => onUscValueChange(e.target.value)}
          label="Deposit USC"
          placeholder="Amount"
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper src={USCLogo} width={24} height={24} />
            </FlexRow>
          }
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md max-w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!balance || !isConnected}
              onClick={() => {
                onUscValueChange(formatUnitsToMaxValue(balance)?.toString());
              }}
            >
              MAX
            </button>
          }
          dollarValue={displayableUscDollarValue}
          walletBalance={uscBalance}
        />
      </div>
      <div className="md:mt-[18.74px] mt-[8.98px]">
        <InputField
          name="ETHAmount"
          value={receiveValue}
          onChange={(e) => onEthValueChange(e.target.value)}
          label="To Receive"
          placeholder="Amount"
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper
                src={TOKEN_CONFIG[selectedAsset]?.logo}
                width={24}
                height={24}
              />
            </FlexRow>
          }
          rightLabel={
            <AssetPicker
              selectedAsset={selectedAsset}
              setSelectedAsset={(e) => {
                setSelectedAsset(e as Address);
              }}
              options2={options}
            />
          }
          walletBalance={finalBalanceDSPL}
          dollarValue={displayableDollarValue}
        />
      </div>

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={!isConnected || (!uscValue && !receiveValue)}
        loading={isMinting || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(uscValue || 0) > 0 || Number(receiveValue || 0) > 0
          ? isApproved
            ? "Redeem USC"
            : "Approve"
          : "Enter amount"}
      </Button>
      <FlexCol>
        <AdditionalInfo selectedAsset={selectedAsset} />
        <FlexRow className="w-full py-3 items-center justify-between">
          <Typography type="caption-regular">Route</Typography>
          <FlexRow className="gap-[10px] items-center">
            <SVGWrapper src={ReserveIcon} width={20} height={20} />
            <Typography type="caption-regular">Reserves</Typography>
            <SVGWrapper src={Right} width={17} height={17} />
            <SVGWrapper
              src={TOKEN_CONFIG[selectedAsset]?.logo}
              width={20}
              height={20}
            />
            <Typography type="caption-regular">
              {TOKEN_CONFIG[selectedAsset]?.symbol}
            </Typography>
          </FlexRow>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};
