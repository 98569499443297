import React from "react";
import { Typography, SVGWrapper } from "@shared";
import { TOKEN_CONFIG } from "../../../../config/tokenConfig";
import { Address } from "viem";
import { contractAddresses } from "@meta";

import filterIcon from "@assets/layout/filter.svg";

const boostedTokenAddresses: Address[] = [
  contractAddresses.USC_ETH_LP_Locking,
  contractAddresses.CHI_ETH_LP_Locking,
  contractAddresses.WST_USC_Locking,
];

const boostedTokenOptions = boostedTokenAddresses.map((address) => ({
  address,
  symbol: TOKEN_CONFIG[address].symbol,
  icon: TOKEN_CONFIG[address].logo,
}));

type BoostingHeaderProps = {
  selectedTokens: Address[];
  setSelectedTokens: React.Dispatch<React.SetStateAction<Address[]>>;
};

export const BoostingHeader: React.FC<BoostingHeaderProps> = ({
  selectedTokens,
  setSelectedTokens,
}) => {
  const toggleTokenSelection = (tokenAddress: Address) => {
    setSelectedTokens((prevSelectedTokens) =>
      prevSelectedTokens.includes(tokenAddress)
        ? prevSelectedTokens.filter((address) => address !== tokenAddress)
        : [...prevSelectedTokens, tokenAddress]
    );
  };

  return (
    <>
      <Typography type="body-medium-bold">Boosting</Typography>
      <div className="grid grid-cols-22 items-center justify-between p-4 border border-[#EEE] bg-[#F9F8F8] shadow-[0px_2px_2px_0px_rgba(0,0,0,0.02)] rounded-xl border-solid">
        <div className="col-span-1">
          <LocalText text="" />
        </div>
        <div className="col-span-7 flex items-center">
          <LocalText text="Boosted Token" />
          <div className="dropdown inline-flex ml-2">
            <div tabIndex={0} className="btn btn-xs">
              <SVGWrapper src={filterIcon} width={12} height={12} />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow-lg"
            >
              {boostedTokenOptions.map((token) => (
                <li key={token.address}>
                  <div
                    className="flex items-center justify-between gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => toggleTokenSelection(token.address)}
                  >
                    <div className="flex items-center gap-2">
                      <SVGWrapper src={token.icon} width={20} height={20} />
                      <span>{token.symbol}</span>
                    </div>
                    <input
                      type="checkbox"
                      checked={selectedTokens.includes(token.address)}
                      onChange={() => toggleTokenSelection(token.address)}
                      className="checkbox [--chkbg:theme(colors.primary)] [--chkfg:white]"
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-span-4">
          <LocalText text="Boosted Amount" />
        </div>
        <div className="col-span-5">
          <LocalText text="CHI Reward" />
        </div>
        <div className="col-span-3">
          <LocalText text="Unlock Date" />
        </div>
        <div className="col-span-2">
          <LocalText text="" />
        </div>
      </div>
    </>
  );
};

const LocalText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="flex flex-row items-center">
      <p className="text-[#949494] text-caption-medium">{text}</p>
    </div>
  );
};
