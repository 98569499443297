import { useState } from 'react';

export interface StepState {
  currentStep: number;
  totalSteps: number;
}

export const useStepState = (initialSteps: string[] = [], startingStep = 0) => {
  const [steps, setSteps] = useState<string[]>(initialSteps);
  const [currentStep, _setCurrentStep] = useState<number>(startingStep);

  const prevStep = () => {
    if (currentStep > 0) {
      _setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const finishSteps = () => {
    _setCurrentStep(steps.length - 1);
  };

  const setCurrentStep = (step: number) => {
    _setCurrentStep(step);
  };

  return {
    steps,
    currentStep,
    totalSteps: steps.length,
    finishSteps,
    setSteps,
    prevStep,
    setCurrentStep,
  };
};
