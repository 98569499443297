import React from "react";

export const ChartWrapper: React.FC<{
  isFetched?: boolean;
  children: React.ReactNode;
}> = ({ isFetched, children }) => {
  return (
    <div className="mx-[-15px]">
      <div className="min-h-[300px] xs:min-h-[500px]">
        {typeof window !== "undefined" &&
          (isFetched ? (
            <>{children}</>
          ) : (
            <div className=" flex justify-center items-center w-full h-full lg:min-h-[500px]">
              <span className="loading loading-spinner loading-sm" />
            </div>
          ))}
      </div>
    </div>
  );
};
