import React from "react";

import styles from "./PolygonTooltipNew.module.css";

import infoBlack from "@assets/common/info-icon-black.svg";
import { SVGWrapper } from "@shared";

export const PolygonTooltipNew: React.FC<{
  tooltip: React.ReactNode;
  icon?: React.ReactNode;
  isSmall?: boolean;
}> = ({ tooltip, icon, isSmall }) => {
  return (
    <div className={styles.tooltip}>
      {icon && icon}
      {icon == null && (
        <SVGWrapper
          src={infoBlack}
          className={`${
            isSmall ? "w-[10px] h-[10px]" : "w-4 h-4"
          } flex-shrink-0`}
        />
      )}
      <span className={styles.tooltiptext}>{tooltip}</span>
    </div>
  );
};
