import {
  Button,
  Card,
  DisplayTokenAmount,
  FlexCol,
  FlexRow,
  StandardNotifBody,
  StepsContainer,
  SVGWrapper,
  TransactionComplete,
  TransferMoney,
  Typography,
  useNotificationContext,
  useWingsContractWrite,
} from "@shared";
import { ReactNode, useEffect, useState } from "react";
import { AuditedBy } from "../../../../components/auditedBy/AuditedBy";

/* ----------- */
/*    Icons    */
/* ----------- */

import VestIcon from "@assets/layout/fileLock.svg";
import Chi from "@assets/tokens/chi.svg";
import { addDays } from "date-fns";
import { useAccount, useReadContract } from "wagmi";
import { CHIVestingAbi, contractAddresses, RewardControllerAbi } from "@meta";
import { displayTokens } from "@utils";
import { useChiRewards } from "../../hook/useTotalLockedChi";
import { displayDate } from "@shared";
import { SingleComponent } from "@shared";

const LocalRow = ({ children }: { children: ReactNode }) => (
  <FlexRow className="gap-3 justify-between items-center">{children}</FlexRow>
);

export const Main = () => {
  const { address } = useAccount();
  const { showNotification } = useNotificationContext();

  // Fetching data using contract reads
  const { data: firstEpochTimestamp, isFetched: isFetchedFET } =
    useReadContract({
      abi: RewardControllerAbi,
      address: contractAddresses.RewardController,
      functionName: "firstEpochTimestamp",
    });

  const { data: cliffDuration, isFetched: isFetchedCD } = useReadContract({
    abi: CHIVestingAbi,
    address: contractAddresses.ChiVesting,
    functionName: "cliffDuration",
  });

  const { data: vestingDuration, isFetched: isFetchedVD } = useReadContract({
    abi: CHIVestingAbi,
    address: contractAddresses.ChiVesting,
    functionName: "vestingDuration",
  });

  const { data: ChiVestinAvailableChiWithdraw, isFetched: isFetchedClaimable } =
    useReadContract({
      abi: CHIVestingAbi,
      functionName: "availableChiWithdraw",
      address: contractAddresses.ChiVesting,
      args: [address!],
    });

  const { data: vestingData, isFetched: isFetchedvestingData } =
    useReadContract({
      abi: CHIVestingAbi,
      functionName: "vestingData",
      address: contractAddresses.ChiVesting,
      args: [address!],
    });

  const epochTimestampMs = Number(firstEpochTimestamp) * 1000; // Convert to milliseconds

  const cliffDays = Number(cliffDuration) * 7; // Convert cliff duration to days

  // Calculate cliff end and vesting end timestamps
  const cliffEndTimestamp =
    firstEpochTimestamp && cliffDuration
      ? addDays(new Date(epochTimestampMs), cliffDays)
      : null;

  const vestingEndTimestamp =
    firstEpochTimestamp && cliffDuration && vestingDuration
      ? addDays(
          new Date(epochTimestampMs),
          (Number(cliffDuration + vestingDuration) || 0) * 7
        )
      : null;

  const formattedCliffEndDate = cliffEndTimestamp
    ? displayDate(cliffEndTimestamp)
    : "Date not available";
  const formattedVestingEndDate = vestingEndTimestamp
    ? displayDate(vestingEndTimestamp)
    : "Date not available";

  const vestingPeriodStartsDSPL = {
    name: "Vesting Period Starts",
    value: formattedCliffEndDate,
    isFetched: isFetchedFET && isFetchedCD && isFetchedVD,
  };
  const vestingPeriodEndsDSPL = {
    name: "Vesting Period Ends",
    value: formattedVestingEndDate,
    isFetched: isFetchedFET && isFetchedCD && isFetchedVD,
  };

  // State to store vesting period data
  const [vestingPeriodStarts, setVestingPeriodStarts] = useState("Loading...");
  const [vestingPeriodEnds, setVestingPeriodEnds] = useState("Loading...");

  useEffect(() => {
    const fetchVestingPeriodData = async () => {
      setVestingPeriodStarts(vestingPeriodStartsDSPL.value);
      setVestingPeriodEnds(vestingPeriodEndsDSPL.value);
    };

    fetchVestingPeriodData();
  }, [vestingPeriodStartsDSPL.value, vestingPeriodEndsDSPL.value]);

  const { chiReward, isFetched } = useChiRewards();

  const {
    writeContractAsync: withdrawChiAsync,
    isPending: isLoadingWithdrawChi,
  } = useWingsContractWrite();

  const onWithdrawCHI = async () => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={Chi} width={22} height={22} />}
                  text={displayTokens(chiReward, {
                    decimals: 18,
                  })}
                  symbol="CHI"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={["Approve", "Claim"]}
              currentStep={1}
              loading
            />
          }
        />
      ),
    });

    try {
      await withdrawChiAsync({
        abi: CHIVestingAbi,
        functionName: "withdrawChi",
        address: contractAddresses.ChiVesting,
        args: [BigInt(0)],
      });
      showNotification({
        status: "success",
        content: (
          <StandardNotifBody
            headerComponent={<TransactionComplete />}
            transferComponent={
              <SingleComponent
                component={
                  <TransferMoney
                    icon={<SVGWrapper src={Chi} width={22} height={22} />}
                    text={displayTokens(chiReward, {
                      decimals: 18,
                    })}
                    symbol="CHI"
                  />
                }
              />
            }
          />
        ),
      });
    } catch (error) {
      showNotification({
        status: "error",
        content: "Claim failed. Please try again.",
      });
    }
  };
  return (
    <Card hasBorder className="flex flex-col overflow-hidden px-6 py-8 gap-10">
      <FlexRow className="gap-3 items-center">
        <Typography type="h2">Vest</Typography>
        <SVGWrapper src={VestIcon} width={32} height={32} />
        <AuditedBy />
      </FlexRow>
      <FlexCol className="gap-6">
        <LocalRow>
          <Typography type="body-medium-regular">
            CHI Balance Reserved For Vesting
          </Typography>
          <FlexCol className="gap-1 items-end">
            <DisplayTokenAmount
              viewValue={displayTokens(vestingData?.[0], {
                decimals: 18,
              })}
              symbol="CHI"
              typography="h5"
              isLoading={!isFetchedvestingData}
            />
          </FlexCol>
        </LocalRow>
        <LocalRow>
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60"
          >
            Boosted CHI Rewards
          </Typography>
          <DisplayTokenAmount
            viewValue={displayTokens(chiReward, {
              decimals: 18,
            })}
            symbol="CHI"
            typography="h5"
            isLoading={!isFetched}
          />
        </LocalRow>
        <LocalRow>
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60"
          >
            Claimable CHI
          </Typography>
          <DisplayTokenAmount
            viewValue={displayTokens(ChiVestinAvailableChiWithdraw, {
              decimals: 18,
            })}
            symbol="CHI"
            typography="h5"
            isLoading={!isFetchedClaimable}
          />
        </LocalRow>
        <LocalRow>
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60"
          >
            Vest Start Date
          </Typography>
          <Typography type="body-medium-regular">
            {vestingPeriodStarts}
          </Typography>
        </LocalRow>
        <LocalRow>
          <Typography
            type="body-medium-regular"
            className="text-neutral-black-60"
          >
            Vest End Date
          </Typography>
          <Typography type="body-medium-regular">
            {vestingPeriodEnds}
          </Typography>
        </LocalRow>
        <Button
          onClick={onWithdrawCHI}
          loading={isLoadingWithdrawChi}
          disabled={
            !isFetchedClaimable || Number(ChiVestinAvailableChiWithdraw) === 0
          }
        >
          Claim CHI
        </Button>
      </FlexCol>
    </Card>
  );
};
