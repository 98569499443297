import { useAccount } from "wagmi";
import { getTokenActualAddressBySymbolAndChainId } from "../across/contractAddressesByChain";
import { useGetPriceByAddress } from "../price-feed-aggregator/useGetPriceByAddress";
import { useGetCoingeckoPriceByAddress } from "./useGetCoingeckoPriceByAddress";
import { Address } from "viem";
import { TOKEN_CONFIG } from "../../config/tokenConfig";

export const useGetTokenPriceCrosschain = (
  address: Address,
  chainId?: number
) => {
  const { chainId: walletChainId } = useAccount();
  const id = chainId || walletChainId;

  const { formattedPrice: cgPrice } = useGetCoingeckoPriceByAddress(
    getTokenActualAddressBySymbolAndChainId(TOKEN_CONFIG[address]?.symbol, id),
    id,
    id !== 1 && !!TOKEN_CONFIG[address]?.symbol
  );

  const { formattedPrice, isFetched } = useGetPriceByAddress(address);

  return {
    formattedPrice: id === 1 ? formattedPrice : cgPrice,
    isFetched: id === 1 ? isFetched : true,
  };
};
