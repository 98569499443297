import React from "react";
import { Address } from "viem";
import { contractAddresses } from "@meta";
import { useCheckStakingStatus } from "../queries/useCheckStakingStatus";
import { Typography, Card, FlexCol } from "@shared";

const StakingStatusCard: React.FC<{ token: string; address: Address }> = ({
  token,
  address,
}) => {
  const { isStaking, isFetched } = useCheckStakingStatus(address);

  if (!isFetched) {
    return (
      <Card className="p-4 border border-gray-300 rounded-lg shadow-md">
        <Typography type="body-medium-bold">{token}</Typography>
        <Typography>Address: {address}</Typography>
        <Typography>Status: Loading...</Typography>
      </Card>
    );
  }

  return (
    <Card className="p-4 border border-gray-300 rounded-lg shadow-md">
      <Typography type="body-medium-bold">{token}</Typography>
      <Typography>Address: {address}</Typography>
      <Typography className={isStaking ? "text-secondary" : "text-red-500"}>
        Status: {isStaking ? "Staking Started" : "Staking Not Started"}
      </Typography>
    </Card>
  );
};

export const StakingStatusList: React.FC = () => {
  const tokens = [
    { token: "CHI_ETH_LP", address: contractAddresses.CHI_ETH_LP },
    { token: "USC_ETH_LP", address: contractAddresses.USC_ETH_LP },
  ];

  return (
    <FlexCol className="gap-4">
      {tokens.map(({ token, address }) => (
        <StakingStatusCard key={token} token={token} address={address} />
      ))}
    </FlexCol>
  );
};
