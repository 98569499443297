import { Card, Typography, FlexRow, Divider, SVGWrapper } from "@shared";
import { StateComponent } from "../table/OptionPosition";
import { LockedPosition } from "./useFetchOptions";
import { displayTokens } from "@utils";

import VeChi from "@assets/tokens/veChiRounded.svg";
import Chi from "@assets/tokens/chiRounded.svg";
import StEth from "@assets/tokens/stEth.svg";

export const MobileRow: React.FC<LockedPosition> = ({
  unlockDate,
  veCHIAmount,
  claimableETHRewards,
  isExpired,
}) => {
  return (
    <Card hasBorder color="primary" className="gap-2 p-3">
      <Typography type="body-medium-bold" className="text-white">
        Position
      </Typography>
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-30">
          State
        </Typography>
        <StateComponent isActive={!isExpired || false} />
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={VeChi} width={20} height={20} />

          <Typography
            type="body-small-regular"
            className="text-neutral-black-30"
          >
            veCHI Amount
          </Typography>
        </FlexRow>

        <FlexRow className="gap-2">
          <Typography
            type="body-small-regular"
            className="text-neutral-black-30"
          >
            {displayTokens(veCHIAmount, {})}
          </Typography>
        </FlexRow>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={Chi} width={20} height={20} />

          <Typography
            type="body-small-regular"
            className="text-neutral-black-30"
          >
            stETH Rewards
          </Typography>
        </FlexRow>
        <Typography type="body-small-regular" className="text-neutral-black-30">
          {displayTokens(claimableETHRewards, {})}
        </Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <FlexRow className="gap-2 items-center">
          <SVGWrapper src={StEth} width={20} height={20} />

          <Typography
            type="body-small-regular"
            className="text-neutral-black-30"
          >
            stCHI Rewards
          </Typography>
        </FlexRow>
        <Typography type="body-small-regular" className="text-neutral-black-30">
          {displayTokens(claimableETHRewards, {})}
        </Typography>
      </FlexRow>
      <Divider />
      <FlexRow className="h-[60px] justify-between items-center">
        <Typography type="body-small-regular" className="text-neutral-black-30">
          Unlock Date
        </Typography>
        <Typography type="body-small-regular" className="text-neutral-black-30">
          {unlockDate?.toLocaleDateString()}
        </Typography>
      </FlexRow>
    </Card>
  );
};
