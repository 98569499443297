import { useAccount } from "wagmi";
import { Typography, FlexRow, TypographyType, Tooltip, Spinner } from "@shared";
import { PolygonTooltip } from "../PolygonTooltip/PolygonTooltip/PolygonTooltip";
import { PolygonTooltipNew } from "../PolygonTooltip/PolygonTooltipNew/PolygonTooltipNew";

export const HorizontalRow: React.FC<{
  name?: string;
  value?: any;
  isFetched?: boolean;
  requireConnectedWallet?: boolean;
  iconText?: string;
  typography?: TypographyType;
  tooltip?: React.ReactNode;
}> = ({
  name,
  value,
  isFetched,
  iconText,
  requireConnectedWallet,
  tooltip,
}) => {
  const { isConnected } = useAccount();

  const renderValue = () => {
    if (requireConnectedWallet && !isConnected)
      return (
        <Tooltip tooltip="Connect your wallet">
          <>x</>
        </Tooltip>
      );

    if (!isFetched && isFetched != null)
      return <Spinner className="w-4 h-4 self-end" />;
    else {
      return (
        <Typography className="text-xs sm:text-sm self-end overflow-hidden whitespace-nowrap text-overflow-ellipsis">
          {value}
        </Typography>
      );
    }
  };

  return (
    <FlexRow className="flex-row justify-between gap-2 sm:gap-3">
      <FlexRow className="gap-2">
        <Typography className="text-xs sm:text-sm overflow-hidden whitespace-nowrap text-overflow-ellipsis flex-grow">
          {name}
        </Typography>
        {iconText && <PolygonTooltip tooltipText={iconText} />}
        {tooltip && <PolygonTooltipNew tooltip={tooltip} />}
      </FlexRow>

      {renderValue()}
    </FlexRow>
  );
};
