import React from "react";

import { Typography } from "../text/Typography/Typography";

export const ApproveComplete: React.FC<{
  className?: string;
}> = ({ className = "" }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-7 ${className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
      >
        <rect x="5.5" y="5" width="50" height="50" rx="25" fill="#46AA66" />
        <rect
          x="5.5"
          y="5"
          width="50"
          height="50"
          rx="25"
          stroke="#46AA66"
          strokeWidth="10"
        />
        <path
          d="M43.8333 20L25.5 38.3333L17.1666 30"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Typography type="body-large-regular">Approve Complete</Typography>
    </div>
  );
};
