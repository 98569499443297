import React, { useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "../../../../../index.css";
import {
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import RightIcon from "@assets/layout/chevron-right.svg";

export interface ScrollContainersProps {
  items: {
    id: string;
    title: string;
    apr: string;
    subtitle: string;
    icon: string;
    illustration: string;
    isFetched: boolean;
  }[];
}

export const ScrollContainers: React.FC<ScrollContainersProps> = ({
  items,
}) => {
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleScroll = (
    apiObj: React.ContextType<typeof VisibilityContext>
  ) => {
    const { scrollContainer } = apiObj;
    const scrollLeft = scrollContainer.current?.scrollLeft || 0;
    const scrollWidth = scrollContainer.current?.scrollWidth || 0;
    const clientWidth = scrollContainer.current?.clientWidth || 0;

    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
  };

  return (
    <div className="overflow-x-hidden whitespace-nowrap relative rounded-xl">
      {!isAtStart && (
        <div className="absolute inset-y-0 left-0 w-20 bg-gradient-to-r from-gray-200 to-transparent z-10 pointer-events-none" />
      )}
      {!isAtEnd && (
        <div className="absolute inset-y-0 right-0 w-20 bg-gradient-to-l from-gray-200 to-transparent z-10 pointer-events-none" />
      )}
      <ScrollMenu
        LeftArrow={<LeftArrow isAtStart={isAtStart} />}
        RightArrow={<RightArrow isAtEnd={isAtEnd} />}
        onScroll={handleScroll}
        scrollContainerClassName="no-scrollbar p-3 bg-neutral-black-30  rounded-xl"
      >
        {items.map(
          ({ id, title, apr, icon, subtitle, illustration, isFetched }) => (
            <ScrollCard
              title={title}
              apr={apr}
              subtitle={subtitle}
              icon={icon}
              key={id}
              onClick={() => {}}
              selected={false}
              illustration={illustration}
              isFetched={isFetched}
            />
          )
        )}
      </ScrollMenu>
    </div>
  );
};

const LeftArrow: React.FC<{ isAtStart: boolean }> = ({ isAtStart }) => {
  const { scrollPrev } = React.useContext(VisibilityContext);

  if (isAtStart) {
    return null;
  }

  return (
    <FlexCol
      className="w-8 h-8 absolute left-0 top-1/2 ml-1 transform -translate-y-1/2 cursor-pointer p-2 bg-white rounded-full border-[1px] border-neutral-black-10 shadow-darkModeButton items-center justify-center z-20"
      onClick={() => scrollPrev()}
    >
      <SVGWrapper
        src={RightIcon}
        width="16px"
        height="16px"
        color="black"
        flip
      />
    </FlexCol>
  );
};

const RightArrow: React.FC<{ isAtEnd: boolean }> = ({ isAtEnd }) => {
  const { scrollNext } = React.useContext(VisibilityContext);

  if (isAtEnd) {
    return null;
  }

  return (
    <FlexCol
      className="w-8 h-8 absolute right-0 top-1/2 mr-1  transform -translate-y-1/2 cursor-pointer p-2 bg-white rounded-full border-[1px] border-neutral-black-10 shadow-darkModeButton items-center justify-center z-20"
      onClick={() => scrollNext()}
    >
      <SVGWrapper src={RightIcon} width="16px" height="16px" color="black" />
    </FlexCol>
  );
};

export function ScrollCard({
  selected,
  title,
  apr,
  subtitle,
  icon,
  illustration,
  isFetched,
}: {
  disabled?: boolean;
  onClick: Function;
  selected: boolean;
  title: string;
  apr: string;
  subtitle: string;
  icon: string;
  illustration: string;
  isFetched: boolean;
}) {
  return (
    <FlexRow
      className={`inline-block rounded-md bg-white w-[362px] h-[180px] mr-[12px] ${selected ? "bg-blue-200" : ""}`}
      tabIndex={0}
    >
      <FlexCol className="w-[135px] h-full rounded-md items-center justify-center bg-neutral-black-10 text-neutral-black-60">
        <SVGWrapper src={illustration} width={160} height={180} />
      </FlexCol>
      <FlexCol className="gap-[4px]  justify-center pl-5">
        <Typography
          type="body-medium-regular"
          className="text-neutral-black-60"
        >
          {title}
        </Typography>
        <FlexRow className="gap-1 items-center">
          <DisplayPercentage
            viewValue={apr}
            symbolPosition="after"
            typography="h5"
            isFetched={isFetched}
          ></DisplayPercentage>
          <span className="text-neutral-black-60 text-h5">APR</span>
        </FlexRow>
        <Typography type="caption-regular" className="text-neutral-black-50">
          {subtitle}
        </Typography>
        <FlexRow className="gap-2 items-center mt-4">
          <button className="w-[63px] h-[32px] rounded-md bg-primary">
            <Typography type="caption-medium" className="text-white">
              View
            </Typography>
          </button>
          <SVGWrapper src={icon} width="24px" height="24px" />
        </FlexRow>
      </FlexCol>
    </FlexRow>
  );
}
