import { InputHTMLAttributes, ReactNode } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

import {
  ExtendedInputFieldProps,
  InputField,
} from "../../input/InputField/InputField";

export type IRHFInputFieldFormattedProps<T> = {
  name: keyof T;
  rightLabel?: ReactNode;
  leftLabel?: ReactNode;
  rules?: RegisterOptions;
  defaultValue?: string | number | null;
  fullWidth?: boolean;
};

type Props<T> = IRHFInputFieldFormattedProps<T> &
  InputHTMLAttributes<HTMLInputElement> &
  Omit<ExtendedInputFieldProps, "value">;

export function RHFInputFieldFormatted<T>({
  name,
  rules,
  rightLabel,
  leftLabel,
  defaultValue,
  fullWidth = true,
  ...other
}: Props<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InputField
          {...field}
          fullWidth={fullWidth}
          rightLabel={rightLabel}
          leftLabel={leftLabel}
          value={(field.value as any) || ""}
          {...other}
        />
      )}
    />
  );
}
