import { contractAddresses, WstUSCAbi } from "@meta";
import { formatMoney } from "@utils";
import { useReadContract } from "wagmi";
import { useSpotPrice } from "../usc/useSpotPrice";
import { formatUnits } from "viem";

export const useFetchWstUscPrice = () => {
  const { data: convertToShares, isFetched: isExchangeRateFetched } =
    useReadContract({
      abi: WstUSCAbi,
      address: contractAddresses.WstUSC,
      functionName: "convertToShares",
      args: [BigInt(1e18)],
    });

  const formattedExchangeRate = formatUnits(convertToShares || 0n, 18);

  const { spotPrice, isFetched: isStUscPriceFetched } = useSpotPrice(
    contractAddresses.USC
  );

  const stUscPrice = parseFloat(spotPrice);

  const wstUscPriceOriginal =
    stUscPrice && formattedExchangeRate
      ? stUscPrice / Number(formattedExchangeRate) // OVDE vršimo deljenje umesto množenja
      : 0;

  return {
    viewValue: formatMoney(wstUscPriceOriginal),
    formattedPrice: wstUscPriceOriginal,
    exchangeRate: formattedExchangeRate,
    isFetched: isStUscPriceFetched && isExchangeRateFetched,
  };
};
