import { Typography } from "@shared";
import { displayTokens } from "@utils";
import { LockedPosition } from "./useFetchOptions";

export const OptionDataRow: React.FC<LockedPosition> = ({
  unlockDate,
  veCHIAmount,
  claimableETHRewards,
  claimableCHIRewards,
  isExpired,
}) => {
  return (
    <tr className={`text-left`}>
      <Row>
        {(veCHIAmount || 0) > 0 && !isExpired && (
          <StateComponent isActive={true} />
        )}
        {((veCHIAmount || 0) === 0 || isExpired) && (
          <StateComponent isActive={false} />
        )}
      </Row>
      <Row>
        <Typography type="caption-medium" className="text-neutral-white-80">
          {displayTokens(veCHIAmount, {})}
        </Typography>
      </Row>
      <Row>
        <Typography type="caption-medium" className="text-neutral-white-80">
          {displayTokens(claimableETHRewards, {})}
        </Typography>
      </Row>
      <Row>
        <Typography type="caption-medium" className="text-neutral-white-80">
          {displayTokens(claimableCHIRewards, {})}
        </Typography>
      </Row>
      {/* <Row>
        <Typography
          type="caption-medium"
          className="text-neutral-white-80 text-center"
        >
          {startDate?.toLocaleDateString()}
        </Typography>
      </Row> */}

      <Row>
        <Typography
          type="caption-medium"
          className="text-neutral-white-80 text-center"
        >
          {unlockDate?.toLocaleDateString()}
        </Typography>
      </Row>
    </tr>
  );
};

export const Row: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <td className={`py-1`} style={{ width: "20%" }}>
      {children}
    </td>
  );
};

export const StateComponent: React.FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      <div
        className={`w-[6px] h-[6px] rounded-full 
      ${isActive ? "bg-secondary" : "bg-red-500"}
        `}
      ></div>
      <Typography
        className="text-neutral-white-80 text-center"
        type="caption-medium"
      >
        {isActive ? "Active" : "Inactive"}
      </Typography>
    </div>
  );
};
