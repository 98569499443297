import { FAQsComponent } from "../../../components/info/FAQsComponent";
import InfoItems from "../../../components/info/InfoItems";
import { InfoTabButton } from "../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

const infos = [
  <>
    APRs are based on current rewards per timestamp, epoch, and TVL, and are
    annualised accordingly. Check the{" "}
    <a
      href="https://chi-protocol.gitbook.io/chi-docs/resources/apr-formulas"
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary"
    >
      APR Formulas
    </a>{" "}
    page for more details.
  </>,
  "Rewards in Chi Protocol are distributed in CHI, USC, stETH, and weETH, providing exposure to various ecosystem assets.",
  "CHI rewards come from the ecosystem's initial token allocation to community incentives. stETH and weETH rewards are generated by the protocol’s reserves. USC rewards come from arbitrage revenue.",
  "Staking rewards are received every block (~12 sec). Boosting rewards are distributed daily (~6 PM CET), while CHI boosting rewards are released weekly (Sunday ~12 AM CET).",
  "LP staking/boosting rewards are claimable instantly. stUSC and wstUSC rewards are automatic. CHI boosting rewards are locked until the boost period ends.",
  "Pair CHI with ETH, stake LP tokens, and earn stETH, weETH, and CHI incentives. Boosting LP tokens increases CHI rewards. Single-sided boosted CHI converts to veCHI, earning stETH and CHI.",
  "APR updates reflect current annualised rewards and TVL metrics.",
];

const faqItems = [
  {
    title: "What rewards do I earn in the Chi Protocol?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Chi Protocol offers rewards in CHI, USC, stETH, and weETH, with the most
        benefits going to active participants and long-term holders.
      </Typography>
    ),
  },

  {
    title: "How often do I receive rewards?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>
            <strong>Staking:</strong> Rewards (USC, CHI, stETH, weETH) are
            distributed continuously with each Ethereum block (~12 sec).
          </li>
          <li>
            <strong>Boosting:</strong> Extra CHI rewards are distributed daily
            (~6 PM CET), while staking rewards are received per block. CHI
            boosting rewards are distributed weekly (Sunday ~12 am CET).
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "When are rewards claimable?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>
            LP staking and boosting rewards can be claimed as soon as received
            (~12 sec for staking, daily at 6 PM CET for boosting).
          </li>
          <li>
            stUSC and wstUSC rewards are automatic with no manual claiming
            needed.
          </li>
          <li>
            CHI boosting rewards are claimable at the end of the boost period.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "What can I do with my earned CHI?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>
            Provide liquidity in the CHI/ETH pool on Uniswap and stake the LP
            token to earn stETH, weETH, and extra CHI rewards.
          </li>
          <li>
            Boost CHI into veCHI to receive stETH rewards, extra CHI, and voting
            power.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "Where are the rewards derived from?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        <ul className="list-disc list-inside">
          <li>
            <strong>stETH and weETH: </strong>From staking yields in the
            protocol’s reserves.
          </li>
          <li>
            <strong>stUSC:</strong> From arbitrage revenue in USC.
          </li>
          <li>
            <strong>CHI: </strong> From the initial token allocation to
            community incentives.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    title: "How are APRs calculated?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        APRs are based on current rewards, epochs, and TVL, and are annualised
        accordingly. For more details, visit the{" "}
        <a
          href="https://chi-protocol.gitbook.io/chi-docs/resources/apr-formulas"
          className="underline text-primary"
          target="_blank"
        >
          APR Formulas
        </a>{" "}
        page in the documentation.
      </Typography>
    ),
  },
];

export const InfoSection = () => {
  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};
