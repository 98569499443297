import { useState, useEffect } from "react";
import { parseUnits, etherUnits, Address } from "viem";
import { useAccount } from "wagmi";
import { contractAddresses } from "@meta";
import {
  useWingsContractWrite,
  useNotificationContext,
  StandardNotifBody,
  TransactionComplete,
  Transfer,
  TransferMoney,
  DisplayErrorNotif,
  StepsContainer,
  ApproveComplete,
  FlexCol,
  InputField,
  FlexRow,
  Button,
  DisplayableInputField,
  SVGWrapper,
} from "@shared";
import { formatMoney, displayTokens, formatUnitsToMaxValue } from "@utils";
import { useButtonPropsOverride } from "../../../../../../state/across/buttonPropsOverride";
import { useStepState } from "../../../../../../state/common/useStepState.ui";
import { useGetWalletBalanceByToken } from "../../../../../../state/erc20/useGetWalletBalanceByToken";

/* ----------- */
/*    Icons    */
/* ----------- */
import USCLogo from "@assets/tokens/usc.svg";
import StUscLogo from "@assets/tokens/stUsc.svg";
import { useSpotPrice } from "../../../../../../state/usc/useSpotPrice";
import { USCStakingAbi } from "@meta";
import { useWingsApprove } from "../../../../../../state/common/useWingsApprove";

export const StakeForm = () => {
  const { isConnected, address } = useAccount();
  const uscPrice = useSpotPrice(contractAddresses.USC);

  const { writeContractAsync: stakeAsync, isPending: isStaking } =
    useWingsContractWrite();

  const [uscValue, setUscValue] = useState("");
  const [stUscValue, setStUscValue] = useState("");

  const {
    approveAsync,
    isApproving,
    isApproved,
  } = useWingsApprove(
    contractAddresses.USC,
    contractAddresses.USCStaking,
    parseUnits(String(uscValue), etherUnits.wei)
  );

  const { showNotification } = useNotificationContext();
  const { steps, currentStep, finishSteps, setCurrentStep } = useStepState(
    ["Approve", "Stake"],
    isApproved ? 1 : 0
  );
  useEffect(() => {
    if (isApproved) {
      setCurrentStep(1);
    } else setCurrentStep(0);
  }, [isApproved]);

  const onUscValueChange = (value: string) => {
    setUscValue(value);

    if (value) {
      setStUscValue(String(value));
    } else {
      setStUscValue("");
    }
  };

  const onStUscChange = (value: string) => {
    setStUscValue(value);
    console.log("StUscValue", stUscValue);
    if (value) {
      setUscValue(String(value));
    } else {
      setUscValue("");
    }
  };

  const stakeFunction = async () => {
    await stakeAsync(
      {
        abi: USCStakingAbi,
        address: contractAddresses.USCStaking,
        functionName: "stake",
        args: [parseUnits(String(uscValue), etherUnits.wei), address!],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="stUSC"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="USC"
                      />
                    }
                  />
                }
              />
            ),
          });
          setUscValue("");
          setStUscValue("");
        },
      }
    );
  };

  const loadingNotif = (currentStep: number) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Staking in Process"}
          loading
          transferComponent={
            <Transfer
              rightComponent={
                <TransferMoney
                  icon={<SVGWrapper src={StUscLogo} width={22} height={22} />}
                  text={formatMoney(stUscValue)}
                  symbol="stUSC"
                />
              }
              leftComponent={
                <TransferMoney
                  icon={<SVGWrapper src={USCLogo} width={22} height={22} />}
                  text={formatMoney(uscValue)}
                  symbol="USC"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleButtonClick = async () => {
    loadingNotif(currentStep);
    if (!isApproved) {
      await approveAsync(parseUnits(String(uscValue), etherUnits.wei), {
        onSuccess: async (txHash?: string) => {
          showNotification({
            txHash,
            status: "success",
            content: (
              <StandardNotifBody
                headerComponent={<ApproveComplete />}
                transferComponent={
                  <Transfer
                    rightComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={StUscLogo} width={22} height={22} />
                        }
                        text={formatMoney(stUscValue)}
                        symbol="stUSC"
                      />
                    }
                    leftComponent={
                      <TransferMoney
                        icon={
                          <SVGWrapper src={USCLogo} width={22} height={22} />
                        }
                        text={formatMoney(uscValue)}
                        symbol="USC"
                      />
                    }
                  />
                }
                stepsComponent={
                  <StepsContainer
                    stepNames={steps}
                    currentStep={currentStep}
                    loading
                  />
                }
              />
            ),
          });
          setCurrentStep(1);
          loadingNotif(1);
          await stakeFunction();
        },
        onError: (message?: string) => {
          showNotification({
            status: "error",
            content: <DisplayErrorNotif message={message} />,
          });
        },
      });
    } else {
      await stakeFunction();
    }
  };

  const { displayData: uscBalance, balance } = useGetWalletBalanceByToken(
    contractAddresses.USC
  );
  const { isSuccess: isStUscBalanceFetched, balance: stUscBalance } =
    useGetWalletBalanceByToken(contractAddresses.USCStaking);

  const displayableStUscWalletBalance: DisplayableInputField = {
    value: displayTokens(stUscBalance, {}),
    isFetched: isStUscBalanceFetched,
    label: "",
  };

  const displayableUscDollarValue: DisplayableInputField = {
    value: formatMoney(Number(uscValue) * Number(uscPrice?.spotPrice)),
    label: "Value: $ ",
    isFetched: uscPrice?.isFetched,
  };
  const { getButtonPropsOverride } = useButtonPropsOverride();

  return (
    <FlexCol className="gap-1">
      <div className="md:mt-[18.74px] mt-[12.98px]">
        <InputField
          name="USCAmount"
          value={uscValue}
          onChange={(e) => onUscValueChange(e.target.value)}
          label="Stake USC"
          placeholder="Amount"
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper src={USCLogo} width={24} height={24} />
            </FlexRow>
          }
          rightLabel={
            <button
              className="bg-primary-10 text-primary rounded-md w-16 h-9 px-4 py-2 flex items-center justify-center text-body-small-medium"
              disabled={!balance || !isConnected}
              onClick={() => {
                onUscValueChange(formatUnitsToMaxValue(balance)?.toString());
              }}
            >
              MAX
            </button>
          }
          dollarValue={displayableUscDollarValue}
          walletBalance={uscBalance}
        />
      </div>
      <div className="md:mt-[18.74px] mt-[8.98px]">
        <InputField
          name="StUscAmount"
          value={stUscValue}
          onChange={(e) => onStUscChange(e.target.value)}
          label="To Receive"
          placeholder="Amount"
          leftLabel={
            <FlexRow className="items-center gap-2">
              <SVGWrapper src={StUscLogo} width={24} height={24} />
            </FlexRow>
          }
          walletBalance={displayableStUscWalletBalance}
          dollarValue={displayableUscDollarValue}
        />
      </div>

      <Button
        className="mt-[8.98px] md:mt-[19px]"
        onClick={handleButtonClick}
        disabled={!isConnected || (!uscValue && !stUscValue)}
        loading={isStaking || isApproving}
        fullWidth
        size="big"
        color="primary"
        {...getButtonPropsOverride(1)}
      >
        {Number(uscValue || 0) > 0 || Number(stUscValue || 0) > 0
          ? isApproved
            ? "Stake USC"
            : "Approve"
          : "Enter amount"}
      </Button>
    </FlexCol>
  );
};
