import { FlexCol } from "@shared";
import { ChiMetrics } from "../../../../components/rightSection/ChiMetrics";
import { ReserveAssets } from "../../../../components/rightSection/reserve-assets/ReserveAssets";
import { Analytics } from "../../../../components/rightSection/Analytics";
import { SavingsCalculator } from "../../../../components/rightSection/SavingsCalculator";
import { YieldComparison } from "../../../../components/rightSection/yield/YieldComparison";

export const RightSection = () => {
  return (
    <FlexCol className="w-full">
      <FlexCol className="gap-4">
        <ChiMetrics />
        <ReserveAssets />
        <Analytics />
        <SavingsCalculator />
        <YieldComparison />
      </FlexCol>
    </FlexCol>
  );
};
