import { FlexRow, Typography } from "@shared";
import { useAccount } from "wagmi";

export const WalletBadge = () => {
  const { isConnected, address } = useAccount();

  return (
    <div>
      {isConnected ? (
        <FlexRow className="gap-2 items-center rounded-full p-1 pl-2 bg-white">
          <Typography type="caption-regular">
            {address?.slice(0, 6)}...{address?.slice(-4)}
          </Typography>
          <div className="w-6 h-6 rounded-full bg-secondary"></div>
        </FlexRow>
      ) : null}
    </div>
  );
};
