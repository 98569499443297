import { contractAddresses, DataProviderAbi } from "@meta";
import { DisplayableAmount } from "@shared";
import { formatUnits } from "viem";
import { useReadContract } from "wagmi";

export const useFetchUscStakingAPR = () => {
  const { data: apr, isFetched: isFetchedAPR } = useReadContract({
    abi: DataProviderAbi,
    address: contractAddresses.DataProvider,
    functionName: "uscStakingApr",
    args: [contractAddresses.USCStaking],
  });

  return {
    apr,
    isFetchedAPR,
    aprDSPL: {
      viewValue: Number(formatUnits(apr || 0n, 8)).toFixed(2),
      isFetched: isFetchedAPR,
      label: "APR",
    } as DisplayableAmount,
  };
};
