import { SVGWrapper, Tooltip } from "@shared";
import React from "react";

import AlertCirle from "@assets/layout/alertCircle.svg";

export const InfoTooltip: React.FC<{
  children: React.ReactNode;
  width?: number;
  openOnClick?: boolean;
}> = ({ children, openOnClick }) => {
  return (
    <Tooltip
      tooltip={children}
      place="top"
      theme="light"
      size="none"
      radius={12}
      openOnClick={openOnClick}
    >
      <SVGWrapper
        src={AlertCirle}
        width="12px"
        height="12px"
        className="cursor-pointer hover:stroke-[#7fb0e8]"
      />
    </Tooltip>
  );
};
