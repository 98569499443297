import { contractAddresses, DataProviderAbi } from "@meta";
import { DisplayableInputField } from "@shared";
import { displayTokens } from "@utils";
import { useReadContract } from "wagmi";

export const useFetchChiStakingAPR = () => {
  const { data: apr, isFetched: isFetchedAPR } = useReadContract({
    abi: DataProviderAbi,
    functionName: "chiStakingAPR",
    address: contractAddresses.DataProvider,
    args: [
      contractAddresses.CHI,
      contractAddresses.ChiStaking,
      contractAddresses.ChiLocking,
      contractAddresses.USCStaking,
      contractAddresses.USC_ETH_LP_Staking,
      contractAddresses.CHI_ETH_LP_Staking,
      contractAddresses.ChiVesting,
      contractAddresses.RewardController,
      contractAddresses.PriceFeedAggregator,
      contractAddresses.ReserveHolder,
    ],
  });

  // 6747729716681108401n

  return {
    apr: (apr as any)?.[0],
    isFetchedAPR,
    aprDSPL: {
      value: displayTokens(((apr as any)?.[0] || 0n) * 100n, {
        decimals: 18,
      }),
      isFetched: isFetchedAPR,
      label: "APR",
    } as DisplayableInputField,
  };
};
