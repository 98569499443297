import { useReadContract } from "wagmi";
import {
  Card,
  DisplayMoney,
  FlexCol,
  FlexRow,
  Typography,
} from "../../../../../shared";
import { ReserveHolderDataProviderAbi } from "../../../../../meta/abis/ReserveHolderDataProviderAbi";
import { contractAddresses } from "@meta";
import { getTokenConfig } from "../../../../config/tokenConfig";
import { formatUnits } from "viem";
import { formatMoney } from "@utils";

export const ReserveBreakdown = () => {
  const { data } = useReadContract({
    abi: ReserveHolderDataProviderAbi,
    address: contractAddresses.ReserveHolderDataProvider,
    functionName: "getReserveBreakdown",
    args: [
      contractAddresses.ReserveHolder,
      contractAddresses.PriceFeedAggregator,
    ],
  });

  console.log(data);

  if (!data) {
    return <Typography>Loading...</Typography>;
  }

  const { totalValue, reserves } = data;

  return (
    <Card className="w-2/3" hasBorder>
      <FlexCol className="w-full p-4 gap-4">
        {/* Prikazivanje ukupne vrednosti */}
        <FlexRow className="items-center gap-3">
          <Typography className="text-lg font-bold">
            Total Reserve Value:
          </Typography>

          <DisplayMoney
            viewValue={formatMoney(formatUnits(totalValue || 0n, 8))}
            typography="h5"
            className="text-primary"
          />
        </FlexRow>

        {/* Prikazivanje informacija o svakom tokenu */}
        {reserves.map((reserve, index) => {
          const { reserveAsset, reserveAssetPrice, reserveValue, rewardValue } =
            reserve;
          const tokenConfig = getTokenConfig(reserveAsset);

          return (
            <FlexRow
              key={index}
              className="p-4 bg-gray-100 rounded-lg items-center"
            >
              <Typography className="w-1/4 ml-4 font-semibold">
                {tokenConfig.symbol || "Unknown Token"}
              </Typography>
              <FlexCol className="ml-8">
                <Typography>
                  Price: {formatMoney(formatUnits(reserveAssetPrice || 0n, 8))}{" "}
                  $
                </Typography>
                <Typography>
                  Reserve Value:{" "}
                  {formatMoney(formatUnits(reserveValue || 0n, 8))} $
                </Typography>
                <Typography>
                  Reward Value: {formatMoney(formatUnits(rewardValue || 0n, 8))}{" "}
                </Typography>
              </FlexCol>
            </FlexRow>
          );
        })}
      </FlexCol>
    </Card>
  );
};
