import { useForm } from "react-hook-form";
import { parseUnits, etherUnits } from "viem";
import { useReadContract } from "wagmi";
import {
  useWingsContractWrite,
  Card,
  FlexCol,
  Typography,
  MyFormProvider,
  RHFInputFieldFormatted,
  Button,
  useNotificationContext,
} from "@shared";
import { displayTokens } from "@utils";
import { ArbitrageAbi, contractAddresses } from "@meta";

interface FormData {
  amount: string;
}
export const ExecuteArbitrage = () => {
  const { showNotification } = useNotificationContext();

  const methods = useForm<FormData>();
  const { handleSubmit, reset } = methods;
  const {
    writeContractAsync: executeArbitrageAsync,
    isPending: isExecuteArbitrage,
  } = useWingsContractWrite();

  const { data: executeArbitrage, isLoading } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "executeArbitrage" as any,
    args: [0] as any,
  });

  const onSubmit = async (data: FormData) => {
    await executeArbitrageAsync(
      {
        abi: ArbitrageAbi,
        address: contractAddresses.Arbitrage,
        functionName: "executeArbitrage",
        args: [parseUnits(String(data.amount), etherUnits.wei)],
      },
      {
        onSuccess: () => {
          showNotification({
            status: "success",
            content: <Typography>success</Typography>,
          });
          reset();
        },
      }
    );
  };

  return (
    <Card className=" max-w-[66%]">
      <FlexCol className="gap-4">
        <Typography>Write expected arbitrage profit</Typography>
        <Typography>
          {displayTokens((executeArbitrage as bigint) || 0n, {
            decimals: 8,
          })}
        </Typography>
        <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <FlexCol className="gap-4">
            <FlexCol className="gap-1">
              <RHFInputFieldFormatted<FormData>
                name="amount"
                placeholder="Maximum chi spot price"
              />
            </FlexCol>
            <Button
              loading={isExecuteArbitrage}
              type="submit"
              disabled={isLoading}
            >
              Execute Arbitrage
            </Button>
          </FlexCol>
        </MyFormProvider>
      </FlexCol>
    </Card>
  );
};
