import { format } from "date-fns";

export const displayDate = (
  date: string | number | Date,
  formatStr: string = "dd/MM/yyyy HH:mm",
  options?: undefined
): string => {
  const dateObj =
    typeof date === "string" ? new Date(Number(date)) : new Date(date);

  return format(dateObj, formatStr, options);
};
