import {
  Card,
  DisplayMoney,
  DisplayPercentage,
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
} from "@shared";
import { TotalSupplyChart } from "../charts/solvency/TotalSupplyChart";
import { SupplyVsReservesChart } from "../charts/solvency/SupplyVsReserves";

import uscIcon from "@assets/tokens/usc.svg";
import { useReserveValue } from "../../../state/reserveholder/useOverviewData";
import { useCollateralRatio } from "../../../state/common/useCollateralRatio";
import { useTotalUscSupply } from "../../../state/usc/useTotalUscSupply";

export const SolvencyTab = () => {
  const reserveValue = useReserveValue();
  const collateralRatio = useCollateralRatio();
  const totalSupply = useTotalUscSupply();

  return (
    <div className="flex flex-col gap-8">
      <Card hasShadowInstead hasBorder>
        <FlexCol className="p-4 gap-6">
          <Typography type="body-small-medium">Solvency Stats</Typography>
          <FlexRow className="flex-col md:flex-row md:items-center gap-6">
            <FlexCol className="md:py-8  md:w-1/3 md:items-center md:text-center">
              <FlexRow className="gap-2">
                <SVGWrapper width={20} height={20} src={uscIcon} />
                <Typography type="body-large-medium">USC Supply</Typography>
              </FlexRow>
              <DisplayMoney
                className="text-primary"
                {...totalSupply}
                typography="h3"
              />
            </FlexCol>

            <FlexCol className="md:py-8  md:w-1/3 md:items-center md:text-center">
              <FlexRow>
                <Typography type="body-large-medium">Total Reserves</Typography>
              </FlexRow>
              <DisplayMoney
                className="text-primary"
                {...reserveValue}
                typography="h3"
              />
            </FlexCol>

            <FlexCol className="md:py-8  md:w-1/3 md:items-center md:text-center">
              <FlexRow>
                <Typography type="body-large-medium">
                  Collateral Ratio
                </Typography>
              </FlexRow>
              <DisplayPercentage
                className="text-primary"
                {...collateralRatio}
                viewValue={collateralRatio.data.viewValue}
                typography="h3"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </Card>

      <div className="flex flex-col lg:flex-row gap-8">
        <Card hasShadowInstead className="w-full lg:w-1/2">
          <TotalSupplyChart
            name="USC Supply"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
        <Card hasShadowInstead className="w-full lg:w-1/2">
          <SupplyVsReservesChart
            name="USC Supply vs Reserves"
            icon={<SVGWrapper width={20} height={20} src={uscIcon} />}
          />
        </Card>
      </div>
    </div>
  );
};
