import { FlexCol } from "@shared";
import { VeChiStats } from "./veChiStats/VeChiStats";
import { Countdown } from "../../../../components/rightSection/countdown/Countdown";
import { Analytics } from "../../../../components/rightSection/Analytics";
import { YourClaimablePositions } from "../../../../components/rightSection/yourRewards/YourClaimablePositions";
import { YourRewards } from "../../../../components/rightSection/yourRewards/YourRewards";

export const RightSection = () => {
  return (
    <FlexCol className="w-full">
      <FlexCol className="gap-4">
        <VeChiStats />

        <YourRewards />
        <YourClaimablePositions />

        <Countdown
          initialStartingDatetime={new Date(2024, 2, 11, 0, 0, 0)}
          durationInDays={7}
        />
        <Analytics />
      </FlexCol>
    </FlexCol>
  );
};
