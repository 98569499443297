import { Address, formatUnits, parseUnits } from "viem";
import { contractAddresses } from "@meta";
import { TOKEN_CONFIG } from "../../../../../../../../config/tokenConfig";
import { getTokenActualAddressBySymbolAndChainId } from "../../../../../../../../state/across/contractAddressesByChain";

export const calculateFirstInputValueZapin = async (
  selectedAsset: Address | undefined,
  wethTokenPrice: any,
  mintBurnFee: bigint | undefined,
  value: string,
  fetchGetQuote: (params: any) => Promise<any>
) => {
  if (!wethTokenPrice) {
    throw new Error("Token price not found");
  }
  if (!mintBurnFee) {
    throw new Error("Mint burn fee is not set");
  }
  if (!selectedAsset) {
    throw new Error("Asset not selected");
  }

  try {
    if (value) {
      const quote = await fetchGetQuote({
        chainId: 1,
        amount: parseUnits(
          value || "0",
          TOKEN_CONFIG[selectedAsset]?.decimals
        ).toString(),
        srcToken: getTokenActualAddressBySymbolAndChainId(
          selectedAsset ? TOKEN_CONFIG[selectedAsset]?.symbol : undefined,
          1
        ),
        dstToken: contractAddresses.WETH,
      });

      const quoteFormatted = Number(
        formatUnits(BigInt(quote?.dstAmount || 0), 18)
      );

      const uscValue =
        (wethTokenPrice || 0) *
        (quoteFormatted * (1 - Number(formatUnits(mintBurnFee || 0n, 4))));

      return {
        quoteRes: quote,
        uscValue: String(uscValue),
      };
    } else {
      return {
        quoteRes: undefined,
        uscValue: "",
      };
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching quote or calculating USC value");
  }
};
