import React, { useState } from "react";
import { SVGWrapper } from "@shared";
import { TOKEN_CONFIG } from "../../../../config/tokenConfig";
import { Address } from "viem";

type DropdownOption = {
  value: Address;
  label: string;
  icon?: string;
};

type DropdownProps = {
  options: DropdownOption[];
  selectedValues: Address[];
  setSelectedValues: React.Dispatch<React.SetStateAction<Address[]>>;
  label: string;
  icon?: string;
};

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedValues,
  setSelectedValues,
  label,
  icon,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelection = (value: Address) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };

  return (
    <div className="relative w-full">
      {/* Dropdown trigger */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-3 w-full px-4 py-2 border border-neutral-black-30 rounded-xl bg-white"
      >
        {selectedValues.length > 0 ? (
          <div className="flex items-center gap-2">
            {selectedValues.map((selectedValue) => (
              <SVGWrapper
                key={selectedValue}
                src={TOKEN_CONFIG[selectedValue].logo}
                width={32}
                height={32}
              />
            ))}
          </div>
        ) : (
          <span>{label}</span>
        )}
        {icon && <SVGWrapper src={icon} width={16} height={16} />}
      </button>

      {/* Dropdown content */}
      {isOpen && (
        <ul className="absolute mt-2 w-full bg-white rounded-lg shadow-lg z-10">
          {options.map((option) => (
            <li
              key={option.value}
              className="flex items-center justify-between gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => toggleSelection(option.value)}
            >
              <div className="flex items-center gap-2">
                {option.icon && (
                  <SVGWrapper src={option.icon} width={20} height={20} />
                )}
                <span>{option.label}</span>
              </div>
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => toggleSelection(option.value)}
                className="checkbox [--chkbg:theme(colors.primary)] [--chkfg:white]"
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
