import React from "react";

import { FlexRow } from "../flexRow/FlexRow";

/* ----------- */
/*    Icons    */
/* ----------- */

export const SingleComponent: React.FC<{
  component: React.ReactNode;
}> = ({ component }) => {
  return <FlexRow className="items-center gap-3">{component}</FlexRow>;
};
