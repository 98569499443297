import { Controller, RegisterOptions, useFormContext } from "react-hook-form";

type DateTimeProps<T> = {
  name: keyof T;
  rules?: RegisterOptions;
  defaultValue?: string;
};
export function RHFDateTime<T>({ name, rules }: DateTimeProps<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={String(name)}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <div>
          <input
            className={`block border-black border-[1px] sm:text-sm ${
              error ? "border-red-500" : ""
            }`}
            type="datetime-local"
            onChange={onChange}
            onBlur={onBlur}
            value={value || ""}
            ref={ref}
          />
          {error && <p style={{ color: "red" }}>{error.message}</p>}
        </div>
      )}
    />
  );
}
