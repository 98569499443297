import { BaseError, ContractFunctionRevertedError } from "viem";

const defaultMessage =
  "An unknown error occurred, use the Support channel in Discord for assistance.";

/**
 * @dev utility function to parse error
 * @param e - error object
 * @returns {string} parsed error string
 */
export const getParsedError = (error: any | BaseError): string => {
  let message = defaultMessage;
  let errorKey = "";

  console.log({ error });

  const revertedError = error?.walk
    ? error.walk((err: unknown) => err instanceof ContractFunctionRevertedError)
    : null;
  if (revertedError instanceof ContractFunctionRevertedError) {
    errorKey =
      revertedError.data?.errorName ??
      revertedError.signature ??
      revertedError.reason ??
      "";
    if (errorMapping[errorKey]) return errorMapping[errorKey];
  }

  message = error.message || error.shortMessage || error.details || message;

  return message;
};

export const errorMapping: Record<string, string> = {
  Panic: "Transaction reverted",
};
