import React from "react";

import { Typography } from "../text/Typography/Typography";

import { StepComp } from "./StepComp";
import { FlexRow } from "../flexRow/FlexRow";
import { SVGWrapper } from "../svgWrapper/SvgWrapper";

/* ----------- */
/*    Icons    */
/* ----------- */
import Line from "../../../assets/layout/line.svg";

export const StepsContainer: React.FC<{
  currentStep: number;
  stepNames: string[];
  loading?: boolean;
}> = ({ currentStep, stepNames, loading }) => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col justify-between gap-[10px]">
        <div className="flex items-center gap-[8.5px] px-12">
          {stepNames.map((_stepName, index) => (
            <React.Fragment key={index}>
              <div
                className={`${
                  index < currentStep
                    ? "text-[#949494] bg-[#F9F8F8]"
                    : index === currentStep
                      ? "bg-[#0051A6] text-[#FFF]"
                      : "bg-[#FFF] border border-solid border-[#EEE]"
                } rounded-full`}
              >
                <Typography className="p-4 px-[22px]" type="body-medium-bold">
                  {index + 1}
                </Typography>
              </div>
              {index !== stepNames.length - 1 && (
                <FlexRow className="items-center">
                  <SVGWrapper src={Line} width={30} height={2} />
                  {loading && (
                    <div className="loading loading-spinner text-[#0051A6] w-5 h-5" />
                  )}
                  <SVGWrapper src={Line} width={30} height={2} />
                </FlexRow>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-evenly gap-5">
          {stepNames.map((stepName, index) => (
            <React.Fragment key={index}>
              <StepComp
                step={index + 1}
                stepName={index != 0 ? "Confirm" : stepName}
                doing={index === currentStep}
                done={index < currentStep}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
