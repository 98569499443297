import { useState, useEffect } from "react";
import { Address, erc20Abi, maxUint256 } from "viem";
import { useFetchAssetAllowance, useWingsContractWrite, WingsWriteAsyncParams } from "../../../shared";

const ALWAYS_APPROVE_MAX = false;

/**
 * Helper function to determine human readable state of approve.
 *
 * @param {boolean} isApproved - Indicates if the approval is already done.
 * @param {boolean} justApproved - Indicates if the user has just approved.
 * @return {string} - The appropriate button text.
 */

export function getApproveState(isApproved?: boolean, justApproved?: boolean) {
  if (isApproved) {
    return justApproved ? "Approve Confirmed" : "Approved";
  }
  return "Approve";
}

/**
 * Custom hook for approving ERC20 token transfers.
 *
 * This hook provides functionality for approving ERC20 token transfers, checking the current allowance, and handling the approval transaction using Wagmi.
 *
 * @param {Address} tokenAddress - The address of the ERC20 token contract.
 * @param {Address} spenderAddress - The address of the spender to approve the transfer to.
 * @param {bigint} [amount=BigInt(0)] - The amount of tokens to approve for transfer. Defaults to 0.
 * @returns {Object} Object containing the following properties:
 * - {boolean} isApproved - Indicates whether the spender is already approved to transfer the specified amount of tokens.
 * - {boolean} isApproving - Indicates whether an approval transaction is currently pending.
 * - {Function} approveAsync - Function to trigger the approval transaction.
 */

export const useWingsApprove = (tokenAddress?: Address, spenderAddress?: Address, amount?: bigint) => {
  const [isApproved, setIsApproved] = useState(false);
  const [justApproved, setJustApproved] = useState(false);

  const { data: allowance, queryKey } = useFetchAssetAllowance({
    asset: tokenAddress,
    spender: spenderAddress,
  });

  const { writeContractAsync: approveTokenAsync, isPending } = useWingsContractWrite({
    queriesToInvalidate: [queryKey],
  });

  useEffect(() => {
    if (amount == null) {
      setIsApproved(false);
    } else if (allowance && allowance.bigIntValue >= amount) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }, [allowance, amount]);

  const approveAsync = async (amount: bigint, settings?: WingsWriteAsyncParams) => {
    const amountToApprove = ALWAYS_APPROVE_MAX ? maxUint256 : amount;

    if (!spenderAddress) {
      throw new Error("spenderAddress is undefined!");
    }
    if (!tokenAddress) {
      throw new Error("tokenAddress is undefined!");
    }
    if (amountToApprove == null) {
      throw new Error("amountToApprove is undefined!");
    }

    try {
      await approveTokenAsync(
        {
          address: tokenAddress,
          abi: erc20Abi,
          functionName: "approve",
          args: [spenderAddress, amountToApprove],
        },
        {
          onSuccess: async (txHash?: Address) => {
            setJustApproved(true);
            setIsApproved(true);
            await settings?.onSuccess?.(txHash!);
          },
          onError: (error) => {
            console.error("Error approving token:", error);
            if (settings?.onError) {
              const message = getParsedError(error);
              console.log({ message });
              settings.onError(message);
            }
          },
          onSettled: () => {
            settings?.onSettled?.();
          },
        }
      );
    } catch (e: any) {
      console.error("Error approving token:", e);
    }
  };

  return {
    isApproved,
    isApproving: isPending,
    justApproved,
    approveAsync,
  };
};

/**
 * @dev utility function to parse error
 * @param e - error object
 * @returns {string} parsed error string
 */
export const getParsedError = (e: any): string => {
  let message = e.message ?? "An unknown error occurred";

  if (e) {
    if (e.details) {
      message = e.details;
    } else if (e.shortMessage) {
      message = e.shortMessage;
    } else if (e.message) {
      message = e.message;
    } else if (e.name) {
      message = e.name;
    }
  }

  return message;
};
