import React from "react";
import { Typography } from "../text/Typography/Typography";

export const StandardNotifBody: React.FC<{
  header?: string;
  headerComponent?: React.ReactNode;
  transferComponent?: React.ReactNode;
  stepsComponent?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
}> = ({
  header,
  headerComponent,
  transferComponent,
  stepsComponent,
  footer,
}) => {
  return (
    <div className="flex flex-col items-center text-center gap-11">
      <div className="flex flex-col gap-4 justify-center items-center">
        {headerComponent || (
          <Typography type="chi-protocol-large">{header || ""}</Typography>
        )}
        {transferComponent}
      </div>
      {stepsComponent}
      {footer}
    </div>
  );
};
