import { formatWithDynamicPrecision } from "./format-with-dynamic-precision";

export type CurrencyPosition = "before" | "after";

export interface FormatMoneyOptions {
  /**
   * Specifies the character to be used as a decimal separator.
   * Default is ".".
   */
  decimalSeparator?: string;

  /**
   * Specifies the character to be used as a thousands separator.
   * Default is ",".
   */
  thousandSeparator?: string;

  /**
   * Specifies the character to be used as a thousands separator.
   * Default is ",".
   */
  numberOfDecimals?: number;

  /**
   * Specifies the character to be used as a thousands separator.
   * Default is ",".
   */
  maxDecimals?: number;

  /**
   * Specifies a fixed number of decimal places.
   * If defined, it will override `maxDecimals` and ignore `formatWithDynamicPrecision`.
   */
  fixedNumberOfDecimals?: number;
}

/**
 * `formatMoney` function
 *
 * This function formats a number to represent money, providing several options for customization.
 *
 * @example
 * ```typescript
 * formatMoney(1000.50)                     // $1,000.50
 * formatMoney(1000.50, {currency: '€'})    // €1,000.50
 * formatMoney(1000.50, {currencyPosition: 'after'}) // 1,000.50$
 * formatMoney(1000.50, {decimalSeparator: ',', thousandSeparator: '.'}) // $1.000,50
 * ```
 *
 * @param amount The numerical value to be formatted.
 * @param options Optional configuration for formatting.
 * @returns A formatted money string.
 */
export const formatMoney = (
  amount: number | string,
  options: FormatMoneyOptions = {}
): string => {
  const {
    decimalSeparator = ".",
    thousandSeparator = ",",
    numberOfDecimals = 2,
    maxDecimals,
    fixedNumberOfDecimals,
  } = options;

  let formattedAmount: string;

  if (typeof fixedNumberOfDecimals === "number") {
    // Use fixed number of decimals
    formattedAmount = Number(amount).toFixed(fixedNumberOfDecimals);
  } else {
    // Use dynamic precision
    formattedAmount = formatWithDynamicPrecision(Number(amount), {
      numberOfDecimals,
      maxDecimals,
    }).replace(".", decimalSeparator);
  }

  // Format the whole part with thousand separators
  const parts = formattedAmount.split(decimalSeparator);
  const integerPart = parts[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  );
  const decimalPart = parts[1] ?? "";

  return `${integerPart}${decimalPart ? decimalSeparator + decimalPart : ""}`;
};
