import {
  FlexCol,
  FlexRow,
  Typography,
  DisplayTokenAmount,
  DisplayPercentage,
} from "@shared";

//icons
import { useAccount } from "wagmi";
import { WalletBadge } from "../../../../../components/walletBadge/WalletBadge";
import { contractAddresses } from "@meta";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { useFetchUscStakingAPR } from "../../../../../state/usc/useFetchUscStakingAPR";

export const StakeDepositPanel = () => {
  /* --------------- */
  /*    Meta data    */
  /* --------------- */
  const { isConnected } = useAccount();
  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const uscBalance = useGetWalletBalanceByToken(contractAddresses.USC);
  const stUscBalance = useGetWalletBalanceByToken(contractAddresses.USCStaking);

  const { aprDSPL, isFetchedAPR } = useFetchUscStakingAPR();

  return (
    <div>
      {isConnected ? (
        <FlexCol className="w-full gap-5 px-3 py-5 sm:px-6 sm:py-10  bg-primary selection:bg-white selection:text-primary ">
          <FlexRow className="w-full items-center justify-between">
            <FlexCol className="gap-2">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-80"
              >
                Available to Stake
              </Typography>
              <DisplayTokenAmount
                {...uscBalance}
                symbol="USC"
                symbolPosition="after"
                typography="body-large-bold"
                className="text-white"
              />
              <FlexRow className="gap-2 items-center"></FlexRow>
            </FlexCol>
            <FlexCol className="gap-3 items-end">
              <WalletBadge />
            </FlexCol>
          </FlexRow>
          <FlexRow className="w-full border-[1px] border-neutral-white-20"></FlexRow>
          <FlexRow className="w-full items-center justify-between">
            <FlexCol className="gap-2">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-80"
              >
                stUSC Balance
              </Typography>
              <FlexRow className="gap-2 items-center">
                <DisplayTokenAmount
                  viewValue={stUscBalance.displayData.value}
                  symbol="stUSC"
                  symbolPosition="after"
                  typography="body-large-bold"
                  className="text-white"
                  isFetched={!stUscBalance.isFetching}
                />
              </FlexRow>
              <FlexRow className="gap-2 items-center"></FlexRow>
            </FlexCol>
            <FlexRow className="gap-2 items-center">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-56"
              >
                stUSC APR
              </Typography>
              <DisplayPercentage
                viewValue={aprDSPL.viewValue}
                isFetched={isFetchedAPR}
                symbolPosition="after"
                className="text-white"
                typography="body-large-bold"
              />
            </FlexRow>
          </FlexRow>
        </FlexCol>
      ) : null}
    </div>
  );
};
