import { useFetchPositions } from "./useFetchOptions";
import { OptionDataRow } from "./OptionPosition";
import { Typography, SVGWrapper, FlexRow } from "@shared";
import { useState, useMemo } from "react";
import { usePaginableSortedData } from "../../../../../state/chi/usePaginableSortedData";
import ArrowRight from "@assets/layout/chevron-right.svg";
import VeChi from "@assets/tokens/veChiRounded.svg";
import StChi from "@assets/tokens/stChi.svg";
import StEth from "@assets/tokens/stEth.svg";
import { NoRewards } from "../NoRewards";
import { MobileRow } from "./MobileRow";

export const OptionPositions = () => {
  return (
    <div className="w-full">
      <Table />
    </div>
  );
};

const Table = () => {
  const { positions, isFetched } = useFetchPositions();
  const [filter, setFilter] = useState<"all" | "active" | "inactive">("all");
  const [visibleMobileRows, setVisibleMobileRows] = useState(3);

  const filteredPositions = useMemo(
    () =>
      positions.filter((position) => {
        if (filter === "all") {
          return true;
        } else if (filter === "active") {
          return (position?.veCHIAmount || 0) > 0 && !position.isExpired;
        } else if (filter === "inactive") {
          return (position?.veCHIAmount || 0) === 0 || position.isExpired;
        }
        return true;
      }),
    [filter, positions.length]
  );

  const { dataToShow, rowsPerPage, setRowsPerPage, page, setPage } =
    usePaginableSortedData(filteredPositions, "unlockDate");

  if (!isFetched) {
    return <NoRewards loading={true} />;
  }

  if (!positions || positions.length === 0) {
    return <NoRewards loading={false} />;
  }

  const handleShowMore = () => {
    setVisibleMobileRows((prev) =>
      Math.min(prev + 3, filteredPositions.length)
    );
  };

  return (
    <div>
      {/* Mobile */}
      <div className="block md:hidden">
        <div className="flex flex-col gap-4">
          {filteredPositions
            .slice(0, visibleMobileRows)
            .map((position, index) => (
              <MobileRow key={index} {...position} />
            ))}
          {visibleMobileRows < filteredPositions.length && (
            <button
              className="bg-primary text-white rounded-lg py-2 mt-4"
              onClick={handleShowMore}
            >
              Show More
            </button>
          )}
        </div>
      </div>

      {/* Desktop */}
      <div className="hidden md:block w-full bg-primary rounded-2xl overflow-x-auto">
        <table className="w-full border-collapse mb-2">
          <thead>
            <tr>
              <ThCustom>
                <div className="relative inline-block m-2 ">
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m7 10l5 5l5-5"
                      />
                    </svg>
                  </div>
                  <select
                    className="appearance-none w-full text-caption-medium border border-neutral-white-20 rounded-lg text-white pl-2 py-1 leading-[normal] focus:outline-none focus:ring-0 pr-6 bg-primary"
                    value={filter}
                    onChange={(e) =>
                      setFilter(e.target.value as "active" | "inactive" | "all")
                    }
                  >
                    <option value="all">
                      <Typography type="body-small-bold">Position</Typography>
                    </option>
                    <option value="active">
                      <Typography type="body-small-bold">Active</Typography>
                    </option>
                    <option value="inactive">
                      <Typography type="body-small-bold">Inactive</Typography>
                    </option>
                  </select>
                </div>
              </ThCustom>
              <ThCustom>
                <FlexRow className="items-center gap-1">
                  <SVGWrapper src={VeChi} width={12} height={12} />
                  <Typography type="body-small-medium" className="text-white">
                    veCHI Amount
                  </Typography>
                </FlexRow>
              </ThCustom>
              <ThCustom>
                <FlexRow className="items-center gap-1">
                  <SVGWrapper src={StEth} width={12} height={12} />
                  <Typography type="body-small-medium" className="text-white">
                    stETH Rewards
                  </Typography>
                </FlexRow>
              </ThCustom>
              <ThCustom>
                <FlexRow className="items-center gap-1">
                  <SVGWrapper src={StChi} width={12} height={12} />
                  <Typography type="body-small-medium" className="text-white">
                    stCHI Rewards
                  </Typography>
                </FlexRow>
              </ThCustom>
              <ThCustom>
                <Typography type="body-small-medium" className="text-white">
                  End Date
                </Typography>
              </ThCustom>
            </tr>
          </thead>
          <tbody>
            {dataToShow?.map((position, index) => (
              <OptionDataRow
                key={index}
                {...position}
                hideBorderBot={dataToShow?.length - 1 === index}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="hidden md:flex items-center justify-between mx-2 mt-[-5px]">
        <div className="flex flex-row items-center gap-2">
          <Typography
            type="body-small-regular"
            className="text-neutral-black-60"
          >
            Rows Per Page
          </Typography>

          <div className="relative inline-block m-2">
            <select
              className="appearance-none w-full text-body-small-regular text-neutral-600 border-neutral-white-50 border rounded-[5px] pl-2 py-1 focus:outline-none focus:ring-0 pr-6"
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(Number(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

        <div className="flex flex-row items-center gap-1">
          <Typography
            type="body-small-regular"
            className="text-neutral-black-60"
          >
            {`${(page - 1) * rowsPerPage + 1} to ${Math.min(
              page * rowsPerPage,
              filteredPositions?.length
            )} of ${Math.ceil(filteredPositions?.length / rowsPerPage)}`}
          </Typography>
          <button
            className="rounded-[5px] border-neutral-white-50 border p-1"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            <SVGWrapper src={ArrowRight} width={16} height={16} flip />
          </button>
          <button
            className="rounded-[5px] border-neutral-white-50 border p-1"
            onClick={() => setPage(page + 1)}
            disabled={
              page * rowsPerPage >= Number(filteredPositions?.length || 0)
            }
          >
            <SVGWrapper src={ArrowRight} width={16} height={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

export const ThCustom: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <th className="py-">{children}</th>;
};
