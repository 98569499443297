import { Address, erc20Abi, formatUnits } from "viem";
import { readContractQueryOptions, useQuery } from "wagmi/query";
import { getQueryClient } from "../../../contexts/CustomQueryClientProvider";
import {
  PriceFeedAggregatorAbi,
  ReserveHolderAbi,
  contractAddresses,
} from "@meta";
import { getWagmiConfig } from "../../config/wagmi.config";
import {
  Displayable,
  metadataQueryConfig,
  priceQueryConfig,
  USD_DECIMALS,
  ViewBigInt,
} from "@shared";
import { getTokenConfig } from "../../config/tokenConfig";
import { displayTokens } from "@utils";
import { AdapterAbi } from "../../../meta/abis/AdapterAbi";
import { calculateValueInUsd } from "../../../utils/formatters/calculate-value-in-usd";

interface FetchReserveAssetsData {
  balanceOf: bigint;
  price: bigint;
  dollarAmount?: bigint;
}

const fetchReserveAssetData = async (
  address: Address
): Promise<FetchReserveAssetsData> => {
  /* --------------- */
  /*    Meta data    */
  /* --------------- */
  const config = getWagmiConfig();
  const queryClient = getQueryClient();
  const { decimals } = getTokenConfig(address);

  /* ---------------- */
  /*   Special case   */
  /* ---------------- */
  if (address === contractAddresses.WETH) {
    const [balanceOf, price] = await Promise.all([
      queryClient.fetchQuery(
        readContractQueryOptions(config, {
          abi: erc20Abi,
          address,
          functionName: "balanceOf",
          args: [contractAddresses.ReserveHolder],
        })
      ),
      queryClient.fetchQuery({
        ...readContractQueryOptions(config, {
          abi: PriceFeedAggregatorAbi,
          address: contractAddresses.PriceFeedAggregator,
          functionName: "peek",
          args: [contractAddresses.WETH],
        }),
        ...priceQueryConfig,
      }),
    ]);

    return {
      balanceOf,
      price,
      dollarAmount: calculateValueInUsd(balanceOf, price, decimals),
    };
  }

  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const adapter = await fetchReserveAssetAdapter(address);

  const [balanceOf, dollarValue] = await Promise.all([
    queryClient.fetchQuery(
      readContractQueryOptions(config, {
        abi: erc20Abi,
        address,
        functionName: "balanceOf",
        args: [adapter],
      })
    ),
    queryClient.fetchQuery({
      ...readContractQueryOptions(config, {
        abi: AdapterAbi,
        address: adapter,
        functionName: "getReserveValue",
      }),
      ...priceQueryConfig,
    }),
  ]);

  return {
    balanceOf,
    price: dollarValue,
    dollarAmount: dollarValue,
  };
};

const fetchReserveAssetAdapter = async (address: Address): Promise<Address> => {
  const config = getWagmiConfig();
  const queryClient = getQueryClient();

  const result = await queryClient.fetchQuery({
    ...readContractQueryOptions(config, {
      abi: ReserveHolderAbi,
      address: contractAddresses.ReserveHolder,
      functionName: "reserveAdapters",
      args: [address],
    }),
    ...metadataQueryConfig,
  });

  return result;
};

export const useReserveAssetByAddress = (
  address?: Address
): Displayable<{
  balanceOf: ViewBigInt;
  price: ViewBigInt;
  dollarAmount?: ViewBigInt;
}> => {
  const result = useQuery({
    queryKey: ["hookReserveAssetByAddress", address],
    queryFn: () => fetchReserveAssetData(address!),
    enabled: !!address,
  });
  const data = result.data as FetchReserveAssetsData | undefined;
  const { decimals, symbol } = getTokenConfig(address);

  const resultData = {
    balanceOf: {
      bigIntValue: data?.balanceOf,
      symbol,
      value: formatUnits(data?.balanceOf || 0n, decimals),
      viewValue: displayTokens(data?.balanceOf || 0n, { decimals }),
    },
    price: {
      bigIntValue: data?.price,
      symbol: "$",
      value: formatUnits(data?.price || 0n, USD_DECIMALS),
      viewValue: displayTokens(data?.price || 0n, { decimals: USD_DECIMALS }),
    },
    dollarAmount: {
      bigIntValue: data?.dollarAmount,
      symbol: "$",
      value: formatUnits(data?.dollarAmount || 0n, USD_DECIMALS),
      viewValue: displayTokens(data?.dollarAmount || 0n, {
        decimals: USD_DECIMALS,
      }),
    },
  };

  return {
    ...result,
    data: resultData,
  };
};
