import { FlexCol } from "@shared";
import { ReserveAssets } from "../../../../components/rightSection/reserve-assets/ReserveAssets";
import { Analytics } from "../../../../components/rightSection/Analytics";
import { SavingsCalculator } from "../../../../components/rightSection/SavingsCalculator";
import { ChiMetrics } from "./ChiMetrics";
import { YieldComparison } from "../../../../components/rightSection/yield/YieldComparison";
import { useActiveTabsFromLocation } from "../../../../state/activeTab/useTabsFromLocation";
import { WrapChiMetrics } from "./WrapChiMetrics";

export const RightSection = () => {
  const { activeStakeFormTab } = useActiveTabsFromLocation();

  return (
    <FlexCol className="w-full">
      <FlexCol className="gap-4">
        {activeStakeFormTab === "Wrap" || activeStakeFormTab === "Unwrap" ? (
          <WrapChiMetrics />
        ) : (
          <ChiMetrics />
        )}
        <ReserveAssets />
        <Analytics />
        <SavingsCalculator />
        <YieldComparison />
      </FlexCol>
    </FlexCol>
  );
};
