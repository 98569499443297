import Chart from "react-apexcharts";

import { DuneData } from "./solvency/TotalSupplyChart";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import {
  buildQueryKey,
  fetchFromApi,
  CHART_STALE_TIME,
  getDuneDataArray,
  checkAndUpdateData,
} from "../../../state/dune/utils";
import { useState, useEffect } from "react";
import { ApexOptions } from "apexcharts";
import { Card, FlexCol, FlexRow, Typography } from "../../../../shared";

export interface HoldingsData {
  Address: string;
  Holdings: number;
}

const queryId = 3790571;

export const HoldingsPieChart: React.FC<{
  name: string;
  icon: React.ReactNode;
}> = ({ name, icon }) => {
  const queryClient = useQueryClient();

  const {
    data: initialData,
    isFetched: isInitialFetched,
    isSuccess: isInitialSuccess,
  } = useQuery({
    queryKey: [buildQueryKey(queryId)],
    queryFn: () => fetchFromApi(queryId),
    staleTime: CHART_STALE_TIME,
    retry: true,
  });

  const [chartOptions, setChartOptions] = useState<ApexOptions>({});
  const [chartSeries, setChartSeries] = useState<number[]>([]);

  useEffect(() => {
    const processData = (data: DuneData[]) => {
      try {
        const arrayData = getDuneDataArray(data);
        const reversedData = data ? [...arrayData].reverse() : [];

        const seriesData = reversedData.map((item) => item.Holdings);

        setChartOptions({
          chart: {
            id: "holdings-pie-chart",
            type: "pie",
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: (val) => val.toFixed(2),
            },
          },
        });

        setChartSeries(seriesData);
      } catch (err) {
        console.log("error in total supply");
        console.log(err);
      }
    };
    if (isInitialFetched && initialData && isInitialSuccess) {
      checkAndUpdateData(initialData, queryId, processData, queryClient);
    }
  }, [initialData, isInitialFetched, isInitialSuccess, queryClient]);

  return (
    <div>
      <Card hasBorder hasShadowInstead>
        <FlexCol className="p-4 min-h-[300px]">
          <FlexRow className="items-center gap-2">
            {icon}
            <Typography>{name}</Typography>
          </FlexRow>
          {typeof window !== "undefined" &&
            (isInitialFetched && initialData && chartOptions?.chart?.type ? (
              <Chart
                options={chartOptions}
                series={chartSeries}
                type={chartOptions?.chart?.type}
                height="100%"
                width="100%"
              />
            ) : (
              <div className="flex justify-center items-center w-full h-full ">
                <span className="loading loading-spinner loading-sm" />
              </div>
            ))}
        </FlexCol>
      </Card>
    </div>
  );
};
