import { useQuery } from "@tanstack/react-query";
import { getQueryClient } from "../../../contexts/CustomQueryClientProvider";
import { queryConfigs } from "@shared";

const baseUrl = import.meta.env.VITE_ZAPIN_API;

interface SwapResponse {
  srcToken: {
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    logoURI: string;
  };
  dstToken: {
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    logoURI: string;
  };
  dstAmount: string;
  protocols: any[];
  tx: {
    from: string;
    to: string;
    data: string;
    value: string;
    gasPrice: string;
    gas: number;
  };
}

interface GetSwapMetadataParams {
  chainId: number;
  srcToken: string;
  dstToken: string;
  amount: string;
  fromAddress: string;
  originAddress: string;
  slippage?: number;
}

export const fetchGetSwapMetadata = async (
  params: GetSwapMetadataParams
): Promise<SwapResponse | undefined> => {
  const queryClient = getQueryClient();
  const {
    chainId,
    srcToken,
    dstToken,
    amount,
    fromAddress,
    originAddress,
    slippage,
  } = params;

  const url = new URL(`${baseUrl}/metadata`);
  url.searchParams.append("chainId", chainId?.toString());
  url.searchParams.append("srcToken", srcToken);
  url.searchParams.append("dstToken", dstToken);
  url.searchParams.append("amount", amount);
  url.searchParams.append("fromAddress", fromAddress);
  url.searchParams.append("originAddress", originAddress);
  url.searchParams.append("slippage", slippage?.toString() || "");

  if (
    !chainId ||
    !srcToken ||
    !dstToken ||
    !amount ||
    !fromAddress ||
    !originAddress
  ) {
    return undefined;
  }

  return queryClient.fetchQuery({
    queryKey: ["getSwapMetadata", params],
    queryFn: async () => {
      const response = await fetch(url.toString(), {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error fetching swap metadata: ${response.statusText}`);
      }

      const result = await response.json();
      return result as SwapResponse;
    },
    ...queryConfigs.priceQueryConfig,
    retry: 10,
    retryDelay: 1000,
  });
};

export const useSwapMetadata = (params: GetSwapMetadataParams) => {
  return useQuery({
    queryKey: ["hookSwapMetadata", params],
    queryFn: async () => {
      return fetchGetSwapMetadata(params);
    },
    ...queryConfigs.priceQueryConfig,
    enabled:
      !!params.chainId &&
      !!params.srcToken &&
      !!params.dstToken &&
      !!params.amount &&
      !!params.fromAddress &&
      !!params.originAddress &&
      !!params.slippage,
  });
};
