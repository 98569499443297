import React from "react";

export const ProgressBar: React.FC<{
  percentage: number;
}> = ({ percentage }) => {
  // Ensure percentage is not below 0 or above 100
  const validPercentage = Math.min(100, Math.max(0, percentage));

  return (
    <div className="w-full bg-white rounded-lg h-[6px] border-[1px] overflow-hidden">
      <div
        className="bg-primary h-full rounded-lg"
        style={{ width: `${validPercentage}%` }}
      ></div>
    </div>
  );
};
