import { useAccount, useReadContract } from "wagmi";
import { useMemo } from "react";
import { StakingManagerAbi, contractAddresses } from "@meta";
import { formatUnits } from "viem";

export const useGetWeEthRewards = () => {
  const { address } = useAccount();

  const { data: uscEthData, isFetched } = useReadContract({
    abi: StakingManagerAbi,
    functionName: "getUserTotalRewardsForToken",
    address: contractAddresses.StakingManager,
    args: [address!, contractAddresses.USC_ETH_LP, contractAddresses.weETH],
  });

  const { data: chiEthData } = useReadContract({
    abi: StakingManagerAbi,
    functionName: "getUserTotalRewardsForToken",
    address: contractAddresses.StakingManager,
    args: [address!, contractAddresses.CHI_ETH_LP, contractAddresses.weETH],
  });

  // Calculate the sum using useMemo for optimization
  const sum = useMemo(() => {
    const uscEthRewards = uscEthData || 0n;
    const chiEthRewards = chiEthData || 0n;
    return formatUnits(uscEthRewards + chiEthRewards, 18);
  }, [uscEthData, chiEthData]);

  return {
    isFetched,
    sum,
    uscEthData: {
      formattedUscEthData: formatUnits(uscEthData || 0n, 18),
      uscEthData: uscEthData || 0n,
    },
    chiEthData: {
      formattedChiEthData: formatUnits(chiEthData || 0n, 18),
      chiEthData: chiEthData || 0n,
    },
  };
};
