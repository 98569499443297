import {
  FlexCol,
  FlexRow,
  SVGWrapper,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  SingleComponent,
  StandardNotifBody,
  StepsContainer,
  TransactionComplete,
  TransferMoney,
  useNotificationContext,
  useWingsContractWrite,
  Button,
} from "@shared";

/* ----------- */
/*    Icons    */
/* ----------- */
import ChiEth from "@assets/tokens/chiEth.svg";
import UscEth from "@assets/tokens/uscEth.svg";
import WeEth from "@assets/tokens/weEth.svg";
import { contractAddresses, StakingManagerAbi } from "@meta";
import { formatMoney } from "@utils";
import { useStepState } from "../../../../../state/common/useStepState.ui";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { useGetWeEthRewards } from "../../../../../state/rewards/useGetWeEthRewards";
import { useGetTokenPriceCrosschain } from "../../../../../state/price/useGetTokenPriceCrosschain";

export const WeEthTooltipContent = () => {
  const { address: userAddress } = useAccount();
  const { showNotification } = useNotificationContext();

  const rewardToken = contractAddresses.weETH;

  const {
    sum: weEthSum,
    uscEthData: weEthUscEthRewards,
    chiEthData: weEthChiEthRewards,
  } = useGetWeEthRewards();

  const { steps, currentStep, finishSteps } = useStepState(
    ["Approve", "Claim"],
    1
  );

  const { formattedPrice: weEthPrice, isFetched: isWeEthPriceFetched } =
    useGetTokenPriceCrosschain(contractAddresses.weETH);

  const dollarSum = formatMoney(Number(weEthSum) * Number(weEthPrice), {});

  const { writeContractAsync: claimAsync } = useWingsContractWrite();

  const loadingNotif = (currentStep: number, lpToken: Address) => {
    showNotification({
      status: "loading",
      content: (
        <StandardNotifBody
          header={"Claiming in Process"}
          loading
          transferComponent={
            <SingleComponent
              component={
                <TransferMoney
                  icon={<SVGWrapper src={WeEth} width={22} height={22} />}
                  text={formatMoney(
                    lpToken === contractAddresses.CHI_ETH_LP
                      ? weEthChiEthRewards.formattedChiEthData
                      : weEthUscEthRewards.formattedUscEthData,
                    {}
                  )}
                  symbol="weETH"
                />
              }
            />
          }
          stepsComponent={
            <StepsContainer
              stepNames={steps}
              currentStep={currentStep}
              loading
            />
          }
        />
      ),
    });
  };

  const handleClaimAsync = async (lpToken: Address) => {
    loadingNotif(currentStep, lpToken);

    if (!userAddress) return;

    await claimAsync(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "claimRewardsForToken",
        args: [lpToken, rewardToken, userAddress],
      },
      {
        onSuccess: (txHash?: Address) => {
          finishSteps();
          showNotification({
            status: "success",
            txHash,
            content: (
              <StandardNotifBody
                headerComponent={<TransactionComplete />}
                transferComponent={
                  <SingleComponent
                    component={
                      <TransferMoney
                        icon={<SVGWrapper src={WeEth} width={22} height={22} />}
                        text={formatMoney(
                          lpToken === contractAddresses.CHI_ETH_LP
                            ? weEthChiEthRewards.formattedChiEthData
                            : weEthUscEthRewards.formattedUscEthData
                        )}
                        symbol="weETH"
                      />
                    }
                  />
                }
              />
            ),
          });
        },
      }
    );
  };
  return (
    <FlexCol className="w-[200px] gap-4 p-3">
      <FlexRow className="gap-2">
        <SVGWrapper src={WeEth} width={20} height={20} />
        <Typography type="body-small-medium">weETH Rewards</Typography>
      </FlexRow>
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Staking
        </Typography>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(weEthChiEthRewards.formattedChiEthData, {})}
            symbol="weETH"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            onClick={() => handleClaimAsync(contractAddresses.CHI_ETH_LP)}
            disabled={Number(weEthChiEthRewards.formattedChiEthData) == 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={ChiEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            CHI/ETH LP
          </Typography>
        </FlexRow>
        <FlexRow className="justify-between">
          <DisplayTokenAmount
            viewValue={formatMoney(weEthUscEthRewards.formattedUscEthData, {})}
            symbol="weETH"
            className="text-body-medium-medium"
          />
          <Button
            className="bg-primary text-white text-caption-medium px-[6px] py-1 rounded-md h-6"
            onClick={() => handleClaimAsync(contractAddresses.USC_ETH_LP)}
            disabled={Number(weEthUscEthRewards.formattedUscEthData) == 0}
          >
            Claim
          </Button>
        </FlexRow>
        <FlexRow className="gap-2">
          <SVGWrapper src={UscEth} width={30} height={18} />
          <Typography type="caption-regular" className="text-neutral-black-50">
            USC/ETH LP
          </Typography>
        </FlexRow>
      </FlexCol>

      <FlexCol className="w-full h-[1px] bg-neutral-black-30" />
      <FlexCol className="gap-2">
        <Typography type="caption-regular" className="text-neutral-black-50">
          Total rewards
        </Typography>
        <DisplayMoney
          viewValue={dollarSum}
          isFetched={isWeEthPriceFetched}
          className="text-body-medium-medium"
        />
      </FlexCol>
    </FlexCol>
  );
};
