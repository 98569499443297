import { useForm } from "react-hook-form";
import { contractAddresses, StakingManagerAbi } from "@meta";
import {
  useWingsContractWrite,
  MyFormProvider,
  Typography,
  FlexCol,
  Button,
  useNotificationContext,
} from "@shared";

export const StartStakingForm: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: startStaking, isPending } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await startStaking(
      {
        abi: StakingManagerAbi,
        address: contractAddresses.StakingManager,
        functionName: "startStaking",
        args: [data.address],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Staking started successfully</Typography>,
          });
        },
      }
    );
  };

  return (
    <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography>Start Staking</Typography>
      <FlexCol className="gap-3">
        <input
          {...methods.register("address")}
          placeholder="Address of token for staking"
          className="input input-bordered w-full"
        />
        <Button type="submit" loading={isPending}>
          Start Staking
        </Button>
      </FlexCol>
    </MyFormProvider>
  );
};
