import { useForm } from "react-hook-form";
import { ReserveHolderAbi, contractAddresses } from "@meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "@shared";

export const SetRebalancer: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: setRebalancer, isPending: isSettingRebalancer } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await setRebalancer(
      {
        abi: ReserveHolderAbi,
        address: contractAddresses.ReserveHolder,
        functionName: "setRebalancer",
        args: [data.rebalancerAddress, false],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Rebalancer set successfully</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>Reserve Holder / setRebalancer /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("rebalancerAddress")}
              placeholder="Rebalancer Address"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isSettingRebalancer}>
              Set Rebalancer
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
