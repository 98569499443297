import { formatUnits } from "viem";
import { contractAddresses, DataProviderAbi } from "@meta";
import { formatUnitsToMoney } from "@utils";
import { useReadContract } from "wagmi";

/**s
 * Use this custom hook to get the price from the PriceFeedAggregator.
 *
 * @param tokenAddress - The contract address to query for the price.
 * @returns An object containing price details or undefined if data is not available.
 */

export const useGetLPPriceByAddress = (
  tokenAddress?: "CHI_ETH_LP" | "USC_ETH_LP",
  blockNumber?: bigint
) => {
  const { data, isFetched, refetch } = useReadContract({
    abi: DataProviderAbi,
    address: contractAddresses.DataProvider,
    functionName: "getLPTokenPrice",
    args: [
      contractAddresses[tokenAddress!],
      contractAddresses.PriceFeedAggregator,
    ],
    blockNumber: blockNumber,
    query: {
      enabled: tokenAddress !== undefined,
    }
  });

  // Early return if data is undefined
  if (data === undefined) {
    return {
      priceInDollars: undefined,
      originalPrice: undefined,
      formattedPrice: undefined,
      decimals: undefined,
      isFetched,
      refetch,
    };
  }

  // Extract originalPrice and decimals from the data
  const originalPrice = data;
  // const decimals = (data as any)[1];
  const decimals = 8;

  // Calculate price in dollars
  const priceInDollars = formatUnitsToMoney(originalPrice, { decimals });

  // Format original price
  const formattedPrice = parseFloat(formatUnits(originalPrice, decimals));

  return {
    priceInDollars,
    originalPrice,
    formattedPrice,
    decimals,
    isFetched,
    refetch,
  };
};
