import { useDebounce } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { Address, formatUnits } from "viem";
import { useAccount } from "wagmi";
import { useNotificationContext } from "../../../../../../../../shared";
import { calculateUscValueAcross } from "./across/calculateUscValueAcross";
import { calculateFirstInputValueZapin } from "./zapin/calculateFirstInputValueZapin";
import {
  QuoteResponse,
  useGetQuote,
} from "../../../../../../../state/across/getQuote";
import { fetchGetQuote as fetchGetQuoteZapin } from "../../../../../../../state/zap/getQuote";
import { useChainIdContext } from "../../../../../contexts/ChainIdContext";
import { useGetTokenPriceCrosschain } from "../../../../../../../state/price/useGetTokenPriceCrosschain";
import { contractAddresses } from "../../../../../../../../meta";

export const useOnFirstInputFieldChangeUnified = (
  selectedAsset: Address | undefined,
  selectedTokenPrice: any,
  mintBurnFee: bigint | undefined,
  setIsTypingIn: (value?: "eth" | "usc" | undefined) => void,
  isTypingIn: string | undefined,
  setQuoteRes: (value?: QuoteResponse) => void,
  setUscValue: (value: string) => void,
  firstInputValue: string,
  setFirstInputValue: (value: string) => void,
  isZappin: boolean,
  isBridging: boolean,
  setIsQuoteFetching?: (value: boolean) => void
) => {
  const { showNotification } = useNotificationContext();
  const { chainId: walletChain } = useAccount();
  const { chainId: accrossChainId } = useChainIdContext();
  const firstInputValueDebounced = useDebounce(firstInputValue, 500);
  const { fetchGetQuote: fetchGetQuoteAcross } = useGetQuote();
  const { formattedPrice: wethTokenPrice } = useGetTokenPriceCrosschain(
    contractAddresses.WETH,
    accrossChainId
  );

  const onEthValueChange = (value: string) => {
    setFirstInputValue(value);
    setIsTypingIn("eth");
  };

  useEffect(() => {
    const handleValueChange = async () => {
      if (isTypingIn === "eth" && firstInputValueDebounced) {
        try {
          setIsQuoteFetching?.(true);

          let uscValue: string = "";

          if (isBridging && isZappin) {
            const result = await calculateUscValueAcross(
              selectedAsset,
              selectedTokenPrice,
              mintBurnFee,
              firstInputValueDebounced,
              accrossChainId || 1,
              fetchGetQuoteAcross
            );
            setQuoteRes(result.quoteRes);
            const zapinResult = await calculateFirstInputValueZapin(
              selectedAsset,
              wethTokenPrice,
              mintBurnFee,
              result.selectedTokenValue?.toString() || "0",
              fetchGetQuoteZapin
            );
            uscValue = zapinResult.uscValue;
          } else if (isBridging) {
            const result = await calculateUscValueAcross(
              selectedAsset,
              selectedTokenPrice,
              mintBurnFee,
              firstInputValueDebounced,
              accrossChainId || 1,
              fetchGetQuoteAcross
            );
            uscValue = result.uscValue;
            setQuoteRes(result.quoteRes);
          } else if (isZappin) {
            const result = await calculateFirstInputValueZapin(
              selectedAsset,
              wethTokenPrice,
              mintBurnFee,
              firstInputValueDebounced,
              fetchGetQuoteZapin
            );
            uscValue = result.uscValue;
          } else {
            const mintFeeMultiplier =
              1 - Number(formatUnits(mintBurnFee || 0n, 4));
            uscValue = String(
              Number(firstInputValueDebounced) *
                (selectedTokenPrice || 0) *
                mintFeeMultiplier
            );
          }

          setUscValue(uscValue);
        } catch (error: any) {
          showNotification({
            status: "error",
            content: error.message || "An error occurred",
          });
          setUscValue("");
        } finally {
          setIsQuoteFetching?.(false);
          setIsTypingIn(undefined);
        }
      } else if (!firstInputValueDebounced) {
        setUscValue("");
        setIsTypingIn(undefined);
      }
    };

    handleValueChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokenPrice, mintBurnFee, firstInputValueDebounced]);

  useEffect(() => {
    setFirstInputValue("");
    setUscValue("");
    setIsTypingIn(undefined);
  }, [walletChain, accrossChainId, selectedAsset]);

  return {
    onEthValueChange,
    firstInputValueDebounced,
  };
};
