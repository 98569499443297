import React from "react";
import { FlexRow, FlexCol, Typography, SVGWrapper } from "@shared";
import { Option2 } from "../../pages/home/utils/getOptions2";
import { Address } from "viem";
import { CustomDropdownWithGroups } from "../../../shared/components/form/input/SelectInputField/CustomDropdownWithGroups";

const mapOptions2ToOptions = (option: Option2[]) => {
  return option.map(({ key, value, name, icon }) => {
    return {
      key,
      value,
      renderOption: (
        <FlexRow className="items-center w-full gap-1 pr-1">
          <SVGWrapper src={icon} width={19} height={19} />
          <FlexCol className="items-start text-start">
            <Typography type="caption-regular">{name}</Typography>
          </FlexCol>
        </FlexRow>
      ),
    };
  });
};

export const MintAssetPicker: React.FC<{
  selectedAsset?: Address;
  setSelectedAsset: (tab?: Address) => void;
  options: {
    zapInOptions: Option2[];
    options: Option2[];
  };
}> = ({ selectedAsset, setSelectedAsset, options }) => {
  return (
    <CustomDropdownWithGroups
      groups={
        options.zapInOptions.length > 1
          ? [
              mapOptions2ToOptions(options.options),
              mapOptions2ToOptions(options.zapInOptions),
            ]
          : [mapOptions2ToOptions(options.options)]
      }
      isSmall
      selectedOption={selectedAsset}
      setSelectedOption={(e) => {
        setSelectedAsset(e as Address);
      }}
    />
  );
};
