import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "../../../../meta";
import { DisplayValue, Typography } from "../../../../shared";
import { Address, formatUnits } from "viem";
import React from "react";

export const DisplayReserveTxLimit: React.FC<{
  fn: "reserveBurnTxLimit" | "reserveMintTxLimit";
}> = ({ fn }) => {
  const [reserveAsset, setReserveAsset] = React.useState<Address | undefined>(
    undefined
  );
  const { data: txLimit, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: fn,
    query: {
      enabled: !!reserveAsset,
    },
    args: [reserveAsset!],
  });

  return (
    <div className="flex flex-col gap-2">
      <Typography>
        Reserve {fn === "reserveBurnTxLimit" ? "Burn" : "Mint"} Tx Limit:
      </Typography>
      <DisplayValue
        viewValue={formatUnits(BigInt(txLimit || 0), 18)}
        isLoading={rest.isLoading && !!reserveAsset}
      />
      <input
        type="text"
        className="input input-bordered w-full"
        placeholder="Enter Reserve Asset To Check Tx Limit (address)"
        value={reserveAsset}
        onChange={(e) => setReserveAsset(e.target.value as Address)}
      />
    </div>
  );
};
