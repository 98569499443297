import { FlexCol } from "@shared";
import { UpdateEpochChiEth } from "./UpdateEpochChiEth";
import { UpdateEpochUscEth } from "./UpdateEpochUscEth";
import { UpdateEpochWstEth } from "./UpdateEpochWstEth";



export default function LockingManagerPage() {
  return (
    <FlexCol className="gap-10">
      <div className="w-2/3">
        <UpdateEpochChiEth />
      </div>
      <div className="w-2/3">
        <UpdateEpochUscEth />
      </div>
      <div className="w-2/3">
        <UpdateEpochWstEth />
      </div>
    </FlexCol>
  );
}
