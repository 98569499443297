import { readContractQueryOptions, useQuery } from "wagmi/query";
import { getQueryClient } from "../../../../../contexts/CustomQueryClientProvider";
import { getWagmiConfig } from "../../../../config/wagmi.config";
import { ReserveHolderAbi, contractAddresses } from "../../../../../meta";
import { Address, formatUnits } from "viem";
import { Card, FlexCol, Spinner, Typography } from "../../../../../shared";

const fetchReserveAssets = async (): Promise<readonly Address[]> => {
  const config = getWagmiConfig();
  const queryClient = getQueryClient();

  const result = await queryClient.fetchQuery(
    readContractQueryOptions(config, {
      abi: ReserveHolderAbi,
      address: contractAddresses.ReserveHolder,
      functionName: "getReserveAssets",
    })
  );

  return result;
};

const fetchPercentageForReserveAsset = async (
  reserveAsset: Address
): Promise<bigint | undefined> => {
  const config = getWagmiConfig();
  const queryClient = getQueryClient();

  const result = await queryClient.fetchQuery(
    readContractQueryOptions(config, {
      abi: ReserveHolderAbi,
      address: contractAddresses.ReserveHolder,
      functionName: "percentages",
      args: [reserveAsset],
    })
  );

  return result;
};

type ReserveAssetData = {
  reserveAsset: Address;
  percentage: bigint | undefined;
};

const fetchReserveAssetsData = async (): Promise<ReserveAssetData[]> => {
  const data = await fetchReserveAssets();

  const result = await Promise.all(
    data.map(async (reserveAsset) => {
      const percentage = await fetchPercentageForReserveAsset(reserveAsset);
      return {
        reserveAsset,
        percentage,
      };
    })
  );
  return result;
};

const useFetchReserveAssetsData = () => {
  return useQuery({
    queryKey: ["fetchReserveAssets"],
    queryFn: () => fetchReserveAssetsData(),
  });
};

export const ReserveAssets = () => {
  const { data, ...rest } = useFetchReserveAssetsData();

  return (
    <Card className="w-2/3" hasBorder>
      <FlexCol className="w-full p-4 gap-4">
        <Typography>Reserve assets percentages</Typography>
        {!rest.isFetched ? (
          <Spinner />
        ) : (
          <div className="flex flex-col gap-6">
            {((data as ReserveAssetData[])?.length === 0 ||
              data === undefined) && <Typography>No data</Typography>}
            {(data as ReserveAssetData[])?.map(
              ({ reserveAsset, percentage }) => (
                <FlexCol key={reserveAsset}>
                  <Typography>{reserveAsset}</Typography>
                  <Typography>
                    Percentage: {formatUnits(percentage || 0n, 2)}
                  </Typography>
                </FlexCol>
              )
            )}
          </div>
        )}
      </FlexCol>
    </Card>
  );
};
