import { useQueryClient, useQuery } from "@tanstack/react-query";
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { FlexRow, Typography } from "../../../../../shared";
import { ChartWrapper } from "../../components/ChartWrapper";
import { getAreaChartOptions } from "../../utils/getAreaChartOptions";
import {
  buildQueryKey,
  fetchFromApi,
  CHART_STALE_TIME,
  getDuneDataArray,
  checkAndUpdateData,
} from "../../../../state/dune/utils";
import { DuneData } from "../solvency/TotalSupplyChart";

export interface UscEthUniswapPoolReservesChartData {
  day: string;
  "ETH Reserves": number;
  "USC Reserves": number;
}

const queryId = 3855147;

export const UscEthUniswapPoolReservesChart: React.FC<{
  name: string;
  icon: React.ReactNode;
}> = ({ name, icon }) => {
  const queryClient = useQueryClient();

  const {
    data: initialData,
    isFetched: isInitialFetched,
    isSuccess: isInitialSuccess,
  } = useQuery({
    queryKey: [buildQueryKey(queryId)],
    queryFn: () => fetchFromApi(queryId),
    staleTime: CHART_STALE_TIME,
    retry: true,
  });

  const [chartOptions, setChartOptions] = useState<ApexOptions>({});
  const [chartSeries, setChartSeries] = useState<
    { name: string; data: number[] }[]
  >([]);

  useEffect(() => {
    const processData = (data: DuneData[]) => {
      try {
        const arrayData = getDuneDataArray(data);
        const reversedData = data ? [...arrayData].reverse() : [];

        const categories = reversedData.map((item) => item.day.split(" ")[0]);
        const ethReservesData = reversedData.map(
          (item) => item["ETH Reserves"]
        );
        const uscReservesData = reversedData.map(
          (item) => item["USC Reserves"]
        );

        setChartOptions(
          getAreaChartOptions(categories, "UscEthUniswapPoolReservesChart")
        );

        setChartSeries([
          {
            name: "ETH Reserves",
            data: ethReservesData,
          },
          {
            name: "USC Reserves",
            data: uscReservesData,
          },
        ]);
      } catch (err) {
        console.log("error in total supply");
        console.log(err);
      }
    };
    if (isInitialFetched && initialData && isInitialSuccess) {
      checkAndUpdateData(initialData, queryId, processData, queryClient);
    }
  }, [initialData, isInitialFetched, isInitialSuccess, queryClient]);

  return (
    <div
      className="flex flex-col border-[1px] border-solid border-[#EEE] p-6 gap-3  rounded-2xl
shadow-card"
    >
      <FlexRow className="items-center gap-2">
        {icon}
        <Typography>{name}</Typography>
      </FlexRow>
      <ChartWrapper
        isFetched={isInitialFetched && initialData && chartOptions?.chart?.type}
      >
        <Chart
          options={chartOptions}
          series={chartSeries}
          type={chartOptions.chart?.type}
          height="100%"
          width="100%"
        />
      </ChartWrapper>
    </div>
  );
};
