import { useForm } from "react-hook-form";
import { ReserveHolderAbi, contractAddresses } from "../../../../../meta";
import {
  useNotificationContext,
  useWingsContractWrite,
  Typography,
  Card,
  MyFormProvider,
  FlexCol,
  Button,
} from "../../../../../shared";

export const ClaimRewards: React.FC = () => {
  const { showNotification } = useNotificationContext();
  const methods = useForm();
  const { reset, handleSubmit } = methods;

  const { writeContractAsync: claimRewards, isPending: isClaimingRewards } =
    useWingsContractWrite();

  const onSubmit = async (data: any) => {
    await claimRewards(
      {
        abi: ReserveHolderAbi,
        address: contractAddresses.ReserveHolder,
        functionName: "claimRewards",
        args: [data.receiverAddress],
      },
      {
        onSuccess: () => {
          reset();
          showNotification({
            status: "success",
            content: <Typography>Rewards claimed successfully</Typography>,
          });
        },
      }
    );
  };

  return (
    <Card className="w-2/3" hasBorder>
      <MyFormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <FlexCol className="w-full p-4 gap-4">
          <Typography>Reserve Holder / claimRewards /</Typography>
          <FlexCol className="gap-3">
            <input
              {...methods.register("receiverAddress")}
              placeholder="Receiver Address"
              className="input input-bordered w-full"
            />
            <Button type="submit" loading={isClaimingRewards}>
              Claim Rewards
            </Button>
          </FlexCol>
        </FlexCol>
      </MyFormProvider>
    </Card>
  );
};
