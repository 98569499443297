import React, { createContext, useState, useCallback, ReactNode } from "react";

import { DisplayNotification } from "../../components/notification/DisplayNotificationv2";

type XPositions = "left" | "right" | "center";
type YPositions = "top" | "bottom" | "center";

export type TPositions = `${YPositions}-${XPositions}`;
export type TNotificationStatus =
  | "success"
  | "info"
  | "loading"
  | "error"
  | "warning";

export type TNotificationProps = {
  content: React.ReactNode;
  status?: TNotificationStatus;
  duration?: number;
  icon?: React.ReactNode;
  position?: TPositions;
  txHash?: string;
};

export type NotificationOptions = {
  duration?: number;
  icon?: string;
  position?: TPositions;
};

export interface NotificationInterface {
  success: (
    content: React.ReactNode,
    options?: NotificationOptions
  ) => ReturnType<never>;
  info: (
    content: React.ReactNode,
    options?: NotificationOptions
  ) => ReturnType<never>;
  warning: (
    content: React.ReactNode,
    options?: NotificationOptions
  ) => ReturnType<never>;
  error: (
    content: React.ReactNode,
    options?: NotificationOptions
  ) => ReturnType<never>;
  loading: (
    content: React.ReactNode,
    options?: NotificationOptions
  ) => ReturnType<never>;
  remove: (toastId: string) => void;
}

interface NotificationContextType {
  showNotification: (notification: TNotificationProps) => void;
  closeNotification: () => void;
}

const defaultNotificationSettings: Omit<TNotificationProps, "content"> = {
  status: "success",
  position: "center-center",
};

const defaultContextValue: NotificationContextType = {
  showNotification: () => {},
  closeNotification: () => {},
};

export const NotificationContext =
  createContext<NotificationContextType>(defaultContextValue);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<
    TNotificationProps | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const showNotification = useCallback((notification: TNotificationProps) => {
    setNotification(notification);
    setIsOpen(true);
  }, []);

  const closeNotification = useCallback(() => {
    setNotification(undefined);
    setIsOpen(false);
  }, []);

  return (
    <NotificationContext.Provider
      value={{ showNotification, closeNotification }}
    >
      {children}
      {isOpen && notification && (
        <DisplayNotification
          {...defaultNotificationSettings}
          {...notification}
          setModalOpen={setIsOpen}
        />
      )}
    </NotificationContext.Provider>
  );
};
