import { useReadContract } from "wagmi";
import { ArbitrageAbi, contractAddresses } from "@meta";
import { Displayable } from "@shared";
import { formatUnits } from "viem";

export const useMintBurnFee = (): Displayable<{
  mintBurnFee: bigint | undefined;
  viewValue: string;
}> => {
  const { data: mintBurnFee, ...rest } = useReadContract({
    abi: ArbitrageAbi,
    address: contractAddresses.Arbitrage,
    functionName: "mintBurnFee",
  });

  return {
    data: { mintBurnFee, viewValue: formatUnits(mintBurnFee || 0n, 2) || "" },
    ...rest,
  };
};
