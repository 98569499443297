import { Button, FlexCol, FlexRow, SVGWrapper, Typography } from "@shared";

import { useAccount } from "wagmi";
//icons
import Nethermind from "@assets/logos/nethermind.svg";
import Lock from "@assets/logos/lockLogo.svg";
import Abdk from "@assets/logos/abdk.svg";

export const BlueBanner = () => {
  const { isConnected } = useAccount();

  return (
    <FlexCol className="w-full  py-8 gap-7 bg-primary p-8 rounded-2xl items-center justify-center">
      <FlexCol className="max-w-[148px] h-8 gap-4 px-3 py-2 rounded-md border-[1px] border-neutral-white-80 bg-neutral-white-10 justify-center">
        <Typography
          type="caption-regular"
          className="text-neutral-white whitespace-nowrap"
        >
          Security Contributors
        </Typography>
      </FlexCol>
      <FlexCol className="gap-3 items-center">
        <FlexRow className="gap-6 items-center justify-center">
          <SVGWrapper
            src={Nethermind}
            width={95}
            height={60}
            className="ml-4"
          />
          <SVGWrapper src={Lock} width={23} height={22} />
          <SVGWrapper src={Abdk} width={107} height={42} className="ml-2" />
        </FlexRow>
        <Typography
          type="caption-regular"
          className="text-neutral-white text-center"
        >
          Audited by the world’s leading security firms, security of the Chi
          Protocol is the highest priority
        </Typography>
        {!isConnected ? (
          <FlexCol className="gap-3 items-center">
            <Button variant="white"></Button>
          </FlexCol>
        ) : null}
      </FlexCol>
    </FlexCol>
  );
};
