import { RouterConfig } from "@router";
import { Button, Card, FlexCol, Typography } from "@shared";
import { useNavigate } from "react-router-dom";

interface NoRewardsProps {
  loading: boolean;
}

export const NoRewards: React.FC<NoRewardsProps> = ({ loading }) => {
  const navigate = useNavigate();

  return (
    <Card hasBorder className=" px-6 py-8 gap-10">
      <FlexCol className="gap-4 items-center">
        <FlexCol className="gap-2 items-center">
          <Typography type="body-medium-bold">
            {loading ? "Loading..." : "No rewards yet!"}
          </Typography>
          <Typography type="caption-regular" className="text-neutral-black-60">
            You don’t have staked tokens. Stake now and receive daily rewards.
          </Typography>
        </FlexCol>
        <FlexCol className="w-[120px]">
          <Button
            size="small"
            onClick={() => navigate(RouterConfig.Routes.stake)}
          >
            Stake Now
          </Button>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
