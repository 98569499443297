import React from "react";

import { Typography } from "../text/Typography/Typography";

import { StandardNotifBody } from "./StandardNotifBody";
import { FlexCol } from "../flexCol/FlexCol";

/* ----------- */
/*    Icons    */
/* ----------- */
import Error from "../../../assets/layout/closeSquare.svg";
import { SVGWrapper } from "../svgWrapper/SvgWrapper";

export const DisplayErrorNotif: React.FC<{
  message?: string;
}> = ({ message }) => {
  return (
    <StandardNotifBody
      headerComponent={
        <FlexCol className="gap-4 w-full justify-center items-center">
          <Typography type="chi-protocol-large">Transaction failed</Typography>
          <SVGWrapper src={Error} width={90} height={90} />
          <Typography type="chi-protocol-extra-small-text">
            Error message: {message || "Woops :("}
          </Typography>
        </FlexCol>
      }
    />
  );
};
