const BASE_API_URL = import.meta.env.VITE_CHI_API_ROUTE;

export const fetchFromApi = async (queryId: number) => {
  console.log("fetching from api....");
  const response = await fetch(`${BASE_API_URL}/data?queryId=${queryId}`);
  if (!response.ok) {
    console.error("Failed to fetch data from API", response.statusText);
    throw new Error("Failed to fetch data from API");
  }
  const data = await response.json();
  console.log("Fetched data:", data);
  return data;
};

export const updateDataFromDune = async (queryId: number) => {
  const response = await fetch(`${BASE_API_URL}/dataUpdate?queryId=${queryId}`);
  if (!response.ok) {
    console.error("Failed to update data from Dune", response.statusText);
    throw new Error("Failed to update data from Dune");
  }
  const data = await response.json();
  console.log("Updated data:", data);
  return data;
};

export const getDuneDataArray = (duneData: any) => {
  const reversedData = Array.isArray(duneData)
    ? [...duneData]
    : Array.isArray((duneData as any)?.dune)
      ? [...(duneData as any).dune]
      : [];

  return reversedData;
};

export const checkAndUpdateData = async (
  initialData: any,
  queryId: number,
  processData: (data: any) => void,
  queryClient: any
) => {
  console.log({ initialData });

  if (initialData?.data) {
    processData(initialData.data);
  } else {
    console.warn("Initial data is missing the 'data' field");
  }

  if (initialData?.timestamp) {
    const oneDay = 24 * 60 * 60 * 1000;
    const now = new Date();

    if (now.getTime() - new Date(initialData.timestamp).getTime() > oneDay) {
      try {
        const updatedData = await updateDataFromDune(queryId);
        if (updatedData.data) {
          processData(updatedData.data);
          queryClient.invalidateQueries([buildQueryKey(queryId)]);
        }
      } catch (error) {
        console.error("Error updating data from Dune:", error);
      }
    }
  } else {
    try {
      const updatedData = await updateDataFromDune(queryId);
      if (updatedData.data) {
        processData(updatedData.data);
        queryClient.invalidateQueries([buildQueryKey(queryId)]);
      }
    } catch (error) {
      console.error("Error updating data from Dune:", error);
    }
  }
};

export const buildQueryKey = (queryId: number) => `dune-query-${queryId}`;

export const CHART_STALE_TIME = 1000 * 60 * 60 * 2;
