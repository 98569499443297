import { FAQsComponent } from "../../../../components/info/FAQsComponent";
import InfoItems from "../../../../components/info/InfoItems";
import { InfoTabButton } from "../../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

const infos = [
  "CHI yield boosts come from the protocol's reserves and additional CHI incentives.",
  "Boost durations range from 4 weeks to 4 years, with longer durations resulting in more veCHI.",
  "Boost durations are fixed once initiated and cannot be adjusted.",
  "Rewards are distributed weekly at the end of each epoch (Sunday around 12 AM CET).",
  "CHI rewards are auto-boosted until the position's unlock period ends.",
  "At the end of the boost period, positions can be unboosted, converting to liquid CHI that is ready to be claimed.",
  "veCHI APR is based on rewards from both stETH and CHI.",
];

const faqItems = [
  {
    title: "What is veCHI?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        veCHI, or vote-escrowed CHI, represents CHI that has been locked in a
        boost for a specified period. The longer you boost your CHI, the more
        veCHI you receive. veCHI cannot be transferred and can only be obtained
        by boosting CHI.
      </Typography>
    ),
  },
  {
    title: "What is the minimum and maximum boost duration?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        The minimum boost duration is 4 weeks, while the maximum is 4 years.
        Longer boosts result in greater CHI incentives and stETH rewards, as you
        will participate in more epochs over time.
      </Typography>
    ),
  },
  {
    title: "What rewards do I earn by boosting my CHI?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        By boosting CHI, users earn stETH rewards from the Chi Protocol's
        reserves and additional CHI incentives. stETH rewards can be claimed at
        any time, while CHI incentives are automatically boosted for the
        remaining duration until the underlying position unlocks.
      </Typography>
    ),
  },

  {
    title: "When are rewards distributed?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Rewards for CHI boosters (veCHI holders) are distributed weekly at the
        end of each epoch, typically every Sunday around 12 AM CET.
      </Typography>
    ),
  },
  {
    title: "Where does the yield come from?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        stETH rewards are sourced from the Chi Protocol’s reserves, which
        include stETH and weETH. weETH rewards are first converted to stETH
        before being distributed to CHI boosters. CHI incentives come from the
        initial allocation of CHI designated for ecosystem rewards.
      </Typography>
    ),
  },
  {
    title: "How do I claim CHI when the boost position expires?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Once the boost position expires, the claimable CH, together with the
        rewards, will appear on the Chi Protocol frontend, allowing users to
        manually claim it.
      </Typography>
    ),
  },
  {
    title: "Can I have multiple boost positions simultaneously?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Yes, you can have multiple boost positions at the same time. Each
        position will have its own amount of veCHI, along with its corresponding
        rewards and boost duration.
      </Typography>
    ),
  },
];

export const InfoSection = () => {
  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};
