import { useQuery } from "@tanstack/react-query";
import { getQueryClient } from "../../../contexts/CustomQueryClientProvider";
import { queryConfigs } from "@shared";
const baseUrl = import.meta.env.VITE_ZAPIN_API;

export interface SwapResponse {
  srcToken: {
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    logoURI: string;
  };
  dstToken: {
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    logoURI: string;
  };
  dstAmount: string;
  protocols: any[];
  tx: {
    from: string;
    to: string;
    data: string;
    value: string;
    gasPrice: string;
    gas: number;
  };
}

interface QuoteParams {
  chainId: number;
  srcToken: string;
  dstToken: string;
  amount: string;
}

export const fetchGetQuote = async (
  params: QuoteParams
): Promise<SwapResponse | undefined> => {
  const queryClient = getQueryClient();
  const { chainId, srcToken, dstToken, amount } = params;

  const url = new URL(`${baseUrl}/swap`);
  url.searchParams.append("chainId", chainId.toString());
  url.searchParams.append("srcToken", srcToken);
  url.searchParams.append("dstToken", dstToken);
  url.searchParams.append("amount", amount);

  if (
    !params.chainId ||
    !params.srcToken ||
    !params.dstToken ||
    !params.amount
  ) {
    return undefined;
  }

  return queryClient.fetchQuery({
    queryKey: ["hookQuote", params],
    queryFn: async () => {
      const response = await fetch(url.toString(), {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error fetching quote: ${response.statusText}`);
      }

      const result = await response.json();
      return result as SwapResponse;
    },
    ...queryConfigs.priceQueryConfig,
    retry: 10,
    retryDelay: 1000,
  });
};

export const use1InchQuote = (params: QuoteParams) => {
  return useQuery({
    queryKey: ["getQuote", params],
    queryFn: async () => {
      return fetchGetQuote(params);
    },
    ...queryConfigs.priceQueryConfig,
    enabled:
      !!params.chainId &&
      !!params.srcToken &&
      !!params.dstToken &&
      !!params.amount,
  });
};
