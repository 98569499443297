import { Card, FlexCol, Typography, DisplayTokenAmount } from "@shared";
import { LocalRow } from "./LocalRow";

/* ----------- */
/*    Icons    */
/* ----------- */

import stETHIcon from "@assets/tokens/stEth.svg";
import chiIcon from "@assets/tokens/chi.svg";
import weETHIcon from "@assets/tokens/weEth.svg";
import { StEthTooltipContent } from "./tooltipContent/StEthTooltipContent";
import { WeEthTooltipContent } from "./tooltipContent/WeEthTooltipContent";
import { ChiTooltipContent } from "./tooltipContent/ChiTooltipContent";

import { useGetStakedEthRewards } from "../../../../state/rewards/useGetStakedEthRewards";
import { useGetWeEthRewards } from "../../../../state/rewards/useGetWeEthRewards";
import { useGetChiRewards } from "../../../../state/rewards/useGetChiRewards";
import { formatMoney } from "../../../../../utils";

export const YourRewardsCard = () => {
  //todo: is fetching, isloading, error (rest object) missing
  const {
    sum: stakedEthSum,
  } = useGetStakedEthRewards();

  //todo: is fetching, isloading, error (rest object) missing
  const {
    sum: weEthSum,
    isFetched,
  } = useGetWeEthRewards();

  //todo: is fetching, isloading, error (rest object) missing
  const {
    sum: chiSum,
  } = useGetChiRewards();

  const hasRewards =
    Number(stakedEthSum) > 0 || Number(weEthSum) > 0 || Number(chiSum) > 0;

  if (!isFetched) {
    return (
      <Card hasBorder className="flex-grow min-h-[200px]">
        <FlexCol className="gap-4 items-center mt-12">
          <Typography type="body-medium-bold">Your Rewards</Typography>
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            Loading. . .
          </Typography>
        </FlexCol>
      </Card>
    );
  }
  if (!hasRewards) {
    return (
      <Card hasBorder className="flex-grow min-h-[200px]">
        <FlexCol className=" gap-4 items-center mt-12">
          <Typography type="body-medium-bold">Your Rewards</Typography>
          <Typography
            type="body-small-medium"
            className="text-neutral-black-60"
          >
            You don't have any rewards yet.
          </Typography>
        </FlexCol>
      </Card>
    );
  }

  return (
    <Card hasBorder className="flex-grow min-h-[200px]">
      <FlexCol className="p-4 h-full justify-between">
        <FlexCol className="gap-5">
          <Typography type="body-medium-bold">Your Rewards</Typography>

          <FlexCol className="gap-3">
            {Number(stakedEthSum) > 0 && (
              <LocalRow
                icon={stETHIcon}
                text="stETH"
                tooltip={<StEthTooltipContent />}
              >
                <DisplayTokenAmount
                  viewValue={formatMoney(stakedEthSum, {})}
                  typography="body-small-medium"
                />
              </LocalRow>
            )}

            {Number(weEthSum) > 0 && (
              <LocalRow
                icon={weETHIcon}
                text="weETH"
                tooltip={<WeEthTooltipContent />}
              >
                <DisplayTokenAmount
                  viewValue={formatMoney(weEthSum, {})}
                  typography="body-small-medium"
                />
              </LocalRow>
            )}

            {Number(chiSum) > 0 && (
              <LocalRow
                icon={chiIcon}
                text="CHI"
                tooltip={<ChiTooltipContent />}
              >
                <DisplayTokenAmount
                  viewValue={formatMoney(chiSum, {})}
                  typography="body-small-medium"
                />
              </LocalRow>
            )}
          </FlexCol>
        </FlexCol>
      </FlexCol>
    </Card>
  );
};
