import { useBalance } from "wagmi";
import { contractAddresses } from "../../../../meta";
import { Card, Typography, FlexCol, Divider } from "../../../../shared";
import { displayTokens } from "../../../../utils";
import { useGetContractBalanceByToken } from "../../../state/erc20/useGetContractBalanceByToken";
import { HorizontalRow } from "../components/HorizontalRow/HorizontalRow";

export const RewardControllerBalances = () => {
  const ethData = useBalance({ address: contractAddresses.RewardController });

  const { displayData: rwDisplayValueCHI } = useGetContractBalanceByToken(
    "CHI",
    contractAddresses.RewardController
  );
  const { displayData: rwDisplayValueStETH } = useGetContractBalanceByToken(
    "stETH",
    contractAddresses.RewardController
  );
  const { displayData: rwDisplayValueUSC } = useGetContractBalanceByToken(
    "USC",
    contractAddresses.RewardController
  );
  const { displayData: rwDisplayValueWETH } = useGetContractBalanceByToken(
    "WETH",
    contractAddresses.RewardController
  );

  // const {} = useReadContract({
  //     contractName: "RewardController",
  //     functionName: "balanceOf"
  // })

  return (
    <Card hasBorder className="p-4">
      <Typography>Reward controller/</Typography>
      <br />
      <FlexCol className="gap-2">
        <HorizontalRow
          name="ETH balance"
          value={displayTokens(ethData?.data?.value, {})}
          isFetched={ethData.isFetched}
        />
        <Divider />
        <HorizontalRow name="CHI balance" {...rwDisplayValueCHI} />
        <Divider />
        <HorizontalRow name="stETH balance" {...rwDisplayValueStETH} />
        <Divider />
        <HorizontalRow name="USC balance" {...rwDisplayValueUSC} />
        <Divider />
        <HorizontalRow name="WETH balance" {...rwDisplayValueWETH} />
      </FlexCol>
    </Card>
  );
};
