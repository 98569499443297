import React, { useState } from "react";
import { FlexRow, SVGWrapper, Typography } from "@shared";
import { InfoTooltip } from "../../../../components/tooltip/InfoTooltip";

interface LocalRowProps {
  icon: string;
  text: string;
  children: React.ReactNode;
  isIconBig?: boolean;
  tooltip?: React.ReactNode;
  onClick?: () => void;
  isSelected: boolean;
}

export const LocalRowClickable: React.FC<LocalRowProps> = ({
  icon,
  text,
  children,
  isIconBig,
  tooltip,
  onClick,
  isSelected,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const rowStyle = `items-center justify-between w-full p-1 cursor-pointer rounded-md ${isSelected ? "bg-primary-10 " : isHovered ? "bg-primary-10" : "bg-white"
    }`;

  const width = isIconBig ? 34 : 20;

  return (
    <FlexRow
      className={rowStyle}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexRow className="gap-2 items-center">
        <SVGWrapper src={icon} width={width} />
        <Typography type="body-small-regular">{text}</Typography>
        {tooltip ? <InfoTooltip>{tooltip}</InfoTooltip> : null}
      </FlexRow>
      {children}
    </FlexRow>
  );
};
