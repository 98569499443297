import { Card, FlexCol, FlexRow, Typography } from "@shared";
import { StEthCard } from "./StEthCard";
import { WeETHCard } from "./WeEthCard";
import { WethCard } from "./WethCard";

export const ReserveAssets = () => {
  return (
    <Card color="primary-10">
      {/* Container for the main content with padding and gap */}
      <FlexCol className="w-full p-4 gap-4">
        {/* Title of the section */}
        <Typography type="body-large-bold">Reserve Assets</Typography>

        {/* Container for the three cards displaying asset information */}
        <FlexRow className="w-full gap-2 ">
          {/* First Card: stETH asset display */}
          <Card hasShadowInstead className="w-1/3">
            <StEthCard />
          </Card>

          {/* Second Card: weETH asset display */}
          <Card hasShadowInstead className="w-1/3">
            <WeETHCard />
          </Card>

          {/* Third Card: WETH asset display */}
          <Card hasShadowInstead className="w-1/3">
            <WethCard />
          </Card>
        </FlexRow>
      </FlexCol>
    </Card>
  );
};
