export const ReserveHolderDataProviderAbi = [
  {
    inputs: [],
    name: "WETH",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ReserveHolderV2",
        name: "reserveHolder",
        type: "address",
      },
      {
        internalType: "contract PriceFeedAggregator",
        name: "priceFeedAggregator",
        type: "address",
      },
    ],
    name: "getReserveBreakdown",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "totalValue",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "reserveAsset",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "reserveAssetPrice",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "reserveValue",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "rewardValue",
                type: "uint256",
              },
            ],
            internalType: "struct ReserveHolderDataProvider.ReserveData[]",
            name: "reserves",
            type: "tuple[]",
          },
        ],
        internalType: "struct ReserveHolderDataProvider.ReserveBreakdown",
        name: "breakdown",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;
