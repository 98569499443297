import { FAQsComponent } from "../../../../components/info/FAQsComponent";
import InfoItems from "../../../../components/info/InfoItems";
import { InfoTabButton } from "../../../../components/tab/InfoTabButton";

import {
  Button,
  FlexCol,
  FlexRow,
  TabContent,
  TabProvider,
  Typography,
} from "@shared";

const infos = [
  "CHI Balance Reserved for Vesting represents the underlying amount of CHI that is still locked and awaiting unlocking and vesting.",
  "Boosted CHI rewards are the accumulated rewards from the vesting position. These rewards are auto-boosted and become claimable at the end of the vesting period.",
  "Vesting occurs weekly once the vesting period begins and continues until the vesting period ends.",
  "CHI yield boosts are sourced from the protocol’s reserves and additional CHI incentives.",
  "CHI and stETH rewards are distributed weekly at the end of each epoch (typically Sunday around 12 AM CEST).",
  "Your veCHI balance can be monitored on the Boost page.",
  "veCHI APR is calculated based on rewards from both stETH and CHI.",
];

const faqItems = [
  {
    title: "What is veCHI?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        veCHI, or vote-escrowed CHI, represents CHI that has been locked in a
        boost for a specified period. The longer your CHI is boosted, the more
        veCHI you accumulate. veCHI is non-transferable and can only be obtained
        by boosting CHI.
      </Typography>
    ),
  },
  {
    title: "What is CHI Balance Reserved for Vesting?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        CHI Balance Reserved for Vesting indicates the amount of CHI that
        remains locked and is scheduled to be unlocked and vested over time.
      </Typography>
    ),
  },
  {
    title: "What rewards do I earn on my vesting position?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Vesters earn stETH rewards from the Chi Protocol's reserves, along with
        additional CHI incentives. stETH rewards can be claimed at any time,
        while CHI incentives are auto-boosted for the remaining vesting period
        until the position fully unlocks.
      </Typography>
    ),
  },
  {
    title: "When are rewards distributed?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Rewards for CHI vesters (veCHI holders) are distributed weekly,
        typically every Sunday at around 12 AM CET, marking the end of each
        epoch.
      </Typography>
    ),
  },
  {
    title: "Where does the yield come from?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        stETH rewards come from the Chi Protocol’s reserves, which consist of
        stETH and weETH. Any weETH rewards are first converted to stETH before
        distribution. CHI incentives are funded from the initial CHI allocation
        for ecosystem rewards.
      </Typography>
    ),
  },
  {
    title: "How do I claim CHI when the vest position expires?",
    content: (
      <Typography type="body-small-regular" className="text-neutral-black-60">
        Once the vest position expires, the claimable CHI will be visible on the
        Chi Protocol frontend, allowing users to manually claim their CHI.
      </Typography>
    ),
  },
];

export const InfoSection = () => {
  return (
    <FlexCol className="w-full p-6 gap-6 rounded-2xl border-[1px] border-neutral-black-30">
      <TabProvider defaultTab="key-insight" overrideUrlSlug="infoTab">
        <FlexRow className="w-[245px] h-[56px] p-[4px] justify-between rounded-[16px] bg-neutral-black-10 ">
          <InfoTabButton tab="key-insight">Key Insight</InfoTabButton>
          <InfoTabButton tab="faq">FAQ</InfoTabButton>
        </FlexRow>
        <TabContent tab="key-insight">
          <InfoItems infos={infos} />
        </TabContent>
        <TabContent tab="faq">
          <FlexCol className="gap-6">
            <FAQsComponent items={faqItems} />
            <Button
              onClick={() =>
                window.open(
                  "https://chi-protocol.gitbook.io/chi-docs",
                  "_blank"
                )
              }
              size="small"
              className="w-[170px] h-10  bg-primary text-white rounded-md"
            >
              <Typography type="body-small-bold">Documentation</Typography>
            </Button>
          </FlexCol>
        </TabContent>
      </TabProvider>
    </FlexCol>
  );
};
