import { Card } from "@shared";
import { BoostChiForm } from "./form/BoostChiForm";
import { useState } from "react";
import { BoostStChiForm } from "./form/BoostStChiForm";

export const Main = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>("chi");

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  return (
    <Card hasBorder className="flex flex-col overflow-hidden px-6 py-8 gap-10">
      {selectedItem === "chi" && (
        <BoostChiForm selectedKey={selectedItem} onKeySelect={handleSelect} />
      )}
      {selectedItem === "stCHI" && (
        <BoostStChiForm selectedKey={selectedItem} onKeySelect={handleSelect} />
      )}
    </Card>
  );
};
