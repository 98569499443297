import {
  useState,
  FC,
  ReactElement,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { FlexRow } from "../../../flexRow/FlexRow";

export interface Option {
  key: string;
  value: string | boolean;
  renderOption: ReactElement;
}

interface CustomDropdownOptionProps {
  onClick: () => void;
  children: ReactElement;
}

interface CustomDropdownProps {
  groups: Option[][]; // Array of arrays for groups of options
  isSmall?: boolean;
  className?: string;
  selectedOption?: string | boolean;
  setSelectedOption: (value: string | boolean) => void;
}

const CustomDropdownOption: FC<CustomDropdownOptionProps> = ({
  onClick,
  children,
}) => (
  <div
    onClick={onClick}
    className="flex items-center cursor-pointer p-2 hover:bg-gray-200"
  >
    {children}
  </div>
);

export interface CustomDropdownHandles {
  open: () => void;
  close: () => void;
  scrollIntoView: () => void;
}

export const CustomDropdownWithGroups = forwardRef<
  CustomDropdownHandles,
  CustomDropdownProps
>(
  (
    { groups, selectedOption, setSelectedOption, isSmall, className = "" },
    ref
  ) => {
    const domRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      scrollIntoView: () =>
        domRef.current?.scrollIntoView({ behavior: "smooth" }),
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    return (
      <div ref={domRef} className="relative w-full z-10">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`w-full ${
            isSmall ? "p-2" : "p-3 px-[18px]"
          } border rounded-[10px] ${className}`}
        >
          <FlexRow className="w-full justify-between items-center">
            {/* Render the selected option */}
            {
              groups
                .flat() // Flatten the array to find the selected option across all groups
                .find((x) => x.value === selectedOption)?.renderOption
            }
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 1024 1024"
            >
              <path
                fill="currentColor"
                d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8l316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496"
              />
            </svg>
          </FlexRow>
        </button>
        {isOpen && (
          <div className="absolute top-full min-w-[150px] left-[-65px] w-full border bg-white rounded-lg">
            {groups.map((group, groupIndex) => (
              <div key={groupIndex}>
                {group.map((option) => (
                  <CustomDropdownOption
                    key={option.key}
                    onClick={() => {
                      setSelectedOption(option.value);
                      setIsOpen(false);
                    }}
                  >
                    {option.renderOption}
                  </CustomDropdownOption>
                ))}
                {/* Render a divider between groups */}
                {groupIndex < groups.length - 1 && (
                  <div className="flex w-full flex-col px-3">
                    <div className="divider divider-start text-caption-medium text-neutral-black-60">
                      Zap in
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

CustomDropdownWithGroups.displayName = "CustomDropdownWithGroups";
