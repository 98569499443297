import { decodeEventLog, pad } from "viem";

const v3FundsDepositedAbi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "inputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "outputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "inputAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "outputAmount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "destinationChainId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint32",
        name: "depositId",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "quoteTimestamp",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "fillDeadline",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "exclusivityDeadline",
        type: "uint32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "depositor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "exclusiveRelayer",
        type: "address",
      },
      { indexed: false, internalType: "bytes", name: "message", type: "bytes" },
    ],
    name: "V3FundsDeposited",
    type: "event",
  },
];

export async function WaitForDepositV3(receipt: any, originChainId: number) {
  try {
    const lastLog = receipt.logs?.[receipt.logs.length - 1];

    if (!lastLog) {
      throw new Error("No logs found in transaction receipt");
    }

    const decodedEvent = decodeEventLog({
      abi: v3FundsDepositedAbi,
      data: lastLog.data,
      topics: [
        lastLog.topics[0],
        lastLog.topics[1] ? pad(lastLog.topics[1]) : "",
        lastLog.topics[2] ? pad(lastLog.topics[2]) : "",
        lastLog.topics[3] ? pad(lastLog.topics[3]) : ("" as any),
      ],
    });
    const depositId = (decodedEvent.args as any)?.depositId; // Convert depositId to a number

    const result = await WaitForDepositRecursive(depositId, originChainId, 0); // Start with 0 retries
    return result;
  } catch (error) {
    console.error("Error tracking deposit status:", error);
    throw error;
  }
}

export async function WaitForDepositRecursive(
  depositId: number,
  originChainId: number,
  retryCount: number
): Promise<any> {
  try {
    if (retryCount >= 10) {
      throw new Error("Max retries reached. Deposit status is still pending.");
    }

    await new Promise((resolve) => setTimeout(resolve, 30000));

    const statusUrl = `https://app.across.to/api/deposit/status?originChainId=${originChainId}&depositId=${depositId}`;
    const statusResponse = await fetch(statusUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent":
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36",
      },
    });

    if (!statusResponse.ok) {
      throw new Error(`Failed to fetch status: ${statusResponse.statusText}`);
    }

    const statusData = await statusResponse.json();
    console.log("Deposit Status:", statusData);

    const statusStr = String(statusData.status)?.toLocaleLowerCase();

    if (statusStr === "filled") {
      return statusData;
    } else if (statusStr === "pending") {
      return await WaitForDepositRecursive(
        depositId,
        originChainId,
        retryCount + 1
      );
    } else {
      throw new Error("Deposit failed");
    }
  } catch (error) {
    console.error("Error fetching deposit status:", error);

    if (retryCount < 8) {
      return await WaitForDepositRecursive(
        depositId,
        originChainId,
        retryCount + 1
      );
    } else {
      throw error;
    }
  }
}
