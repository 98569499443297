import { useCallback } from "react";
import { useAccount } from "wagmi";

export interface ButtonPropsOverride {
  textOverride?: string;
  onClickOverride?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
}

export const useButtonPropsOverride = () => {
  const { chainId } = useAccount();

  const getButtonPropsOverride = useCallback(
    (targetChainId?: number) => {
      if (!targetChainId) return undefined;
      if (!chainId) return undefined;

      if (chainId === targetChainId) {
        return undefined;
      } else {
        return {
          // todo fix this
          textOverride: "Switch to correct network",
          onClick: async () => {
            if (window && window.ethereum) {
              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x" + targetChainId.toString(16) }],
              });
            }
          },
          disabled: false,
          type: "button",
        };
      }
    },
    [chainId]
  );

  return { getButtonPropsOverride };
};
