import {
  Card,
  FlexCol,
  FlexRow,
  InputField,
  ModalBody,
  SVGWrapper,
  Typography,
} from "@shared";
import { TokenPicker } from "../../tokenPicker/TokenPicker";

import Scale from "@assets/layout/scales-01.svg";
import { useEffect, useState } from "react";
import { StUSCInfo } from "./StUSCInfo";

/* ----------- */
/*    Icons    */
/* ----------- */

import StUsc from "@assets/tokens/stUsc.svg";
import SFrax from "@assets/tokens/sFrax.svg";
import StUsdt from "@assets/tokens/stUsdt.svg";
import SUSDE from "@assets/tokens/sUSDe.svg";
import SDai from "@assets/tokens/sDai.svg";
import { useGetForeignAPYFromStorage } from "../../../state/defiLamma/useGetForeignAPYFromStorage";
import { TokenInfo } from "./TokenInfo";
import { TokenSummary } from "./TokenSummary";
import { useFetchUscStakingAPR } from "../../../../app/state/usc/useFetchUscStakingAPR";

export const YieldComparison = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState<string | undefined>(
    "sUSDE"
  );

  const { apyData, isLoading } = useGetForeignAPYFromStorage();
  const { aprDSPL, isFetchedAPR } = useFetchUscStakingAPR();

  const aprValue = Number(aprDSPL.viewValue) || 0;

  const [selectedTokenAPY, setSelectedTokenAPY] = useState<
    number | undefined
  >();

  const [inputValue, setInputValue] = useState("");

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleSetSelectedAsset = (asset?: string) => {
    setSelectedToken(asset);
    if (asset === "sUSDE") {
      setSelectedTokenAPY(apyData?.susde);
    } else if (asset === "stUSDT") {
      setSelectedTokenAPY(apyData?.stusdt);
    } else if (asset === "sDAI") {
      setSelectedTokenAPY(apyData?.sdai);
    } else if (asset === "sFRAX") {
      setSelectedTokenAPY(apyData?.sfrax);
    }
  };

  useEffect(() => {
    if (apyData && selectedToken) {
      switch (selectedToken) {
        case "sUSDE":
          setSelectedTokenAPY(apyData?.susde);
          break;
        case "stUSDT":
          setSelectedTokenAPY(apyData?.stusdt);
          break;
        case "sDAI":
          setSelectedTokenAPY(apyData?.sdai);
          break;
        case "sFRAX":
          setSelectedTokenAPY(apyData?.sfrax);
          break;
        default:
          setSelectedTokenAPY(undefined);
      }
    }
  }, [apyData, selectedToken]);

  return (
    <Card hasBorder>
      <FlexCol className="w-full py-4 px-6 gap-4">
        <FlexRow className="items-center gap-2">
          <SVGWrapper src={Scale} width="24px" height="24px" />
          <a onClick={toggleModal}>
            <Typography
              type="body-medium-bold"
              className="text-primary cursor-pointer hover:underline"
            >
              Yield Comparison
            </Typography>
          </a>
        </FlexRow>
        <Typography type="body-small-regular">
          Compare your estimated saving rewards vs other stablecoins based on
          the latest market conditions
        </Typography>
      </FlexCol>
      {isModalOpen && (
        <ModalBody setModalOpen={setModalOpen} size="modal">
          <FlexCol className="gap-6 px-2 pb-1 mt-[-15px]">
            <FlexCol className="gap-2">
              <Typography type="h2" className="text-button-selection-stroke">
                Yield Comparison
              </Typography>
              <Typography type="body-medium-regular">
                Estimate your future stUSC savings rewards with our simple
                calculator tool
              </Typography>
            </FlexCol>
            <FlexRow className="w-full gap-3 flex-col lg:flex-row lg:items-center">
              <Typography
                type="body-small-medium"
                className="whitespace-nowrap"
              >
                Enter your amount
              </Typography>
              <InputField
                leftLabel={
                  <SVGWrapper src={StUsc} width="24px" height="24px" />
                }
                value={""}
                placeholder="Amount"
                fullWidth
                onChange={(e) => setInputValue(e.target.value)}
                maxLength={14}
              />
              <div className=" items-center">
                <Typography
                  type="body-small-medium"
                  className="whitespace-nowrap text-neutral-black-50 text-center"
                >
                  VS
                </Typography>
              </div>
              <TokenPicker
                selectedAsset={selectedToken}
                setSelectedAsset={handleSetSelectedAsset}
                options2={[
                  {
                    key: "sUSDE",
                    name: "sUSDE",
                    icon: SUSDE,
                  },
                  {
                    key: "stUSDT",
                    name: "stUSDT",
                    icon: StUsdt,
                  },
                  {
                    key: "sDAI",
                    name: "sDAI",
                    icon: SDai,
                  },
                  {
                    key: "sFRAX",
                    name: "sFRAX",
                    icon: SFrax,
                  },
                ]}
              />
            </FlexRow>

            <FlexRow className="border bg-neutral-black-30"></FlexRow>

            <StUSCInfo
              inputValue={Number(inputValue) || 0}
              apy={aprValue}
              isFetchedAPR={isFetchedAPR}
              selectedToken={selectedToken || ""}
              selectedTokenAPY={selectedTokenAPY ?? 0}
            />
            {selectedToken === "sUSDE" && (
              <TokenInfo
                icon={SUSDE}
                tokenName="sUSDE"
                apy={apyData?.susde}
                inputValue={Number(inputValue)}
                isLoading={isLoading}
              />
            )}
            {selectedToken === "stUSDT" && (
              <TokenInfo
                icon={StUsdt}
                tokenName="stUSDT"
                apy={apyData?.stusdt}
                inputValue={Number(inputValue)}
                isLoading={isLoading}
              />
            )}
            {selectedToken === "sDAI" && (
              <TokenInfo
                icon={SDai}
                tokenName="sDAI"
                apy={apyData?.sdai}
                inputValue={Number(inputValue)}
                isLoading={isLoading}
              />
            )}
            {selectedToken === "sFRAX" && (
              <TokenInfo
                icon={SFrax}
                tokenName="sFRAX"
                apy={apyData?.sfrax}
                inputValue={Number(inputValue)}
                isLoading={isLoading}
              />
            )}

            <FlexRow className="border bg-neutral-black-60"></FlexRow>
            <TokenSummary
              stUscApy={aprValue}
              selectedTokenAPY={selectedTokenAPY ?? 0}
              selectedToken={selectedToken || ""}
              icon={
                selectedToken === "sUSDE"
                  ? SUSDE
                  : selectedToken === "stUSDT"
                    ? StUsdt
                    : selectedToken === "sDAI"
                      ? SDai
                      : SFrax
              }
            />
          </FlexCol>
        </ModalBody>
      )}
    </Card>
  );
};
