type CustomType =
  | "body-small-bold"
  | "body-large-regular"
  | "body-medium-regular"
  | "body-medium-medium"
  | "body-small-regular"
  | "body-large-medium"
  | "body-large-bold"
  | "body-medium-bold"
  | "chi-protocol-small-text"
  | "caption-regular"
  | "body-small-medium"
  | "caption-medium"
  | "h2"
  | "h3"
  | "h5"
  | "chi-protocol-extra-small-text"
  | "chi-protocol-small-bold"
  | "chi-protocol-large";

export type TypographyType = CustomType;

export type TypographyColor =
  | "primary"
  | "secondary"
  | "disabled"
  | "muted"
  | "highlight"
  | "links"
  | "hover"
  | "light"
  | "dark";

export const tailwindStyles = {
  "body-small-bold": "text-body-small-bold",
  "body-large-regular": "text-body-large-regular",
  "body-medium-regular": "text-body-medium-regular",
  "body-medium-medium": "text-body-medium-medium",
  "body-small-regular": "text-body-small-regular",
  "body-large-medium": "text-body-large-medium",
  "body-large-bold": "text-body-large-bold",
  "body-medium-bold": "text-body-medium-bold",
  "chi-protocol-small-text": "text-chi-protocol-small-text",
  "caption-regular": "text-caption-regular",
  "body-small-medium": "text-body-small-medium",
  "caption-medium": "text-caption-medium",
  h2: "text-h5 sm:text-h2",
  h5: "text-h5",
  h3: "text-h3",
  "chi-protocol-extra-small-text": "text-chi-protocol-extra-small-text",
  "chi-protocol-small-bold": "text-chi-protocol-small-bold",
  "chi-protocol-large": "text-chi-protocol-large",
};

export const ComponentMap = {
  "body-small-bold": "p",
  "body-large-regular": "p",
  "body-medium-regular": "p",
  "body-medium-medium": "p",
  "body-small-regular": "p",
  "body-large-medium": "p",
  "body-large-bold": "p",
  "body-medium-bold": "p",
  "chi-protocol-small-text": "p",
  "caption-regular": "p",
  "body-small-medium": "p",
  "caption-medium": "p",
  h2: "h2",
  h5: "h5",
  h3: "h3",
  "chi-protocol-extra-small-text": "p",
  "chi-protocol-small-bold": "p",
  "chi-protocol-large": "p",
};
