import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAccount } from "wagmi";

// Define types for the context
export interface ChainIdContextType {
  chainId?: number; // Assuming chainId is a number
  setChainId: (chainId?: number) => void;
}

// Create the context
export const ChainIdContext = createContext<ChainIdContextType | undefined>(
  undefined
);

// Define props for the provider component
interface ChainIdProviderProps {
  children: ReactNode;
}

// Provider component
export const ChainIdProvider: React.FC<ChainIdProviderProps> = ({
  children,
}) => {
  const { chainId: walletChainId } = useAccount();
  const [chainId, setChainIdState] = useState<number | undefined>(undefined);

  useEffect(() => {
    setChainIdState(walletChainId);
  }, [walletChainId]);

  // Setter function for chainId
  const setChainId = (newChainId?: number) => {
    setChainIdState(newChainId);
  };

  return (
    <ChainIdContext.Provider value={{ chainId, setChainId }}>
      {children}
    </ChainIdContext.Provider>
  );
};

// Custom hook for using ChainIdContext
export const useChainIdContext = () => {
  const context = useContext(ChainIdContext);
  if (!context) {
    throw new Error("useChainId must be used within a ChainIdProvider");
  }
  return context;
};
