import { BigNumber } from "ethers";

import { generateMessageForMulticallHandler } from "./deposit";
import { useQueryClient } from "@tanstack/react-query";
import { Address } from "viem";
import { contractAddresses } from "../../../meta";
import { useNotificationContext, DisplayErrorNotif } from "@shared";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export interface QuoteResponse {
  totalRelayFee: {
    total: string;
  };
  timestamp: number;
  exclusivityDeadline: number;
  exclusiveRelayer: Address;
  // Add other fields from the response as necessary
}

export interface QuoteParams {
  inputToken: string;
  outputToken: string;
  originChainId: number;
  destinationChainId: number;
  amount: string;
  recipient?: string;
  inputAmount: string;
  isEth?: boolean;
  isAmountConsideredSmall?: boolean;
}

export const getQuote = async ({
  inputToken,
  outputToken,
  originChainId,
  destinationChainId,
  amount,
  recipient,
  inputAmount,
}: QuoteParams): Promise<QuoteResponse> => {
  try {
    const message = await generateMessageForMulticallHandler(
      contractAddresses.Arbitrage,
      BigNumber.from(inputAmount),
      outputToken,
      recipient
    );

    const response = await fetch(
      `https://app.across.to/api/suggested-fees?token=${inputToken}&outputToken=${outputToken}&originChainId=${originChainId}&destinationChainId=${destinationChainId}&amount=${amount}&recipient=${recipient}&message=${message}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data: QuoteResponse = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Across bridge error: Amount too low, please try higher amount"
    );
    throw error;
  }
};

export const useGetQuote = () => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotificationContext();

  const fetchGetQuote = async (params: QuoteParams) => {
    return queryClient.fetchQuery({
      queryKey: ["getQuote", params],
      queryFn: async () => {
        try {
          const result = await getQuote(params);
          return result;
        } catch (error: any) {
          showNotification({
            status: "error",
            content: (
              <DisplayErrorNotif
                message={
                  "Sent amount is too low relative to fees. Try sending more. (more then ~40$ usually work.)"
                }
              />
            ),
          });
          throw error;
        }
      },
    });
  };

  const isLoading =
    queryClient.isFetching({
      queryKey: ["getQuote"],
    }) > 0;

  return { fetchGetQuote, isLoading };
};
