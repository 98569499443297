import {
  FlexCol,
  FlexRow,
  Typography,
  DisplayTokenAmount,
  DisplayMoney,
  DisplayPercentage,
} from "@shared";

//icons
import { useAccount, useReadContract } from "wagmi";
import { WalletBadge } from "../../../../../components/walletBadge/WalletBadge";
import { contractAddresses, WstUSCAbi } from "@meta";
import { useGetWalletBalanceByToken } from "../../../../../state/erc20/useGetWalletBalanceByToken";
import { displayTokens } from "@utils";
import { useFetchUscStakingAPR } from "../../../../../state/usc/useFetchUscStakingAPR";
import { useFetchWstUscPrice } from "../../../../../state/wstUsc/useFetchWstUscPrice";

export const UnwrapDepositPanel = () => {
  /* --------------- */
  /*    Meta data    */
  /* --------------- */
  const { isConnected, address } = useAccount();
  /* ----------- */
  /*    Fetch    */
  /* ----------- */
  const stUscBalance = useGetWalletBalanceByToken(contractAddresses.USCStaking);
  const { aprDSPL, isFetchedAPR } = useFetchUscStakingAPR();

  const { data: wstUscBalance, isFetched: isWstBalanceFetched } =
    useReadContract({
      abi: WstUSCAbi,
      address: contractAddresses.WstUSC,
      functionName: "balanceOf",
      args: [address!],
    });

  const { formattedPrice, isFetched: isWstPriceFetched } =
    useFetchWstUscPrice();

  const wstUscBalanceInDollars = displayTokens(wstUscBalance, {
    formattedPrice,
    hideTokenAmount: true,
    displayInDollars: true,
  });

  return (
    <div>
      {isConnected ? (
        <FlexCol className="w-full gap-5 px-3 py-5 sm:px-6 sm:py-10  bg-primary selection:bg-white selection:text-primary ">
          <FlexRow className="w-full items-center justify-between">
            <FlexCol className="gap-2">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-80"
              >
                Available to Unwrap
              </Typography>
              <DisplayTokenAmount
                viewValue={displayTokens(wstUscBalance, {})}
                isFetched={isWstBalanceFetched}
                symbol="wstUSC"
                symbolPosition="after"
                typography="body-large-bold"
                className="text-white"
              />
              <FlexRow className="gap-2 items-center">
                <DisplayMoney
                  viewValue={wstUscBalanceInDollars}
                  isFetched={isWstPriceFetched}
                  symbol="$"
                  symbolPosition="before"
                  typography="caption-regular"
                  className="text-neutral-white-80"
                />
              </FlexRow>
            </FlexCol>
            <FlexCol className="gap-3 items-end">
              <WalletBadge />
            </FlexCol>
          </FlexRow>
          <FlexRow className="w-full border-[1px] border-neutral-white-20"></FlexRow>
          <FlexRow className="w-full items-center justify-between">
            <FlexCol className="gap-2">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-80"
              >
                stUSC Balance
              </Typography>
              <FlexRow className="gap-2 items-center">
                <DisplayTokenAmount
                  viewValue={stUscBalance.displayData.value}
                  symbol="stUSC"
                  symbolPosition="after"
                  typography="body-large-bold"
                  className="text-white"
                  isFetched={!stUscBalance.isFetching}
                />
              </FlexRow>

              <FlexRow className="gap-2 items-center"></FlexRow>
            </FlexCol>
            <FlexRow className="gap-2 items-center">
              <Typography
                type="body-small-medium"
                className="text-neutral-white-56"
              >
                wstUSC APR
              </Typography>
              <DisplayPercentage
                viewValue={aprDSPL.viewValue}
                isFetched={isFetchedAPR}
                symbolPosition="after"
                className="text-white"
                typography="body-large-bold"
              />
            </FlexRow>
          </FlexRow>
        </FlexCol>
      ) : null}
    </div>
  );
};
